import React, { useState, useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { getChartOptions, SKU_LEVEL_SUMMARY } from '../Decision/data';
import Chart from '../../../../utils/charts/Chart';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const SkuGraphModel = (props) => {
  const { show, type, data } = props;
  const [open, setOpen] = useState(props.show);
  const [selectedSku, setSelectedSku] = useState([]);
  useEffect(() => {
    console.log('final props.data', props);
    let categories = props.data.map((obj) => obj.label);
    let text = 'Forecast Visualisation';
    let yAxisTitle = 'Units';
    let options = getChartOptions({
      ...props,
      categories,
      text,
      yAxisTitle,
      parentLabel: SKU_LEVEL_SUMMARY,
    });

    options.data = [
      {
        name: 'Forecast',
        data: props.data.map((obj) => parseInt(obj.forecast)),
        color: '#ffa502',
        type: 'line',
      },
      {
        name: 'LY',
        data: props.data.map((obj) => parseInt(obj.ly)),
        color: '#acacac',
        type: 'line',
      },
      {
        name: 'Receipt Date',
        data: props.data.map((obj) => parseInt(obj.receipt_date)),
        color: '#fb764a',
        type: 'column',
      },
      {
        name: 'ROQ Units (days of stock)',
        data: props.data.map((obj) => parseInt(obj.roq)),
        color: '#00accb',
        type: 'column',
      },
      {
        name: 'OH Units (days of stock)',
        data: props.data.map((obj) => parseInt(obj.oh_units)),
        color: '#3b85ee',
        type: 'column',
      },
    ];
    setSelectedSku(options);
  }, [props.data]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>SkU Level</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Chart {...selectedSku} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
