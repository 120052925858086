import React, { useState, useEffect, useMemo } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Table } from '../../../../components/Table';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../Decision/TabPanel';
import Button from '@mui/material/Button';
import { fetchHierarchyData } from './action';

import {
  driver_contribution_columns,
  driver_forecast_columns,
  forecast_adjustments_tabs,
  // Forecast_Multiplier_columns,
  scenario_accordion_list,
  // view_hierarchy_columns,
} from './data';
import { getChartOptions } from '../Decision/data';
import Chart from '../../../../utils/charts/Chart';
import { Card, CardContent, CardHeader } from '@mui/material';
import * as css_constants from '../../../../constants/cssConstants';
import { getMultipleModelsData } from '../../services/decisionDashboardService';

export const Scenario = (props) => {
  const [childTableData, setChildTableData] = useState([]);
  const [childStoreTableData, setChildStoreTableData] = useState([]);
  const [showChildSkuTable, setShowChildSkuTable] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [tableData, setTableData] = useState(props.data.forecastAdj);
  const [chartOptions, setChartOptions] = useState(0);
  const [driverForecastData, setDriverForecastData] = useState([
    ...props.data.driver_forecast,
  ]);
  const [allTablesData, setAllTablesData] = useState({
    tb_drivers_contribution: [],
    tb_channel_contribution : []
  })

  const openPopup = () => {
    setShowChildSkuTable((prev) => !prev);
  };
  const forecast_Multiplier_columns = useMemo(() => [
    {
      field: 'drivers',
      headerName: '',
      className:
        css_constants.IMPACT_CSS_CLASS +
        css_constants.IMPACT_TAB_FIELD_CSS_CLASS,
    },
    { field: 'fw_202405', headerName: 'FW-202405' },
    { field: 'fw_202406', headerName: 'FW-202406' },
    { field: 'fw_202407', headerName: 'FW-202407' },
    { field: 'fw_202408', headerName: 'FW-202408' },
    { field: 'fw_202409', headerName: 'FW-202409' },
    { field: 'fw_202410', headerName: 'FW-202410' },
    { field: 'fw_202411', headerName: 'FW-202411' },
    { field: 'fw_202412', headerName: 'FW-202412' },
  ]);
  const view_hierarchy_columns = useMemo(() => [
    {
      field: 'store_sku',
      headerName: "Stores/SKU's",
      editable: false,
      cellRendererFramework: (cell) => (
        <Button
          style={{ textTransform: 'none' }}
          onClick={openPopup}
          autoFocus
          variant='text'
          className={
            css_constants.IMPACT_CSS_CLASS +
            css_constants.IMPACT_BUTTON_CSS_CLASS
          }
        >
          {cell.data.store_sku}
        </Button>
      ),
    },
    { field: 'fw_202405', headerName: 'FW-202405' },
    { field: 'fw_202406', headerName: 'FW-202406' },
    { field: 'fw_202407', headerName: 'FW-202407' },
    { field: 'fw_202408', headerName: 'FW-202408' },
    { field: 'fw_202409', headerName: 'FW-202409' },
    { field: 'fw_202410', headerName: 'FW-202410' },
    { field: 'fw_202411', headerName: 'FW-202411' },
    { field: 'fw_202412', headerName: 'FW-202412' },
  ]);
  const childStoreCol = useMemo(() => {
    return [
      {
        field: 'child_sku',
        headerName: "Child SKU's",
        cellRenderer: 'agGroupCellRenderer',
      },
      { field: 'fw_202405', headerName: 'FW-202405' },
      { field: 'fw_202406', headerName: 'FW-202406' },
      { field: 'fw_202407', headerName: 'FW-202407' },
      { field: 'fw_202408', headerName: 'FW-202408' },
      { field: 'fw_202409', headerName: 'FW-202409' },
      { field: 'fw_202410', headerName: 'FW-202410' },
      { field: 'fw_202411', headerName: 'FW-202411' },
      { field: 'fw_202412', headerName: 'FW-202412' },
    ];
  });
  const childTableColumn = useMemo(() => {
    return [
      { field: 'child_sku', headerName: 'Store ID' },
      { field: 'all_weeks', headerName: 'All Weeks' },
      { field: 'fw_202405', headerName: 'FW-202405' },
      { field: 'fw_202406', headerName: 'FW-202406' },
      { field: 'fw_202407', headerName: 'FW-202407' },
      { field: 'fw_202408', headerName: 'FW-202408' },
      { field: 'fw_202409', headerName: 'FW-202409' },
      { field: 'fw_202410', headerName: 'FW-202410' },
      { field: 'fw_202411', headerName: 'FW-202411' },
      { field: 'fw_202412', headerName: 'FW-202412' },
    ];
  });
  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs: childTableColumn,
        defaultColDef: {
          flex: 1,
        },
        rowData: childStoreTableData,
      },
    };
  }, [tabIndex]);
  useEffect(() => {
    let categories = [
      'Week 1',
      'Week 2',
      'Week 3',
      'Week 4',
      'Week 5',
      'Week 6',
      'Week 7',
      'Week 8',
      'Week 9',
      'Week 10',
      'Week 11',
      'Week 12',
    ];
    let text = 'Forecast Visualisation';
    let yAxisTitle = 'Sales Units';
    const options = {
      chartType: 'linechart',
      yaxis: {
        title: 'Sales Units',
      },
      xaxis: { categories },
      showLegend: true,
    };
    getChartOptions({ options });
    console.log(options);
    options.data = [
      // {
      //   name: 'Actual',
      //   data: props.data.sales_units_forecast.map((obj) =>
      //     parseInt(obj.actual)
      //   ),
      //   color: '#ff9705',
      //   type: 'spline',
      // },
      {
        name: 'Forecasted',
        data: props.data.sales_units_forecast.map((obj) =>
          parseInt(obj.forecast)
        ),
        color: '#37b6ff',
        type: 'spline',
      },
      // {
      //   name: 'Adj. Forecast',
      //   data: props.data.sales_units_forecast.map((obj) =>
      //     parseInt(obj.adj_forecast)
      //   ),
      //   color: '#ac40e2',
      //   type: 'spline',
      // },
      {
        name: 'LY',
        data: props.data.sales_units_forecast.map((obj) => parseInt(obj.ly)),
        color: '#a5a5a5',
        type: 'spline',
      },
    ];
    console.log(options);
    setChartOptions(options);
  }, [props.data.sales_units_forecast]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };
  const handleTabChange = (_, newValue) => {
    setTabIndex(newValue);
  };
  const renderTabContent = (tabDetails) => {
    const { label, value } = tabDetails;
    switch (label) {
      case 'Forecast Multiplier':
        return (
          <>
            <Table
              rowData={tableData}
              columnDefs={forecast_Multiplier_columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              floatingFilter={false}
              height={'300px'}
              rowSelection={'multiple'}
              columnFit={'sizeColumnsToFit'}
              tableCssStyle='sizeColumnsToFit'
              className={
                css_constants.IMPACT_CSS_CLASS +
                css_constants.IMPACT_TABLE_CLASS
              }
            />
          </>
        );
        break;
      case 'View/Edit Hierachy Forecast':
        return (
          <>
            <Table
              rowData={props.data.view_hierarchy}
              columnDefs={view_hierarchy_columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              floatingFilter={false}
              height={'300px'}
              rowSelection={'multiple'}
              columnFit={'sizeColumnsToFit'}
              tableCssStyle='sizeColumnsToFit'
              className={
                css_constants.IMPACT_CSS_CLASS +
                css_constants.IMPACT_TABLE_CLASS
              }
            />
            {showChildSkuTable && (
              <>
                <Table
                  rowData={childTableData}
                  columnDefs={childStoreCol}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  floatingFilter={false}
                  height={'auto'}
                  rowSelection={'multiple'}
                  columnFit={'sizeColumnsToFit'}
                  tableCssStyle='sizeColumnsToFit'
                  className={
                    css_constants.IMPACT_CSS_CLASS +
                    css_constants.IMPACT_TABLE_CLASS
                  }
                  domLayout='autoHeight'
                  detailCellRendererParams={detailCellRendererParams}
                />
              </>
            )}
          </>
        );
        break;
      default:
        return <> </>;
        break;
    }
  };

  const renderAccodianChild = (accordObj) => {
    const { label, id } = accordObj;
    switch (id) {
      case 'Driver Forecast':
        return (
          <>
            <Table
              rowData={driverForecastData}
              columnDefs={driver_forecast_columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              floatingFilter={false}
              height={'200px'}
              rowSelection={'multiple'}
              columnFit={'sizeColumnsToFit'}
              tableCssStyle='sizeColumnsToFit'
              className={
                css_constants.IMPACT_CSS_CLASS +
                css_constants.IMPACT_TABLE_CLASS
              }
            />
          </>
        );
        break;
        case 'Drivers Contribution':
          return (
            <>
              <Table
                rowData={allTablesData.tb_drivers_contribution}
                columnDefs={driver_contribution_columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                floatingFilter={false}
                height={'200px'}
                rowSelection={'multiple'}
                columnFit={'sizeColumnsToFit'}
                tableCssStyle='sizeColumnsToFit'
                className={
                  css_constants.IMPACT_CSS_CLASS +
                  css_constants.IMPACT_TABLE_CLASS
                }
              />
            </>
          );
          break;
          case 'Channel Contribution':
            return (
              <>
                <Table
                  rowData={allTablesData.tb_channel_contribution}
                  columnDefs={driver_contribution_columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  floatingFilter={false}
                  height={'200px'}
                  rowSelection={'multiple'}
                  columnFit={'sizeColumnsToFit'}
                  tableCssStyle='sizeColumnsToFit'
                  className={
                    css_constants.IMPACT_CSS_CLASS +
                    css_constants.IMPACT_TABLE_CLASS
                  }
                />
              </>
            );
            break;
      case 'Forecast Adjustments':
        return (
          <>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label='basic tabs example'
            >
              {forecast_adjustments_tabs.map((obj, index) => (
                <Tab
                  label={obj.label}
                  {...a11yProps(0)}
                  style={{
                    textTransform: 'none',
                  }}
                />
              ))}
            </Tabs>
            {forecast_adjustments_tabs.map((tabObj, index) => (
              <>
                <TabPanel value={tabIndex} index={index}>
                  {renderTabContent(tabObj)}
                </TabPanel>
              </>
            ))}
          </>
        );
        break;

      default:
        break;
    }
  };

  const getData = async (table, name) => {
    const data = await fetchHierarchyData(table);
    if (data && name === 'child_sku') setChildTableData(data);
    if (data && name === 'child_store_sku') setChildStoreTableData(data);
  };
  useEffect(async() => {
    getData('tb_child_sku_store', 'child_sku');
    getData('tb_child_sku_child_store', 'child_store_sku');
    const result = await getMultipleModelsData({
      model_names: ["tb_drivers_contribution","tb_channel_contribution"]
    })
    setAllTablesData({
      ...result
    })

  }, []);

  return (
    <div>
      {scenario_accordion_list.map((obj) => (
        <>
          <Accordion
            // expanded={expanded === obj.label}
            onChange={handleChange(obj.label)}
            style={{
              borderLeft: '2px solid rgb(0, 85, 175)',
            }}
            className={
              css_constants.IMPACT_CSS_CLASS +
              css_constants.IMPACT_ACCORDION_CLASS
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
              className={
                css_constants.IMPACT_CSS_CLASS +
                css_constants.IMPACT_ACCORDION_SUMMARY_CLASS
              }
            >
              <Typography
                sx={{ width: '33%', flexShrink: 0 }}
                className={
                  css_constants.IMPACT_CSS_CLASS +
                  css_constants.IMPACT_TYPOGRAPHY_CLASS
                }
              >
                {obj.label}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              className={
                css_constants.IMPACT_CSS_CLASS +
                css_constants.IMPACT_ACCORDIONDETAILS_CLASS
              }
            >
              {renderAccodianChild(obj)}
            </AccordionDetails>
          </Accordion>
          <div className='spacer'></div>
        </>
      ))}

      <div className='spacer'> </div>
      <Card
        className={
          css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_CARD_CSS_CLASS
        }
      >
        <CardHeader
          title='Visualization'
          className={
            css_constants.IMPACT_CSS_CLASS +
            css_constants.IMPACT_CARDHEADER_CLASS
          }
        />
        <CardContent
          className={
            css_constants.IMPACT_CSS_CLASS +
            css_constants.IMPACT_CARDCONTENT_CLASS
          }
        >
          <Chart
            {...chartOptions}
            className={
              css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_CHART_CLASS
            }
          />
        </CardContent>
      </Card>
    </div>
  );
};
