import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../components/CustomButton';
import Table from '../../../../components/Table/Table';
import { makeStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';
import { fetchHierarchyData } from './action';
import CustomCard from '../../../../components/CustomCard';
import {
  order_columns,
  allocation_columns,
  forecasting_columns,
} from './WhatIfData';

const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  headerCard: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
  },
});

const useStyles = makeStyles(styles);

export const SimulationTable = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  let isLoading = props.isLoading;
  const [tableData, setTableData] = React.useState([]);

  const selectColumns = () => {
    if (props.module.label === 'Allocation') {
      return allocation_columns;
    } else if (props.module.label === 'Forecasting') {
      return forecasting_columns;
    }
    return order_columns;
  };

  const getData = async (label) => {
    isLoading = true;
    const data = await fetchHierarchyData(label);
    if (data.length !== 0) {
      isLoading = false;
      setTableData(data)
    }
  };

  const selectTableName = () => {
    if (props.module.label === 'Allocation') {
      return 'tb_whatif_allocation';
    } else if (props.module.label === 'Forecasting') {
      return 'tb_whatif_forecasting'
    }
    return 'tb_whatif_order';
  }

  const selectNavigation = () => {
    if (props.module.label === 'Allocation') {
      return '/inventory-smart/new-product-allocation';
    } else if (props.module.label === 'Forecasting') {
      return '/inventory-smart/ada_visuals'
    }
    return '/inventory-smart/order-repository';
  }

  useEffect(() => {
    const tableName = selectTableName();
    getData(tableName)
  }, []);

  return (
    <>
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress className={classes.loader} />
        </div>
      )}

      {!isLoading && (
        <div style={{ marginTop: '2rem', marginLeft :'1rem' }}>
          <CustomCard cardStyles={classes.headerCard}>
          <Table
            rowData={tableData}
            columnDefs={selectColumns()}
            pageSize={5}
            rowsPerPageOptions={[5]}
            floatingFilter={true}
            height={'350px'}
            rowSelection={'multiple'}
          />
          </CustomCard>
          <div style={{ padding: '0.5rem',  display: 'flex', justifyContent: 'right'  }}>
            <CustomButton
              isPrimary={true}
              variant='contained'
              height={36}
              width={104}
              label='Finalize'
              padding='100'
              onButtonClick={() => navigate(selectNavigation())}
            />
          </div>
        </div>
      )}
    </>
  );
};
