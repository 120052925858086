import { Grid} from '@mui/material';
import React from 'react';
import CustomCard from '../../../../components/CustomCard';
import DataCard from './DataCard';

export const KPI = (props) => {
  const renderView = () => {
    switch (props.parentLabel) {
      case 'Forecast':
        return (
          <>
          <div class="tile_container_main">
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {props.data?.map((cardValue, cardIndex) => (
                  
                  <Grid item xs={12} md={4} key={`data-card-${cardIndex}`}>
                    <div class='card_container'>
                    <CustomCard>
                      <DataCard data={cardValue} />
                    </CustomCard>
                    </div>
                  </Grid>
                
                ))}
              </Grid>
            </Grid>
            </div>
          </>
        );
        break;
      case 'Allocation':
        return (
          <>
           <div class="tile_container_main">
            <Grid item xs={12}>
            
              <Grid container spacing={3}>
                {props.data?.map((cardValue, cardIndex) => (
                  <Grid item xs={6} sm={6} md={3.5} key={`data-card-${cardIndex}`}>
                    <div class="card_container">
                    <CustomCard>
                      <DataCard data={cardValue} type={props.parentLabel}/>
                    </CustomCard>
                    </div>
                  </Grid>
                ))}
              </Grid>
             
            </Grid>
            </div>
          </>
        );
        break;
      case 'Replenishment':
        return (
          <>
          <div class="tile_container_main">
            <Grid item xs={12}>
           
              <Grid container spacing={3}>
                {props.data?.map((cardValue, cardIndex) => (
                  <Grid item xs={12} md={2.4} key={`data-card-${cardIndex}`}>
                     <div class="card_container">
                    <CustomCard>
                      <DataCard data={cardValue} type={props.parentLabel} />
                    </CustomCard>
                    </div>
                  </Grid>
                ))}
              </Grid>
         
            </Grid>
            </div>
          </>
        );
        break;
      case 'Order':
        return (
          <>
           <div class="tile_container_main">
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {props.data?.map((cardValue, cardIndex) => (
                  <Grid item xs={12} md={2.4} key={`data-card-${cardIndex}`}>
                    <div class="card_container">
                    <CustomCard>
                      <DataCard data={cardValue} type={props.parentLabel} />
                    </CustomCard>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            </div>
          </>
         
        );
      default:
        break;
    }
  };

  return <>{renderView()}</>;
};
