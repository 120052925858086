import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SelectRenderer from '../../../../components/Select/SelectRenderer';
import { Checkbox } from '@mui/material';
import { useEffect } from 'react';

export const OrderSummaryFilters = () => {
  const dispatch = useDispatch();
  const [orderFilters, setOrderFilters] = useState({
    openToBudget: false,
    containerCapacity: false,
    costTier: false,
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const costTierOptions = [
    {
      value: 'Consolidate future SKU orders',
      label: 'Consolidate future SKU orders',
    },
    {
      value: 'Consolidate multiple SKU orders',
      label: 'Consolidate multiple SKU orders',
    },
    {
      value: 'Consolidate multiple channel/sites orders',
      label: 'Consolidate multiple channel/sites orders',
    },
  ];
  const handleCheckboxChange = (e) => {
    console.log('-------', e);
    const { checked, name, value } = e.target;
    console.log(checked, name, value);
    const updateObj = { ...orderFilters };
    updateObj[`${name}`] = checked;
    setOrderFilters(updateObj);
  };

  useEffect(() => {
    dispatch({
      type: 'CHECKBOX_FILTERS',
      payload: { ...orderFilters, selectedItems },
    });
  }, [orderFilters, selectedItems]);

  return (
    <div>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby='demo-row-radio-buttons-group-label'
          name='row-radio-buttons-group'
        >
          <FormControlLabel
            value='Open To Buy / Budget'
            control={<Checkbox />}
            label='Open To Buy / Budget'
            onChange={handleCheckboxChange}
            name='openToBudget'
          />
          <FormControlLabel
            value='Container Capacity'
            control={<Checkbox />}
            label='Container Capacity'
            onChange={handleCheckboxChange}
            name='containerCapacity'
          />

          <FormControlLabel
            value='Cost Tier'
            control={<Checkbox />}
            label='Cost Tier'
            onChange={handleCheckboxChange}
            name='costTier'
          />
          {orderFilters.costTier ? (
            <div>
              <SelectRenderer
                options={costTierOptions}
                selectedItems={selectedItems}
                isMulti={false}
                width='18rem'
                filterLabel={''}
                updateSelected={(params) =>
                  setSelectedItems(params.selectedItems)
                }
              />
            </div>
          ) : null}
        </RadioGroup>
      </FormControl>
    </div>
  );
};
