import * as css_constants from '../constants/cssConstants';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomButton from '../components/CustomButton';
import FilterAltOutlined from '@mui/icons-material/FilterAltOutlined';

import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DynamicFilters from './DynamicFilters/DynamicFilters.js';
import CustomCard from '../components/CustomCard';
import CustomAccordion from '../components/CustomAccordion';
import FiltersHead from './FiltersHead';
import moment from 'moment';
import LoadingOverlay from '../components/LoadingOverlay';
import { getData } from './DynamicFilters/api';
import Filters from '../components/FilterChips';
import { getMultipleModelsData } from '../modules/InventorySmart/services/decisionDashboardService';
import zIndex from '@mui/material/styles/zIndex';

const styles = (theme) => ({
  headerCard: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
});

const useStyles = makeStyles(styles);
const FiltersList = (props) => {
  const { openLoader, closeLoader, screenName } = props;
  const classes = useStyles();
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [loader, setLoader] = useState(false);
  const [filterMenu, setFilterMenu] = useState([]);
  const [filterMasterValues, setFilterMasterValues] = useState([]);
  const [dynamicFiltersProps, setDynamicFiltersProps] = useState([]);
  const [dynamicFiltersOptions, setDynamicFiltersOptions] = useState([]);
  const [independentApiCalls, setIndependentApiCalls] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [selectedFilterChips, setSelectedFilterChips] = useState({});
  const [filtersProps, setFiltersProps] = useState({});
  useEffect(async () => {
    openLoader();
    const result = await getMultipleModelsData({
      model_names: ['tb_filter_master_copy', 'Copy of tb_filter_master_values'],
    });
    const tb_filter_master = result.tb_filter_master_copy;
    const tb_filter_master_values = result['Copy of tb_filter_master_values'];
    setFilterMenu(tb_filter_master);
    setFilterMasterValues(tb_filter_master_values);
    closeLoader();
  }, []);

  const reloadFilters = async () => {
    const filterObj = {};
    await filterMenu.forEach((obj) => {
      if (obj.type === 'datetime') {
        filterObj[`${obj.master_category}`] = [
          new Date(moment().subtract(1, 'years')),
          new Date(moment()),
        ];
      } else {
        const newData = filterMasterValues.filter(
          (childObj) =>
            parseInt(childObj.parent_id) === parseInt(obj.categoryid) &&
            childObj.is_default_value === 'Y'
        );
        newData.filter((obj) => {
          obj['label'] = obj.name;
          obj['value'] = obj.name;
        });

        filterObj[`${obj.master_category}`] = newData;
        // filterObj[`${obj.master_category}`] = [];
      }
    });
    setFilterData(filterObj);
    setSelectedFilterChips({});
  };
  useEffect(async () => {
    reloadFilters();
  }, [filterMasterValues]);

  useEffect(() => {
    if (Object.keys(filterData).length > 0) {
      const filtersProps = {
        '40_id': {
          title: 'Date Range & Aggregtion Level',
          filters: [],
        },
        '41_id': {
          title: 'Product',
          filters: [],
        },
        '42_id': {
          title: 'Store',
          filters: [],
        },
        '43_id': {
          title: 'Compare',
          filters: [],
        },
      };
      const filtersPropsList = [];
      const filtersOptionsList = {};
      const independentApis = [];

      filterMenu.forEach((obj) => {
        let isDependentOn = filterMenu.filter((filterObj) => {
          return filterObj.is_dependent_on === obj.categoryid;
        });

        filtersProps[obj.filter_id + '_id']?.filters.push({
          type: obj.type,
          placeholder: `Select ${obj.master_category}`,
          state: filterData[`${obj.master_category}`],
          mappingKey: obj.master_category,
          label: obj.label,
          required: obj.is_required,
          is_dependent_on: obj.is_dependent_on,
          id: obj.categoryid,
          isDependentOn,
        });

        filtersPropsList.push({
          type: obj.type,
          placeholder: `Select ${obj.master_category}`,
          state: filterData[`${obj.master_category}`],
          mappingKey: obj.master_category,
          label: obj.label,
          required: obj.is_required,
          is_dependent_on: obj.is_dependent_on,
          id: obj.categoryid,
          isDependentOn,
        });
        filtersOptionsList[`${obj.master_category}Options`] = [];
        if (obj.is_dependent === 'FALSE') {
          independentApis.push({
            optionsMappingKey: obj.master_category,
            UrlName: obj.categoryid,
          });
        }
      });
      setFiltersProps(filtersProps);
      setDynamicFiltersProps(filtersPropsList);
      setDynamicFiltersOptions(filtersOptionsList);
      setIndependentApiCalls(independentApis);
    }
  }, [filterData]);

  const updateState = (params) => {
    let reqParams = {
      ...filterData,
      ...params,
    };
    setFilterData(reqParams);
    props.updateParentState(reqParams);
  };

  const onReset = () => {
    reloadFilters();
    props.onReset();
  };
  const appliedFiltersAction = async () => {
    props.appliedFilters(filterData);
    let reqObj = {};
    Object.keys(filterData).filter((obj, index) => {
      if (filterData[`${obj}`].length > 0) {
        reqObj[`${obj}`] = {
          label: obj,
          section: filterData[`${obj}`].map((obj) => obj.value),
        };
      }
    });
    setSelectedFilterChips(reqObj);
    setFiltersExpanded(false);
  };

  return (
    <div
      style={{
        height: props.height || 90,
        backgroundColor: 'white',
      }}
    >
      {/* <LoadingOverlay loader={loader}> */}
      <div className='filter_main_container'>
        <div
          className='filter_main_container_sub_container'
          style={{
            position: 'fixed',
            width: '100%',
            zIndex: 9999,
            backgroundColor: 'white',
          }}
        >
          <Grid>
            <CustomCard cardStyles={classes.headerCard}>
              {dynamicFiltersProps.length > 0 ? (
                <>
                  <CustomAccordion
                    summary={
                      <div class='filter_heading_container'>
                        <FiltersHead
                          filtersExpanded={filtersExpanded}
                          setFiltersExpanded={setFiltersExpanded}
                          screenName={screenName}
                          filtersSummary={selectedFilterChips}
                        />
                      </div>
                    }
                    details={
                      <div class='filter_dd_main_container_parent'>
                        {Object.keys(filtersProps)?.map((id) => {
                          const { title, filters } = filtersProps[id];
                          return filters.length > 0 ? (
                            <Accordion
                              className={
                                css_constants.IMPACT_CSS_CLASS +
                                css_constants.IMPACT_ACCORDION_CLASS
                              }
                              style={{
                                borderLeft: '2px solid rgb(0, 85, 175)',
                              }}
                              defaultExpanded={true}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls='panel1bh-content'
                                className={
                                  css_constants.IMPACT_CSS_CLASS +
                                  css_constants.IMPACT_ACCORDION_SUMMARY_CLASS
                                }
                                id='panel1bh-header'
                              >
                                <Typography
                                  sx={{ width: '33%', flexShrink: 0 }}
                                  className={
                                    css_constants.IMPACT_CSS_CLASS +
                                    css_constants.IMPACT_TYPOGRAPHY_CLASS
                                  }
                                >
                                  <h3>{title}</h3>
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails
                                className={
                                  css_constants.IMPACT_CSS_CLASS +
                                  css_constants.IMPACT_ACCORDIONDETAILS_CLASS
                                }
                              >
                                <div className='spacer'></div>
                                <div class='filter_dd_main_container_0'>
                                  <DynamicFilters
                                    filtersList={filters}
                                    options={dynamicFiltersOptions}
                                    hierarchyApiCalls={independentApiCalls}
                                    openLoader={props.openLoader}
                                    closeLoader={props.closeLoader}
                                    updateParentState={updateState}
                                    appliedFilters={appliedFiltersAction}
                                    onReset={onReset}
                                    screenName={screenName}
                                    filterMasterValues={filterMasterValues}
                                    filtersSummary={selectedFilterChips}
                                    filtersExpanded={filtersExpanded}
                                  />
                                  <div className='spacer'></div>
                                  <div className='spacer'></div>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          ) : (
                            ''
                          );
                        })}
                        <div class='btn_bottom_container_filter'>
                          <div className='primary_btn'>
                            <CustomButton
                              className='primaryBtn'
                              isPrimary={true}
                              variant='contained'
                              height={36}
                              width={150}
                              label='Apply Filter'
                              startIcon={<FilterAltOutlined />}
                              onButtonClick={() => appliedFiltersAction()}
                            />
                          </div>
                          <div class='secondary_btn'>
                            <CustomButton
                              isPrimary={false}
                              variant='outlined'
                              height={36}
                              width={104}
                              label='Save'
                              onButtonClick={() => console.log('saved')}
                            />
                          </div>
                        </div>
                      </div>
                    }
                    expanded={filtersExpanded}
                  />
                  {filtersExpanded ? <div className='overlay'></div> : ''}
                </>
              ) : null}
            </CustomCard>
          </Grid>

          {/* </LoadingOverlay> */}
        </div>
      </div>
    </div>
  );
};

export default FiltersList;
