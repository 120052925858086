import React, { useState } from 'react';
import TextFieldWithLabel from '../../../../components/TextField/TextFieldWithLabel';
import * as css_constants from '../../../../constants/cssConstants';
export const EditWeekData = (props) => {
  const [updatedValue, setUpdatedValue] = useState({});
  const handleInputChange = (e) => {
    const { label, value } = e.target;
    console.log('========', props, value);
    const updateObj = {
      currentRow: props.data,
      field: props.colDef.field,
      value: value,
      rowData: props.rowData,
    };
    setUpdatedValue(updateObj);
  };
  const handleOnBlur = () => {
    props.handleCellChange(updatedValue);
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextFieldWithLabel
        type='number'
        // width='7rem'
        onChange={handleInputChange}
        defaultValue={parseInt(props.value)}
        inputProps={{}}
        handleOnBlur={handleOnBlur}
        disabled={props.disabled}
        className={css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TEXTFIELDWITHLABEL_CLASS }
      />
    </div>
  );
};
