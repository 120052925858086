import React,{useState} from 'react'
import {SkuGraphModel} from './SkuGraphModal'
export const SkuLevelGraph = (props) => {
    console.log(props)
    const [show, setShow] = useState(false)
    const [selectedSku, setSelectedSku] = useState([]);
    
    const handleOpen = () => {
        const newData = props.skuSummaryGraph.filter(obj=> obj.skuId === props.data.skuID )
        setSelectedSku(newData)
        setShow(true);
      };
      const handleClose = () => {
        setShow(false);
      }; 
  return (
    <>
    {show ? <SkuGraphModel show={show} handleClose={handleClose} data={selectedSku} /> : null }
        <div>
            <a href="javascript:void(0)" onClick={handleOpen}>{props.data.skuID}</a>
        </div>
    </>
  )
}
