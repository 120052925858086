export const tabListData = {
  product_view: {
    label: 'Product View',
    value: 'product_view',
    tables: [
      {
        title: 'Allocation Summary',
        key: 'tb_rw_al_pv_product_alsummary',
        height: '30vh',
        tableCssStyle: 'sizeColumnsToFit',
        column: [
          {
            field: 'style_color',
            headerName: '# Style Color',
            width: 140,
            pinned: 'left',
          },
          {
            field: 'store',
            headerName: '# Store',
            width: 100,
            pinned: 'left',
          },
          {
            field: 'allocated_stores_style_color',
            headerName: 'Allocated Stores/ StyleColor',
            width: 248,
          },
          { field: 'allocated_qty', headerName: 'Allocated qty', width: 140 },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc_DC01', headerName: 'DC01', width: 120 },
              { field: 'net_dc_DC02', headerName: 'DC02', width: 120 },
              { field: 'net_dc_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          {
            headerName: 'Allocated Qty(by size)',
            children: [
              { field: 'qty_6B', headerName: '6B', width: 80 },
              { field: 'qty_7B', headerName: '7B', width: 80 },
              { field: 'qty_8B', headerName: '8B', width: 80 },
              { field: 'qty_9B', headerName: '9B', width: 80 },
              { field: 'qty_10B', headerName: '10B', width: 80 },
              { field: 'qty_11B', headerName: '11B', width: 80 },
            ],
          },
          {
            headerName: 'Allocation %',
            children: [
              { field: 'per_6B', headerName: '6B', width: 80 },
              { field: 'per_7B', headerName: '7B', width: 80 },
              { field: 'per_8B', headerName: '8B', width: 80 },
              { field: 'per_9B', headerName: '9B', width: 80 },
              { field: 'per_10B', headerName: '10B', width: 80 },
              { field: 'per_11B', headerName: '11B', width: 80 },
            ],
          },
        ],
        data: [],
      },
      {
        title: 'Product Details',
        key: 'tb_rw_al_pv_product_details',
        column: [
          {
            field: 'style_color_id',
            headerName: 'Style Color ID',
            pinned: 'left',
          },
          { field: 'color_id', headerName: 'Color ID', pinned: 'left' },
          { field: 'demand_type', headerName: 'Demand Type' },
          { field: 'store_group', headerName: 'Store Group' },
          { field: 'store', headerName: '# Store' },
          { field: 'channel', headerName: 'Channel' },
          { field: 'department', headerName: 'Department' },
          { field: 'class', headerName: 'Class' },
          { field: 'style_group', headerName: 'Style Group' },
          { field: 'silhouette', headerName: 'Silhouette' },
          { field: 'sub_collect', headerName: 'Sub Collect' },
          { field: 'allocated_stores', headerName: 'Allocated Stores' },
          { field: 'minimum_constraint', headerName: 'Minimum Constraint' },
          {
            headerName: 'Allocated Total Qty.',
            children: [
              { field: 'allocated_qty_DC01', headerName: 'DC01', width: 120 },
              { field: 'allocated_qty_DC02', headerName: 'DC02', width: 120 },
              { field: 'allocated_qty_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc_DC01', headerName: 'DC01', width: 120 },
              { field: 'net_dc_DC02', headerName: 'DC02', width: 120 },
              { field: 'net_dc_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          { field: 'original_aps', headerName: 'Original APS' },
          { field: 'forecasted_aps', headerName: 'Forecasted APS' },
          { field: 'target_wos', headerName: 'Target WOS' },
          { field: 'forecasted_wos', headerName: 'Forecasted WOS' },
          { field: 'dc_oh', headerName: 'DC OH' },
          { field: 'wos', headerName: 'WOS' },
          {
            headerName: 'Allocation by Size',
            children: [
              { field: 'allocation_size_6b', headerName: '6B', width: 200 },
              { field: 'allocation_size_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
          {
            headerName: 'Size - DC OH',
            children: [
              { field: 'size_6b', headerName: '6B', width: 200 },
              { field: 'size_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'size_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'size_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'size_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'size_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
          {
            headerName: 'Size - WOS',
            children: [
              { field: 'size_wos_6b', headerName: '6B', width: 200 },
              { field: 'size_wos_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_rw_al_pv_stores',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color Id',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'target_allocation_qty',
                headerName: 'Target Allocation Qty.',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_rw_al_pv_store_group',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_rw_al_pv_store_grade',
            column: [
              {
                headerName: 'Store Grade Information',
                pinned: 'left',
                width: 200,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# style colors' },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  store_view: {
    label: 'Store View',
    value: 'store_view',
    tables: [
      {
        title: 'Allocation Summary',
        isRadio: false,
        key: 'tb_rw_al_st_alsummary',
        height: '35vh',
        column: [
          {
            headerName: '',
            children: [
              {
                field: 'style_color',
                headerName: '# Style Color',
                pinned: 'left',
                width: 160,
              },

              {
                field: 'store',
                headerName: '# Store',
                pinned: 'left',
                width: 130,
              },
              {
                field: 'style_color_depth_store',
                headerName: 'Style Color Depth/Store',
                pinned: 'left',
                width: 130,
              },
            ],
          },
          {
            headerName: 'Allocated Qty.',
            children: [
              {
                headerName: 'DC01',
                children: [
                  {
                    field: 'alqty_dc_ea_01',
                    headerName: 'Eaches',
                  },
                  {
                    field: 'alqty_dc_pre_01',
                    headerName: 'Prepacks',
                  },
                ],
              },
              {
                headerName: 'DC02',
                children: [
                  {
                    field: 'alqty_dc_ea_02',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'alqty_dc_pre_02',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: 'DC03',
                children: [
                  {
                    field: 'alqty_dc_ea_03',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'alqty_dc_pre_03',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: '',
                children: [
                  {
                    field: 'alqty_total',
                    headerName: 'Total',
                  },
                ],
              },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              {
                headerName: 'DC01',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_01',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_01',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: 'DC02',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_02',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_02',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: 'DC03',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_03',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_03',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: '',
                children: [
                  {
                    field: 'net_dc_total',
                    headerName: 'Total',
                  },
                ],
              },
            ],
          },
          {
            headerName: 'Store Grade - Allocation Qty',
            children: [
              { field: 'qty_aaa', headerName: 'AAA', width: 110 },
              { field: 'qty_aa', headerName: 'AA', width: 110 },
              { field: 'qty_a', headerName: 'A', width: 110 },
              { field: 'qty_b', headerName: 'B', width: 110 },
              { field: 'qty_c', headerName: 'C', width: 110 },
              { field: 'qty_d', headerName: 'D', width: 110 },
            ],
          },
          {
            headerName: 'Store Grade - Allocation %',
            children: [
              { field: 'index_aaa', headerName: 'AAA', width: 110 },
              { field: 'index_aa', headerName: 'AA', width: 110 },
              { field: 'index_a', headerName: 'A', width: 110 },
              { field: 'index_b', headerName: 'B', width: 110 },
              { field: 'index_c', headerName: 'C', width: 110 },
              { field: 'index_d', headerName: 'D', width: 110 },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        showSetAllButton: true,
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_rw_al_st_view_stores',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_rw_al_st_view_store_group',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_rw_al_st_view_store_grade',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Information',
                pinned: 'left',
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# Style Color' },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
        },
      },
      {
        title: 'Style',
        isRadio: false,
        key: 'tb_rw_al_st_view_store_articles',
        height: '40vh',
        column: [
          { field: 'style_color', headerName: '# Style Color', pinned: 'left' },
          { field: 'color', headerName: 'Color', pinned: 'left' },
          { field: 'description', headerName: 'Description', pinned: 'left' },
          { field: 'constraint', headerName: 'Constraint', pinned: 'left'  },
          {
            headerName: 'Allocated Total Qty.',
            children: [
              { field: 'qty_dco1', headerName: 'DC01' },
              { field: 'qty_dco2', headerName: 'DC02' },
              { field: 'qty_dco3', headerName: 'DC03' },
              { field: 'qty_total', headerName: 'Total' },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc01', headerName: 'DC01' },
              { field: 'net_dc02', headerName: 'DC02' },
              { field: 'net_dc03', headerName: 'DC03' },
              { field: 'net_total', headerName: 'Total' },
            ],
          },
          { field: 'original_aps', headerName: 'Original Aps' },
          { field: 'forecasted_aps', headerName: 'Forecasted Aps' },
          { field: 'target_wos', headerName: 'Target WOS' },
          { field: 'forecasted_wos', headerName: 'Forecasted WOS' },
          {
            headerName: 'Size',
            children: [
              { field: 'size_6b', headerName: '6B' },
              { field: 'size_7b', headerName: '7B' },
              { field: 'size_8b', headerName: '8B' },
              { field: 'size_9b', headerName: '9B' },
              { field: 'size_10b', headerName: '10B' },
              { field: 'size_11b', headerName: '11B' },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_rw_al_sv_articles_stores',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_rw_al_sv_articles_store_group',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_rw_al_sv_articles_store_grade',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Grade Information',
                pinned: 'left',
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# style colors' },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  capacity_breach: {
    label: 'Capacity Breach',
    value: 'capacity_breach',
    tables: [
      {
        title: '',
        isRadio: false,
        value: 'capacity_breach',
        height: '450px',
        key: 'tb_allocation_capacity_breach',
        tableCssStyle: 'sizeColumnsToFit',
        defaultExpansion: 1,
        autoGroupColumnDef: { headerName: 'Store Name' },
        column: [
          {
            headerName: 'Store Name',
            field: 'store_name',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: 'Department',
            field: 'department',
            aggFunc: (params) => {
              let final = null;
              params.values.forEach((value, index) => {
                if (index === 0) {
                  final = value;
                }
              });
              return final;
            },
          },
          {
            headerName: 'Style Colors',
            field: 'style_colors',
            aggFunc: 'count',
          },
          {
            headerName: 'Capacity',
            field: 'capacity',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'OH+IT+OO',
            field: 'oh_it_oo',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Forecasted sales',
            field: 'forecasted_sales',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Allocated Units in  current batch',
            field: 'allocated_units_current_batch',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Current Allocation recommendation',
            field: 'current_allocation_recommendation',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Net available capacity',
            field: 'net_available_capacity',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
            // valueFormatter: (params) =>
            //   params?.node?.rowIndex === 0
            //     ? params?.node?.aggData?.oh_it_oo +
            //       params?.node?.aggData?.current_allocation_recommendation -
            //       params?.node?.aggData?.forecasted_sales
            //     : 0,
          },
          {
            headerName: 'Current WOS (OH+IT+OO)',
            field: 'current_wos',
            width: 250,
          },
          {
            headerName: 'Margin/Unit',
            field: 'margin_per_unit',
          },
          {
            headerName: 'Product grade',
            field: 'product_grade',
          },
          {
            headerName: '6B',
            field: '6B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '7B',
            field: '7B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '8B',
            field: '8B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '9B',
            field: '9B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '10B',
            field: '10B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '11B',
            field: '11B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '12B',
            field: '12B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '13B',
            field: '13B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
        ],
        editColumn: [
          {
            headerName: 'Store Name',
            field: 'store_name',
            hide: true,
            rowGroup: true,
          },
          {
            headerName: 'Department',
            field: 'department',
          },
          {
            headerName: 'Style Colors',
            field: 'style_colors',
          },
          {
            headerName: 'Capacity',
            field: 'capacity',
            aggFunc: 'sum',
          },
          {
            headerName: 'OH+IT+OO',
            field: 'oh_it_oo',
            aggFunc: 'sum',
          },
          {
            headerName: 'Forecasted sales',
            field: 'forecasted_sales',
            aggFunc: 'sum',
          },
          {
            headerName: 'Allocated Units in  current batch',
            field: 'allocated_units_current_batch',
            aggFunc: 'sum',
          },
          {
            headerName: 'Current Allocation recommendation',
            field: 'current_allocation_recommendation',
            aggFunc: 'sum',
          },
          {
            headerName: 'Net available capacity',
            field: 'net_available_capacity',
          },
          {
            headerName: 'Current WOS (OH+IT+OO)',
            field: 'current_wos',
            width: 300,
          },
          {
            headerName: 'Margin/Unit',
            field: 'margin_per_unit',
          },
          {
            headerName: 'Product grade',
            field: 'product_grade',
          },
          {
            headerName: 'Small',
            field: 'small',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Medium',
            field: 'medium',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Large',
            field: 'large',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
        ],
        data: [],
      },
    ],
  },
};

export const singleAllocationtabListData = {
  product_view: {
    label: 'Product View',
    value: 'product_view',
    tables: [
      {
        title: 'Allocation Summary',
        key: 'tb_rw_al_pv_product_alsummary_specific',
        height: '30vh',
        tableCssStyle: 'sizeColumnsToFit',
        column: [
          {
            field: 'style_color',
            headerName: '# Style Color',
            width: 140,
            pinned: 'left',
          },
          {
            field: 'store',
            headerName: '# Store',
            width: 100,
            pinned: 'left',
          },
          {
            field: 'allocated_stores_style_color',
            headerName: 'Allocated Stores/ StyleColor',
            width: 248,
          },
          { field: 'allocated_qty', headerName: 'Allocated qty', width: 140 },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc_DC01', headerName: 'DC01', width: 120 },
              { field: 'net_dc_DC02', headerName: 'DC02', width: 120 },
              { field: 'net_dc_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          {
            headerName: 'Allocated Qty(by size)',
            children: [
              { field: 'qty_6B', headerName: '6B', width: 80 },
              { field: 'qty_7B', headerName: '7B', width: 80 },
              { field: 'qty_8B', headerName: '8B', width: 80 },
              { field: 'qty_9B', headerName: '9B', width: 80 },
              { field: 'qty_10B', headerName: '10B', width: 80 },
              { field: 'qty_11B', headerName: '11B', width: 80 },
            ],
          },
          {
            headerName: 'Allocation %',
            children: [
              { field: 'per_6B', headerName: '6B', width: 80 },
              { field: 'per_7B', headerName: '7B', width: 80 },
              { field: 'per_8B', headerName: '8B', width: 80 },
              { field: 'per_9B', headerName: '9B', width: 80 },
              { field: 'per_10B', headerName: '10B', width: 80 },
              { field: 'per_11B', headerName: '11B', width: 80 },
            ],
          },
        ],
        data: [],
      },
      {
        title: 'Product Details',
        key: 'tb_rw_al_pv_product_details_specific',
        column: [
          {
            field: 'style_color_id',
            headerName: 'Style Color ID',
            pinned: 'left',
          },
          { field: 'color_id', headerName: 'Color ID', pinned: 'left' },
          { field: 'demand_type', headerName: 'Demand Type' },
          { field: 'store_group', headerName: 'Store Group' },
          { field: 'store', headerName: '# Store' },
          { field: 'channel', headerName: 'Channel' },
          { field: 'department', headerName: 'Department' },
          { field: 'class', headerName: 'Class' },
          { field: 'style_group', headerName: 'Style Group' },
          { field: 'silhouette', headerName: 'Silhouette' },
          { field: 'sub_collect', headerName: 'Sub Collect' },
          { field: 'allocated_stores', headerName: 'Allocated Stores' },
          { field: 'minimum_constraint', headerName: 'Minimum Constraint' },
          {
            headerName: 'Allocated Total Qty.',
            children: [
              { field: 'allocated_qty_DC01', headerName: 'DC01', width: 120 },
              { field: 'allocated_qty_DC02', headerName: 'DC02', width: 120 },
              { field: 'allocated_qty_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc_DC01', headerName: 'DC01', width: 120 },
              { field: 'net_dc_DC02', headerName: 'DC02', width: 120 },
              { field: 'net_dc_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          { field: 'original_aps', headerName: 'Original APS' },
          { field: 'forecasted_aps', headerName: 'Forecasted APS' },
          { field: 'target_wos', headerName: 'Target WOS' },
          { field: 'forecasted_wos', headerName: 'Forecasted WOS' },
          { field: 'dc_oh', headerName: 'DC OH' },
          { field: 'wos', headerName: 'WOS' },
          {
            headerName: 'Allocation by Size',
            children: [
              { field: 'allocation_size_6b', headerName: '6B', width: 200 },
              { field: 'allocation_size_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
          {
            headerName: 'Size - DC OH',
            children: [
              { field: 'size_6b', headerName: '6B', width: 200 },
              { field: 'size_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'size_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'size_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'size_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'size_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
          {
            headerName: 'Size - WOS',
            children: [
              { field: 'size_wos_6b', headerName: '6B', width: 200 },
              { field: 'size_wos_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_rw_al_pv_stores_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color Id',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'target_allocation_qty',
                headerName: 'Target Allocation Qty.',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_rw_al_pv_store_group_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_rw_al_pv_store_grade_specific',
            column: [
              {
                headerName: 'Store Grade Information',
                pinned: 'left',
                width: 200,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# style colors' },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  store_view: {
    label: 'Store View',
    value: 'store_view',
    tables: [
      {
        title: 'Allocation Summary',
        isRadio: false,
        key: 'tb_rw_al_st_alsummary_specific',
        height: '35vh',
        column: [
          {
            headerName: '',
            children: [
              {
                field: 'style_color',
                headerName: '# Style Color',
                pinned: 'left',
                width: 160,
              },

              {
                field: 'store',
                headerName: '# Store',
                pinned: 'left',
                width: 130,
              },
              {
                field: 'style_color_depth_store',
                headerName: 'Style Color Depth/Store',
                pinned: 'left',
                width: 130,
              },
            ],
          },
          {
            headerName: 'Allocated Qty.',
            children: [
              {
                headerName: 'DC01',
                children: [
                  {
                    field: 'alqty_dc_ea_01',
                    headerName: 'Eaches',
                  },
                  {
                    field: 'alqty_dc_pre_01',
                    headerName: 'Prepacks',
                  },
                ],
              },
              {
                headerName: 'DC02',
                children: [
                  {
                    field: 'alqty_dc_ea_02',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'alqty_dc_pre_02',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: 'DC03',
                children: [
                  {
                    field: 'alqty_dc_ea_03',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'alqty_dc_pre_03',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: '',
                children: [
                  {
                    field: 'alqty_total',
                    headerName: 'Total',
                  },
                ],
              },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              {
                headerName: 'DC01',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_01',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_01',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: 'DC02',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_02',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_02',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: 'DC03',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_03',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_03',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: '',
                children: [
                  {
                    field: 'net_dc_total',
                    headerName: 'Total',
                  },
                ],
              },
            ],
          },
          {
            headerName: 'Store Grade - Allocation Qty',
            children: [
              { field: 'qty_aaa', headerName: 'AAA', width: 110 },
              { field: 'qty_aa', headerName: 'AA', width: 110 },
              { field: 'qty_a', headerName: 'A', width: 110 },
              { field: 'qty_b', headerName: 'B', width: 110 },
              { field: 'qty_c', headerName: 'C', width: 110 },
              { field: 'qty_d', headerName: 'D', width: 110 },
            ],
          },
          {
            headerName: 'Store Grade - Allocation %',
            children: [
              { field: 'index_aaa', headerName: 'AAA', width: 110 },
              { field: 'index_aa', headerName: 'AA', width: 110 },
              { field: 'index_a', headerName: 'A', width: 110 },
              { field: 'index_b', headerName: 'B', width: 110 },
              { field: 'index_c', headerName: 'C', width: 110 },
              { field: 'index_d', headerName: 'D', width: 110 },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        showSetAllButton: true,
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_rw_al_st_view_stores_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_rw_al_st_view_store_group_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_rw_al_st_view_store_grade_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Information',
                pinned: 'left',
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# Style Color' },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
        },
      },
      {
        title: 'Style',
        isRadio: false,
        key: 'tb_rw_al_st_view_store_articles_specific',
        height: '40vh',
        column: [
          { field: 'style_color', headerName: '# Style Color', pinned: 'left' },
          { field: 'color', headerName: 'Color', pinned: 'left' },
          { field: 'description', headerName: 'Description', pinned: 'left' },
          { field: 'constraint', headerName: 'Constraint', pinned: 'left'  },
          {
            headerName: 'Allocated Total Qty.',
            children: [
              { field: 'qty_dco1', headerName: 'DC01' },
              { field: 'qty_dco2', headerName: 'DC02' },
              { field: 'qty_dco3', headerName: 'DC03' },
              { field: 'qty_total', headerName: 'Total' },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc01', headerName: 'DC01' },
              { field: 'net_dc02', headerName: 'DC02' },
              { field: 'net_dc03', headerName: 'DC03' },
              { field: 'net_total', headerName: 'Total' },
            ],
          },
          { field: 'original_aps', headerName: 'Original Aps' },
          { field: 'forecasted_aps', headerName: 'Forecasted Aps' },
          { field: 'target_wos', headerName: 'Target WOS' },
          { field: 'forecasted_wos', headerName: 'Forecasted WOS' },
          {
            headerName: 'Size',
            children: [
              { field: 'size_6b', headerName: '6B' },
              { field: 'size_7b', headerName: '7B' },
              { field: 'size_8b', headerName: '8B' },
              { field: 'size_9b', headerName: '9B' },
              { field: 'size_10b', headerName: '10B' },
              { field: 'size_11b', headerName: '11B' },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_rw_al_sv_articles_stores_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_rw_al_sv_articles_store_group_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_rw_al_sv_articles_store_grade_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Grade Information',
                pinned: 'left',
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# style colors' },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  capacity_breach: {
    label: 'Capacity Breach',
    value: 'capacity_breach',
    tables: [
      {
        title: '',
        isRadio: false,
        value: 'capacity_breach',
        height: '450px',
        key: 'tb_allocation_capacity_breach_specific',
        tableCssStyle: 'sizeColumnsToFit',
        defaultExpansion: 1,
        autoGroupColumnDef: { headerName: 'Store Name' },
        column: [
          {
            headerName: 'Store Name',
            field: 'store_name',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: 'Department',
            field: 'department',
            aggFunc: (params) => {
              let final = null;
              params.values.forEach((value, index) => {
                if (index === 0) {
                  final = value;
                }
              });
              return final;
            },
          },
          {
            headerName: 'Style Colors',
            field: 'style_colors',
            aggFunc: 'count',
          },
          {
            headerName: 'Capacity',
            field: 'capacity',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'OH+IT+OO',
            field: 'oh_it_oo',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Forecasted sales',
            field: 'forecasted_sales',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Allocated Units in  current batch',
            field: 'allocated_units_current_batch',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Current Allocation recommendation',
            field: 'current_allocation_recommendation',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Net available capacity',
            field: 'net_available_capacity',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
            // valueFormatter: (params) =>
            //   params?.node?.rowIndex === 0
            //     ? params?.node?.aggData?.oh_it_oo +
            //       params?.node?.aggData?.current_allocation_recommendation -
            //       params?.node?.aggData?.forecasted_sales
            //     : 0,
          },
          {
            headerName: 'Current WOS (OH+IT+OO)',
            field: 'current_wos',
            width: 250,
          },
          {
            headerName: 'Margin/Unit',
            field: 'margin_per_unit',
          },
          {
            headerName: 'Product grade',
            field: 'product_grade',
          },
          {
            headerName: '6B',
            field: '6B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '7B',
            field: '7B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '8B',
            field: '8B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '9B',
            field: '9B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '10B',
            field: '10B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '11B',
            field: '11B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '12B',
            field: '12B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '13B',
            field: '13B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
        ],
        editColumn: [
          {
            headerName: 'Store Name',
            field: 'store_name',
            hide: true,
            rowGroup: true,
          },
          {
            headerName: 'Department',
            field: 'department',
          },
          {
            headerName: 'Style Colors',
            field: 'style_colors',
          },
          {
            headerName: 'Capacity',
            field: 'capacity',
            aggFunc: 'sum',
          },
          {
            headerName: 'OH+IT+OO',
            field: 'oh_it_oo',
            aggFunc: 'sum',
          },
          {
            headerName: 'Forecasted sales',
            field: 'forecasted_sales',
            aggFunc: 'sum',
          },
          {
            headerName: 'Allocated Units in  current batch',
            field: 'allocated_units_current_batch',
            aggFunc: 'sum',
          },
          {
            headerName: 'Current Allocation recommendation',
            field: 'current_allocation_recommendation',
            aggFunc: 'sum',
          },
          {
            headerName: 'Net available capacity',
            field: 'net_available_capacity',
          },
          {
            headerName: 'Current WOS (OH+IT+OO)',
            field: 'current_wos',
            width: 300,
          },
          {
            headerName: 'Margin/Unit',
            field: 'margin_per_unit',
          },
          {
            headerName: 'Product grade',
            field: 'product_grade',
          },
          {
            headerName: 'Small',
            field: 'small',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Medium',
            field: 'medium',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Large',
            field: 'large',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
        ],
        data: [],
      },
    ],
  },
};

export const tabListJCPennyData = {
  product_view: {
    label: 'Product View',
    value: 'product_view',
    tables: [
      {
        title: 'Allocation Summary',
        key: 'tb_rw_al_pv_product_alsummary',
        height: '30vh',
        tableCssStyle: 'sizeColumnsToFit',
        column: [
          {
            field: 'style_color',
            headerName: '# Style Color',
            width: 140,
            pinned: 'left',
          },
          {
            field: 'store',
            headerName: '# Store',
            width: 100,
            pinned: 'left',
          },
          {
            field: 'allocated_stores_style_color',
            headerName: 'Allocated Stores/ StyleColor',
            width: 248,
          },
          { field: 'allocated_qty', headerName: 'Allocated qty', width: 140 },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc_DC01', headerName: 'DC01', width: 120 },
              { field: 'net_dc_DC02', headerName: 'DC02', width: 120 },
              { field: 'net_dc_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          {
            headerName: 'Allocated Qty(by size)',
            children: [
              { field: 'qty_6B', headerName: '6B', width: 80 },
              { field: 'qty_7B', headerName: '7B', width: 80 },
              { field: 'qty_8B', headerName: '8B', width: 80 },
              { field: 'qty_9B', headerName: '9B', width: 80 },
              { field: 'qty_10B', headerName: '10B', width: 80 },
              { field: 'qty_11B', headerName: '11B', width: 80 },
            ],
          },
          {
            headerName: 'Allocation %',
            children: [
              { field: 'per_6B', headerName: '6B', width: 80 },
              { field: 'per_7B', headerName: '7B', width: 80 },
              { field: 'per_8B', headerName: '8B', width: 80 },
              { field: 'per_9B', headerName: '9B', width: 80 },
              { field: 'per_10B', headerName: '10B', width: 80 },
              { field: 'per_11B', headerName: '11B', width: 80 },
            ],
          },
        ],
        data: [],
      },
      {
        title: 'Product Details',
        key: 'tb_rw_al_pv_product_details',
        column: [
          {
            field: 'style_color_id',
            headerName: 'Style Color ID',
            pinned: 'left',
          },
          { field: 'color_id', headerName: 'Color ID', pinned: 'left' },
          { field: 'demand_type', headerName: 'Demand Type' },
          { field: 'store_group', headerName: 'Store Group' },
          { field: 'store', headerName: '# Store' },
          { field: 'channel', headerName: 'Channel' },
          { field: 'department', headerName: 'Department' },
          { field: 'class', headerName: 'Class' },
          { field: 'style_group', headerName: 'Style Group' },
          { field: 'silhouette', headerName: 'Silhouette' },
          { field: 'sub_collect', headerName: 'Sub Collect' },
          { field: 'allocated_stores', headerName: 'Allocated Stores' },
          { field: 'minimum_constraint', headerName: 'Minimum Constraint' },
          {
            headerName: 'Allocated Total Qty.',
            children: [
              { field: 'allocated_qty_DC01', headerName: 'DC01', width: 120 },
              { field: 'allocated_qty_DC02', headerName: 'DC02', width: 120 },
              { field: 'allocated_qty_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc_DC01', headerName: 'DC01', width: 120 },
              { field: 'net_dc_DC02', headerName: 'DC02', width: 120 },
              { field: 'net_dc_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          { field: 'original_aps', headerName: 'Original APS' },
          { field: 'forecasted_aps', headerName: 'Forecasted APS' },
          { field: 'target_wos', headerName: 'Target WOS' },
          { field: 'forecasted_wos', headerName: 'Forecasted WOS' },
          { field: 'dc_oh', headerName: 'DC OH' },
          { field: 'wos', headerName: 'WOS' },
          {
            headerName: 'Allocation by Size',
            children: [
              { field: 'allocation_size_6b', headerName: '6B', width: 200 },
              { field: 'allocation_size_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
          {
            headerName: 'Size - DC OH',
            children: [
              { field: 'size_6b', headerName: '6B', width: 200 },
              { field: 'size_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'size_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'size_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'size_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'size_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
          {
            headerName: 'Size - WOS',
            children: [
              { field: 'size_wos_6b', headerName: '6B', width: 200 },
              { field: 'size_wos_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_rw_al_pv_stores',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color Id',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'target_allocation_qty',
                headerName: 'Target Allocation Qty.',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                field: 'pack_id',
                headerName: 'Pack ID',
                width: 180,
              },
              {
                headerName: '# of packs allocated',
                children: [
                  {
                    field: 'packs_dc_01',
                    headerName: 'DC01',
                    width: 140,
                  },
                  { field: 'packs_dc_02', headerName: 'DC02', width: 140 },
                  { field: 'packs_dc_03', headerName: 'DC03', width: 140 },
                ],
              },
              {
                headerName: '# of Eaches allocated',
                children: [
                  {
                    field: 'eaches_dc_01',
                    headerName: 'DC01',
                    width: 140,
                  },
                  { field: 'eaches_dc_02', headerName: 'DC02', width: 140 },
                  { field: 'eaches_dc_03', headerName: 'DC03', width: 140 },
                ],
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_rw_al_pv_store_group',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_rw_al_pv_store_grade',
            column: [
              {
                headerName: 'Store Grade Information',
                pinned: 'left',
                width: 200,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# style colors' },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  store_view: {
    label: 'Store View',
    value: 'store_view',
    tables: [
      {
        title: 'Allocation Summary',
        isRadio: false,
        key: 'tb_rw_al_st_alsummary',
        height: '35vh',
        column: [
          {
            headerName: '',
            children: [
              {
                field: 'style_color',
                headerName: '# Style Color',
                pinned: 'left',
                width: 160,
              },

              {
                field: 'store',
                headerName: '# Store',
                pinned: 'left',
                width: 130,
              },
              {
                field: 'style_color_depth_store',
                headerName: 'Style Color Depth/Store',
                pinned: 'left',
                width: 130,
              },
            ],
          },
          {
            headerName: 'Allocated Qty.',
            children: [
              {
                headerName: 'DC01',
                children: [
                  {
                    field: 'alqty_dc_ea_01',
                    headerName: 'Eaches',
                  },
                  {
                    field: 'alqty_dc_pre_01',
                    headerName: 'Prepacks',
                  },
                ],
              },
              {
                headerName: 'DC02',
                children: [
                  {
                    field: 'alqty_dc_ea_02',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'alqty_dc_pre_02',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: 'DC03',
                children: [
                  {
                    field: 'alqty_dc_ea_03',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'alqty_dc_pre_03',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: '',
                children: [
                  {
                    field: 'alqty_total',
                    headerName: 'Total',
                  },
                ],
              },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              {
                headerName: 'DC01',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_01',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_01',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: 'DC02',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_02',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_02',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: 'DC03',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_03',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_03',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: '',
                children: [
                  {
                    field: 'net_dc_total',
                    headerName: 'Total',
                  },
                ],
              },
            ],
          },
          {
            headerName: 'Store Grade - Allocation Qty',
            children: [
              { field: 'qty_aaa', headerName: 'AAA', width: 110 },
              { field: 'qty_aa', headerName: 'AA', width: 110 },
              { field: 'qty_a', headerName: 'A', width: 110 },
              { field: 'qty_b', headerName: 'B', width: 110 },
              { field: 'qty_c', headerName: 'C', width: 110 },
              { field: 'qty_d', headerName: 'D', width: 110 },
            ],
          },
          {
            headerName: 'Store Grade - Allocation %',
            children: [
              { field: 'index_aaa', headerName: 'AAA', width: 110 },
              { field: 'index_aa', headerName: 'AA', width: 110 },
              { field: 'index_a', headerName: 'A', width: 110 },
              { field: 'index_b', headerName: 'B', width: 110 },
              { field: 'index_c', headerName: 'C', width: 110 },
              { field: 'index_d', headerName: 'D', width: 110 },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        showSetAllButton: true,
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_rw_al_st_view_stores',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_rw_al_st_view_store_group',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_rw_al_st_view_store_grade',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Information',
                pinned: 'left',
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# Style Color' },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
        },
      },
      {
        title: 'Style',
        isRadio: false,
        key: 'tb_rw_al_st_view_store_articles',
        height: '40vh',
        column: [
          { field: 'style_color', headerName: '# Style Color', pinned: 'left' },
          { field: 'color', headerName: 'Color', pinned: 'left' },
          { field: 'description', headerName: 'Description', pinned: 'left' },
          { field: 'constraint', headerName: 'Constraint', pinned: 'left'  },
          {
            headerName: 'Allocated Total Qty.',
            children: [
              { field: 'qty_dco1', headerName: 'DC01' },
              { field: 'qty_dco2', headerName: 'DC02' },
              { field: 'qty_dco3', headerName: 'DC03' },
              { field: 'qty_total', headerName: 'Total' },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc01', headerName: 'DC01' },
              { field: 'net_dc02', headerName: 'DC02' },
              { field: 'net_dc03', headerName: 'DC03' },
              { field: 'net_total', headerName: 'Total' },
            ],
          },
          { field: 'original_aps', headerName: 'Original Aps' },
          { field: 'forecasted_aps', headerName: 'Forecasted Aps' },
          { field: 'target_wos', headerName: 'Target WOS' },
          { field: 'forecasted_wos', headerName: 'Forecasted WOS' },
          {
            headerName: 'Size',
            children: [
              { field: 'size_6b', headerName: '6B' },
              { field: 'size_7b', headerName: '7B' },
              { field: 'size_8b', headerName: '8B' },
              { field: 'size_9b', headerName: '9B' },
              { field: 'size_10b', headerName: '10B' },
              { field: 'size_11b', headerName: '11B' },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_rw_al_sv_articles_stores',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_rw_al_sv_articles_store_group',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_rw_al_sv_articles_store_grade',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Grade Information',
                pinned: 'left',
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# style colors' },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  capacity_breach: {
    label: 'Capacity Breach',
    value: 'capacity_breach',
    tables: [
      {
        title: '',
        isRadio: false,
        value: 'capacity_breach',
        height: '450px',
        key: 'tb_allocation_capacity_breach',
        tableCssStyle: 'sizeColumnsToFit',
        defaultExpansion: 1,
        autoGroupColumnDef: { headerName: 'Store Name' },
        column: [
          {
            headerName: 'Store Name',
            field: 'store_name',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: 'Department',
            field: 'department',
            aggFunc: (params) => {
              let final = null;
              params.values.forEach((value, index) => {
                if (index === 0) {
                  final = value;
                }
              });
              return final;
            },
          },
          {
            headerName: 'Style Colors',
            field: 'style_colors',
            aggFunc: 'count',
          },
          {
            headerName: 'Capacity',
            field: 'capacity',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'OH+IT+OO',
            field: 'oh_it_oo',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Forecasted sales',
            field: 'forecasted_sales',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Allocated Units in  current batch',
            field: 'allocated_units_current_batch',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Current Allocation recommendation',
            field: 'current_allocation_recommendation',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Net available capacity',
            field: 'net_available_capacity',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
            // valueFormatter: (params) =>
            //   params?.node?.rowIndex === 0
            //     ? params?.node?.aggData?.oh_it_oo +
            //       params?.node?.aggData?.current_allocation_recommendation -
            //       params?.node?.aggData?.forecasted_sales
            //     : 0,
          },
          {
            headerName: 'Current WOS (OH+IT+OO)',
            field: 'current_wos',
            width: 250,
          },
          {
            headerName: 'Margin/Unit',
            field: 'margin_per_unit',
          },
          {
            headerName: 'Product grade',
            field: 'product_grade',
          },
          {
            headerName: '6B',
            field: '6B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '7B',
            field: '7B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '8B',
            field: '8B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '9B',
            field: '9B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '10B',
            field: '10B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '11B',
            field: '11B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '12B',
            field: '12B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '13B',
            field: '13B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
        ],
        editColumn: [
          {
            headerName: 'Store Name',
            field: 'store_name',
            hide: true,
            rowGroup: true,
          },
          {
            headerName: 'Department',
            field: 'department',
          },
          {
            headerName: 'Style Colors',
            field: 'style_colors',
          },
          {
            headerName: 'Capacity',
            field: 'capacity',
            aggFunc: 'sum',
          },
          {
            headerName: 'OH+IT+OO',
            field: 'oh_it_oo',
            aggFunc: 'sum',
          },
          {
            headerName: 'Forecasted sales',
            field: 'forecasted_sales',
            aggFunc: 'sum',
          },
          {
            headerName: 'Allocated Units in  current batch',
            field: 'allocated_units_current_batch',
            aggFunc: 'sum',
          },
          {
            headerName: 'Current Allocation recommendation',
            field: 'current_allocation_recommendation',
            aggFunc: 'sum',
          },
          {
            headerName: 'Net available capacity',
            field: 'net_available_capacity',
          },
          {
            headerName: 'Current WOS (OH+IT+OO)',
            field: 'current_wos',
            width: 300,
          },
          {
            headerName: 'Margin/Unit',
            field: 'margin_per_unit',
          },
          {
            headerName: 'Product grade',
            field: 'product_grade',
          },
          {
            headerName: 'Small',
            field: 'small',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Medium',
            field: 'medium',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Large',
            field: 'large',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
        ],
        data: [],
      },
    ],
  },
};

export const singleAllocationJCPennytabListData = {
  product_view: {
    label: 'Product View',
    value: 'product_view',
    tables: [
      {
        title: 'Allocation Summary',
        key: 'tb_rw_al_pv_product_alsummary_specific',
        height: '30vh',
        tableCssStyle: 'sizeColumnsToFit',
        column: [
          {
            field: 'style_color',
            headerName: '# Style Color',
            width: 140,
            pinned: 'left',
          },
          {
            field: 'store',
            headerName: '# Store',
            width: 100,
            pinned: 'left',
          },
          {
            field: 'allocated_stores_style_color',
            headerName: 'Allocated Stores/ StyleColor',
            width: 248,
          },
          { field: 'allocated_qty', headerName: 'Allocated qty', width: 140 },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc_DC01', headerName: 'DC01', width: 120 },
              { field: 'net_dc_DC02', headerName: 'DC02', width: 120 },
              { field: 'net_dc_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          {
            headerName: 'Allocated Qty(by size)',
            children: [
              { field: 'qty_6B', headerName: '6B', width: 80 },
              { field: 'qty_7B', headerName: '7B', width: 80 },
              { field: 'qty_8B', headerName: '8B', width: 80 },
              { field: 'qty_9B', headerName: '9B', width: 80 },
              { field: 'qty_10B', headerName: '10B', width: 80 },
              { field: 'qty_11B', headerName: '11B', width: 80 },
            ],
          },
          {
            headerName: 'Allocation %',
            children: [
              { field: 'per_6B', headerName: '6B', width: 80 },
              { field: 'per_7B', headerName: '7B', width: 80 },
              { field: 'per_8B', headerName: '8B', width: 80 },
              { field: 'per_9B', headerName: '9B', width: 80 },
              { field: 'per_10B', headerName: '10B', width: 80 },
              { field: 'per_11B', headerName: '11B', width: 80 },
            ],
          },
        ],
        data: [],
      },
      {
        title: 'Product Details',
        key: 'tb_rw_al_pv_product_details_specific',
        column: [
          {
            field: 'style_color_id',
            headerName: 'Style Color ID',
            pinned: 'left',
          },
          { field: 'color_id', headerName: 'Color ID', pinned: 'left' },
          { field: 'demand_type', headerName: 'Demand Type' },
          { field: 'store_group', headerName: 'Store Group' },
          { field: 'store', headerName: '# Store' },
          { field: 'channel', headerName: 'Channel' },
          { field: 'department', headerName: 'Department' },
          { field: 'class', headerName: 'Class' },
          { field: 'style_group', headerName: 'Style Group' },
          { field: 'silhouette', headerName: 'Silhouette' },
          { field: 'sub_collect', headerName: 'Sub Collect' },
          { field: 'allocated_stores', headerName: 'Allocated Stores' },
          { field: 'minimum_constraint', headerName: 'Minimum Constraint' },
          {
            headerName: 'Allocated Total Qty.',
            children: [
              { field: 'allocated_qty_DC01', headerName: 'DC01', width: 120 },
              { field: 'allocated_qty_DC02', headerName: 'DC02', width: 120 },
              { field: 'allocated_qty_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc_DC01', headerName: 'DC01', width: 120 },
              { field: 'net_dc_DC02', headerName: 'DC02', width: 120 },
              { field: 'net_dc_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          { field: 'original_aps', headerName: 'Original APS' },
          { field: 'forecasted_aps', headerName: 'Forecasted APS' },
          { field: 'target_wos', headerName: 'Target WOS' },
          { field: 'forecasted_wos', headerName: 'Forecasted WOS' },
          { field: 'dc_oh', headerName: 'DC OH' },
          { field: 'wos', headerName: 'WOS' },
          {
            headerName: 'Allocation by Size',
            children: [
              { field: 'allocation_size_6b', headerName: '6B', width: 200 },
              { field: 'allocation_size_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
          {
            headerName: 'Size - DC OH',
            children: [
              { field: 'size_6b', headerName: '6B', width: 200 },
              { field: 'size_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'size_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'size_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'size_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'size_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
          {
            headerName: 'Size - WOS',
            children: [
              { field: 'size_wos_6b', headerName: '6B', width: 200 },
              { field: 'size_wos_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_rw_al_pv_stores_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color Id',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'target_allocation_qty',
                headerName: 'Target Allocation Qty.',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                field: 'pack_id',
                headerName: 'Pack ID',
                width: 180,
              },
              {
                headerName: '# of packs allocated',
                children: [
                  {
                    field: 'packs_dc_01',
                    headerName: 'DC01',
                    width: 140,
                  },
                  { field: 'packs_dc_02', headerName: 'DC02', width: 140 },
                  { field: 'packs_dc_03', headerName: 'DC03', width: 140 },
                ],
              },
              {
                headerName: '# of Eaches allocated',
                children: [
                  {
                    field: 'eaches_dc_01',
                    headerName: 'DC01',
                    width: 140,
                  },
                  { field: 'eaches_dc_02', headerName: 'DC02', width: 140 },
                  { field: 'eaches_dc_03', headerName: 'DC03', width: 140 },
                ],
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_rw_al_pv_store_group_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_rw_al_pv_store_grade_specific',
            column: [
              {
                headerName: 'Store Grade Information',
                pinned: 'left',
                width: 200,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# style colors' },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  store_view: {
    label: 'Store View',
    value: 'store_view',
    tables: [
      {
        title: 'Allocation Summary',
        isRadio: false,
        key: 'tb_rw_al_st_alsummary_specific',
        height: '35vh',
        column: [
          {
            headerName: '',
            children: [
              {
                field: 'style_color',
                headerName: '# Style Color',
                pinned: 'left',
                width: 160,
              },

              {
                field: 'store',
                headerName: '# Store',
                pinned: 'left',
                width: 130,
              },
              {
                field: 'style_color_depth_store',
                headerName: 'Style Color Depth/Store',
                pinned: 'left',
                width: 130,
              },
            ],
          },
          {
            headerName: 'Allocated Qty.',
            children: [
              {
                headerName: 'DC01',
                children: [
                  {
                    field: 'alqty_dc_ea_01',
                    headerName: 'Eaches',
                  },
                  {
                    field: 'alqty_dc_pre_01',
                    headerName: 'Prepacks',
                  },
                ],
              },
              {
                headerName: 'DC02',
                children: [
                  {
                    field: 'alqty_dc_ea_02',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'alqty_dc_pre_02',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: 'DC03',
                children: [
                  {
                    field: 'alqty_dc_ea_03',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'alqty_dc_pre_03',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: '',
                children: [
                  {
                    field: 'alqty_total',
                    headerName: 'Total',
                  },
                ],
              },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              {
                headerName: 'DC01',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_01',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_01',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: 'DC02',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_02',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_02',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: 'DC03',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_03',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_03',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: '',
                children: [
                  {
                    field: 'net_dc_total',
                    headerName: 'Total',
                  },
                ],
              },
            ],
          },
          {
            headerName: 'Store Grade - Allocation Qty',
            children: [
              { field: 'qty_aaa', headerName: 'AAA', width: 110 },
              { field: 'qty_aa', headerName: 'AA', width: 110 },
              { field: 'qty_a', headerName: 'A', width: 110 },
              { field: 'qty_b', headerName: 'B', width: 110 },
              { field: 'qty_c', headerName: 'C', width: 110 },
              { field: 'qty_d', headerName: 'D', width: 110 },
            ],
          },
          {
            headerName: 'Store Grade - Allocation %',
            children: [
              { field: 'index_aaa', headerName: 'AAA', width: 110 },
              { field: 'index_aa', headerName: 'AA', width: 110 },
              { field: 'index_a', headerName: 'A', width: 110 },
              { field: 'index_b', headerName: 'B', width: 110 },
              { field: 'index_c', headerName: 'C', width: 110 },
              { field: 'index_d', headerName: 'D', width: 110 },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        showSetAllButton: true,
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_rw_al_st_view_stores_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_rw_al_st_view_store_group_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_rw_al_st_view_store_grade_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Information',
                pinned: 'left',
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# Style Color' },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
        },
      },
      {
        title: 'Style',
        isRadio: false,
        key: 'tb_rw_al_st_view_store_articles_specific',
        height: '40vh',
        column: [
          { field: 'style_color', headerName: '# Style Color', pinned: 'left' },
          { field: 'color', headerName: 'Color', pinned: 'left' },
          { field: 'description', headerName: 'Description', pinned: 'left' },
          { field: 'constraint', headerName: 'Constraint', pinned: 'left'  },
          {
            headerName: 'Allocated Total Qty.',
            children: [
              { field: 'qty_dco1', headerName: 'DC01' },
              { field: 'qty_dco2', headerName: 'DC02' },
              { field: 'qty_dco3', headerName: 'DC03' },
              { field: 'qty_total', headerName: 'Total' },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc01', headerName: 'DC01' },
              { field: 'net_dc02', headerName: 'DC02' },
              { field: 'net_dc03', headerName: 'DC03' },
              { field: 'net_total', headerName: 'Total' },
            ],
          },
          { field: 'original_aps', headerName: 'Original Aps' },
          { field: 'forecasted_aps', headerName: 'Forecasted Aps' },
          { field: 'target_wos', headerName: 'Target WOS' },
          { field: 'forecasted_wos', headerName: 'Forecasted WOS' },
          {
            headerName: 'Size',
            children: [
              { field: 'size_6b', headerName: '6B' },
              { field: 'size_7b', headerName: '7B' },
              { field: 'size_8b', headerName: '8B' },
              { field: 'size_9b', headerName: '9B' },
              { field: 'size_10b', headerName: '10B' },
              { field: 'size_11b', headerName: '11B' },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_rw_al_sv_articles_stores_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_rw_al_sv_articles_store_group_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_rw_al_sv_articles_store_grade_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Grade Information',
                pinned: 'left',
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# style colors' },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  capacity_breach: {
    label: 'Capacity Breach',
    value: 'capacity_breach',
    tables: [
      {
        title: '',
        isRadio: false,
        value: 'capacity_breach',
        height: '450px',
        key: 'tb_allocation_capacity_breach_specific',
        tableCssStyle: 'sizeColumnsToFit',
        defaultExpansion: 1,
        autoGroupColumnDef: { headerName: 'Store Name' },
        column: [
          {
            headerName: 'Store Name',
            field: 'store_name',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: 'Department',
            field: 'department',
            aggFunc: (params) => {
              let final = null;
              params.values.forEach((value, index) => {
                if (index === 0) {
                  final = value;
                }
              });
              return final;
            },
          },
          {
            headerName: 'Style Colors',
            field: 'style_colors',
            aggFunc: 'count',
          },
          {
            headerName: 'Capacity',
            field: 'capacity',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'OH+IT+OO',
            field: 'oh_it_oo',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Forecasted sales',
            field: 'forecasted_sales',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Allocated Units in  current batch',
            field: 'allocated_units_current_batch',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Current Allocation recommendation',
            field: 'current_allocation_recommendation',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Net available capacity',
            field: 'net_available_capacity',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
            // valueFormatter: (params) =>
            //   params?.node?.rowIndex === 0
            //     ? params?.node?.aggData?.oh_it_oo +
            //       params?.node?.aggData?.current_allocation_recommendation -
            //       params?.node?.aggData?.forecasted_sales
            //     : 0,
          },
          {
            headerName: 'Current WOS (OH+IT+OO)',
            field: 'current_wos',
            width: 250,
          },
          {
            headerName: 'Margin/Unit',
            field: 'margin_per_unit',
          },
          {
            headerName: 'Product grade',
            field: 'product_grade',
          },
          {
            headerName: '6B',
            field: '6B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '7B',
            field: '7B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '8B',
            field: '8B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '9B',
            field: '9B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '10B',
            field: '10B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '11B',
            field: '11B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '12B',
            field: '12B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '13B',
            field: '13B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
        ],
        editColumn: [
          {
            headerName: 'Store Name',
            field: 'store_name',
            hide: true,
            rowGroup: true,
          },
          {
            headerName: 'Department',
            field: 'department',
          },
          {
            headerName: 'Style Colors',
            field: 'style_colors',
          },
          {
            headerName: 'Capacity',
            field: 'capacity',
            aggFunc: 'sum',
          },
          {
            headerName: 'OH+IT+OO',
            field: 'oh_it_oo',
            aggFunc: 'sum',
          },
          {
            headerName: 'Forecasted sales',
            field: 'forecasted_sales',
            aggFunc: 'sum',
          },
          {
            headerName: 'Allocated Units in  current batch',
            field: 'allocated_units_current_batch',
            aggFunc: 'sum',
          },
          {
            headerName: 'Current Allocation recommendation',
            field: 'current_allocation_recommendation',
            aggFunc: 'sum',
          },
          {
            headerName: 'Net available capacity',
            field: 'net_available_capacity',
          },
          {
            headerName: 'Current WOS (OH+IT+OO)',
            field: 'current_wos',
            width: 300,
          },
          {
            headerName: 'Margin/Unit',
            field: 'margin_per_unit',
          },
          {
            headerName: 'Product grade',
            field: 'product_grade',
          },
          {
            headerName: 'Small',
            field: 'small',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Medium',
            field: 'medium',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Large',
            field: 'large',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
        ],
        data: [],
      },
    ],
  },
};

export const singleProducttabListData = {
  product_view: {
    label: 'Product View',
    value: 'product_view',
    tables: [
      {
        title: 'Allocation Summary',
        key: 'tb_rw_al_pv_product_alsummary_modal_specific',
        height: '30vh',
        tableCssStyle: 'sizeColumnsToFit',
        column: [
          {
            field: 'style_color',
            headerName: '# Style Color',
            width: 140,
            pinned: 'left',
          },
          {
            field: 'store',
            headerName: '# Store',
            width: 100,
            pinned: 'left',
          },
          {
            field: 'allocated_stores_style_color',
            headerName: 'Allocated Stores/ StyleColor',
            width: 248,
          },
          { field: 'allocated_qty', headerName: 'Allocated qty', width: 140 },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc_DC01', headerName: 'DC01', width: 120 },
              { field: 'net_dc_DC02', headerName: 'DC02', width: 120 },
              { field: 'net_dc_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          {
            headerName: 'Allocated Qty(by size)',
            children: [
              { field: 'qty_6B', headerName: '6B', width: 80 },
              { field: 'qty_7B', headerName: '7B', width: 80 },
              { field: 'qty_8B', headerName: '8B', width: 80 },
              { field: 'qty_9B', headerName: '9B', width: 80 },
              { field: 'qty_10B', headerName: '10B', width: 80 },
              { field: 'qty_11B', headerName: '11B', width: 80 },
            ],
          },
          {
            headerName: 'Allocation %',
            children: [
              { field: 'per_6B', headerName: '6B', width: 80 },
              { field: 'per_7B', headerName: '7B', width: 80 },
              { field: 'per_8B', headerName: '8B', width: 80 },
              { field: 'per_9B', headerName: '9B', width: 80 },
              { field: 'per_10B', headerName: '10B', width: 80 },
              { field: 'per_11B', headerName: '11B', width: 80 },
            ],
          },
        ],
        data: [],
      },
      {
        title: 'Product Details',
        key: 'tb_rw_al_pv_product_details_modal_specific',
        column: [
          {
            field: 'style_color_id',
            headerName: 'Style Color ID',
            pinned: 'left',
          },
          { field: 'color_id', headerName: 'Color ID', pinned: 'left' },
          { field: 'demand_type', headerName: 'Demand Type' },
          { field: 'store_group', headerName: 'Store Group' },
          { field: 'store', headerName: '# Store' },
          { field: 'channel', headerName: 'Channel' },
          { field: 'department', headerName: 'Department' },
          { field: 'class', headerName: 'Class' },
          { field: 'style_group', headerName: 'Style Group' },
          { field: 'silhouette', headerName: 'Silhouette' },
          { field: 'sub_collect', headerName: 'Sub Collect' },
          { field: 'allocated_stores', headerName: 'Allocated Stores' },
          { field: 'minimum_constraint', headerName: 'Minimum Constraint' },
          {
            headerName: 'Allocated Total Qty.',
            children: [
              { field: 'allocated_qty_DC01', headerName: 'DC01', width: 120 },
              { field: 'allocated_qty_DC02', headerName: 'DC02', width: 120 },
              { field: 'allocated_qty_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc_DC01', headerName: 'DC01', width: 120 },
              { field: 'net_dc_DC02', headerName: 'DC02', width: 120 },
              { field: 'net_dc_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          { field: 'original_aps', headerName: 'Original APS' },
          { field: 'forecasted_aps', headerName: 'Forecasted APS' },
          { field: 'target_wos', headerName: 'Target WOS' },
          { field: 'forecasted_wos', headerName: 'Forecasted WOS' },
          { field: 'dc_oh', headerName: 'DC OH' },
          { field: 'wos', headerName: 'WOS' },
          {
            headerName: 'Allocation by Size',
            children: [
              { field: 'allocation_size_6b', headerName: '6B', width: 200 },
              { field: 'allocation_size_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
          {
            headerName: 'Size - DC OH',
            children: [
              { field: 'size_6b', headerName: '6B', width: 200 },
              { field: 'size_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'size_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'size_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'size_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'size_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
          {
            headerName: 'Size - WOS',
            children: [
              { field: 'size_wos_6b', headerName: '6B', width: 200 },
              { field: 'size_wos_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_rw_al_pv_stores_modal_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color Id',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'target_allocation_qty',
                headerName: 'Target Allocation Qty.',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_rw_al_pv_store_group_modal_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_rw_al_pv_store_grade_modal_specific',
            column: [
              {
                headerName: 'Store Grade Information',
                pinned: 'left',
                width: 200,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# style colors' },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  store_view: {
    label: 'Store View',
    value: 'store_view',
    tables: [
      {
        title: 'Allocation Summary',
        isRadio: false,
        key: 'tb_rw_al_st_alsummary_modal_specific',
        height: '35vh',
        column: [
          {
            headerName: '',
            children: [
              {
                field: 'style_color',
                headerName: '# Style Color',
                pinned: 'left',
                width: 160,
              },

              {
                field: 'store',
                headerName: '# Store',
                pinned: 'left',
                width: 130,
              },
              {
                field: 'style_color_depth_store',
                headerName: 'Style Color Depth/Store',
                pinned: 'left',
                width: 130,
              },
            ],
          },
          {
            headerName: 'Allocated Qty.',
            children: [
              {
                headerName: 'DC01',
                children: [
                  {
                    field: 'alqty_dc_ea_01',
                    headerName: 'Eaches',
                  },
                  {
                    field: 'alqty_dc_pre_01',
                    headerName: 'Prepacks',
                  },
                ],
              },
              {
                headerName: 'DC02',
                children: [
                  {
                    field: 'alqty_dc_ea_02',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'alqty_dc_pre_02',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: 'DC03',
                children: [
                  {
                    field: 'alqty_dc_ea_03',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'alqty_dc_pre_03',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: '',
                children: [
                  {
                    field: 'alqty_total',
                    headerName: 'Total',
                  },
                ],
              },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              {
                headerName: 'DC01',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_01',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_01',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: 'DC02',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_02',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_02',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: 'DC03',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_03',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_03',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: '',
                children: [
                  {
                    field: 'net_dc_total',
                    headerName: 'Total',
                  },
                ],
              },
            ],
          },
          {
            headerName: 'Store Grade - Allocation Qty',
            children: [
              { field: 'qty_aaa', headerName: 'AAA', width: 110 },
              { field: 'qty_aa', headerName: 'AA', width: 110 },
              { field: 'qty_a', headerName: 'A', width: 110 },
              { field: 'qty_b', headerName: 'B', width: 110 },
              { field: 'qty_c', headerName: 'C', width: 110 },
              { field: 'qty_d', headerName: 'D', width: 110 },
            ],
          },
          {
            headerName: 'Store Grade - Allocation %',
            children: [
              { field: 'index_aaa', headerName: 'AAA', width: 110 },
              { field: 'index_aa', headerName: 'AA', width: 110 },
              { field: 'index_a', headerName: 'A', width: 110 },
              { field: 'index_b', headerName: 'B', width: 110 },
              { field: 'index_c', headerName: 'C', width: 110 },
              { field: 'index_d', headerName: 'D', width: 110 },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        showSetAllButton: true,
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_rw_al_st_view_stores_modal_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_rw_al_st_view_store_group_modal_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_rw_al_st_view_store_grade_modal_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Information',
                pinned: 'left',
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# Style Color' },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
        },
      },
      {
        title: 'Style',
        isRadio: false,
        key: 'tb_rw_al_st_view_store_articles_modal_specific',
        height: '40vh',
        column: [
          { field: 'style_color', headerName: '# Style Color', pinned: 'left' },
          { field: 'color', headerName: 'Color', pinned: 'left' },
          { field: 'description', headerName: 'Description', pinned: 'left' },
          { field: 'constraint', headerName: 'Constraint', pinned: 'left'  },
          {
            headerName: 'Allocated Total Qty.',
            children: [
              { field: 'qty_dco1', headerName: 'DC01' },
              { field: 'qty_dco2', headerName: 'DC02' },
              { field: 'qty_dco3', headerName: 'DC03' },
              { field: 'qty_total', headerName: 'Total' },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc01', headerName: 'DC01' },
              { field: 'net_dc02', headerName: 'DC02' },
              { field: 'net_dc03', headerName: 'DC03' },
              { field: 'net_total', headerName: 'Total' },
            ],
          },
          { field: 'original_aps', headerName: 'Original Aps' },
          { field: 'forecasted_aps', headerName: 'Forecasted Aps' },
          { field: 'target_wos', headerName: 'Target WOS' },
          { field: 'forecasted_wos', headerName: 'Forecasted WOS' },
          {
            headerName: 'Size',
            children: [
              { field: 'size_6b', headerName: '6B' },
              { field: 'size_7b', headerName: '7B' },
              { field: 'size_8b', headerName: '8B' },
              { field: 'size_9b', headerName: '9B' },
              { field: 'size_10b', headerName: '10B' },
              { field: 'size_11b', headerName: '11B' },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_rw_al_sv_articles_stores_modal_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_rw_al_sv_articles_store_group_modal_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_rw_al_sv_articles_store_grade_modal_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Grade Information',
                pinned: 'left',
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# style colors' },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  capacity_breach: {
    label: 'Capacity Breach',
    value: 'capacity_breach',
    tables: [
      {
        title: '',
        isRadio: false,
        value: 'capacity_breach',
        height: '450px',
        key: 'tb_allocation_capacity_breach_modal_specific',
        tableCssStyle: 'sizeColumnsToFit',
        defaultExpansion: 1,
        autoGroupColumnDef: { headerName: 'Store Name' },
        column: [
          {
            headerName: 'Store Name',
            field: 'store_name',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: 'Department',
            field: 'department',
            aggFunc: (params) => {
              let final = null;
              params.values.forEach((value, index) => {
                if (index === 0) {
                  final = value;
                }
              });
              return final;
            },
          },
          {
            headerName: 'Style Colors',
            field: 'style_colors',
            aggFunc: 'count',
          },
          {
            headerName: 'Capacity',
            field: 'capacity',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'OH+IT+OO',
            field: 'oh_it_oo',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Forecasted sales',
            field: 'forecasted_sales',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Allocated Units in  current batch',
            field: 'allocated_units_current_batch',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Current Allocation recommendation',
            field: 'current_allocation_recommendation',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Net available capacity',
            field: 'net_available_capacity',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
            // valueFormatter: (params) =>
            //   params?.node?.rowIndex === 0
            //     ? params?.node?.aggData?.oh_it_oo +
            //       params?.node?.aggData?.current_allocation_recommendation -
            //       params?.node?.aggData?.forecasted_sales
            //     : 0,
          },
          {
            headerName: 'Current WOS (OH+IT+OO)',
            field: 'current_wos',
            width: 250,
          },
          {
            headerName: 'Margin/Unit',
            field: 'margin_per_unit',
          },
          {
            headerName: 'Product grade',
            field: 'product_grade',
          },
          {
            headerName: '6B',
            field: '6B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '7B',
            field: '7B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '8B',
            field: '8B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '9B',
            field: '9B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '10B',
            field: '10B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '11B',
            field: '11B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '12B',
            field: '12B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '13B',
            field: '13B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
        ],
        editColumn: [
          {
            headerName: 'Store Name',
            field: 'store_name',
            hide: true,
            rowGroup: true,
          },
          {
            headerName: 'Department',
            field: 'department',
          },
          {
            headerName: 'Style Colors',
            field: 'style_colors',
          },
          {
            headerName: 'Capacity',
            field: 'capacity',
            aggFunc: 'sum',
          },
          {
            headerName: 'OH+IT+OO',
            field: 'oh_it_oo',
            aggFunc: 'sum',
          },
          {
            headerName: 'Forecasted sales',
            field: 'forecasted_sales',
            aggFunc: 'sum',
          },
          {
            headerName: 'Allocated Units in  current batch',
            field: 'allocated_units_current_batch',
            aggFunc: 'sum',
          },
          {
            headerName: 'Current Allocation recommendation',
            field: 'current_allocation_recommendation',
            aggFunc: 'sum',
          },
          {
            headerName: 'Net available capacity',
            field: 'net_available_capacity',
          },
          {
            headerName: 'Current WOS (OH+IT+OO)',
            field: 'current_wos',
            width: 300,
          },
          {
            headerName: 'Margin/Unit',
            field: 'margin_per_unit',
          },
          {
            headerName: 'Product grade',
            field: 'product_grade',
          },
          {
            headerName: 'Small',
            field: 'small',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Medium',
            field: 'medium',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Large',
            field: 'large',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
        ],
        data: [],
      },
    ],
  },
};

export const singleProductJCPennytabListData = {
  product_view: {
    label: 'Product View',
    value: 'product_view',
    tables: [
      {
        title: 'Allocation Summary',
        key: 'tb_rw_al_pv_product_alsummary_modal_specific',
        height: '30vh',
        tableCssStyle: 'sizeColumnsToFit',
        column: [
          {
            field: 'style_color',
            headerName: '# Style Color',
            width: 140,
            pinned: 'left',
          },
          {
            field: 'store',
            headerName: '# Store',
            width: 100,
            pinned: 'left',
          },
          {
            field: 'allocated_stores_style_color',
            headerName: 'Allocated Stores/ StyleColor',
            width: 248,
          },
          { field: 'allocated_qty', headerName: 'Allocated qty', width: 140 },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc_DC01', headerName: 'DC01', width: 120 },
              { field: 'net_dc_DC02', headerName: 'DC02', width: 120 },
              { field: 'net_dc_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          {
            headerName: 'Allocated Qty(by size)',
            children: [
              { field: 'qty_6B', headerName: '6B', width: 80 },
              { field: 'qty_7B', headerName: '7B', width: 80 },
              { field: 'qty_8B', headerName: '8B', width: 80 },
              { field: 'qty_9B', headerName: '9B', width: 80 },
              { field: 'qty_10B', headerName: '10B', width: 80 },
              { field: 'qty_11B', headerName: '11B', width: 80 },
            ],
          },
          {
            headerName: 'Allocation %',
            children: [
              { field: 'per_6B', headerName: '6B', width: 80 },
              { field: 'per_7B', headerName: '7B', width: 80 },
              { field: 'per_8B', headerName: '8B', width: 80 },
              { field: 'per_9B', headerName: '9B', width: 80 },
              { field: 'per_10B', headerName: '10B', width: 80 },
              { field: 'per_11B', headerName: '11B', width: 80 },
            ],
          },
        ],
        data: [],
      },
      {
        title: 'Product Details',
        key: 'tb_rw_al_pv_product_details_modal_specific',
        column: [
          {
            field: 'style_color_id',
            headerName: 'Style Color ID',
            pinned: 'left',
          },
          { field: 'color_id', headerName: 'Color ID', pinned: 'left' },
          { field: 'demand_type', headerName: 'Demand Type' },
          { field: 'store_group', headerName: 'Store Group' },
          { field: 'store', headerName: '# Store' },
          { field: 'channel', headerName: 'Channel' },
          { field: 'department', headerName: 'Department' },
          { field: 'class', headerName: 'Class' },
          { field: 'style_group', headerName: 'Style Group' },
          { field: 'silhouette', headerName: 'Silhouette' },
          { field: 'sub_collect', headerName: 'Sub Collect' },
          { field: 'allocated_stores', headerName: 'Allocated Stores' },
          { field: 'minimum_constraint', headerName: 'Minimum Constraint' },
          {
            headerName: 'Allocated Total Qty.',
            children: [
              { field: 'allocated_qty_DC01', headerName: 'DC01', width: 120 },
              { field: 'allocated_qty_DC02', headerName: 'DC02', width: 120 },
              { field: 'allocated_qty_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc_DC01', headerName: 'DC01', width: 120 },
              { field: 'net_dc_DC02', headerName: 'DC02', width: 120 },
              { field: 'net_dc_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          { field: 'original_aps', headerName: 'Original APS' },
          { field: 'forecasted_aps', headerName: 'Forecasted APS' },
          { field: 'target_wos', headerName: 'Target WOS' },
          { field: 'forecasted_wos', headerName: 'Forecasted WOS' },
          { field: 'dc_oh', headerName: 'DC OH' },
          { field: 'wos', headerName: 'WOS' },
          {
            headerName: 'Allocation by Size',
            children: [
              { field: 'allocation_size_6b', headerName: '6B', width: 200 },
              { field: 'allocation_size_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
          {
            headerName: 'Size - DC OH',
            children: [
              { field: 'size_6b', headerName: '6B', width: 200 },
              { field: 'size_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'size_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'size_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'size_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'size_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
          {
            headerName: 'Size - WOS',
            children: [
              { field: 'size_wos_6b', headerName: '6B', width: 200 },
              { field: 'size_wos_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_rw_al_pv_stores_modal_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color Id',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'target_allocation_qty',
                headerName: 'Target Allocation Qty.',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'target_vs_allocated',
                headerName: 'Target vs Allocated',
                width: 150,
                cellStyle: function (params) {
                  const percentage = {
                    low: {
                      background: '#f6c6c8',
                    }
                  };
            
                  let label = '';
                  let value = parseInt(params.data.target_vs_allocated);
                  if (value < 0) {
                    label = 'low';
                  }
            
                  return percentage[`${label}`];
                },
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'min_pres_target',
                headerName: 'Min Pres Target',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              {
                field: 'demand_forecast_@_target_wos',
                headerName: 'Demand Forecast @ Target WOS',
                width: 160,
              },
              {
                field: 'target_wos',
                headerName: 'Target WOS',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'pack_id',
                headerName: 'Pack ID',
                width: 180,
              },
              {
                headerName: '# of packs allocated',
                children: [
                  {
                    field: 'packs_dc_01',
                    headerName: 'DC01',
                    width: 140,
                  },
                  { field: 'packs_dc_02', headerName: 'DC02', width: 140 },
                  { field: 'packs_dc_03', headerName: 'DC03', width: 140 },
                ],
              },
              {
                headerName: '# of Eaches allocated',
                children: [
                  {
                    field: 'eaches_dc_01',
                    headerName: 'DC01',
                    width: 140,
                  },
                  { field: 'eaches_dc_02', headerName: 'DC02', width: 140 },
                  { field: 'eaches_dc_03', headerName: 'DC03', width: 140 },
                ],
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_rw_al_pv_store_group_modal_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_rw_al_pv_store_grade_modal_specific',
            column: [
              {
                headerName: 'Store Grade Information',
                pinned: 'left',
                width: 200,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# style colors' },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  store_view: {
    label: 'Store View',
    value: 'store_view',
    tables: [
      {
        title: 'Allocation Summary',
        isRadio: false,
        key: 'tb_rw_al_st_alsummary_modal_specific',
        height: '35vh',
        column: [
          {
            headerName: '',
            children: [
              {
                field: 'style_color',
                headerName: '# Style Color',
                pinned: 'left',
                width: 160,
              },

              {
                field: 'store',
                headerName: '# Store',
                pinned: 'left',
                width: 130,
              },
              {
                field: 'style_color_depth_store',
                headerName: 'Style Color Depth/Store',
                pinned: 'left',
                width: 130,
              },
            ],
          },
          {
            headerName: 'Allocated Qty.',
            children: [
              {
                headerName: 'DC01',
                children: [
                  {
                    field: 'alqty_dc_ea_01',
                    headerName: 'Eaches',
                  },
                  {
                    field: 'alqty_dc_pre_01',
                    headerName: 'Prepacks',
                  },
                ],
              },
              {
                headerName: 'DC02',
                children: [
                  {
                    field: 'alqty_dc_ea_02',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'alqty_dc_pre_02',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: 'DC03',
                children: [
                  {
                    field: 'alqty_dc_ea_03',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'alqty_dc_pre_03',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: '',
                children: [
                  {
                    field: 'alqty_total',
                    headerName: 'Total',
                  },
                ],
              },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              {
                headerName: 'DC01',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_01',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_01',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: 'DC02',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_02',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_02',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: 'DC03',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_03',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_03',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: '',
                children: [
                  {
                    field: 'net_dc_total',
                    headerName: 'Total',
                  },
                ],
              },
            ],
          },
          {
            headerName: 'Store Grade - Allocation Qty',
            children: [
              { field: 'qty_aaa', headerName: 'AAA', width: 110 },
              { field: 'qty_aa', headerName: 'AA', width: 110 },
              { field: 'qty_a', headerName: 'A', width: 110 },
              { field: 'qty_b', headerName: 'B', width: 110 },
              { field: 'qty_c', headerName: 'C', width: 110 },
              { field: 'qty_d', headerName: 'D', width: 110 },
            ],
          },
          {
            headerName: 'Store Grade - Allocation %',
            children: [
              { field: 'index_aaa', headerName: 'AAA', width: 110 },
              { field: 'index_aa', headerName: 'AA', width: 110 },
              { field: 'index_a', headerName: 'A', width: 110 },
              { field: 'index_b', headerName: 'B', width: 110 },
              { field: 'index_c', headerName: 'C', width: 110 },
              { field: 'index_d', headerName: 'D', width: 110 },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        showSetAllButton: true,
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_rw_al_st_view_stores_modal_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_rw_al_st_view_store_group_modal_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_rw_al_st_view_store_grade_modal_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Information',
                pinned: 'left',
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# Style Color' },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
        },
      },
      {
        title: 'Style',
        isRadio: false,
        key: 'tb_rw_al_st_view_store_articles_modal_specific',
        height: '40vh',
        column: [
          { field: 'style_color', headerName: '# Style Color', pinned: 'left' },
          { field: 'color', headerName: 'Color', pinned: 'left' },
          { field: 'description', headerName: 'Description', pinned: 'left' },
          { field: 'constraint', headerName: 'Constraint', pinned: 'left'  },
          {
            headerName: 'Allocated Total Qty.',
            children: [
              { field: 'qty_dco1', headerName: 'DC01' },
              { field: 'qty_dco2', headerName: 'DC02' },
              { field: 'qty_dco3', headerName: 'DC03' },
              { field: 'qty_total', headerName: 'Total' },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc01', headerName: 'DC01' },
              { field: 'net_dc02', headerName: 'DC02' },
              { field: 'net_dc03', headerName: 'DC03' },
              { field: 'net_total', headerName: 'Total' },
            ],
          },
          { field: 'original_aps', headerName: 'Original Aps' },
          { field: 'forecasted_aps', headerName: 'Forecasted Aps' },
          { field: 'target_wos', headerName: 'Target WOS' },
          { field: 'forecasted_wos', headerName: 'Forecasted WOS' },
          {
            headerName: 'Size',
            children: [
              { field: 'size_6b', headerName: '6B' },
              { field: 'size_7b', headerName: '7B' },
              { field: 'size_8b', headerName: '8B' },
              { field: 'size_9b', headerName: '9B' },
              { field: 'size_10b', headerName: '10B' },
              { field: 'size_11b', headerName: '11B' },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_rw_al_sv_articles_stores_modal_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_rw_al_sv_articles_store_group_modal_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_rw_al_sv_articles_store_grade_modal_specific',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Grade Information',
                pinned: 'left',
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# style colors' },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'min_allocation',
                headerName: 'Min Allocation',
                width: 150,
              },
              {
                field: 'wos_allocation',
                headerName: 'WOS Allocation',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  capacity_breach: {
    label: 'Capacity Breach',
    value: 'capacity_breach',
    tables: [
      {
        title: '',
        isRadio: false,
        value: 'capacity_breach',
        height: '450px',
        key: 'tb_allocation_capacity_breach_modal_specific',
        tableCssStyle: 'sizeColumnsToFit',
        defaultExpansion: 1,
        autoGroupColumnDef: { headerName: 'Store Name' },
        column: [
          {
            headerName: 'Store Name',
            field: 'store_name',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: 'Department',
            field: 'department',
            aggFunc: (params) => {
              let final = null;
              params.values.forEach((value, index) => {
                if (index === 0) {
                  final = value;
                }
              });
              return final;
            },
          },
          {
            headerName: 'Style Colors',
            field: 'style_colors',
            aggFunc: 'count',
          },
          {
            headerName: 'Capacity',
            field: 'capacity',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'OH+IT+OO',
            field: 'oh_it_oo',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Forecasted sales',
            field: 'forecasted_sales',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Allocated Units in  current batch',
            field: 'allocated_units_current_batch',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Current Allocation recommendation',
            field: 'current_allocation_recommendation',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Net available capacity',
            field: 'net_available_capacity',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
            // valueFormatter: (params) =>
            //   params?.node?.rowIndex === 0
            //     ? params?.node?.aggData?.oh_it_oo +
            //       params?.node?.aggData?.current_allocation_recommendation -
            //       params?.node?.aggData?.forecasted_sales
            //     : 0,
          },
          {
            headerName: 'Current WOS (OH+IT+OO)',
            field: 'current_wos',
            width: 250,
          },
          {
            headerName: 'Margin/Unit',
            field: 'margin_per_unit',
          },
          {
            headerName: 'Product grade',
            field: 'product_grade',
          },
          {
            headerName: '6B',
            field: '6B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '7B',
            field: '7B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '8B',
            field: '8B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '9B',
            field: '9B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '10B',
            field: '10B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '11B',
            field: '11B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '12B',
            field: '12B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '13B',
            field: '13B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
        ],
        editColumn: [
          {
            headerName: 'Store Name',
            field: 'store_name',
            hide: true,
            rowGroup: true,
          },
          {
            headerName: 'Department',
            field: 'department',
          },
          {
            headerName: 'Style Colors',
            field: 'style_colors',
          },
          {
            headerName: 'Capacity',
            field: 'capacity',
            aggFunc: 'sum',
          },
          {
            headerName: 'OH+IT+OO',
            field: 'oh_it_oo',
            aggFunc: 'sum',
          },
          {
            headerName: 'Forecasted sales',
            field: 'forecasted_sales',
            aggFunc: 'sum',
          },
          {
            headerName: 'Allocated Units in  current batch',
            field: 'allocated_units_current_batch',
            aggFunc: 'sum',
          },
          {
            headerName: 'Current Allocation recommendation',
            field: 'current_allocation_recommendation',
            aggFunc: 'sum',
          },
          {
            headerName: 'Net available capacity',
            field: 'net_available_capacity',
          },
          {
            headerName: 'Current WOS (OH+IT+OO)',
            field: 'current_wos',
            width: 300,
          },
          {
            headerName: 'Margin/Unit',
            field: 'margin_per_unit',
          },
          {
            headerName: 'Product grade',
            field: 'product_grade',
          },
          {
            headerName: 'Small',
            field: 'small',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Medium',
            field: 'medium',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Large',
            field: 'large',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
        ],
        data: [],
      },
    ],
  },
};

export const WholesaleTabListData = {
  product_view: {
    label: 'Product View',
    value: 'product_view',
    tables: [
      {
        title: 'Allocation Summary',
        key: 'tb_wholesale_rw_al_pv_product_alsummary',
        height: '30vh',
        tableCssStyle: 'sizeColumnsToFit',
        column: [
          {
            field: 'style_color',
            headerName: '# Style Color',
            width: 140,
            pinned: 'left',
          },
          {
            field: '#account',
            headerName: '#Account',
            width: 150,
            pinned: 'left',
          },
          {
            field: 'allocated_accounts_style_color',
            headerName: 'Allocated Account/ StyleColor',
            width: 248,
          },
          { field: 'allocated_qty', headerName: 'Allocated qty', width: 140 },
          {
            headerName: 'Total available Inventory',
            children: [
              { field: 'total_wholesale_qty', headerName: 'Total wholesale Qty.', width: 120 },
              { field: 'total_retail_qty', headerName: 'Total Retail Qty.', width: 120 },
              { field: 'total_virtual_qty', headerName: 'Total Virtual Qty.', width: 120 },
            ],
          },
          {
            field: 'sales_order',
            headerName: 'Sales Order',
            width: 140,
          },
          {
            headerName: 'Allocated Qty(by size)',
            children: [
              { field: 'qty_6B', headerName: '6B', width: 80 },
              { field: 'qty_7B', headerName: '7B', width: 80 },
              { field: 'qty_8B', headerName: '8B', width: 80 },
              { field: 'qty_9B', headerName: '9B', width: 80 },
              { field: 'qty_10B', headerName: '10B', width: 80 },
              { field: 'qty_11B', headerName: '11B', width: 80 },
            ],
          },
          {
            headerName: 'Allocation %',
            children: [
              { field: 'per_6B', headerName: '6B', width: 80 },
              { field: 'per_7B', headerName: '7B', width: 80 },
              { field: 'per_8B', headerName: '8B', width: 80 },
              { field: 'per_9B', headerName: '9B', width: 80 },
              { field: 'per_10B', headerName: '10B', width: 80 },
              { field: 'per_11B', headerName: '11B', width: 80 },
            ],
          },
        ],
        data: [],
      },
      {
        title: 'Product Details',
        key: 'tb_wholesale_rw_al_pv_product_details',
        column: [
          {
            field: 'style_color_id',
            headerName: 'Style Color ID',
            pinned: 'left',
          },
          { field: 'color_id', headerName: 'Color ID', pinned: 'left' },
          { field: 'demand_type', headerName: 'Demand Type' },
          { field: 'territory', headerName: 'Territory' },
          { field: '#account', headerName: '#Account' },
          { field: 'channel', headerName: 'Channel' },
          { field: 'department', headerName: 'Department' },
          { field: 'class', headerName: 'Class' },
          { field: 'style_group', headerName: 'Style Group' },
          { field: 'silhouette', headerName: 'Silhouette' },
          { field: 'sub_collect', headerName: 'Sub Collect' },
          { field: 'allocated_accounts', headerName: 'Allocated Accounts' },
          {
            headerName: 'Allocated Total Qty.',
            children: [
              { field: 'allocated_total_qty_wholesale', headerName: 'Wholesale', width: 120 }
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc_avl_wholesale', headerName: 'Wholesale', width: 120 }
            ],
          },
          { field: 'dc_oh', headerName: 'DC OH' },
          { field: 'wos', headerName: 'WOS' },
          {
            headerName: 'Allocation by Size',
            children: [
              { field: 'allocation_size_6b', headerName: '6B', width: 200 },
              { field: 'allocation_size_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'allocation_size_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
          {
            headerName: 'Size - DC OH',
            children: [
              { field: 'size_6b', headerName: '6B', width: 200 },
              { field: 'size_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'size_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'size_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'size_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'size_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
          {
            headerName: 'Size - WOS',
            children: [
              { field: 'size_wos_6b', headerName: '6B', width: 200 },
              { field: 'size_wos_7b', headerName: '7B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_8b', headerName: '8B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_9b', headerName: '9B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_10b', headerName: '10B', width: 120, columnGroupShow: 'open' },
              { field: 'size_wos_11b', headerName: '11B', width: 120, columnGroupShow: 'open' },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Accounts', value: 'stores' },
            { label: 'Territory', value: 'territory' },
            { label: 'Account grade', value: 'account_grade' },
          ],
          stores: {
            key: 'tb_rw_al_pv_accounts',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Account Information',
                children: [
                  {
                    field: 'account_id',
                    headerName: 'Account ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'account_name',
                    headerName: 'Account Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'account_grade',
                    headerName: 'Account Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color Id',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'inventory_need',
                headerName: 'Inventory Need(Based on sales order)',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          territory: {
            title: 'Territory',
            key: 'tb_rw_al_pv_territory',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Territory Information',
                children: [
                  {
                    field: 'territory_id',
                    headerName: 'Territory ID',
                    pinned: 'left',
                  },
                  {
                    field: 'territory_name',
                    headerName: 'Territory Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'inventory_need',
                headerName: 'Inventory Need(Based on sales order)',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          account_grade: {
            title: 'Account grade',
            key: 'tb_rw_al_pv_account_grade',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Account Grade Information',
                children: [
                  { field: 'account_grade', headerName: 'Account Grade', pinned: 'left' },
                  { field: 'style_color', headerName: '# style colors', pinned: 'left' },
                ],
              },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'inventory_need',
                headerName: 'Inventory Need(Based on sales order)',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  account_view: {
    label: 'Account View',
    value: 'account_view',
    tables: [
      {
        title: 'Allocation Summary',
        isRadio: false,
        key: 'tb_wholesale_rw_al_st_alsummary',
        height: '35vh',
        column: [
          {
            headerName: '',
            children: [
              {
                field: 'style_color',
                headerName: '# Style Color',
                pinned: 'left',
                width: 160,
              },

              {
                field: '#account',
                headerName: '#Account',
                pinned: 'left',
                width: 130,
              },
              {
                field: 'allocated_account_style_color',
                headerName: 'Allocated Account/style color',
                pinned: 'left',
                width: 130,
              },
            ],
          },
          {
            headerName: 'Allocated Qty.',
            children: [
              {
                headerName: 'Wholesale',
                children: [
                  {
                    field: 'alqty_dc_ea_01',
                    headerName: 'Eaches',
                  },
                  {
                    field: 'alqty_dc_pre_01',
                    headerName: 'Prepacks',
                  },
                ],
              },
              {
                headerName: '',
                children: [
                  {
                    field: 'alqty_total',
                    headerName: 'Total',
                  },
                ],
              },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              {
                headerName: 'Wholesale',
                children: [
                  {
                    field: 'net_dc_avl_dc_ea_01',
                    headerName: 'Eaches',
                    width: 110,
                  },
                  {
                    field: 'net_dc_avl_dc_pre_01',
                    headerName: 'Prepacks',
                    width: 140,
                  },
                ],
              },
              {
                headerName: '',
                children: [
                  {
                    field: 'net_dc_total',
                    headerName: 'Total',
                  },
                ],
              },
            ],
          },
          {
            headerName: 'Store Grade - Allocation Qty',
            children: [
              { field: 'qty_aaa', headerName: 'AAA', width: 110 },
              { field: 'qty_aa', headerName: 'AA', width: 110 },
              { field: 'qty_a', headerName: 'A', width: 110 },
              { field: 'qty_b', headerName: 'B', width: 110 },
              { field: 'qty_c', headerName: 'C', width: 110 },
              { field: 'qty_d', headerName: 'D', width: 110 },
            ],
          },
          {
            headerName: 'Store Grade - Allocation %',
            children: [
              { field: 'index_aaa', headerName: 'AAA', width: 110 },
              { field: 'index_aa', headerName: 'AA', width: 110 },
              { field: 'index_a', headerName: 'A', width: 110 },
              { field: 'index_b', headerName: 'B', width: 110 },
              { field: 'index_c', headerName: 'C', width: 110 },
              { field: 'index_d', headerName: 'D', width: 110 },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        showSetAllButton: true,
        radio: {
          checks: [
            { label: 'Accounts', value: 'stores' },
            { label: 'Territory', value: 'territory' },
            { label: 'Account grade', value: 'account_grade' },
          ],
          stores: {
            key: 'tb_rw_al_st_view_accounts',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Account Information',
                children: [
                  {
                    field: 'account_id',
                    headerName: 'Account ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'account_name',
                    headerName: 'Account Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'account_grade',
                    headerName: 'Account Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'inventory_need',
                headerName: 'Inventory Need(Based on sales order)',
                width: 160,
              },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
          territory: {
            title: 'Territory',
            key: 'tb_rw_al_st_view_territory',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Territory Information',
                children: [
                  {
                    field: 'territory_id',
                    headerName: 'Territory ID',
                    pinned: 'left',
                  },
                  {
                    field: 'territory_name',
                    headerName: 'Territory Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'inventory_need',
                headerName: 'Inventory Need(Based on sales order)',
                width: 160,
              },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
          account_grade: {
            title: 'Account grade',
            key: 'tb_rw_al_st_view_account_grade',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Account Grade Information',
                pinned: 'left',
                children: [
                  { field: 'account_grade', headerName: 'Account Grade', pinned: 'left' },
                  { field: 'style_color', headerName: '# Style Color', pinned: 'left' },
                ],
              },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              { field: 'total_allocated_units', headerName: 'Total Allocated Units', width: 180 },
              {
                field: 'inventory_need',
                headerName: 'Inventory Need(Based on sales order)',
                width: 160,
              },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
        },
      },
      {
        title: 'Style',
        isRadio: false,
        key: 'tb_wholesale_rw_al_st_view_store_articles',
        height: '40vh',
        column: [
          { field: 'style_color', headerName: '# Style Color', pinned: 'left' },
          { field: 'color', headerName: 'Color', pinned: 'left' },
          { field: 'description', headerName: 'Description', pinned: 'left' },
          { field: 'constraint', headerName: 'Constraint' },
          {
            headerName: 'Allocated Total Qty.',
            children: [
              { field: 'al_tot_qty_wholesale', headerName: 'Wholesale' },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc_avl_wholesale', headerName: 'Wholesale' },
            ],
          },
          {
            headerName: 'Size',
            children: [
              { field: 'size_6b', headerName: '6B' },
              { field: 'size_7b', headerName: '7B' },
              { field: 'size_8b', headerName: '8B' },
              { field: 'size_9b', headerName: '9B' },
              { field: 'size_10b', headerName: '10B' },
              { field: 'size_11b', headerName: '11B' },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Accounts', value: 'stores' },
            { label: 'Territory', value: 'territory' },
            { label: 'Account grade', value: 'account_grade' },
          ],
          stores: {
            key: 'tb_rw_al_sv_articles_accounts',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Account Information',
                children: [
                  {
                    field: 'Account_id',
                    headerName: 'Account ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'account_name',
                    headerName: 'Account Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'account_grade',
                    headerName: 'Account Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'inventory_need',
                headerName: 'Inventory Need(Based on sales order)',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          territory: {
            title: 'Territory',
            key: 'tb_rw_al_sv_articles_territory',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Territory Information',
                children: [
                  {
                    field: 'territory_id',
                    headerName: 'Territory ID',
                    pinned: 'left',
                  },
                  {
                    field: 'territory_name',
                    headerName: 'Territory Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'inventory_need',
                headerName: 'Inventory Need(Based on sales order)',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
          account_grade: {
            title: 'Account grade',
            key: 'tb_rw_al_sv_articles_account_grade',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Account Grade Information',
                pinned: 'left',
                children: [
                  { field: 'account_grade', headerName: 'Account Grade', pinned: 'left' },
                  { field: 'style_color', headerName: '# style colors', pinned: 'left' },
                ],
              },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'total_allocated_units',
                headerName: 'Total Allocated Units',
                width: 200,
              },
              {
                field: 'inventory_need',
                headerName: 'Inventory Need(Based on sales order)',
                width: 160,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 170,
              },
              {
                field: 'size_integrity_%',
                headerName: 'Size Integrity %',
                width: 180,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 140 },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  capacity_breach: {
    label: 'Capacity Breach',
    value: 'capacity_breach',
    tables: [
      {
        title: '',
        isRadio: false,
        value: 'capacity_breach',
        height: '450px',
        key: 'tb_allocation_capacity_breach',
        tableCssStyle: 'sizeColumnsToFit',
        defaultExpansion: 1,
        autoGroupColumnDef: { headerName: 'Store Name' },
        column: [
          {
            headerName: 'Store Name',
            field: 'store_name',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: 'Department',
            field: 'department',
            aggFunc: (params) => {
              let final = null;
              params.values.forEach((value, index) => {
                if (index === 0) {
                  final = value;
                }
              });
              return final;
            },
          },
          {
            headerName: 'Style Colors',
            field: 'style_colors',
            aggFunc: 'count',
          },
          {
            headerName: 'Capacity',
            field: 'capacity',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'OH+IT+OO',
            field: 'oh_it_oo',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Forecasted sales',
            field: 'forecasted_sales',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Allocated Units in  current batch',
            field: 'allocated_units_current_batch',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Current Allocation recommendation',
            field: 'current_allocation_recommendation',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Net available capacity',
            field: 'net_available_capacity',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
            // valueFormatter: (params) =>
            //   params?.node?.rowIndex === 0
            //     ? params?.node?.aggData?.oh_it_oo +
            //       params?.node?.aggData?.current_allocation_recommendation -
            //       params?.node?.aggData?.forecasted_sales
            //     : 0,
          },
          {
            headerName: 'Current WOS (OH+IT+OO)',
            field: 'current_wos',
            width: 250,
          },
          {
            headerName: 'Margin/Unit',
            field: 'margin_per_unit',
          },
          {
            headerName: 'Product grade',
            field: 'product_grade',
          },
          {
            headerName: '6B',
            field: '6B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '7B',
            field: '7B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '8B',
            field: '8B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '9B',
            field: '9B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '10B',
            field: '10B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '11B',
            field: '11B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '12B',
            field: '12B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: '13B',
            field: '13B',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
        ],
        editColumn: [
          {
            headerName: 'Store Name',
            field: 'store_name',
            hide: true,
            rowGroup: true,
          },
          {
            headerName: 'Department',
            field: 'department',
          },
          {
            headerName: 'Style Colors',
            field: 'style_colors',
          },
          {
            headerName: 'Capacity',
            field: 'capacity',
            aggFunc: 'sum',
          },
          {
            headerName: 'OH+IT+OO',
            field: 'oh_it_oo',
            aggFunc: 'sum',
          },
          {
            headerName: 'Forecasted sales',
            field: 'forecasted_sales',
            aggFunc: 'sum',
          },
          {
            headerName: 'Allocated Units in  current batch',
            field: 'allocated_units_current_batch',
            aggFunc: 'sum',
          },
          {
            headerName: 'Current Allocation recommendation',
            field: 'current_allocation_recommendation',
            aggFunc: 'sum',
          },
          {
            headerName: 'Net available capacity',
            field: 'net_available_capacity',
          },
          {
            headerName: 'Current WOS (OH+IT+OO)',
            field: 'current_wos',
            width: 300,
          },
          {
            headerName: 'Margin/Unit',
            field: 'margin_per_unit',
          },
          {
            headerName: 'Product grade',
            field: 'product_grade',
          },
          {
            headerName: 'Small',
            field: 'small',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Medium',
            field: 'medium',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Large',
            field: 'large',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
        ],
        data: [],
      },
    ],
  },
};

export const bulk_edit_table = {
  column: [
    { field: 'name', headerName: '' },
    { field: 'b6', headerName: '6B' },
    { field: 'b7', headerName: '7B' },
    { field: 'b8', headerName: '8B' },
    { field: 'b9', headerName: '9B' },
    { field: 'b10', headerName: '10B' },
    { field: 'b11', headerName: '11B' },
  ],
  data: [
    {
      name: 'Eaches per store',
      b6: '3',
      b7: '3',
      b8: '3',
      b9: '3',
      b10: '3',
      b11: '3',
    },
    {
      name: 'Eaches Available',
      b6: '10',
      b7: '12',
      b8: '15',
      b9: '10',
      b10: '10',
      b11: '10',
    },
  ],
};

export const re_al_label_data = [
  { label: 'Plan Name:', value: 'DC_Auto_Allocate_012345' },
  { label: 'Creation Date', value: '07-22-2022' },
];
