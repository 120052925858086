import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';

export default function AllocationReviewRecommendationCol() {
  const dispatch = useDispatch();
  const openPopup = () => {
    dispatch({
      type: 'SINGLE_PRODUCT_SELECTED',
      payload: false,
    });
    dispatch({
      type: 'SHOW_PRODUCT_DETAILS_POPUP',
      payload: true,
    });
  };
  return [
    { field: 'allocation_name', headerName: 'Allocation Name' },
    { field: 'style_color', headerName: 'Style Color' },
    { field: 'department', headerName: 'Department' },
    { field: 'class', headerName: 'Class' },
    { field: 'creation_date', headerName: 'Creation Date' },
    { field: 'lw_units', headerName: 'LW Units' },
    { field: 'bulk_remaining', headerName: 'Bulk Remaining' },
    { field: 'allocated_qty', headerName: 'Allocated Qty.' },
    {
      field: 'action',
      headerName: 'Actions',
      cellRendererFramework: (cell) => (
        <Button
          style={{ textTransform: 'none' }}
          onClick={openPopup}
          autoFocus
          variant='text'
        >
          Review
        </Button>
      ),
    },
  ];
}
