import callApi from "../../../utils/callApi";

export const FILTERS_LIST = "FILTERS_LIST";
export const OPEN_LOADER = "OPEN_LOADER";
export const CLOSE_LOADER = "CLOSE_LOADER";
export const MASTER_CATEGORY_DATA = "MASTER_CATEGORY_DATA";


export const openLoader = (val) => dispatch => {
  dispatch({type: OPEN_LOADER, payload: true});
};
export const closeLoader = (val) => dispatch => {
  dispatch({type: CLOSE_LOADER, payload: false});
};

export const filtersListAction = (modelName) => async (dispatch) => {
    try {
      dispatch(openLoader());
      // const data = await callApi.get("filter/list",{params:reqParams});
      const data = await callApi.post(`get_data_model?model_name=${modelName}`);
      console.log(data)
      if (data.data.success) {
        dispatch({
            type: FILTERS_LIST,
            payload : [
              {"categoryid":"1","master_category":"Department","infotip":"This API reference is organized by resource type. Each resource type has one or more data representations and one or more methods.","type":"single-select","is_required":"1","is_dependent":false,"value_order":"5","infotip_enabled":"0","is_active":"1","remarks":"","is_dependent_on":""},
              {"categoryid":"2","master_category":"Class","infotip":"This API reference is organized by resource type. Each resource type has one or more data representations and one or more methods.","type":"multi-select","is_required":"1","is_dependent":false,"value_order":"6","infotip_enabled":"0","is_active":"1","remarks":"","is_dependent_on":""},
              {"categoryid":"3","master_category":"Sub Class","infotip":"This API reference is organized by resource type. Each resource type has one or more data representations and one or more methods.","type":"multi-select","is_required":"1","is_dependent":false,"value_order":"7","infotip_enabled":"0","is_active":"1","remarks":"","is_dependent_on":""},
              {"categoryid":"4","master_category":"Merchandise category","infotip":"This API reference is organized by resource type. Each resource type has one or more data representations and one or more methods.","type":"multi-select","is_required":"0","is_dependent":false,"value_order":"8","infotip_enabled":"0","is_active":"1","remarks":"","is_dependent_on":""},
              {"categoryid":"5","master_category":"Asset vs memo","infotip":"This API reference is organized by resource type. Each resource type has one or more data representations and one or more methods.","type":"multi-select","is_required":"0","is_dependent":false,"value_order":"10","infotip_enabled":"0","is_active":"1","remarks":"","is_dependent_on":""},
              {"categoryid":"6","master_category":"Product grade","infotip":"This API reference is organized by resource type. Each resource type has one or more data representations and one or more methods.","type":"multi-select","is_required":"0","is_dependent":false,"value_order":"11","infotip_enabled":"0","is_active":"1","remarks":"","is_dependent_on":""},
              {"categoryid":"7","master_category":"Region","infotip":"This API reference is organized by resource type. Each resource type has one or more data representations and one or more methods.","type":"multi-select","is_required":"0","is_dependent":false,"value_order":"2","infotip_enabled":"0","is_active":"1","remarks":"","is_dependent_on":""},
              {"categoryid":"8","master_category":"State","infotip":"This API reference is organized by resource type. Each resource type has one or more data representations and one or more methods.","type":"multi-select","is_required":"0","is_dependent":false,"value_order":"3","infotip_enabled":"0","is_active":"1","remarks":"","is_dependent_on":""},
              {"categoryid":"9","master_category":"Store grade","infotip":"This API reference is organized by resource type. Each resource type has one or more data representations and one or more methods.","type":"multi-select","is_required":"0","is_dependent":false,"value_order":"9","infotip_enabled":"0","is_active":"1","remarks":"","is_dependent_on":""},
              {"categoryid":"10","master_category":"City","infotip":"This API reference is organized by resource type. Each resource type has one or more data representations and one or more methods.","type":"multi-select","is_required":"0","is_dependent":false,"value_order":"4","infotip_enabled":"0","is_active":"1","remarks":"","is_dependent_on":""},
              {"categoryid":"11","master_category":"Country","infotip":"This API reference is organized by resource type. Each resource type has one or more data representations and one or more methods.","type":"multi-select","is_required":"0","is_dependent":false,"value_order":"1","infotip_enabled":"0","is_active":"1","remarks":"","is_dependent_on":""},
              {"categoryid":"12","master_category":"Location type","infotip":"This API reference is organized by resource type. Each resource type has one or more data representations and one or more methods.","type":"multi-select","is_required":"0","is_dependent":false,"value_order":"12","infotip_enabled":"0","is_active":"1","remarks":"","is_dependent_on":""},
              {"categoryid":"13","master_category":"Climate","infotip":"This API reference is organized by resource type. Each resource type has one or more data representations and one or more methods.","type":"multi-select","is_required":"0","is_dependent":false,"value_order":"13","infotip_enabled":"0","is_active":"1","remarks":"","is_dependent_on":""},
              {"categoryid":"14","master_category":"Store type","infotip":"This API reference is organized by resource type. Each resource type has one or more data representations and one or more methods.","type":"multi-select","is_required":"0","is_dependent":false,"value_order":"14","infotip_enabled":"0","is_active":"1","remarks":"","is_dependent_on":""},
              {"categoryid":"15","master_category":"Channel","infotip":"This API reference is organized by resource type. Each resource type has one or more data representations and one or more methods.","type":"multi-select","is_required":"0","is_dependent":false,"value_order":"15","infotip_enabled":"0","is_active":"1","remarks":"","is_dependent_on":""},
              {"categoryid":"16","master_category":"Sub channel","infotip":"This API reference is organized by resource type. Each resource type has one or more data representations and one or more methods.","type":"multi-select","is_required":"0","is_dependent":false,"value_order":"16","infotip_enabled":"0","is_active":"1","remarks":"","is_dependent_on":""}
              ]
          });
      } else {
        dispatch(closeLoader());
      }
    } catch (error) {
      console.log("Error message",error)
      dispatch(closeLoader());
    }
};

export const getMasterCategoryData = (reqParams) => async (dispatch) => {
  try {
    dispatch(openLoader());
    const data = await callApi.get("filter/list",{params:reqParams});
    console.log(data)
    if (data.data.success) {
      dispatch({
          type: MASTER_CATEGORY_DATA,
          payload : []
        });
    } else {
      dispatch(closeLoader());
    }
  } catch (error) {
    console.log("Error message",error)
    dispatch(closeLoader());
  }
};