import React, { useState } from 'react';
import { SkuGraphModel } from './SkuGraphModal';
const SkuLevelGraph = (props) => {
  console.log(props);
  const [show, setShow] = useState(false);
  const [selectedSku, setSelectedSku] = useState([]);

  const handleOpen = () => {
    console.log('skuSummaryGraph', props);
    console.log(
      'skuSummaryGraph.data.styel_color_id',
      props.data.styel_color_id
    );
    const newData = props.skuSummaryGraph.filter(
      (obj) => obj.styel_color_id === props.data.styel_color_id
    );
    setSelectedSku(newData);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      {show ? (
        <SkuGraphModel
          show={show}
          handleClose={handleClose}
          data={selectedSku}
        />
      ) : null}
      <div>
        <a href='javascript:void(0)' onClick={handleOpen}>
          {props.data.styel_color_id}
        </a>
      </div>
    </>
  );
};

export default SkuLevelGraph;
