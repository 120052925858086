import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Table } from '../../../../components/Table';
import { Button, Grid } from '@mui/material';
import { EditWeekData } from './editWeekData';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as css_constants from '../../../../constants/cssConstants';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const ScenarioModal = (props) => {
  const { show, type } = props;
  const [open, setOpen] = useState(props.show);
  const [driverForecastData, setDriverForecastData] = useState([...props.data]);
  const [dupDriverForecastData, setDupDriverForecastData] = useState([
    ...props.data,
  ]);

  useEffect(() => {
    console.log(props.data);
    setDriverForecastData([...props.data]);
    setDupDriverForecastData([...props.data]);
  }, []);

  const handleCellChange = (params) => {
    console.log('Hanlde Cell Change', params);
    const newData = dupDriverForecastData.map((obj) => {
      let updatedObj = { ...obj };
      if (obj.id === params.currentRow.id) {
        console.log('======', updatedObj);
        updatedObj[`${params.field}`] = params.value;
        return updatedObj;
      }
      return updatedObj;
    });
    console.log(newData);
    setDupDriverForecastData([...newData]);
  };

  const scenario_level_columns = [
    {
      field: 'drivers',
      headerName: 'Drivers',
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    // { field: 'all_weeks', headerName: 'All Weeks',
    // cellRenderer: 'editWeekData',
    // width: 110,
    // cellRendererParams: {
    //   handleCellChange,
    // }},
    {
      field: 'week_1',
      headerName: 'FW-202405',
      cellRenderer: 'editWeekData',
      width: 110,
      cellRendererParams: {
        handleCellChange,
        type: '%',
      },
    },
    {
      field: 'week_2',
      headerName: 'FW-202406',
      cellRenderer: 'editWeekData',
      width: 110,
      cellRendererParams: {
        handleCellChange,
      },
    },
    {
      field: 'week_3',
      headerName: 'FW-202407',
      cellRenderer: 'editWeekData',
      width: 110,
      cellRendererParams: {
        handleCellChange,
      },
    },
    {
      field: 'week_4',
      headerName: 'FW-202408',
      cellRenderer: 'editWeekData',
      width: 110,
      cellRendererParams: {
        handleCellChange,
      },
    },
    {
      field: 'week_5',
      headerName: 'FW-202409',
      cellRenderer: 'editWeekData',
      width: 110,
      cellRendererParams: {
        handleCellChange,
      },
    },
    {
      field: 'week_6',
      headerName: 'FW-202410',
      cellRenderer: 'editWeekData',
      width: 110,
      cellRendererParams: {
        handleCellChange,
      },
    },
    {
      field: 'week_7',
      headerName: 'FW-202411',
      cellRenderer: 'editWeekData',
      width: 110,
      cellRendererParams: {
        handleCellChange,
      },
    },
    {
      field: 'week_8',
      headerName: 'FW-202412',
      cellRenderer: 'editWeekData',
      width: 110,
      cellRendererParams: {
        handleCellChange,
      },
    },
  ];

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };
  const submitScenario = () => {
    props.submitScenario(dupDriverForecastData);
  };
  return (
    <React.Fragment>
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={open}
          onClose={handleClose}
          className={
            css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_DIALOG_CLASS
          }
        >
          <DialogTitle>Scenario Level {type} </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Table
                rowData={driverForecastData}
                columnDefs={scenario_level_columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                floatingFilter={false}
                height={'300px'}
                rowSelection={'multiple'}
                // columnFit={'sizeColumnsToFit'}
                frameworkComponents={{
                  editWeekData: EditWeekData,
                }}
                className={
                  css_constants.IMPACT_CSS_CLASS +
                  css_constants.IMPACT_TABLE_CLASS
                }
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions
            className={
              css_constants.IMPACT_CSS_CLASS +
              css_constants.IMPACT_DIALOGACTIONS_CLASS
            }
          >
            <Button
              onClick={submitScenario}
              className={
                css_constants.IMPACT_CSS_CLASS +
                css_constants.IMPACT_BUTTON_CSS_CLASS +
                css_constants.IMPACT_BUTTON_SUBMIT_CSS_CLASS
              }
            >
              Submit
            </Button>
            <Button
              onClick={handleClose}
              className={
                css_constants.IMPACT_CSS_CLASS +
                css_constants.IMPACT_BUTTON_CSS_CLASS +
                css_constants.IMPACT_BUTTON_CLOSE_CSS_CLASS
              }
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </React.Fragment>
  );
};
