import React, { useState,useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import SelectRenderer from '../../../../components/Select/SelectRenderer';

const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  headerCard: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
});

const useStyles = makeStyles(styles);

const FilterSection = (props) => {
  const { filters} = props;
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({})
  useEffect(() => {
    setSelectedOptions({})
  }, [])
  
  const handleSelect=(selectdObj,item)=>{
      let params = { ... selectedOptions}
      params[`${item.label}`] = selectdObj.selectedItems
      setSelectedOptions({
        ...params
      })
  }

  const handleApply =()=>{
    if(props.handleApply){
      props.handleApply(selectedOptions)
    }
  }
  const handleCancel = ()=>{
    setSelectedOptions({})
  }
  return (
    <>
    <div style={{ display: 'flex', margin: 5 }}><b>{filters.label && <>{filters.label}</>}</b></div>
    <span style={{ display: 'flex', margin: 5 }}>
      {filters.setions.map((item) => (
        <div style={{ margin: '1rem' }}>
          <SelectRenderer
            options={item.options}
            selectedItems={item.value}
            isMulti={true}
            width='12rem'
            filterLabel={item.label}
            updateSelected={(params)=>{
              console.log("Parms",params,item)
              handleSelect(params,item)
            }}
          />
        </div>
      ))}

      {filters.filterButtons && (
        <div style={{ margin: '2.4rem' }}>
          <Button
            style={{ marginLeft: 5, bottom: 0 }}
            size='small'
            variant='contained'
            onClick={handleApply}
          >
            Apply
          </Button>
          <Button
            style={{ marginLeft: 5, bottom: 0 }}
            size='small'
            variant='outlined'
            onClick={handleCancel}
          >
            Reset
          </Button>
        </div>
      )}
    </span>
    </>
  );
};

export default FilterSection;
