import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import CustomButton from '../components/CustomButton';
import FilterAltOutlined from '@mui/icons-material/FilterAltOutlined';
import Filters from '../components/FilterChips';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const styles = (theme) => ({
  filtersHeader: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    float: 'right',
    text: 'right',
  },
  filtersHeaderTitle: {
    ...theme.typography.fontSizes.sectionHeaderText,
    margin: '0',
    marginRight: 48,
    fontWeight: theme.typography.fontWeight.bold,
  },
});

const useStyles = makeStyles(styles);

const FiltersHead = ({
  filtersExpanded,
  setFiltersExpanded,
  screenName,
  filtersSummary,
  type,
}) => {
  const [filterValueExpanded, setFilterValueExpanded] = useState(false);
  const classes = useStyles();

  return (
    <>
      {type == 'Accordion' ? (
        <>
          <div class='filter_heading_container'>
            <div>
              <p className={classes.filtersHeaderTitle}>
                {'Executive Summary'}
              </p>
              <CustomButton
                isPrimary={false}
                variant='outlined'
                height={32}
                width={104}
                label='Filters'
                startIcon={<FilterListIcon />}
                onButtonClick={() => setFiltersExpanded(!filtersExpanded)}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='header_container'>
            <Grid className='rowsnew'>
              <Grid
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  padding: '0px',
                }}
                container
              >
                <Grid>
                  <h2 className='filter_heading'>
                    {screenName || 'Executive Summary'}
                  </h2>
                </Grid>
                <Grid>
                  <div className={'filter_btn_container'}>
                    {Object.keys(filtersSummary).length > 0 && (
                      <>
                        <Badge
                          badgeContent={Object.keys(filtersSummary).length}
                          color='info'
                        >
                          {filterValueExpanded ? (
                            <Tooltip title='Close filters'>
                              <IconButton>
                                <ExpandLessIcon
                                  className={'filter_icon_button'}
                                  fontSize={'medium'}
                                  onClick={() =>
                                    setFilterValueExpanded(!filterValueExpanded)
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title='Expand filters'>
                              <IconButton>
                                <ExpandMoreIcon
                                  className={'filter_icon_button'}
                                  fontSize={'medium'}
                                  onClick={() =>
                                    setFilterValueExpanded(!filterValueExpanded)
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Badge>
                      </>
                    )}
                    <CustomButton
                      // isPrimary={true}
                      // variant='outlined'
                      isPrimary={true}
                      variant='contained'
                      height={32}
                      width={154}
                      label='Select Filters'
                      startIcon={<FilterAltOutlined />}
                      onButtonClick={() =>
                        setFiltersExpanded(!filtersExpanded, filtersSummary)
                      }
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid className='row'>
                {!filtersExpanded &&
                  filterValueExpanded &&
                  Object.keys(filtersSummary).length > 0 && (
                    <Filters filtersSummary={filtersSummary} />
                  )}
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default FiltersHead;
