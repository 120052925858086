import React, { useEffect, useState, useMemo } from 'react';
import {
  getMultipleModelsData,
  orderMgtFinalizeAction,
} from '../../services/decisionDashboardService';
import { Table } from '../../../../components/Table';
import { EditWeekData } from '../ADAVisuals/editWeekData';
import { Button, ButtonGroup, Grid } from '@mui/material';
import styles from './index.module.scss';
import { useNavigate } from 'react-router';
import { Accordion, AccordionSummary, Typography } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';
import Snackbar from '../../../../components/SnackBar';
import { convertToInteger, deepDiveColumnsConvertedToInteger } from './data';

export const DeepDive = () => {
  const [displayMessage, setDisplayMessage] = React.useState(false);
  const dispatch = useDispatch();
  const [deepDiveData, setDeepDiveData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [copydDeepDiveData, setCopyDeepDiveData] = useState({});

  const navigate = useNavigate();
  useEffect(async () => {
    const result = await getMultipleModelsData({
      model_names: ['tb_order_mnt_deep_dive_copy'],
    });
    const { tb_order_mnt_deep_dive_copy } = result;
    // const tb_order_mnt_deep_dive_copy_data = convertToInteger(
    //   tb_order_mnt_deep_dive_copy,
    //   deepDiveColumnsConvertedToInteger
    // );
    setDeepDiveData(tb_order_mnt_deep_dive_copy);
  }, []);

  const handleClose = () => {
    setDisplayMessage(false);
  };

  const handleCellChange = (params) => {
    console.log('Hanlde Cell Change', params);
    const { field, value, currentRow } = params;
    if (Object.keys(params).length > 0) {
      let updateObj = {};
      let copydDeepDiveObj = { ...copydDeepDiveData };
      updateObj[`${field}`] = value;
      if (copydDeepDiveObj[`${currentRow.item}`]) {
        if (copydDeepDiveObj[`${currentRow.item}`][`${field}`]) {
          copydDeepDiveObj[`${currentRow.item}`][`${field}`] = value;
        } else {
          copydDeepDiveObj[`${currentRow.item}`] = {
            ...copydDeepDiveObj[`${currentRow.item}`],
            ...updateObj,
          };
        }
      } else {
        copydDeepDiveObj[`${currentRow.item}`] = updateObj;
      }
      setCopyDeepDiveData(copydDeepDiveObj);
      console.log('============', copydDeepDiveObj, copydDeepDiveData);
    }
  };
  const DeepDiveColumns = [
    {
      field: 'item',
      headerName: 'Item #',
      rowGroup: false,
      hide: false,
      // pinned: 'left',
      // headerCheckboxSelection: true,
      // checkboxSelection: true,
    },
    // {
    //   field: 'month',
    //   headerName: 'Month',
    //   // rowGroup: true,
    //   //  hide: true
    //   // pinned: 'left',
    // },
    {
      field: 'week_wise_date',
      headerName: 'Week Wise Date',
      rowGroup: false,
    },
    {
      field: 'bom_units',
      headerName: 'BOM Units',
      aggFunc: 'sum',
    },
    { field: 'store_oh', headerName: 'Store OH', aggFunc: 'sum' },
    { field: 'dc_oh', headerName: 'DC OH', aggFunc: 'sum' },

    {
      field: 'oo_units',
      headerName: 'OO Units',
      aggFunc: 'sum',
    },
    {
      field: 'late_on_order',
      headerName: 'Late On Order',
      aggFunc: 'sum',
    },
    {
      field: 'roq',
      headerName: 'ROQ',
      aggFunc: 'sum',
    },
    {
      field: 'sales_forecast',
      headerName: 'Sales Forecast',
      aggFunc: 'sum',
      // editable:isEdit,
      cellRenderer: 'editWeekData',
      cellRendererParams: {
        handleCellChange,
        disabled: !isEdit,
      },
    },
    {
      field: 'safety_stock',
      headerName: 'Safety Stock',
      // editable:isEdit,
      cellRenderer: 'editWeekData',
      cellRendererParams: {
        handleCellChange,
        disabled: !isEdit,
      },
      aggFunc: 'sum',
    },
    {
      field: 'aur',
      headerName: 'AUR',
      // editable:isEdit,
      cellRenderer: 'editWeekData',
      cellRendererParams: {
        handleCellChange,
        disabled: !isEdit,
      },
      aggFunc: 'sum',
    },
    {
      field: 'wos',
      headerName: 'WOS',
      aggFunc: 'sum',
    },
    {
      field: 'sell_through',
      headerName: 'Sell Through',
      aggFunc: 'sum',
    },
    {
      field: 'sales_units_to_period',
      headerName: 'Sales Units % To Period',
      aggFunc: 'sum',
    },
    {
      field: 'discount_off',
      headerName: 'Disc. Off',
      aggFunc: 'sum',
    },
    {
      field: 'md_percentage',
      headerName: 'MD %',
      aggFunc: 'sum',
    },
    {
      field: 'md_dollar',
      headerName: 'MD $',
      aggFunc: 'sum',
    },
  ];
  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const sendEmail = () => {
    setDisplayMessage(true);
  };

  const handleFinalize = () => {
    if (!isEdit) {
      dispatch(
        orderMgtFinalizeAction({
          isFinilizeChanged: true,
          finalizeData: copydDeepDiveData,
        })
      );
      navigate('/inventory-smart/order-management');
    }
  };
  const autoGroupColumnDef = useMemo(() => {
    return {
      headerValueGetter: (params) => `Item`,
      minWidth: 220,
      cellRendererParams: {
        suppressCount: true,
        checkbox: true,
      },
    };
  }, []);

  return (
    <>
      <div className='spacer'></div>
      <div className='spacer'></div>
      <Accordion
        expanded={true}
        // onChange={handleChange(obj.label)}
        style={{
          borderLeft: '2px solid rgb(0, 85, 175)',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={7} sm={4} md={7} lg={7} xl={9}>
                <p>Deep Dive</p>
              </Grid>

              <Grid item xs={5} sm={8} md={5} lg={5} xl={3} >
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{textAlign:"right"}}>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={sendEmail}
                      style={{ textTransform: 'none' }}
                    >
                      Share with supplier
                    </Button>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} style={{textAlign:"right"}}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleEdit}
                    style={{ textTransform: 'none' }}
                  >
                    {isEdit ? 'Update' : 'Edit'}
                  </Button>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
                  <ButtonGroup
                    variant='outlined'
                    aria-label='outlined button group'
                  >
                    <Button style={{ textTransform: 'none' }}>Charts</Button>
                    <Button style={{ textTransform: 'none' }}>Tabls</Button>
                  </ButtonGroup>
                </Grid>
                
              </Grid>
              
                {/* <Button
                  variant='contained'
                  color='primary'
                  onClick={sendEmail}
                  style={{ textTransform: 'none' }}
                >
                  Share with supplier
                </Button>
                &nbsp;&nbsp;
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleEdit}
                  style={{ textTransform: 'none' }}
                >
                  {isEdit ? 'Update' : 'Edit'}
                </Button>
                &nbsp;&nbsp;
                <ButtonGroup
                  variant='outlined'
                  aria-label='outlined button group'
                >
                  <Button style={{ textTransform: 'none' }}>Charts</Button>
                  <Button style={{ textTransform: 'none' }}>Tabls</Button>
                </ButtonGroup> */}
              </Grid>
            </Grid>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Table
                suppressColumnVirtualisation
                enableRangeSelection
                enableCharts
                columnDefs={DeepDiveColumns}
                rowData={deepDiveData}
                floatingFilter={false}
                pageSize={5}
                rowsPerPageOptions={[5]}
                height={'70vh'}
                suppressRowClickSelection={true}
                rowSelection={'multiple'}
                suppressClickEdit={false}
                frameworkComponents={{
                  editWeekData: EditWeekData,
                }}
                defaultColDef={{
                  editable: false,
                }}
                autoGroupColumnDef={autoGroupColumnDef}
                tableCssStyle='autoSizeAllColumns'
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div
            className={`${styles['flex-align-center']} ${styles['flex-justify-center']}`}
            style={{ padding: 10, display: 'flex', justifyContent: 'right' }}
          >
            {displayMessage && (
              <Snackbar
                message='Successfully shared with supplier!'
                variant='success'
                handleClose={handleClose}
              />
            )}
            <Button
              variant='contained'
              color='primary'
              onClick={handleFinalize}
              disabled={isEdit}
              style={{ textTransform: 'none' }}
            >
              Finalize
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
