import { ORDER_MANAGEMENT_DEEP_DIVE } from "../services/decisionDashboardService";

const initialState = {
  isFinilizeChanged: false,
  finalizeData:{}
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_MANAGEMENT_DEEP_DIVE:
      return {
        ...state,
        isFinilizeChanged: action.payload.isFinilizeChanged,
        finalizeData : action.payload.finalizeData
      };
    default:
      return state;
  }
};

export default commonReducer;
