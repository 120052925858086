import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import SelectRenderer from '../../../../components/Select/SelectRenderer';
import TextFieldWithLabel from '../../../../components/TextField/TextFieldWithLabel';
import CustomButton from '../../../../components/CustomButton';
import { CircularProgress } from '@mui/material';
import { fetchHierarchyData } from './action';

import { Grid, InputAdornment } from '@mui/material';


const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  borderCard: {
    ...theme.content.borderCard,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
  },
});
const useStyles = makeStyles(styles);

const SelectedLabels = (props) => {
  const classes = useStyles();
  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [stepValue, setStepValue] = useState(0);
  const [stepSize, setStepSize] = useState([]);
  let isLoading = props.isLoading;


  const updateStepSize = (e) => {
    setStepValue(parseInt(e.selectedItems[0].label));
  };

  const getData = async (label, content) => {
    let data = [];
    data = await fetchHierarchyData(label);
    
    if (data.length !== 0) {
      isLoading = false;
      if (content === 'stepSize') {
        setStepSize(data);
      }
    }
  };

  useEffect(() => {
    getData('tb_step_size', 'stepSize');
  }, []);



  const selectLabel = (label) => {
    return (
      <>
        {label} <span style={{ color: 'red' }}> * </span>
      </>
    );
  };

  return (
    
    <>
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress className={classes.loader} />
        </div>
      )}

      {!isLoading && (
        
          <Grid container spacing={2}>
            <Grid xs={2}>
              <>
                <div>
                  <p
                    style={{
                      marginLeft: '3rem',marginRight: '3rem',marginTop: '3rem',
                    }}
                  >
                    {props.label}
                  </p>
                </div>
              </>
            </Grid>
            <Grid xs={5}>
              <>
                <div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginTop: '2rem', marginLeft:'2rem' }}>
                      <TextFieldWithLabel
                        type='number'
                        label={selectLabel('Max')}
                        onChange={(e) => setMaxValue(parseInt(e.target.value))}
                        defaultValue={0}
                        width='6rem'
                        endAdornment={
                          <InputAdornment position='end'>%</InputAdornment>
                        }
                      />
                    </div>
                    <div style={{ marginLeft: '2rem', marginTop:'2rem' }}>
                      <TextFieldWithLabel
                        type='number'
                        label={selectLabel('Min')}
                        defaultValue={0}
                        width='6rem'
                        onChange={(e) => setMinValue(parseInt(e.target.value))}
                        endAdornment={
                          <InputAdornment position='end'>%</InputAdornment>
                        }
                      />
                    </div>
                    <div style={{ marginLeft: '2rem', marginTop:'2rem' }}>
                      <SelectRenderer
                        filterLabel={'% Step Size'}
                        isMandatory={true}
                        height='20rem'
                        width='6rem'
                        overflow={true}
                        isMulti={false}
                        options={stepSize}
                        updateSelected={updateStepSize}
                      />
                    </div>
                    
                    <div style={{ marginLeft: '2rem' , marginTop:'2rem'}}>
                      <TextFieldWithLabel
                        type='number'
                        label='Scenario Count'
                        defaultValue={0}
                        width='6rem'
                        disabled={true}
                        value={parseInt((maxValue - minValue) / stepValue + 1)}
                      />
                    </div>
                  </div>
                </div>
              </>
            </Grid>
          </Grid>
      )}
    </>
  );
};

export default SelectedLabels;
