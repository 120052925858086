import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';

export default function PreAllocationReview() {
  const dispatch = useDispatch();
  const showProductTable = () => {
    dispatch({
      type: 'SHOW_PRODUCT_TABLE',
      payload: true,
    });
  };
  return [
    {
      field: 'PO_ID',
      headerName: 'PO ID',
      pinned: 'left',
      width: 180,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: 'vendor_id',
      headerName: 'Vendor ID',
      pinned: 'left',
      width: 160,
    },
    {
      field: '#_style_colors',
      headerName: '# Style colors',
      width: 160,
    },
    {
      field: 'validity_start',
      headerName: 'Validity Start',
      width: 160,
    },
    {
      field: 'OH+IT+OO',
      headerName: 'OH+IT+OO',
      width: 200,
    },
    { field: 'ecomm_sales_units', headerName: 'Ecomm Sales Units', width: 200 },
    { field: 'lw_sales_units', headerName: 'LW Sales Units', width: 160 },
    { field: 'target_quantity', headerName: 'Target Quantity', width: 160 },
    { field: 'open_target_quantity', headerName: 'Open Target Quantity', width: 200 },
    {
      field: 'action',
      headerName: 'Action',
      cellRendererFramework: (cell) => (
        <Button
          style={{ textTransform: 'none' }}
          onClick={showProductTable}
          autoFocus
          variant='text'
        >
          {'Review >'}
        </Button>
      ),
    },
  ];
}

