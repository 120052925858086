import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import SelectRenderer from '../../../../components/Select/SelectRenderer';
import { CircularProgress,Grid } from '@mui/material';
import CustomButton from '../../../../components/CustomButton';
import Table from '../../../../components/Table/Table';
import { forecast_alert_columns, sku_ids } from './data';
import Card from '@mui/material/Card';
import { fetchHierarchyData } from './action';
import FiltersList from '../../../../molecules/FiltersList';
import Snackbar from '../../../../components/SnackBar';

const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
  },
});

const useStyles = makeStyles(styles);

const ForecastAlertReview = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dateRange = '02-01-2022 - 03-30-2022';
  const [isLoading, setIsLoading] = React.useState(true);
  const [metrics, setMetrics] = React.useState([]);
  const [reviewTable, setReviewTable] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [selectedFilters, setSelectedFilters] = React.useState({});
  const [displayMessage, setDisplayMessage] = React.useState(false);

  const getData = async (label, content) => {
    setIsLoading(true);
    const data = await fetchHierarchyData(label);
    if (data.length !== 0) {
      setIsLoading(false);
      if (content === 'metrics') setMetrics(data);
      else setReviewTable(data);
    }
  };

  useEffect(() => {
    getData('tb_review_metrics', 'metrics');
    getData('tb_forecast_alert_review', 'review_table');
  }, []);

  const selectedModule = (e) => {
    return null;
  };

  const filterHandler = () => {
    return null;
  };
  const reloadFilters = async () => {
  };

  const openLoader = () => {
    console.log('Open Loader');
    setLoader(true);
  };
  const closeLoader = () => {
    setLoader(false);
  };
  const updateState = (params) => {
    console.log('Updating state', params);
    setSelectedFilters(params);
  };
  const appliedFiltersAction = async () => {
    console.log('Seldcted filters', selectedFilters);
  };
  const onReset = () => {
    reloadFilters();
  };
  const handleFinalize = () => {
    setDisplayMessage(true);
    setTimeout(() => {
      navigate('inventory-smart/decision-dashboard');
    }, 2000);
  }

  const handleClose = () => {
    setDisplayMessage(false);
  };

  return (
    <>
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress className={classes.loader} />
        </div>
      )}

      {!isLoading && (
        <Card
          style={{
            display: 'block',
            height: '50vw',
          }}
        >
          <Grid container spacing={2}>
          <Grid item xs={12}>
            <FiltersList
              className='impact impact-forecast-alert-review-filters'
              openLoader={openLoader}
              closeLoader={closeLoader}
              updateParentState={updateState}
              appliedFilters={appliedFiltersAction}
              onReset={onReset}
              screenName={'Review Recommendation'}
            />
          </Grid>
          </Grid>
          <div style={{ padding: '0.75rem' }}>
          {displayMessage && (<Snackbar message='Forecast Approved' variant='success' handleClose={handleClose} /> )}
            {/* <p style={{ fontWeight: 'bold' }}>Review Recommendation</p> */}
            {/* <div style={{ display: 'flex' }}>
          {displayMessage && (<Snackbar message='Forecast Approved' variant='success' handleClose={handleClose} /> )}
            <p style={{ fontWeight: 'bold' }}>Review Recommendation</p>
            <div style={{ display: 'flex' }}>
              <SelectRenderer
                options={sku_ids}
                isMandatory={true}
                isMulti={false}
                width='15rem'
                filterLabel={'SKU ID'}
                updateSelected={selectedModule}
              />
              <div style={{ marginLeft: '2rem', marginTop: '1rem' }}>
                <CustomButton
                  isPrimary={true}
                  variant='contained'
                  height={32}
                  width={75}
                  label='Filter'
                  onButtonClick={() => filterHandler()}
                />
              </div>
              <div style={{ marginLeft: '1rem', marginTop: '1rem' }}>
                <CustomButton
                  isPrimary={true}
                  variant='outlined'
                  height={32}
                  width={75}
                  label='Reset'
                  onButtonClick={() => filterHandler()}
                />
              </div>
            </div> */}
            <div style={{ display: 'flex' }}>
              <p style={{ marginTop: '2rem', fontSize: 'small' }}>
                Date Range:
              </p>
              <p
                style={{
                  marginLeft: '0.25rem',
                  marginTop: '2rem',
                  fontSize: 'small',
                  color: 'grey',
                }}
              >
                {dateRange}
              </p>
              {/* <div style={{ margin: '1.25rem', marginLeft: '2.1rem' }}>
                <SelectRenderer
                  options={metrics}
                  isMulti={false}
                  width='15rem'
                  updateSelected={selectedModule}
                />
              </div> */}
            </div>
            <Table
              className='impact impact-forecast-alert-review-table'
              rowData={reviewTable}
              columnDefs={forecast_alert_columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              floatingFilter={false}
              height={'450px'}
              rowSelection={'multiple'}
            />
            <div className={classes.saveButton} style={{ display: 'flex', justifyContent: 'right' }}>
              <CustomButton
                isPrimary={true}
                variant='contained'
                height={36}
                width={150}
                label='Review Forecast'
                padding='100'
                margin={10}
                onButtonClick={() => navigate('/inventory-smart/ada_visuals')}
              />
              <CustomButton
                isPrimary={true}
                variant='outlined'
                height={36}
                width={150}
                label='Approve Forecast'
                padding='100'
                margin={10}
                onButtonClick={handleFinalize}
              />
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default ForecastAlertReview;
