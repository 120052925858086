import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import CustomCard from '../../../../components/CustomCard';
import CustomAccordion from '../../../../components/CustomAccordion';
import FiltersHead from '../../../../molecules/FiltersHead';
import FiltersContainer from '../../../../molecules/FiltersContainer';
import DynamicFilters from '../../../../molecules/DynamicFilters';
import { GetFilters } from '../../../../services/filters';
import DashboardSummary from './DashboardSummary';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import moment from 'moment'
import { filtersListAction } from '../../actions/executiveSummaryActions';
const styles = (theme) => ({
  headerCard: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
});

const useStyles = makeStyles(styles);

const CarterExecutiveSummary = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filterMenu } = useSelector((state) => state.execSummary);
  console.log(filterMenu)
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [filtersHeaderData, setFiltersHeaderData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});
  const [loader, setLoader] = useState(false);
  // const [filterMenu, setFilterMenu] = useState([]);
  const [dynamicFiltersProps, setDynamicFiltersProps] = useState([]);
  const [dynamicFiltersOptions, setDynamicFiltersOptions] = useState([]);
  const [independentApiCalls, setIndependentApiCalls] = useState([]);
  const [filterData, setFilterData] = useState({}); 

  const {
    isLoading,
    data: filters,
    refetch: refetchFilters,
    isFetching,
  } = GetFilters({});
  useEffect(async() => {
    await dispatch(filtersListAction({
      screen_name: "Executive Summary"
    }))
    refetchFilters();
  }, []);

  const reloadFilters =async()=>{
    const filterObj={}
      await filterMenu.forEach(obj=>{
        if(obj.type === 'datetime'){
          filterObj[`${obj.label}`] =[new Date(moment().subtract(1,'years')),new Date(moment())] 
        }else{
          filterObj[`${obj.label}`] =[]
        }
        // filterObj[`${obj.label}Options`] =[]
      })
      setFilterData(filterObj)
  }
  useEffect(async() => {
      console.log(filterMenu)
      reloadFilters()
  }, [filterMenu]);

  useEffect(() => {
    if(Object.keys(filterData).length > 0){
      const filtersPropsList = []
      const filtersOptionsList = {}
      const independentApis = []

      filterMenu.forEach(obj=>{
        let isDependentOn =  filterMenu.filter(filterObj=> filterObj.is_dependent_on ===  obj.id)

        filtersPropsList.push({
          type: obj.type,
          placeholder: `Select ${obj.label}`,
          state: filterData[`${obj.label}`],
          mappingKey: obj.label,
          label: obj.label,
          required: obj.is_required,
          is_dependent_on: obj.is_dependent_on,
          id: obj.id,
          isDependentOn
        })
        filtersOptionsList[`${obj.label}Options`] = []
        if(!obj.is_dependent){
          independentApis.push({
                  optionsMappingKey: obj.label,
                  UrlName: obj.label,
          })
        }

      })
      
      setDynamicFiltersProps(filtersPropsList)
      setDynamicFiltersOptions(filtersOptionsList)
      setIndependentApiCalls(independentApis)
    }
  }, [filterData])
  

  useEffect(() => {
    if (filters && filters?.length > 0) {
      setFilters(filters);
    }
  }, [filters]);
  
  const openLoader = () => {
    setLoader(true)
  };
  const closeLoader = () => {
    setLoader(false)
  };
  const updateState = (params) => {
    console.log("Updating state",params)
    setFilterData({
      ...filterData,
      ...params
    })
  };


  const handleOnSelect = ({ selectedItems, type }) => {
    const filters = { ...selectedFilters };
    filters[type] = selectedItems;

    if (selectedItems?.length === 0) {
      filtersHeaderData.forEach((filterHeader) => {
        if (
          (filterHeader.type === 'select' ||
            filterHeader.type === 'multi-select') &&
          filterHeader.id >= type
        ) {
          filters[filterHeader.id] = [];
        }
      });
    }

    setSelectedFilters(filters);
  };

  const handleDateChange = (value, index) => {
    const filters = { ...selectedFilters };
    filters[index] = value;

    setSelectedFilters(filters);
  };

  const handleDateRangeChange = (values, index) => {
    const filters = { ...selectedFilters };
    filters[index] = values;

    setSelectedFilters(filters);
  };

  const handleCheckboxChange = (e, index) => {
    const filters = { ...selectedFilters };

    const values = { ...selectedFilters[index] };
    values[e.target.name] = !values[e.target.name];

    filters[index] = { ...values };
    setSelectedFilters(filters);
  };

  const applyFilter = () => {
    const filters = { ...selectedFilters };

    for (let i = 0; i < filtersHeaderData.length; i++) {
      if (
        (filtersHeaderData[i].type === 'multi-select' ||
          filtersHeaderData[i].type === 'single-select') &&
        filters[filtersHeaderData[i].id]?.length > 0
      ) {
        setAppliedFilters(filters);
        break;
      }
    }
  };

  const resetFilters = () => {
    let filters = {};

    filtersHeaderData.forEach((header) => {
      if (header.type === 'date') {
        const values = [];
        values.push(new Date());

        filters[header.id] = values;
      } else if (header.type === 'checkboxes') {
        const obj = {};

        header.filter_values.forEach((field) => {
          obj[field.value] = false;
        });

        filters[header.id] = obj;
      } else if (header.type === 'datetime') {
        const values = [];
        values.push(new Date());
        values.push(new Date());

        filters[header.id] = values;
      } else filters[header.id] = [];
    });
    setSelectedFilters(filters);
    setAppliedFilters({});
  };
  
  const setFilters = (filters) => {
    let selectedFiltersValues = {};

    filters?.forEach((header) => {
      if (header.type === 'date') {
        const values = [];
        values.push(new Date());

        selectedFiltersValues[header.id] = values;
      } else if (header.type === 'checkboxes') {
        const obj = {};

        header.filter_values.forEach((field) => {
          obj[field.value] = false;
        });

        selectedFiltersValues[header.id] = obj;
      } else if (header.type === 'datetime') {
        const values = [];
        values.push(new Date());
        values.push(new Date());

        selectedFiltersValues[header.id] = values;
      } else {
        selectedFiltersValues[header.id] = [];
      }
    });

    setSelectedFilters(selectedFiltersValues);
    setFiltersHeaderData(filters);
  };

  const appliedFiltersAction = async() => {
    console.log(filterData)
  };
  const onReset =()=>{
    reloadFilters()
  }

  return (
    <div>
      {(isLoading || loader) ? (
        <LoadingOverlay
          loader
          text='Fetching Data'
          position='relative'
          background='transparent'
          // color='#fff'
        />
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomCard cardStyles={classes.headerCard}>
            {dynamicFiltersProps.length > 0 ? (
              <>
              <CustomAccordion
              summary={
                <FiltersHead
                  filtersExpanded={filtersExpanded}
                  setFiltersExpanded={setFiltersExpanded}
                />
              }
              details={
                // <FiltersContainer
                //   filters={filtersHeaderData}
                //   selectedFilters={selectedFilters}
                //   handleOnSelect={handleOnSelect}
                //   handleDateChange={handleDateChange}
                //   handleDateRangeChange={handleDateRangeChange}
                //   handleCheckboxChange={handleCheckboxChange}
                //   applyFilter={applyFilter}
                //   resetFilters={resetFilters}
                // />
                <DynamicFilters
                filtersList={dynamicFiltersProps}
                options={dynamicFiltersOptions}
                hierarchyApiCalls={independentApiCalls}
                openLoader={openLoader}
                closeLoader={closeLoader}
                updateParentState={updateState}
                appliedFilters={appliedFiltersAction}
                onReset={onReset}
                screenName="Executive Summary"
              />
              }
              expanded={filtersExpanded}
            />
              </>
            ) : null }
            
            
          </CustomCard>
        </Grid>

        <Grid item xs={12}>
          <DashboardSummary appliedFilters={filterData} />
        </Grid>
      </Grid>
    </div>
  );
};

export default CarterExecutiveSummary;
