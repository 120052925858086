import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CellTextField from '../../../../components/Table/CellTextField';
import CustomButton from '../../../../components/CustomButton';
import { Table } from '../../../../components/Table';
import { getMultipleModelsData } from '../../services/decisionDashboardService';
import Select from '../../../../components/Select/Select';
import FiltersList from '../../../../molecules/FiltersList';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import DatePicker from 'react-date-picker';
import './DatePicker.scss';
import CalendarMonthOutlined from '@mui/icons-material/CalendarMonthOutlined';
import styles from './index.module.scss';
import TextFieldWithLabel from '../../../../components/TextField/TextFieldWithLabel';
import SnackBar from '../../../../components/SnackBar';
// const styles = (theme) => ({
//   headerCard: {
//     ...theme.content.card,
//     width: '100%',
//     height: '100%',
//     overflow: theme.content.overflow.visible,
//   },
//   container: {
//     ...theme.content.flexStyles.flexRow,
//     ...theme.content.flexStyles.flexAlignBetweenCenter,
//   },
// });

// const useStyles = makeStyles(styles);

const productProfileTabs = ['User Created', 'IA Recommended'];
const sizeAndStoreTabs = ['Penetration', 'Style Color Description'];
const channelOptions = [
  { label: 'Factory Line Reatil', value: 'Factory Line Reatil' },
  { label: 'Full Line Reatil', value: 'Full Line Reatil' },
];
const departmentOptions = [
  {
    label: 'Apparel',
    value: 'Apparel',
  },
  {
    label: 'Baby Bags',
    value: 'Baby Bags',
  },
  {
    label: 'Backpacks/Lunch Bags',
    value: 'Backpacks/Lunch Bags',
  },
  {
    label: 'Fashion Accessories',
    value: 'Fashion Accessories',
  },
  {
    label: 'Footwear',
    value: 'Footwear',
  },
  {
    label: 'Handbag Accessories',
    value: 'Handbag Accessories',
  },
  {
    label: 'Handbags',
    value: 'Handbags',
  },
  {
    label: 'Home',
    value: 'Home',
  },
  {
    label: 'Jewelry',
    value: 'Jewelry',
  },
  {
    label: 'Luggage',
    value: 'Luggage',
  },
  {
    label: 'Other',
    value: 'Other',
  },
  {
    label: 'Stationery',
    value: 'Stationery',
  },
  {
    label: 'Tech Accessories',
    value: 'Tech Accessories',
  },
  {
    label: 'Travel Accessories',
    value: 'Travel Accessories',
  },
  {
    label: 'Visual Merchandising',
    value: 'Visual Merchandising',
  },
];
const classOptions = [
  {
    label: 'Anklets',
    value: 'Anklets',
  },
  {
    label: 'Baby Apparel/Accessories',
    value: 'Baby Apparel/Accessories',
  },
  {
    label: 'Baby Bags',
    value: 'Baby Bags',
  },
  {
    label: 'Backpacks',
    value: 'Backpacks',
  },
  {
    label: 'Bottoms',
    value: 'Bottoms',
  },
  {
    label: 'Bracelets',
    value: 'Bracelets',
  },
  {
    label: 'Clothing',
    value: 'Clothing',
  },
  {
    label: 'Clutches',
    value: 'Clutches',
  },
  {
    label: 'Cold Weather Accessories',
    value: 'Cold Weather Accessories',
  },
  {
    label: 'Cosmetics',
    value: 'Cosmetics',
  },
  {
    label: 'Crossbodies',
    value: 'Crossbodies',
  },
  {
    label: 'Decor',
    value: 'Decor',
  },
  {
    label: 'Earrings',
    value: 'Earrings',
  },
  {
    label: 'Eyewear',
    value: 'Eyewear',
  },
  {
    label: 'Fashion Scarves',
    value: 'Fashion Scarves',
  },
  {
    label: 'Footwear',
    value: 'Footwear',
  },
  {
    label: 'Hair Accessories',
    value: 'Hair Accessories',
  },
  {
    label: 'IDs/Keychains',
    value: 'IDs/Keychains',
  },
  {
    label: 'Laptop/Tablet Accessories',
    value: 'Laptop/Tablet Accessories',
  },
  {
    label: 'Lunch Bags',
    value: 'Lunch Bags',
  },
  {
    label: 'Necklaces',
    value: 'Necklaces',
  },
  {
    label: 'Non-Paper Stationery',
    value: 'Non-Paper Stationery',
  },
  {
    label: 'Other',
    value: 'Other',
  },
  {
    label: 'Other Fashion Accessories',
    value: 'Other Fashion Accessories',
  },
  {
    label: 'Other Handbag Accessories',
    value: 'Other Handbag Accessories',
  },
  {
    label: 'Other Home',
    value: 'Other Home',
  },
  {
    label: 'Other Jewelry',
    value: 'Other Jewelry',
  },
  {
    label: 'Other Tech Accessories',
    value: 'Other Tech Accessories',
  },
  {
    label: 'Outerwear',
    value: 'Outerwear',
  },
  {
    label: 'Paper Stationery',
    value: 'Paper Stationery',
  },
  {
    label: 'Phone Accessories',
    value: 'Phone Accessories',
  },
  {
    label: 'Rings',
    value: 'Rings',
  },
  {
    label: 'Rolling Luggage',
    value: 'Rolling Luggage',
  },
  {
    label: 'Satchels',
    value: 'Satchels',
  },
  {
    label: 'Sleepwear',
    value: 'Sleepwear',
  },
  {
    label: 'Swimwear',
    value: 'Swimwear',
  },
  {
    label: 'Textiles',
    value: 'Textiles',
  },
  {
    label: 'Tops',
    value: 'Tops',
  },
  {
    label: 'Totes',
    value: 'Totes',
  },
  {
    label: 'Travel Bags',
    value: 'Travel Bags',
  },
  {
    label: 'Travel/Packing Accessories',
    value: 'Travel/Packing Accessories',
  },
  {
    label: 'Visual Merchandising',
    value: 'Visual Merchandising',
  },
  {
    label: 'Wallets',
    value: 'Wallets',
  },
  {
    label: 'Wristlets',
    value: 'Wristlets',
  },
];
const subClassOptions = [
  { label: 'Immune Health', value: 'Immune Health' },
  { label: 'Multivitamins', value: 'Multivitamins' },
  { label: 'Baby & Child Vitamins', value: 'Baby & Child Vitamins' },
  { label: 'Beauty Supplements', value: 'Beauty Supplements' },
  { label: 'Joint Health', value: 'Joint Health' },
];
const timePeriodOptions = [
  { label: 'Last 30 days', value: 'Last 30 days' },
  { label: 'Last 90 days', value: 'Last 90 days' },
  { label: 'Last 180 days', value: 'Last 180 days' },
  { label: 'Last 365 days', value: 'Last 365 days' },
];
const businessCategoryOptions = [
  { label: 'Accessories', value: 'Accessories' },
  { label: 'Apparel/Footwear', value: 'Apparel/Footwear' },
  { label: 'Bags', value: 'Bags' },
  { label: 'Home', value: 'Home' },
  { label: 'Other', value: 'Other' },
  { label: 'Stationary', value: 'Stationary' },
];
const colorCodeOptions = [
  {
    label: '005',
    value: '005',
  },
  {
    label: '010',
    value: '010',
  },
  {
    label: '023',
    value: '023',
  },
  {
    label: '033',
    value: '033',
  },
  {
    label: '050',
    value: '050',
  },
  {
    label: '052',
    value: '052',
  },
  {
    label: '052481',
    value: '052481',
  },
  {
    label: '061',
    value: '061',
  },
  {
    label: '063',
    value: '063',
  },
  {
    label: '068',
    value: '068',
  },
  {
    label: '069',
    value: '069',
  },
  {
    label: '073',
    value: '073',
  },
  {
    label: '077',
    value: '077',
  },
];
const sellingCollection = [
  {
    label: '1982',
    value: '1982',
  },
  {
    label: 'aspen',
    value: 'aspen',
  },
  {
    label: 'baby',
    value: 'baby',
  },
  {
    label: 'bath',
    value: 'bath',
  },
  {
    label: 'b.b. collection',
    value: 'b.b. collection',
  },
  {
    label: 'beach',
    value: 'beach',
  },
  {
    label: 'beach babe',
    value: 'beach babe',
  },
  {
    label: 'bedding',
    value: 'bedding',
  },
  {
    label: 'birthstone',
    value: 'birthstone',
  },
  {
    label: 'bitty charm',
    value: 'bitty charm',
  },
  {
    label: 'california dreamin',
    value: 'california dreamin',
  },
  {
    label: 'carryall',
    value: 'carryall',
  },
  {
    label: 'carson',
    value: 'carson',
  },
  {
    label: 'casual leather',
    value: 'casual leather',
  },
];
const primaryFabrication = [
  {
    label: 'accessories',
    value: 'accessories',
  },
  {
    label: 'acrylic/melamine',
    value: 'acrylic/melamine',
  },
  {
    label: 'b.b. collection',
    value: 'b.b. collection',
  },
  {
    label: 'bracelets',
    value: 'bracelets',
  },
  {
    label: 'cotton',
    value: 'cotton',
  },
  {
    label: 'cotton looped terry',
    value: 'cotton looped terry',
  },
  {
    label: 'denim',
    value: 'denim',
  },
  {
    label: 'digital',
    value: 'digital',
  },
  {
    label: 'elastic',
    value: 'elastic',
  },
  {
    label: 'fleece',
    value: 'fleece',
  },
  {
    label: 'foldable luggage',
    value: 'foldable luggage',
  },
  {
    label: 'french terry',
    value: 'french terry',
  },
  {
    label: 'gallatin',
    value: 'gallatin',
  },
  {
    label: 'hardside luggage',
    value: 'hardside luggage',
  },
  {
    label: 'knit cotton/spandex',
    value: 'knit cotton/spandex',
  },
  {
    label: 'lighten up',
    value: 'lighten up',
  },
  {
    label: 'metal',
    value: 'metal',
  },
  {
    label: 'mfo poly',
    value: 'mfo poly',
  },
  {
    label: 'microfiber',
    value: 'microfiber',
  },
  {
    label: 'northbrook',
    value: 'northbrook',
  },
  {
    label: 'other fabrication',
    value: 'other fabrication',
  },
  {
    label: 'performance twill',
    value: 'performance twill',
  },
  {
    label: 'poly',
    value: 'poly',
  },
  {
    label: 'pop',
    value: 'pop',
  },
  {
    label: 'preppy poly',
    value: 'preppy poly',
  },
  {
    label: 'recycled cotton',
    value: 'recycled cotton',
  },
  {
    label: 'recycled lighten up',
    value: 'recycled lighten up',
  },
  {
    label: 'recycled microfiber',
    value: 'recycled microfiber',
  },
  {
    label: 'recycled nylon',
    value: 'recycled nylon',
  },
  {
    label: 'recycled ripstop',
    value: 'recycled ripstop',
  },
  {
    label: 'ribbon',
    value: 'ribbon',
  },
  {
    label: 'ripstop',
    value: 'ripstop',
  },
  {
    label: 'saffiano',
    value: 'saffiano',
  },
  {
    label: 'stainless steel',
    value: 'stainless steel',
  },
  {
    label: 'storm cotton',
    value: 'storm cotton',
  },
  {
    label: 'streeterville',
    value: 'streeterville',
  },
  {
    label: 'string',
    value: 'string',
  },
  {
    label: 'string metal',
    value: 'string metal',
  },
  {
    label: 'sycamore',
    value: 'sycamore',
  },
  {
    label: 'traditional luggage',
    value: 'traditional luggage',
  },
  {
    label: 'velvet',
    value: 'velvet',
  },
  {
    label: 'wildwood',
    value: 'wildwood',
  },
  {
    label: null,
    value: null,
  },
];
export const ProductProfile = () => {
  // const dispatch = useDispatch();
  // const classes = useStyles();
  // const { filterMenu } = useSelector((state) => state.execSummary);
  // const [filtersExpanded, setFiltersExpanded] = useState(false);
  // const [appliedFilters, setAppliedFilters] = useState({});
  // const [loader, setLoader] = useState(false);
  // // const [filterMenu, setFilterMenu] = useState([]);
  // const [dynamicFiltersProps, setDynamicFiltersProps] = useState([]);
  // const [dynamicFiltersOptions, setDynamicFiltersOptions] = useState([]);
  // const [independentApiCalls, setIndependentApiCalls] = useState([]);
  // const [filterData, setFilterData] = useState({});
  // const [tabIndex, setTabIndex] = useState(0);
  // useEffect(async () => {
  //   await dispatch(
  //     filtersListAction({
  //       screen_name: 'Executive Summary',
  //     })
  //   );
  // }, []);

  // const reloadFilters = async () => {
  //   const filterObj = {};
  //   await filterMenu.forEach((obj) => {
  //     if (obj.type === 'datetime') {
  //       filterObj[`${obj.label}`] = [
  //         new Date(moment().subtract(1, 'years')),
  //         new Date(moment()),
  //       ];
  //     } else {
  //       filterObj[`${obj.label}`] = [];
  //     }
  //     // filterObj[`${obj.label}Options`] =[]
  //   });
  //   setFilterData(filterObj);
  // };
  // useEffect(async () => {
  //   console.log(filterMenu);
  //   reloadFilters();
  // }, [filterMenu]);

  // useEffect(() => {
  //   if (Object.keys(filterData).length > 0) {
  //     const filtersPropsList = [];
  //     const filtersOptionsList = {};
  //     const independentApis = [];

  //     filterMenu.forEach((obj) => {
  //       let isDependentOn = filterMenu.filter(
  //         (filterObj) => filterObj.is_dependent_on === obj.id
  //       );

  //       filtersPropsList.push({
  //         type: obj.type,
  //         placeholder: `Select ${obj.label}`,
  //         state: filterData[`${obj.label}`],
  //         mappingKey: obj.label,
  //         label: obj.label,
  //         required: obj.is_required,
  //         is_dependent_on: obj.is_dependent_on,
  //         id: obj.id,
  //         isDependentOn,
  //       });
  //       filtersOptionsList[`${obj.label}Options`] = [];
  //       if (!obj.is_dependent) {
  //         independentApis.push({
  //           optionsMappingKey: obj.label,
  //           UrlName: obj.label,
  //         });
  //       }
  //     });

  //     setDynamicFiltersProps(filtersPropsList);
  //     setDynamicFiltersOptions(filtersOptionsList);
  //     setIndependentApiCalls(independentApis);
  //   }
  // }, [filterData]);

  // const openLoader = () => {
  //   setLoader(true);
  // };
  // const closeLoader = () => {
  //   setLoader(false);
  // };
  // const updateState = (params) => {
  //   console.log('Updating state', params);
  //   setFilterData({
  //     ...filterData,
  //     ...params,
  //   });
  // };
  // const appliedFiltersAction = async () => {
  //   console.log(filterData);
  // };
  // const onReset = () => {
  //   reloadFilters();
  // };
  // const handleTabChange = (_, newValue) => {
  //   setTabIndex(newValue);
  // };
  // return (
  //   <div>
  //     <p> Product Profile </p>
  //     <Grid container spacing={2}>
  //       <Grid item xs={12}>
  //         <CustomCard cardStyles={classes.headerCard}>
  //           <Grid xs={12}>
  //             <SimpleTabs
  //               items={[
  //                 {
  //                   label: 'IA Recommended',
  //                   id: 'IA_Recommended',
  //                 },
  //                 {
  //                   label: 'User Created',
  //                   id: 'User_Created',
  //                 },
  //               ]}
  //               value={tabIndex}
  //               handleChange={handleTabChange}
  //               customClasses={{
  //                 indicator: classes.tabIndicator,
  //                 tab: classes.tab,
  //                 activeTab: classes.activeTab,
  //               }}
  //             />
  //           </Grid>
  //           <div className='spacer'></div>
  //           <Grid xs={12}>
  //             <div>
  //               <DynamicFilters
  //                   filtersList={dynamicFiltersProps}
  //                   options={dynamicFiltersOptions}
  //                   hierarchyApiCalls={independentApiCalls}
  //                   openLoader={openLoader}
  //                   closeLoader={closeLoader}
  //                   updateParentState={updateState}
  //                   appliedFilters={appliedFiltersAction}
  //                   onReset={onReset}
  //                   screenName='Executive Summary'
  //               />
  //             </div>
  //           </Grid>
  //           <div className='spacer'></div>
  //           <hr/>
  //           <div className='spacer'></div>
  //           <div className='spacer'></div>
  //           <div className='spacer'></div>
  //           <ProductTable />
  //         </CustomCard>
  //       </Grid>
  //     </Grid>
  //     <div className="spacer"></div>
  //     <SizeContribution />

  //   </div>
  // );

  const [loader, setLoader] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isCreatingProfile, setIsCreatingProfile] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [timePeriod, setTimePeriod] = useState('static');
  const [createProductProfileStep, setCreateProductProfileStep] = useState(1);
  const [productProfileRowData, setProductProfileRowData] = useState([]);
  const [productProfileColumnDefs, setProductProfileColumnDefs] = useState([
    {
      field: 'productProfile',
      headerName: 'Product Profile',
      width: 200,
    },
    {
      field: 'department',
      headerName: 'Department',
    },
    {
      field: 'category',
      headerName: 'Category',
    },
    {
      field: 'class',
      headerName: 'Class',
    },
    {
      field: 'timePeriod',
      headerName: 'Time Period',
    },
    {
      field: 'salesAttribute',
      headerName: 'Sales Attribute',
    },
    {
      field: 'productAttribute',
      headerName: 'Product Attribute',
    },
  ]);
  const [sizeAndStoreTabIndex, setSizeAndStoreTabIndex] = useState(0);
  const [penetrationRowData, setPenetrationRowData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [displayMessage, setDisplayMessage] = React.useState(false);
  const [penetrationColumnDefs, setPenetrationColumnDefs] = useState([
    {
      field: 'storeNumber',
      headerName: 'Store Number',
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: 'overallPenetration',
      headerName: 'Overall Penetration',
    },
    {
      headerName: 'Product Profile Pen %',
      children: [
        { field: '6b', headerName: '6B' },
        { field: '7b', headerName: '7B' },
        { field: '8b', headerName: '8B' },
        { field: '9b', headerName: '9B' },
        { field: '10b', headerName: '10B' },
        { field: '11b', headerName: '11B' },
      ],
    },
  ]);
  const [styleColorRowData, setStyleColorRowData] = useState([]);
  const [styleColorColumnDefs, setStyleColorColumnDefs] = useState([
    {
      field: 'styleColor',
      headerName: 'Style Color',
    },
    {
      field: 'styleDescription',
      headerName: 'Style Description',
    },
    {
      field: 'color',
      headerName: 'Color',
    },
    {
      field: 'contribution',
      headerName: 'Contribution',
    },
  ]);
  const [recommendedProductsRowData, setRecommendedProductsRowData] = useState(
    []
  );
  const [recommendedProductsColumnDefs, setRecommendedProductsColumnDefs] =
    useState([
      {
        field: 'styleColor',
        headerName: 'Style Color',
      },
      {
        field: 'styleID',
        headerName: 'Style ID',
      },
      {
        field: 'color',
        headerName: 'Color',
      },
      {
        field: 'styleDescription',
        headerName: 'Style Description',
      },
      {
        field: 'channel',
        headerName: 'Channel',
      },
      {
        field: 'department',
        headerName: 'Department',
      },
      {
        field: 'class',
        headerName: 'Class',
      },
      {
        field: 'styleGroup',
        headerName: 'Style Group',
      },
      {
        field: 'silhoutte',
        headerName: 'Silhoutte',
      },
      {
        field: 'subClassCollection',
        headerName: 'Sub Class Collection',
      },
      {
        field: 'similarityMapping',
        headerName: 'Similarity Mapping',
      },
    ]);
  const [createProductProfileRowData, setCreateProductProfileRowData] =
    useState([]);
  const [createProductProfileColumnDefs, setCreateProductProfileColumnDefs] =
    useState([
      {
        field: 'styleColor',
        headerName: 'Style Color',
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {
        field: 'styleDescription',
        headerName: 'Style Description',
      },
      {
        field: 'color',
        headerName: 'Color',
      },
      {
        field: 'contribution',
        headerName: 'Contribution',
      },
      {
        field: 'salesQuantity',
        headerName: 'Sales Quantity',
      },
    ]);
  const [productProfileCopy, setProductProfileCopy] = useState([]);

  useEffect(() => {
    setIsFetchingData(true);

    getMultipleModelsData({
      model_names: [
        'tb_product_profiles',
        'tb_size_and_store',
        'tb_style_color',
        'tb_recommended_products',
        'tb_create_product_profile',
        'tb_product_profiles_copy',
      ],
    }).then((result) => {
      const {
        tb_product_profiles,
        tb_size_and_store,
        tb_style_color,
        tb_recommended_products,
        tb_create_product_profile,
        tb_product_profiles_copy,
      } = result;

      setProductProfileRowData(tb_product_profiles);
      setPenetrationRowData(tb_size_and_store);
      setStyleColorRowData(tb_style_color);
      setRecommendedProductsRowData(tb_recommended_products);
      setCreateProductProfileRowData(tb_create_product_profile);
      setProductProfileCopy(tb_product_profiles_copy);
      setIsFetchingData(false);
    });
  }, []);

  const handleTabChange = (_, newValue) => {
    setTabIndex(newValue);
  };

  const createProductProfileHandler = () => {
    setIsCreatingProfile(true);
  };

  const createProductProfileBackHandler = () => {
    setIsCreatingProfile(false);
  };

  const createProductProfileProceedHandler = () => {
    setCreateProductProfileStep(2);
  };

  const showStoreAndSizeContributionClickHandler = () => {
    setCreateProductProfileStep(3);
  };

  const createProductProfileCancelHandler = () => {
    setCreateProductProfileStep(1);
    setIsCreatingProfile(false);
  };

  const handleClose = () => {
    setDisplayMessage(false);
  };

  const onEdit = () => {
    setPenetrationColumnDefs([
      {
        field: 'storeNumber',
        headerName: 'Store Number',
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {
        field: 'overallPenetration',
        headerName: 'Overall Penetration',
        cellRenderer: 'cellTextField',
      },
      {
        headerName: 'Product Profile Pen %',
        children: [
          { field: '6b', headerName: '6B', cellRenderer: 'cellTextField' },
          { field: '7b', headerName: '7B', cellRenderer: 'cellTextField' },
          { field: '8b', headerName: '8B', cellRenderer: 'cellTextField' },
          { field: '9b', headerName: '9B', cellRenderer: 'cellTextField' },
          { field: '10b', headerName: '10B', cellRenderer: 'cellTextField' },
          { field: '11b', headerName: '11B', cellRenderer: 'cellTextField' },
        ],
      },
    ]);
  };

  const onSave = () => {
    setDisplayMessage(true);
    setPenetrationColumnDefs([
      {
        field: 'storeNumber',
        headerName: 'Store Number',
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {
        field: 'overallPenetration',
        headerName: 'Overall Penetration',
      },
      {
        headerName: 'Product Profile Pen %',
        children: [
          { field: '6b', headerName: '6B' },
          { field: '7b', headerName: '7B' },
          { field: '8b', headerName: '8B' },
          { field: '9b', headerName: '9B' },
          { field: '10b', headerName: '10B' },
          { field: '11b', headerName: '11B' },
        ],
      },
    ]);
  };

  const handleSizeAndStoreTabChange = (_, newValue) => {
    setSizeAndStoreTabIndex(newValue);
  };

  const renderTabContent = (tabIndex) => {
    switch (productProfileTabs[tabIndex]) {
      case 'User Created':
        return (
          <>
            {/* <FiltersList
              openLoader={() => {
                setLoader(true);
              }}
              closeLoader={() => {
                setLoader(false);
              }}
              updateParentState={(params) => {
                console.log('Gokul', 'params', params);
              }}
              appliedFilters={() => {}}
              onReset={() => {}}
              screenName={'Select Products'}
              height={"30px"}
            /> */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={10} sm={12} lg={10} xl={10}></Grid>
              <Grid
                item
                xs={12}
                md={2}
                sm={12}
                lg={2}
                xl={2}
                style={{ paddingLeft: '20px' }}
              >
                <CustomButton
                  isPrimary={true}
                  variant='contained'
                  height={36}
                  minWidth={30}
                  label={'Create Product Profile'}
                  // padding='50px'
                  onButtonClick={createProductProfileHandler}
                />
              </Grid>
            </Grid>
            <div className='spacer'></div>
            {/* <div
              className={`${styles['flex-align-center']} ${styles['flex-justify-space-between']}`}
            >
              <h3>Product Profile</h3>
              <CustomButton
                isPrimary={true}
                variant='contained'
                height={36}
                minWidth={30}
                label={"Create Product Profile"}
                padding='100'
                onButtonClick={createProductProfileHandler}
              />
            </div> */}

            {/* <div
              className={`${styles['flex-align-center']} ${styles['flex-justify-space-between']}`}
            >
              <h4>Product Profiles</h4>
              <div className={styles['flex']}>
                <CustomButton
                  isPrimary={true}
                  variant='contained'
                  height={36}
                  width={160}
                  startIcon={<FilterAltOutlinedIcon />}
                  label='Select Filters'
                  padding='100'
                  onButtonClick={createProductProfileHandler} // TODO
                />
              </div>
            </div>
            <div
              className={`${styles['filter-list-container']} ${styles['flex-wrap']}`}
            >
              <div
                className={`${styles['filter-list-item']} ${styles['flex-align-center']}`}
              >
                <span className={styles['filter-list-item-title']}>
                  Category
                </span>
                <div
                  className={`${styles['flex-align-center']} ${styles['flex-wrap']}`}
                >
                  <span
                    className={`${styles['filter-list-item-value']} ${styles['flex-align-center']}`}
                  >
                    Women's Footwear
                    <CloseOutlinedIcon
                      className={styles['filter-list-item-close']}
                    />
                  </span>
                  <span
                    className={`${styles['filter-list-item-value']} ${styles['flex-align-center']}`}
                  >
                    Women's Footwear
                    <CloseOutlinedIcon
                      className={styles['filter-list-item-close']}
                    />
                  </span>
                </div>
              </div>
              <div
                className={`${styles['filter-list-item']} ${styles['flex-align-center']}`}
              >
                <span className={styles['filter-list-item-title']}>
                  Department
                </span>
                <div
                  className={`${styles['flex-align-center']} ${styles['flex-wrap']}`}
                >
                  <span
                    className={`${styles['filter-list-item-value']} ${styles['flex-align-center']}`}
                  >
                    Women's Shoes
                    <CloseOutlinedIcon
                      className={styles['filter-list-item-close']}
                    />
                  </span>
                  <span
                    className={`${styles['filter-list-item-value']} ${styles['flex-align-center']}`}
                  >
                    Women's Shoes
                    <CloseOutlinedIcon
                      className={styles['filter-list-item-close']}
                    />
                  </span>
                </div>
              </div>
            </div> */}
            {productProfileRowData.length > 0 && (
              <Table
                rowData={productProfileRowData}
                columnDefs={productProfileColumnDefs}
                floatingFilter={false}
                height={'120px'}
                rowSelection={'multiple'}
                tableCssStyle={'sizeColumnsToFit'}
                // columnAutoSize
              />
            )}
            <h4>Size and Store Contribution</h4>
            <div style={{ borderBottom: '1px solid #CCC' }}>
              <Tabs
                value={sizeAndStoreTabIndex}
                onChange={handleSizeAndStoreTabChange}
              >
                {sizeAndStoreTabs.map((tab) => (
                  <Tab
                    label={tab}
                    style={{
                      textTransform: 'none',
                    }}
                  />
                ))}
              </Tabs>
            </div>
            <div>{renderSizeAndStoreTabContent(sizeAndStoreTabIndex)}</div>
          </>
        );
      case 'IA Recommended':
        return (
          <>
            {productProfileRowData.length > 0 && (
              <Table
                rowData={recommendedProductsRowData}
                columnDefs={recommendedProductsColumnDefs}
                floatingFilter={false}
                height={'418px'}
                rowSelection={'multiple'}
                tableCssStyle={'sizeColumnsToFit'}
                // columnAutoSize
              />
            )}
            <h4>Size and Store Contribution</h4>
            {penetrationTable}
          </>
        );
      default:
    }
  };

  const penetrationTable = useMemo(() => {
    return (
      <div style={{ marginTop: '30px' }}>
        {penetrationRowData.length > 0 && (
          <>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <CustomButton
                isPrimary={true}
                variant='contained'
                height={36}
                width={100}
                label='Edit'
                margin={10}
                onButtonClick={() => onEdit()}
              />
              <CustomButton
                isPrimary={true}
                variant='contained'
                height={36}
                width={100}
                label='Save'
                margin={10}
                onButtonClick={() => onSave()}
              />
            </div>

            <div className='spacer'></div>
            <Table
              rowData={penetrationRowData}
              columnDefs={penetrationColumnDefs}
              floatingFilter={false}
              height={'490px'}
              rowSelection={'multiple'}
              tableCssStyle={'sizeColumnsToFit'}
              frameworkComponents={{
                cellTextField: CellTextField,
              }}
              // columnAutoSize
            />
          </>
        )}
      </div>
    );
  }, [penetrationRowData, penetrationColumnDefs]);

  const styleColorTable = useMemo(() => {
    return (
      <div style={{ marginTop: '30px' }}>
        {styleColorRowData.length > 0 && (
          <Table
            rowData={styleColorRowData}
            columnDefs={styleColorColumnDefs}
            floatingFilter={false}
            height={'490px'}
            rowSelection={'multiple'}
            tableCssStyle={'sizeColumnsToFit'}
            // columnAutoSize
          />
        )}
      </div>
    );
  }, [styleColorRowData, styleColorColumnDefs]);

  const renderSizeAndStoreTabContent = (tabIndex) => {
    switch (sizeAndStoreTabs[tabIndex]) {
      case 'Penetration':
        return penetrationTable;
      case 'Style Color Description':
        return styleColorTable;
      default:
    }
  };

  return (
    <div className='impact_grid_sub_container'>
      {displayMessage && (
        <SnackBar
          message='Saved Successfully'
          variant='success'
          handleClose={handleClose}
        />
      )}
      <LoadingOverlay loader={isFetchingData || loader}>
        <div style={{ paddingLeft: 10 }}>
          {isCreatingProfile ? (
            <>
              <h3>Create product profile</h3>
              <Grid container spacing={2}>
                <Grid item xs={2} md={2} sm={2} lg={2} xl={2}>
                  <TextFieldWithLabel
                    type='text'
                    label={'Profile Name'}
                    // onChange={(e) => setMaxValue(parseInt(e.target.value))}
                    // defaultValue={0}
                    // width='6rem'
                  />
                </Grid>
                <Grid item xs={3} md={3} sm={3} lg={3} xl={3}>
                  <TextFieldWithLabel
                    type='text'
                    label={'Profile Description'}
                    // onChange={(e) => setMaxValue(parseInt(e.target.value))}
                    // defaultValue={0}
                    // width='6rem'
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                  <span
                    className={styles['create-profile-title']}
                    style={{ margin: '0px' }}
                  >
                    <b> Time Period </b>
                  </span>
                  <div className={styles['flex']}>
                    <RadioGroup
                      row
                      value={timePeriod}
                      onChange={(e) => {
                        setTimePeriod(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value='static'
                        control={<Radio />}
                        label='Static'
                      />
                      <FormControlLabel
                        value='dynamic'
                        control={<Radio />}
                        label='Dynamic'
                      />
                    </RadioGroup>
                    {timePeriod === 'static' ? (
                      <>
                        <span style={{ paddingTop: '10px' }}>
                          <Select
                            placeholder={'Select Time Period'}
                            isMulti={true}
                            initialData={timePeriodOptions}
                            selectedOptions={timePeriodOptions}
                            reset={false}
                            dependency={timePeriodOptions}
                            updateSelected={(params) => {}}
                            forceApiCall={false}
                            isRequired={true}
                          />
                        </span>
                      </>
                    ) : null}

                    {timePeriod === 'dynamic' && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <>
                          <span
                            className={styles['create-profile-title']}
                            style={{
                              margin: '5px',
                              marginTop: '10px',
                              width: '185px',
                            }}
                          >
                            From date
                          </span>
                          <DatePicker
                            onChange={(date) => setStartDate(date)}
                            value={startDate}
                            clearIcon={null}
                            dayPlaceholder={'dd'}
                            monthPlaceholder={'mm'}
                            yearPlaceholder={'yyyy'}
                            calendarIcon={
                              <CalendarMonthOutlined
                                style={{ fontSize: 20, color: '#515151' }}
                              />
                            }
                          />
                        </>
                        <>
                          <span
                            className={styles['create-profile-title']}
                            style={{
                              margin: '5px',
                              marginTop: '10px',
                              paddingLeft: '25px',
                              width: '185px',
                            }}
                          >
                            To date
                          </span>
                          <DatePicker
                            onChange={(date) => setStartDate(date)}
                            value={startDate}
                            clearIcon={null}
                            dayPlaceholder={'dd'}
                            monthPlaceholder={'mm'}
                            yearPlaceholder={'yyyy'}
                            calendarIcon={
                              <CalendarMonthOutlined
                                style={{ fontSize: 20, color: '#515151' }}
                              />
                            }
                          />
                        </>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
              <div className='spacer'></div>
              <Grid container spacing={2}>
                <Grid item xs={3} md={3} sm={3} lg={3} xl={3}>
                  <span className={styles['create-profile-title']}>
                    Channel
                  </span>
                  <Select
                    placeholder={'Select Channel'}
                    isMulti={true}
                    initialData={channelOptions}
                    selectedOptions={channelOptions}
                    reset={false}
                    dependency={channelOptions}
                    updateSelected={(params) => {}}
                    forceApiCall={false}
                  />
                </Grid>
                <Grid item xs={3} md={3} sm={3} lg={3} xl={3}>
                  <span className={styles['create-profile-title']}>
                    Merch Business Category
                  </span>
                  <Select
                    placeholder={'Merch Business Category'}
                    isMulti={true}
                    initialData={businessCategoryOptions}
                    selectedOptions={businessCategoryOptions}
                    reset={false}
                    dependency={channelOptions}
                    updateSelected={(params) => {}}
                    forceApiCall={false}
                  />
                </Grid>
                <Grid item xs={3} md={3} sm={3} lg={3} xl={3}>
                  <span className={styles['create-profile-title']}>
                    Merch Department
                  </span>
                  <Select
                    placeholder={'Merch Department'}
                    isMulti={true}
                    initialData={departmentOptions}
                    selectedOptions={departmentOptions}
                    reset={false}
                    // dependency={channelOptions}
                    updateSelected={(params) => {}}
                    forceApiCall={false}
                  />
                </Grid>
                <Grid item xs={3} md={3} sm={3} lg={3} xl={3}>
                  <span className={styles['create-profile-title']}>
                    Merch Class
                  </span>
                  <Select
                    placeholder={'Merch Class'}
                    isMulti={true}
                    initialData={classOptions}
                    selectedOptions={classOptions}
                    reset={false}
                    // dependency={channelOptions}
                    updateSelected={(params) => {}}
                    forceApiCall={false}
                  />
                </Grid>
                <Grid item xs={3} md={3} sm={3} lg={3} xl={3}>
                  <span className={styles['create-profile-title']}>
                    Color Code
                  </span>
                  <Select
                    placeholder={'Color Code'}
                    isMulti={true}
                    initialData={colorCodeOptions}
                    selectedOptions={colorCodeOptions}
                    reset={false}
                    dependency={channelOptions}
                    updateSelected={(params) => {}}
                    forceApiCall={false}
                  />
                </Grid>
                <Grid item xs={3} md={3} sm={3} lg={3} xl={3}>
                  <span className={styles['create-profile-title']}>
                    Selling Collection
                  </span>
                  <Select
                    placeholder={'Selling Collection'}
                    isMulti={true}
                    initialData={sellingCollection}
                    selectedOptions={sellingCollection}
                    reset={false}
                    dependency={channelOptions}
                    updateSelected={(params) => {}}
                    forceApiCall={false}
                  />
                </Grid>
                <Grid item xs={3} md={3} sm={3} lg={3} xl={3}>
                  <span className={styles['create-profile-title']}>
                    Primary Fabrication
                  </span>
                  <Select
                    placeholder={'Primary Fabrication'}
                    isMulti={true}
                    initialData={primaryFabrication}
                    selectedOptions={primaryFabrication}
                    reset={false}
                    dependency={channelOptions}
                    updateSelected={(params) => {}}
                    forceApiCall={false}
                  />
                </Grid>
              </Grid>
              <div
                className={`${styles['flex-align-center']} ${styles['flex-wrap']}`}
              >
                <div
                  className={`${styles['flex-align-center']} ${styles['flex-wrap']}`}
                ></div>
              </div>
              <div className={styles['horizontal-divider']}></div>
              <h3>Set Attributes</h3>
              <div
                className={`${styles['flex-align-center']}`}
                style={{ marginBottom: '20px' }}
              >
                <span
                  className={styles['create-profile-title']}
                  style={{ margin: '0px' }}
                >
                  Sales Attribute<sup>*</sup>
                </span>
                <span>
                  <Checkbox /> Regular
                </span>
                <span>
                  <Checkbox /> Clearance
                </span>
                <span>
                  <Checkbox /> Promo
                </span>
              </div>
              <div
                className={`${styles['flex-align-center']}`}
                style={{ marginBottom: '20px' }}
              >
                <span
                  className={styles['create-profile-title']}
                  style={{ margin: '0px' }}
                >
                  Price &nbsp;&nbsp;<b>MIN</b>
                  <sup>*</sup>
                </span>
                <span style={{ marginLeft: '20px' }}>
                  <TextField
                    InputProps={{
                      sx: {
                        height: 30,
                        width: 80,
                        fontSize: '13px',
                        fontFamily:
                          '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
                      },
                      inputProps: { max: 9999, min: 1 },
                    }}
                    type='number'
                    value={20}
                  />
                </span>
                <span style={{ marginLeft: '20px', fontSize: '15px' }}>
                  {' '}
                  Max <sup>*</sup>{' '}
                </span>
                <span style={{ marginLeft: '20px' }}>
                  <TextField
                    InputProps={{
                      sx: {
                        height: 30,
                        width: 80,
                        fontSize: '13px',
                        fontFamily:
                          '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
                      },
                      inputProps: { max: 9999, min: 1 },
                    }}
                    type='number'
                    value={40}
                  />
                </span>
              </div>

              <div className={styles['horizontal-divider']}></div>
              {createProductProfileStep === 1 && (
                <div
                  className={`${styles['flex-align-center']} ${styles['flex-justify-center']}`}
                >
                  <CustomButton
                    isPrimary={true}
                    variant='outlined'
                    height={36}
                    minWidth={30}
                    label={'Back'}
                    padding='100'
                    onButtonClick={createProductProfileBackHandler}
                  />
                  <span style={{ margin: '0px 10px' }}></span>
                  <CustomButton
                    isPrimary={true}
                    variant='contained'
                    height={36}
                    minWidth={30}
                    label={'Proceed'}
                    padding='100'
                    onButtonClick={createProductProfileProceedHandler}
                  />
                </div>
              )}
              {createProductProfileStep > 1 && (
                <>
                  <Table
                    rowData={createProductProfileRowData}
                    columnDefs={createProductProfileColumnDefs}
                    floatingFilter={false}
                    height={'418px'}
                    rowSelection={'multiple'}
                    tableCssStyle={'sizeColumnsToFit'}
                    // columnAutoSize
                  />
                  <div
                    className={`${styles['flex-align-center']} ${styles['flex-justify-center']}`}
                  >
                    {createProductProfileStep === 2 && (
                      <>
                        <CustomButton
                          isPrimary={true}
                          variant='outlined'
                          height={36}
                          minWidth={30}
                          label={'Cancel'}
                          padding='100'
                          onButtonClick={createProductProfileCancelHandler}
                        />
                        <span style={{ margin: '0px 10px' }}></span>
                      </>
                    )}
                    <CustomButton
                      isPrimary={true}
                      variant='contained'
                      height={36}
                      minWidth={30}
                      label={'Show Store & Size Contribution'}
                      padding='100'
                      onButtonClick={showStoreAndSizeContributionClickHandler}
                    />
                  </div>
                </>
              )}
              {createProductProfileStep > 2 && (
                <>
                  <h3>Store & Size Contribution</h3>
                  {penetrationTable}
                  <div
                    className={`${styles['flex-align-center']} ${styles['flex-justify-center']}`}
                  >
                    <CustomButton
                      isPrimary={true}
                      variant='outlined'
                      height={36}
                      minWidth={30}
                      label={'Cancel'}
                      padding='100'
                      onButtonClick={createProductProfileCancelHandler}
                    />
                    <span style={{ margin: '0px 10px' }}></span>
                    <CustomButton
                      isPrimary={true}
                      variant='contained'
                      height={36}
                      minWidth={30}
                      label={'Save Product Profile'}
                      padding='100'
                      onButtonClick={() => setShowDialog(true)}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <FiltersList
                openLoader={() => {
                  setLoader(true);
                }}
                closeLoader={() => {
                  setLoader(false);
                }}
                updateParentState={(params) => {
                  console.log('Gokul', 'params', params);
                }}
                appliedFilters={() => {}}
                onReset={() => {}}
                screenName={'Select Products'}
              />
              <div style={{ borderBottom: '1px solid #CCC' }}>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  {productProfileTabs.map((tab) => (
                    <Tab
                      label={tab}
                      style={{
                        textTransform: 'none',
                      }}
                    />
                  ))}
                </Tabs>
              </div>
              <div className='spacer'></div>
              <div>{renderTabContent(tabIndex)}</div>
            </>
          )}
          <Dialog fullWidth={true} maxWidth={'sm'} open={showDialog}>
            <DialogTitle>Save Product Profile</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div style={{ marginBottom: '10px' }}>Name</div>
                <TextField
                  InputProps={{
                    sx: {
                      height: 30,
                      width: 400,
                      fontSize: '13px',
                      fontFamily:
                        '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
                    },
                    inputProps: { max: 9999, min: 1 },
                  }}
                />
                <div style={{ margin: '20px 0px 10px 0px' }}>Description</div>
                <TextField
                  InputProps={{
                    sx: {
                      height: 30,
                      width: 400,
                      fontSize: '13px',
                      fontFamily:
                        '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
                    },
                    inputProps: { max: 9999, min: 1 },
                  }}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CustomButton
                isPrimary={true}
                variant='outlined'
                height={36}
                minWidth={30}
                label={'Cancel'}
                padding='100'
                onButtonClick={() => setShowDialog(false)}
              />
              <span style={{ margin: '0px 10px' }}></span>
              <CustomButton
                isPrimary={true}
                variant='contained'
                height={36}
                minWidth={30}
                label={'Save'}
                padding='100'
                onButtonClick={() => {
                  setShowDialog(false);
                  setIsCreatingProfile(false);
                  setProductProfileRowData(productProfileCopy);
                }}
              />
            </DialogActions>
          </Dialog>
        </div>
      </LoadingOverlay>
    </div>
  );
};
