import React, { useEffect, useMemo, useState } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import Chart from '../../../../utils/charts/Chart';
import { Table } from '../../../../components/Table';
import CustomCard from '../../../../components/CustomCard';
import FilterSection from './FilterSection';
import { getChartData, tabListData } from './data';
import { fetchHierarchyData } from './action';
import FiltersList from '../../../../molecules/FiltersList';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import { getMultipleModelsData } from '../../services/decisionDashboardService';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  headerCard: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
    position: 'relative',
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
});

const useStyles = makeStyles(styles);

const Report = () => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [radioValue, setRadioValue] = useState('Product');
  const [isLoading, setIsLoading] = useState(false);
  const [curentObject, setCurentObject] = useState({});
  const [loader, setLoader] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [radioTableData, setRadioTableData] = useState({});
  const [thresholdValue, setThresholdValue] = useState({});
  const getData = async (label) => {
    // const data = await fetchHierarchyData(label);
    // if (Object.keys(curentObject).length > 0 && data) {
    //   setCurentObject({
    //     ...curentObject,
    //     table: {
    //       ...curentObject.table,
    //       data,
    //     },
    //   });
    // }
    let dupObj = { ...curentObject };
    const result = await getMultipleModelsData({
      model_names: curentObject.apiCall,
    });
    setRadioTableData(result);
    let updatedObj = {};
    curentObject.dataMapping.forEach((obj, index) => {
      let data = [];
      let tabName = dupObj.value;
      if (updatedObj[`${obj}`]) {
        data = result[`${curentObject.apiCall[index]}`];
        if (obj === 'chart') {
          data = getChartData(data, `${tabName}_${index}`);
        }
        updatedObj[`${obj}`]['data'].push(data);
        // updatedObj[`${obj}`]['data_copy'].push(data);
      } else {
        updatedObj[`${obj}`] = {
          data: [],
        };
        data = result[`${curentObject.apiCall[index]}`];
        if (obj === 'chart') {
          data = getChartData(data, `${tabName}_${index}`);
        }
        updatedObj[`${obj}`]['data'].push(data);
        // updatedObj[`${obj}`]['data_copy'].push(data);
      }
    });
    Object.keys(updatedObj).forEach((obj) => {
      dupObj[`${obj}`].filter((nestedObj, index) => {
        nestedObj.data = updatedObj[`${obj}`]['data'][index];
      });
    });
    setCurentObject({
      ...dupObj,
    });
    // setCurentObject({
    //   ...curentObject,
    //   table: {
    //       ...curentObject.table,
    //           data,
    //     },
    // })
    // if (Object.keys(curentObject).length > 0 && data) {
    //   setCurentObject({
    //     ...curentObject,
    //     table: {
    //       ...curentObject.table,
    //       data,
    //     },
    //   });
    // }

    setIsLoading(false);
  };

  useEffect(() => {
    setCurentObject(tabListData['lost_sales']);
    setValue('lost_sales');
  }, []);

  const handleChange = (event, newValue) => {
    setCurentObject(tabListData[newValue]);
    setValue(newValue);
    setIsLoading(true);
  };

  useEffect(async () => {
    if (value) {
      await getData(`tb_${value}`);
    }
  }, [value]);

  const reloadFilters = async () => {
    setSelectedFilters({});
  };

  const openLoader = () => {
    console.log('Open Loader');
    setLoader(true);
  };
  const closeLoader = () => {
    setLoader(false);
  };
  const updateState = (params) => {
    console.log('Updating state', params);
    setSelectedFilters(params);
  };
  const appliedFiltersAction = async (params) => {
    console.log(selectedFilters);
  };

  const onReset = () => {
    reloadFilters();
  };
  const autoGroupColumnDef = useMemo(() => {
    return {
      headerValueGetter: (params) => `Week`,
      minWidth: 220,
      cellRendererParams: {
        suppressCount: true,
        // checkbox: true,
      },
    };
  }, []);

  const radioHandleChange = (action, value) => {
    setRadioValue(value);
  };

  const handleApply = (selectedParams, action, index, type) => {
    console.log('Slected ', selectedParams);
    let params = { ...curentObject };
    let requiredObj = curentObject[`${type}`][index];
    if (!requiredObj[`data_copy`]) {
      requiredObj[`data_copy`] = requiredObj.data;
    }
    switch (action.name) {
      case 'Warehouse stock forecasts':
        params[`${type}`][index]['data'] = params[`${type}`][index][
          'data_copy'
        ].filter(
          (obj) =>
            selectedParams[`${action.key}`]
              .map((selObj) => selObj.value)
              .indexOf(obj.name) !== -1
        );
        break;
      case 'dIY_reports':
        params[`${type}`][index]['data'] = params[`${type}`][index][
          'data_copy'
        ].filter(
          (obj) =>
            selectedParams[`${action.key}`]
              .map((selObj) => selObj.value)
              .indexOf(obj.name) !== -1
        );
      default:
        break;
    }
    setCurentObject({
      ...params,
    });
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let params = { ...thresholdValue };
    params[`${name}`] = value;
    setThresholdValue({
      ...params,
    });
  };
  const handleThreshold = (tableObj) => {
    let params = { ...thresholdValue };
    if (!tableObj['data_copy']) {
      tableObj['data_copy'] = tableObj.data;
      params[`${tableObj.title}_data`] = tableObj.data;
    }
    if (thresholdValue[`${tableObj['title']}`]) {
      tableObj['data'] = tableObj['data_copy'].filter((obj) =>
        obj.ia_forecast.includes(`${thresholdValue[`${tableObj['title']}`]} `)
      );
      params[`${tableObj.title}_data`] = tableObj['data_copy'].filter((obj) =>
        obj.ia_forecast.includes(`${thresholdValue[`${tableObj['title']}`]} `)
      );
      setThresholdValue({
        ...params,
      });
    }
    // tableObj['data']
  };
  return (
    <LoadingOverlay loader={loader}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FiltersList
            openLoader={openLoader}
            closeLoader={closeLoader}
            updateParentState={updateState}
            appliedFilters={appliedFiltersAction}
            onReset={onReset}
            screenName={'Report'}
          />
          <div className='impact_grid_sub_container'>
            <CustomCard cardStyles={classes.headerCard}>
              <div className='spacer'></div>
              <Grid item xs={12}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label='wrapped label tabs'
                >
                  {Object.values(tabListData).map((item) => (
                    <Tab
                      value={item.value}
                      label={item.label}
                      style={{
                        textTransform: 'none',
                      }}
                    />
                  ))}
                </Tabs>
                {isLoading && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress className={classes.loader} />
                  </div>
                )}
                {!isLoading && (
                  <>
                    {curentObject?.orderBy?.map((orderObj) => (
                      <>
                        {orderObj === 'card' && curentObject?.card && (
                          <>
                            {curentObject.card.map((cardObj) => (
                              <>
                                {curentObject.value === 'forecasted_units' ? (
                                  <>
                                    <Grid container xs={12}>
                                      <Card
                                        sx={{
                                          minWidth: 250,
                                          margin: 3,
                                        }}
                                      >
                                        <Typography
                                          gutterBottom
                                          variant='h5'
                                          sx={{
                                            margin: 1,
                                          }}
                                        >
                                          {cardObj.title}
                                        </Typography>
                                        {cardObj.data.map((item) => (
                                          <CardContent
                                            sx={{
                                              flex: '1 0 auto',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                            }}
                                          >
                                            <Typography
                                              sx={{ fontSize: 14 }}
                                              color='text.secondary'
                                              gutterBottom
                                            >
                                              {item.label}
                                            </Typography>
                                            <Typography
                                              variant='h5'
                                              component='div'
                                              color='#156bd4'
                                            >
                                              {item.count}
                                            </Typography>
                                          </CardContent>
                                        ))}
                                      </Card>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Typography
                                      gutterBottom
                                      variant='h5'
                                      sx={{
                                        margin: 1,
                                      }}
                                    >
                                      {cardObj.title}
                                    </Typography>

                                    <Grid container xs={12}>
                                      {cardObj.data.map((item) => (
                                        <Card
                                          sx={{
                                            minWidth: 250,
                                            margin: 3,
                                            display: 'flex',
                                          }}
                                        >
                                          <Inventory2OutlinedIcon
                                            color='primary'
                                            sx={{
                                              alignSelf: 'center',
                                              margin: 2,
                                            }}
                                          />
                                          <CardContent
                                            sx={{ flex: '1 0 auto' }}
                                          >
                                            <Typography
                                              sx={{ fontSize: 14 }}
                                              color='text.secondary'
                                              gutterBottom
                                            >
                                              {item.label}
                                            </Typography>
                                            <Typography
                                              variant='h5'
                                              component='div'
                                              color='#156bd4'
                                            >
                                              {item.count}
                                            </Typography>
                                          </CardContent>
                                        </Card>
                                      ))}
                                    </Grid>
                                  </>
                                )}
                              </>
                            ))}
                          </>
                        )}

                        {orderObj === 'chart' && curentObject?.chart && (
                          <>
                            {curentObject.chart.map((chartObj, index) => (
                              <>
                                {chartObj?.filters && (
                                  <>
                                    {' '}
                                    <FilterSection
                                      filters={chartObj.filters}
                                      handleApply={(params) => {
                                        if (chartObj.filters.action) {
                                          handleApply(
                                            params,
                                            chartObj.filters.action,
                                            index,
                                            'chart'
                                          );
                                        }
                                      }}
                                    />{' '}
                                  </>
                                )}
                                <Chart
                                  chartType={chartObj.type}
                                  title={chartObj.title}
                                  yaxis={chartObj.yaxis}
                                  xaxis={chartObj.xaxis}
                                  showLegend={chartObj.showLegend}
                                  data={chartObj.data}
                                />
                                <div className='spacer'></div>
                              </>
                            ))}
                          </>
                        )}

                        {orderObj === 'table' &&
                          (curentObject?.table ||
                            curentObject?.tableWithFilter) && (
                            <>
                              <Grid
                                container
                                className={classes.demo}
                                justify='center'
                                sm={12}
                              >
                                {curentObject?.table?.map((tableObj) => (
                                  <>
                                    {curentObject.value ===
                                    'forecasted_units' ? (
                                      <>
                                        <Grid sm={6} style={{ padding: 20 }}>
                                          <Typography variant='h6'>
                                            {tableObj.title} :
                                            <TextField
                                              id='outlined-number'
                                              type='number'
                                              defaultValue={
                                                tableObj.thresholdValue
                                              }
                                              size='small'
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position='start'>
                                                    %
                                                  </InputAdornment>
                                                ),
                                              }}
                                              name={tableObj.title}
                                              style={{
                                                width: 100,
                                                bottom: 5,
                                                marginLeft: 30,
                                              }}
                                              onChange={(params) => {
                                                handleInputChange(params);
                                              }}
                                            />
                                            <Button
                                              variant='text'
                                              onClick={() => {
                                                handleThreshold(tableObj);
                                              }}
                                            >
                                              Apply
                                            </Button>
                                          </Typography>

                                          <Table
                                            // rowData={tableObj.data}
                                            rowData={
                                              Object.keys(thresholdValue)
                                                .length > 0
                                                ? thresholdValue[
                                                    `${tableObj.title}_data`
                                                  ]
                                                  ? thresholdValue[
                                                      `${tableObj.title}_data`
                                                    ]
                                                  : tableObj.data
                                                : tableObj.data
                                            }
                                            columnDefs={tableObj.column}
                                            pageSize={4}
                                            rowsPerPageOptions={[4]}
                                            floatingFilter={false}
                                            height={'350px'}
                                            tableCssStyle='sizeColumnsToFit'
                                          />
                                        </Grid>
                                      </>
                                    ) : (
                                      <>
                                        <Typography variant='h6'>
                                          {tableObj.title}
                                        </Typography>
                                        <Table
                                          rowData={
                                            Object.keys(thresholdValue).length >
                                            0
                                              ? thresholdValue[
                                                  `${tableObj.title}_data`
                                                ]
                                                ? thresholdValue[
                                                    `${tableObj.title}_data`
                                                  ]
                                                : tableObj.data
                                              : tableObj.data
                                          }
                                          columnDefs={tableObj.column}
                                          floatingFilter={false}
                                          height={tableObj.height || '450px'}
                                          rowSelection={'multiple'}
                                          autoGroupColumnDef={
                                            autoGroupColumnDef
                                          }
                                          groupDefaultExpanded={
                                            tableObj.defaultExpansion
                                          }
                                          tableCssStyle='sizeColumnsToFit'
                                        />
                                      </>
                                    )}
                                    <div className='spacer'></div>
                                  </>
                                ))}

                                {curentObject.value ===
                                  'daily_allocation_summary' && (
                                  <>
                                    <FormControl>
                                      <RadioGroup
                                        row
                                        aria-labelledby='demo-row-radio-buttons-group-label'
                                        name='row-radio-buttons-group'
                                        value={radioValue}
                                        onChange={radioHandleChange}
                                      >
                                        <FormControlLabel
                                          value='Product'
                                          control={<Radio />}
                                          label='Product'
                                        />
                                        <FormControlLabel
                                          value='Store'
                                          control={<Radio />}
                                          label='Store'
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                    {curentObject?.tableWithFilter.map(
                                      (item) => (
                                        <>
                                          {item.title === radioValue && (
                                            <Table
                                              rowData={radioTableData[item.key]}
                                              columnDefs={item.column}
                                              floatingFilter={false}
                                              height={'450px'}
                                              rowSelection={'multiple'}
                                              autoGroupColumnDef={
                                                autoGroupColumnDef
                                              }
                                            />
                                          )}
                                        </>
                                      )
                                    )}
                                  </>
                                )}
                              </Grid>
                            </>
                          )}
                      </>
                    ))}
                  </>
                )}
              </Grid>
            </CustomCard>
          </div>
        </Grid>
      </Grid>
    </LoadingOverlay>
  );
};

export default Report;
