import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import * as css_constants from '../../../../constants/cssConstants';
export default function ChildStoreTablesCol() {
  const dispatch = useDispatch();
  const openPopup = () => {
    dispatch({
      type: 'SHOW_CHILD_STORE_SKU_TABLE',
      payload: true,
    });
  };
  return [
    {
      field: 'child_sku',
      headerName: "Child SKU's",
      cellRenderer: 'agGroupCellRenderer',
      // cellRendererFramework: (cell) => (
      //   <Button
      //     style={{ textTransform: 'none' }}
      //     onClick={openPopup}
      //     autoFocus
      //     variant='text'
      //     className={css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_BUTTON_CSS_CLASS }
      //   >
      //     {cell.data.child_sku}
      //   </Button>
      // ),
    },
  { field: 'fw_202405', headerName: 'FW-202405' },
  { field: 'fw_202406', headerName: 'FW-202406' },
  { field: 'fw_202407', headerName: 'FW-202407' },
  { field: 'fw_202408', headerName: 'FW-202408' },
  { field: 'fw_202409', headerName: 'FW-202409' },
  { field: 'fw_202410', headerName: 'FW-202410' },
  { field: 'fw_202411', headerName: 'FW-202411' },
  { field: 'fw_202412', headerName: 'FW-202412' }
  ];
}
