import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from '@mui/material';
import { Inventory2Outlined } from '@mui/icons-material';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import FiltersList from '../../../../molecules/FiltersList';
import styles from './index.module.scss';
import { useStyles } from './styles';
import CustomAccordion from '../../../../components/CustomAccordion';
import { Button } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { Table } from '../../../../components/Table';
import {
  getColorCoding,
  subClassSummaryColumns,
  OrderSummaryColumns,
  convertToInteger,
  OrderSummaryColumnsConvertedToInteger,
  getLTLCColorCoding,
} from './data';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  getMultipleModelsData,
  orderMgtFinalizeAction,
} from '../../services/decisionDashboardService';
import { SkuLevelGraph } from './skuLevelGraph';
import SnackBar from '../../../../components/SnackBar';
import { OrderSummaryFilters } from './orderSummaryFilters';
// const useStyles = makeStyles(styles);
const OrderManagement = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    checkboxFilters: {
      openToBudget,
      containerCapacity,
      costTier,
      selectedItems,
    },
  } = useSelector((state) => state.orderManagement);
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [kpis, setKpis] = useState([]);
  const [kpisCostToggle, setKpisCostToggle] = useState(false);
  const [subClassSummary, setSubClassSummary] = useState([]);
  const [orderSummary, setOrderSummary] = useState([]);
  const [skuSummary, setSKUSummary] = useState([]);
  const [orderSummaryOptimized, setOrderSummaryOptimized] = useState([]);
  const [skuSummaryGraph, setSKUSummaryGraph] = useState([]);
  const { isFinilizeChanged, finalizeData } = useSelector(
    (state) => state.common
  );
  const [displayMessage, setDisplayMessage] = useState(false);
  const [isOptimized, setIsOptimized] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedValue, setSelectedValue] = useState('SKU');
  const [skuSummaryCopy, setSKUSummaryCopy] = useState([]);
  const [gridApi, setGridApi] = useState({});
  const [copyData, setCopyData] = useState({});
  useEffect(async () => {
    const result = await getMultipleModelsData({
      model_names: [
        'tb_order_mnt_kpis',
        'tb_order_mnt_subclass_summary',
        'tb_order_summary',
        'tb_order_mnt_sku_summary',
        'tb_order_summary_optimized',
        'tb_order_mnt_sku_summary_graph',
      ],
    });
    const {
      tb_order_mnt_kpis,
      tb_order_mnt_subclass_summary,
      tb_order_summary,
      tb_order_mnt_sku_summary,
      tb_order_summary_optimized,
      tb_order_mnt_sku_summary_graph,
    } = result;
    setKpis(tb_order_mnt_kpis);
    setSubClassSummary(tb_order_mnt_subclass_summary);
    const tb_order_summary_data = convertToInteger(
      tb_order_summary,
      OrderSummaryColumnsConvertedToInteger
    );
    const tb_order_summary_optimized_data = convertToInteger(
      tb_order_summary_optimized,
      OrderSummaryColumnsConvertedToInteger
    );
    setOrderSummary(tb_order_summary_data);
    setSKUSummary(tb_order_mnt_sku_summary);
    setSKUSummaryCopy(tb_order_mnt_sku_summary);
    setOrderSummaryOptimized(tb_order_summary_optimized_data);
    setSKUSummaryGraph(tb_order_mnt_sku_summary_graph);
    setCopyData({
      subClassSummary: tb_order_mnt_subclass_summary,
      orderSummary: tb_order_summary_data,
      orderSummaryOptimized: tb_order_summary_optimized_data,
      skuSummary: tb_order_mnt_sku_summary,
    });
  }, []);
  useEffect(() => {
    console.log('Sku Summary ', skuSummary);
    if (Object.keys(finalizeData).length > 0 && skuSummary.length > 0) {
      let mappingObj = {
        sales_forecast: 'SL',
        safety_stock: 'ss',
        aur: 'WOS',
      };
      const updatedData = skuSummary.filter((obj) => {
        let deepObj = finalizeData[`${obj.skuID}`];
        console.log('--------', deepObj);
        if (deepObj) {
          Object.keys(deepObj).forEach((nestedObj) => {
            console.log('Nested Objs ', nestedObj);
            obj[`${mappingObj[`${nestedObj}`]}`] = deepObj[`${nestedObj}`];
          });
        }
        return obj;
      });

      const updatedOptimizedData = orderSummaryOptimized.filter((obj) => {
        let deepObj = finalizeData[`${obj.skuID}`];
        console.log('--------', deepObj);
        if (deepObj) {
          Object.keys(deepObj).forEach((nestedObj) => {
            console.log('Nested Objs ', nestedObj);
            obj[`${mappingObj[`${nestedObj}`]}`] = deepObj[`${nestedObj}`];
          });
        }
        return obj;
      });

      console.log('Updated Obj ', updatedData);
      setSKUSummary([...updatedData]);
      const tb_order_summary_optimized_data = convertToInteger(
        [...updatedOptimizedData],
        OrderSummaryColumnsConvertedToInteger
      );
      setOrderSummaryOptimized(tb_order_summary_optimized_data);
      dispatch(
        orderMgtFinalizeAction({
          isFinilizeChanged: false,
          finalizeData: {},
        })
      );
    }
  }, [skuSummary, orderSummaryOptimized]);

  useEffect(() => {
    if (isOptimized) {
      setOrderSummaryOptimized([...orderSummaryOptimized]);
    } else {
      setOrderSummary([...orderSummary]);
    }
    setSKUSummary([...skuSummaryCopy]);
  }, [selectedValue]);

  const SKUSummaryColumns = [
    {
      field: 'skuID',
      headerName: 'SKU Id',
      pinned: 'left',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRenderer: 'skuLevelGraph',
      cellRendererParams: {
        skuSummaryGraph,
      },
      // rowGroup: true,
      // hide:true
    },
    {
      field: 'subclass',
      headerName: 'Subclass',
      // pinned: 'left',
    },
    {
      field: 'itemGrading',
      headerName: 'Item Grading',
      // pinned: 'left',
    },
    {
      field: 'DCLocation',
      headerName: 'DC Location',
      // pinned: 'left',
    },
    {
      field: 'vendor',
      headerName: 'Vendor',
      // pinned: 'left',
    },
    { field: 'storeOH', headerName: 'Store OH' },
    { field: 'DCOH', headerName: 'DC OH' },
    {
      field: 'expeditedOrders',
      headerName: 'Expedited orders',
    },
    { field: 'orderQty', headerName: 'Order qty' },
    {
      field: 'IASuggestedORderQty',
      headerName: 'IA suggested order qty',
      width: 280,
    },
    {
      field: 'constrainedOrderQty',
      headerName: 'Constrained order qty',
    },
    {
      field: 'orderCost',
      headerName: 'Order cost',
      type: 'numericColumn',
    },
    {
      field: 'orderPlacementDate',
      headerName: 'Order placement date',
    },
    {
      field: 'expectedReceiptDate',
      headerName: 'Expected receipt date',
    },
    {
      headerName: 'Safety stock at DC',
      children: [
        {
          field: 'ss',
          headerName: 'SS',
          cellStyle: function (params) {
            let value = Math.abs(
              parseInt(
                ((parseInt(params.data.ss_lc) - parseInt(params.value)) /
                  parseInt(params.data.ss_lc)) *
                  100
              )
            );

            return getColorCoding(value);
          },
        },
        { field: 'ss_lc', headerName: 'SS LC' },
      ],
    },
    {
      headerName: 'WOS',
      children: [
        {
          field: 'WOS',
          headerName: 'WOS',
          cellStyle: function (params) {
            let value = Math.abs(
              parseInt(
                ((parseInt(params.data.WOSLC) - parseInt(params.value)) /
                  parseInt(params.data.WOSLC)) *
                  100
              )
            );
            return getColorCoding(value);
          },
        },
        { field: 'WOSLC', headerName: 'WOS LC' },
      ],
    },
    {
      headerName: 'Service Level',
      children: [
        {
          field: 'SL',
          headerName: 'SL',
          cellStyle: function (params) {
            let value = Math.abs(
              parseInt(
                ((parseInt(params.data.targetSL) - parseInt(params.value)) /
                  parseInt(params.data.targetSL)) *
                  100
              )
            );
            return getColorCoding(value);
          },
        },
        { field: 'targetSL', headerName: 'Target SL' },
      ],
    },
    {
      headerName: 'Lead Time',
      children: [
        {
          field: 'effectiveLT',
          headerName: 'Effective LT',
          cellStyle: function (params) {
            let value = parseInt(
              ((parseInt(params.data.effectiveLT) -
                parseInt(params.data.effectiveLTLC)) /
                parseInt(params.data.effectiveLTLC)) *
                100
            );
            return getLTLCColorCoding(value);
          },
        },
        {
          field: 'effectiveLTLC',
          headerName: 'Effective LT LC',
        },
      ],
    },
    {
      headerName: 'Demand Forecast',
      children: [
        {
          field: 'last30Days',
          headerName: 'Last 30 days',
        },
        { field: '30DayFCST', headerName: '30 day fcst' },
        {
          field: 'varLC30Days',
          headerName: 'var LC (30 days)',
          cellStyle: function (params) {
            let value = Math.abs(
              parseInt(
                (Math.abs(params.value) / parseInt(params.data.last30Days)) *
                  100
              )
            );
            console.log(value);
            return getColorCoding(value);
          },
        },
        {
          field: 'last90Days',
          headerName: 'Last 90 days',
        },
        { field: '90DayFCST', headerName: '90 day fcst' },
        {
          field: 'varLC90Days',
          headerName: 'var LC (90 days)',
          cellStyle: function (params) {
            let value = Math.abs(
              parseInt(
                (Math.abs(params.value) / parseInt(params.data.last90Days)) *
                  100
              )
            );
            return getColorCoding(value);
          },
        },
        {
          field: 'last120Days',
          headerName: 'Last 120 days',
          type: 'numericColumn',
        },
        {
          field: '120DayFCST',
          headerName: '120 day fcst',
        },
        {
          field: 'varLC120Days',
          headerName: 'var LC (120 days)',
          cellStyle: function (params) {
            let value = Math.abs(
              parseInt(
                (Math.abs(params.value) / parseInt(params.data.last120Days)) *
                  100
              )
            );
            return getColorCoding(value);
          },
        },
      ],
    },
  ];
  const reloadFilters = async () => {};

  const openLoader = () => {
    console.log('Open Loader');
    setLoader(true);
  };
  const closeLoader = () => {
    setLoader(false);
  };
  const updateState = (params) => {
    console.log('Updating state', params);
    setSelectedFilters(params);
  };
  const appliedFiltersAction = async () => {
    if (
      selectedFilters['Department'] &&
      selectedFilters['Department'].length > 0
    ) {
      const departmentValues = selectedFilters['Department'].map(
        (depObj) => depObj.value
      );
      const subClassSummaryData = copyData[`subClassSummary`].filter(
        (obj) => departmentValues.indexOf(obj['Department']) !== -1
      );
      const orderSummaryData = copyData[`orderSummary`].filter(
        (obj) => departmentValues.indexOf(obj['Department']) !== -1
      );
      const orderSummaryOptimizedData = copyData[
        `orderSummaryOptimized`
      ].filter((obj) => departmentValues.indexOf(obj['Department']) !== -1);
      const skuSummaryData = copyData[`skuSummary`].filter(
        (obj) => departmentValues.indexOf(obj['Department']) !== -1
      );
      setSubClassSummary([...subClassSummaryData]);
      setOrderSummary([...orderSummaryData]);
      setOrderSummaryOptimized([...orderSummaryOptimizedData]);
      setSKUSummary([...skuSummaryData]);
    } else {
      setSubClassSummary([...copyData[`subClassSummary`]]);
      setOrderSummary([...copyData[`orderSummary`]]);
      setOrderSummaryOptimized([...copyData[`orderSummaryOptimized`]]);
      setSKUSummary([...copyData[`skuSummary`]]);
    }
  };
  const onReset = () => {
    reloadFilters();
  };
  const handleMessageClose = () => {
    setDisplayMessage(false);
    setMessage(null);
  };
  const autoGroupColumnDef = useMemo(() => {
    return {
      headerValueGetter: (params) => `IA PO id`,
      minWidth: 220,
      cellRendererParams: {
        suppressCount: true,
        checkbox: true,
      },
    };
  }, []);
  const handleCheckboxChange = (e) => {
    console.log('-------', e);
    const { checked, name, value } = e.target;
    console.log(checked, name, value, gridRef);
    setSelectedValue(value);
    if (Object.keys(gridRef).length > 0) {
      gridApi.deselectAll();
    }
    // updateObj[`${name}`] = checked;
    // setOrderFilters(updateObj);
  };
  const onSelectionChanged = (rows, gridApiObj) => {
    setGridApi(gridApiObj);
    let selectedSkuIds = rows.map((obj) => obj.sku);
    let data = [];
    if (selectedValue === 'SKU') {
      data = skuSummaryCopy.filter((obj) => selectedSkuIds.includes(obj.skuID));
    } else {
      let orderData = [];
      let selectedSkuIds = rows.map((obj) => obj.parent_sku);
      if (isOptimized) {
        orderData = orderSummaryOptimized
          .filter((obj) => selectedSkuIds.includes(obj.parent_sku))
          .map((obj) => obj.sku);
      } else {
        orderData = orderSummary
          .filter((obj) => selectedSkuIds.includes(obj.parent_sku))
          .map((obj) => obj.sku);
      }
      data = skuSummaryCopy.filter((obj) => orderData.includes(obj.skuID));
    }

    if (selectedSkuIds.length > 0) {
      setSKUSummary([...data]);
    } else {
      setSKUSummary([...skuSummaryCopy]);
    }
  };

  return (
    <div className='impact_grid_sub_container'>
      <LoadingOverlay loader={loader}>
        {displayMessage && (
          <SnackBar
            message={message}
            variant='success'
            handleClose={handleMessageClose}
            // anchorOrigin={{
            //   vertical: 'bottom',
            //   horizontal: 'left',
            // }}
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FiltersList
              className='impact impact-order-management-filters'
              openLoader={openLoader}
              closeLoader={closeLoader}
              updateParentState={updateState}
              appliedFilters={appliedFiltersAction}
              onReset={onReset}
              screenName={'Order Management'}
            />
          </Grid>
        </Grid>
        <div className='spacer'></div>
        <div className='spacer'></div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Accordion
              className='impact impact-order-management-accordian'
              expanded={true}
              // onChange={handleChange(obj.label)}
              style={{
                borderLeft: '2px solid rgb(0, 85, 175)',
              }}
            >
              <AccordionSummary
                className='impact impact-order-management-accordian-summary'
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography
                  className='impact impact-order-management-typography'
                  sx={{ width: '100%', flexShrink: 0 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <p>KPI's</p>
                    </Grid>
                    <Grid item xs={2}>
                      <div style={{ textAlign: 'right' }}>
                        Units
                        <Switch
                          defaultChecked={kpisCostToggle}
                          className='switch'
                          id='kpisCostToggleBtn'
                          onChange={(event) => {
                            setKpisCostToggle(event.target.checked);
                          }}
                        ></Switch>
                        Cost
                      </div>
                    </Grid>
                  </Grid>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* <div className={styles['flex']}> */}
                <Grid container spacing={2}>
                  {kpis
                    .filter(
                      (kpi) =>
                        kpi.identifier === (kpisCostToggle ? 'cost' : 'unit')
                    )
                    .map((kpi) => (
                      <>
                        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}> */}
                        <div
                          className={`${styles['kpi-container']} ${styles['flex-column']}`}
                        >
                          <div className={styles['flex-align-center']}>
                            <div className={styles['kpi-icon-container']}>
                              <Inventory2Outlined
                                style={{ color: '#758398' }}
                              />
                            </div>
                            <div className={styles['kpi-title']}>
                              {kpi.type}
                            </div>
                          </div>
                          <div
                            className={`${styles['kpi-details-container']} ${styles['flex-align-end']} ${styles['flex-justify-space-between']}`}
                          >
                            <div
                              className={`${styles['unit-wrapper']} ${styles['flex']}`}
                            >
                              <div
                                className={`${styles['unit-container']} ${styles['flex-column']}`}
                              >
                                <div className={styles['unit-label']}>A</div>
                                <div className={styles['unit-value']}>
                                  {kpi.A}
                                </div>
                              </div>
                              <div
                                className={`${styles['unit-container']} ${styles['flex-column']}`}
                              >
                                <div className={styles['unit-label']}>B</div>
                                <div className={styles['unit-value']}>
                                  {kpi.B}
                                </div>
                              </div>
                              <div
                                className={`${styles['unit-container']} ${styles['flex-column']}`}
                              >
                                <div className={styles['unit-label']}>C</div>
                                <div className={styles['unit-value']}>
                                  {kpi.C}
                                </div>
                              </div>
                              <div
                                className={`${styles['unit-container']} ${styles['flex-column']}`}
                              >
                                <div className={styles['unit-label']}>D</div>
                                <div className={styles['unit-value']}>
                                  {kpi.D}
                                </div>
                              </div>
                            </div>
                            <div
                              className={`${styles['total-container']} ${styles['flex-column']} ${styles['flex-align-end']}`}
                            >
                              <div className={styles['total-label']}>Total</div>

                              <div className={styles['total-value']}>
                                {kpisCostToggle ? '$' : null}

                                {new Intl.NumberFormat('en-US').format(
                                  parseInt(kpi.A.replace(/,/g, '')) +
                                    parseInt(kpi.B.replace(/,/g, '')) +
                                    parseInt(kpi.C.replace(/,/g, '')) +
                                    parseInt(kpi.D.replace(/,/g, ''))
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* </Grid> */}
                      </>
                    ))}
                </Grid>
                {/* </div> */}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <div className='spacer'></div>
        <div className='spacer'></div>
        <Card>
          <CardHeader
            className='impact impact-order-management-subclass-title'
            title='Subclass Level Summary'
          />
          <CardContent className='impact impact-order-management-subclass-cardcontent'>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Table
                  className='impact impact-order-management-subclass-table'
                  suppressColumnVirtualisation
                  enableRangeSelection
                  enableCharts
                  columnDefs={subClassSummaryColumns}
                  rowData={subClassSummary}
                  floatingFilter={false}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  height={'300px'}
                  rowSelection={'multiple'}
                  autoSizeAll={false}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <div className='spacer'></div>
        <div className='spacer'></div>
        <Card>
          <CardHeader
            className='impact impact-order-management-order-summary-title'
            title='Order Summary'
          />
          <CardContent>
            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid>
                <OrderSummaryFilters className='impact impact-order-management-order-summary-filters' />
              </Grid>
              <Grid>
                <div className={`${styles['flex-align-center']}`}>
                  <Button
                    // className={styles['mr-20']}
                    variant='contained'
                    color='primary'
                    disabled={
                      !(
                        openToBudget ||
                        containerCapacity ||
                        (costTier && selectedItems.length > 0)
                      )
                    }
                    onClick={() => {
                      setMessage(
                        'Request has been submitted for optimization  will notify soon !!'
                      );
                      setTimeout(() => {
                        setIsOptimized(true);
                        setMessage(
                          'Request has been optimized  Successfully, reloading the data...'
                        );
                        setDisplayMessage(true);
                      }, 3000);

                      setDisplayMessage(true);
                    }}
                    style={{ textTransform: 'none', minWidth: '100px' }}
                  >
                    Optimize
                  </Button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      setMessage(
                        'Selected items has been approved successfully'
                      );
                      setDisplayMessage(true);
                    }}
                    style={{ textTransform: 'none', minWidth: '100px' }}
                  >
                    Approve
                  </Button>{' '}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button
                    // className={styles['mr-20']}
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      navigate('/inventory-smart/deep-dive');
                    }}
                    style={{ textTransform: 'none', minWidth: '120px' }}
                  >
                    Deep Dive
                  </Button>{' '}
                  &nbsp;&nbsp;
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      navigate('/inventory-smart/what-if?flow=order');
                    }}
                    style={{ textTransform: 'none', minWidth: '100px' }}
                  >
                    What If
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    // className={styles['mr-20']}
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      setMessage('reset the selection');
                      setDisplayMessage(true);
                    }}
                    style={{ textTransform: 'none', minWidth: '100px' }}
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
            </Grid>
            <div className='spacer'></div>
            <div>
              <Grid container>
                <Grid xs={12} md={1} sm={1} xl={1} lg={1}>
                  Filter By
                </Grid>
                <Grid xs={12} md={8} sm={8} xl={8} lg={8}>
                  <FormControl>
                    <RadioGroup
                      className='impact impact-order-management-order-summary-radio-group'
                      row
                      aria-labelledby='demo-row-radio-buttons-group-label'
                      name='row-radio-buttons-group'
                      onChange={handleCheckboxChange}
                    >
                      <FormControlLabel
                        className='impact impact-order-management-order-summary-formcontrol-1'
                        value='SKU'
                        checked={selectedValue === 'SKU'}
                        control={<Radio defaultChecked />}
                        label='SKU'
                        name='orderSummaryLevel'
                      />
                      <FormControlLabel
                        className='impact impact-order-management-order-summary-formcontrol-2'
                        value='Parent Sku'
                        control={<Radio />}
                        label='Parent Sku'
                        checked={selectedValue === 'Parent Sku'}
                        name='orderSummaryLevel'
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Table
                  className='impact impact-order-management-order-summary-table'
                  ref={gridRef}
                  suppressColumnVirtualisation
                  enableRangeSelection
                  enableCharts
                  columnDefs={[...OrderSummaryColumns]}
                  rowData={
                    isOptimized ? [...orderSummaryOptimized] : [...orderSummary]
                  }
                  floatingFilter={false}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  height={'300px'}
                  rowSelection={'multiple'}
                  onRowselectionChanged={onSelectionChanged}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <div className='spacer'></div>
        <div className='spacer'></div>
        <div className='spacer'></div>
        <Card>
          <CardContent className='impact impact-order-manamagement-card-content'>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* <div
                  className={`${styles['flex-align-center']} ${styles['flex-justify-space-between']}`}
                >
                  <h3>SKU Summary</h3>
                  <div className={`${styles['flex-align-center']}`}>
                    
                    <Button
                      className={styles['mr-20']}
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        navigate('/inventory-smart/deep-dive');
                      }}
                      style={{textTransform:'none'}}
                    >
                      Deep Dive
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        navigate(
                          '/inventory-smart/what-if?flow=order'
                        );
                      }}
                      style={{textTransform:'none'}}
                    >
                      What If
                    </Button>
                  </div>
                </div> */}
                <Table
                  className='impact impact-order-management-table-2'
                  suppressColumnVirtualisation
                  enableRangeSelection
                  enableCharts
                  columnDefs={SKUSummaryColumns}
                  rowData={skuSummary}
                  floatingFilter={false}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  height={'350px'}
                  rowSelection={'multiple'}
                  frameworkComponents={{
                    skuLevelGraph: SkuLevelGraph,
                  }}
                  skuLevelGraphData={skuSummaryGraph}
                  autoSizeAll={true}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className='impact impact-order-manamagement-card-actions'>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div
                  className={`${styles['flex-align-center']} ${styles['flex-justify-center']}`}
                  style={{
                    padding: 10,
                    display: 'flex',
                    justifyContent: 'right',
                  }}
                >
                  <Button
                    className={styles['mr-20']}
                    variant='outlined'
                    color='primary'
                    onClick={() => {
                      setMessage('reset the selection');
                      setDisplayMessage(true);
                    }}
                    style={{ textTransform: 'none' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      setMessage('Selected order has been released');
                      setDisplayMessage(true);
                      setTimeout(() => {
                        dispatch({
                          type: 'WORKFLOW',
                          payload: 2,
                        });
                        navigate('/inventory-smart/order-repository');
                      }, 2000);
                    }}
                    style={{ textTransform: 'none' }}
                  >
                    Send for approval
                  </Button>
                </div>

                <div className='spacer'></div>
                <div className='spacer'></div>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </LoadingOverlay>
    </div>
  );
};

export default OrderManagement;
