export const IMPACT_CSS_CLASS = ' impact '
export const IMPACT_GRID_CSS_CLASS = ' impact-grid ';
export const IMPACT_CARD_CSS_CLASS = ' impact-card ';
export const IMPACT_AG_GRID_TABLE_CSS_CLASS = ' impact-aggrid-table ';
export const IMPACT_AG_GRID_TABLE_HEADER_CSS_CLASS = ' impact-aggrid-table-header ';
export const IMPACT_AG_GRID_TABLE_FIELD_CSS_CLASS = ' impact-aggrid-table-field ';
export const IMPACT_TABLE_CLASS = ' impact-table '
export const IMPACT_TABLE_HEADER_CSS_CLASS = ' impact-table-header ';
export const IMPACT_TABLE_FIELD_CSS_CLASS = ' impact-table-field ';
export const IMPACT_TAB_FIELD_CSS_CLASS = ' impact-tab-field ';
export const IMPACT_ACCORDION_CLASS =' impact-accordion '
export const IMPACT_ACCORDION_SUMMARY_CLASS =' impact-accordion-summary '
export const IMPACT_TYPOGRAPHY_CLASS =' impact-typography '
export const IMPACT_ACCORDIONDETAILS_CLASS =' impact-accordiondetails '
export const IMPACT_CARDHEADER_CLASS =' impact-cardheader '
export const IMPACT_FORMCONTROL_CLASS =' impact-formcontrol '
export const IMPACT_RADIOGROUP_CLASS =' impact-radiogroup '
export const IMPACT_CARDCONTENT_CLASS =' impact-cardcontent '
export const IMPACT_CHART_CLASS =' impact-chart '
export const IMPACT_FORMCONTROLLABEL_CLASS =' impact-formcontrollabel '
 export const IMPACT_BUTTON_SUBMIT_CSS_CLASS =' impact-button-submit '
 export const IMPACT_BUTTON_CLOSE_CSS_CLASS =' impact-button-CLOSE '
 export const IMPACT_BUTTON_CSS_CLASS =' impact-button '
 export const IMPACT_BUTTON_CANCEL_CSS_CLASS =' impact-button-cancel '
export const IMPACT_TAB_CLASS =' impact-tab '
export const IMPACT_DIALOGTITLE_CLASS =' impact-dialog '
export const IMPACT_DIALOGCONTENT_CLASS =' impact-dialogcontent '
export const IMPACT_DIALOGCONTENTTEXT_CLASS =' impact-dialogcontenttext '
export const IMPACT_DIALOGACTIONS_CLASS =' impact-dialogactions '
export const IMPACT_DIALOG_CLASS = ' impact-dialog '
export const IMPACT_SCENARIOMODAL_CLASS = ' impact-scenariomodal '
export const IMPACT_LOADINGOVERLAY_CLASS = ' impact-loadingoverlay '
export const IMPACT_FILTERSLIST_CLASS = ' impact-filterslist '
export const IMPACT_TABPANEL_CLASS = ' impact-tabpanel '
export const IMPACT_TEXTFIELDWITHLABEL_CLASS = ' impact-textfieldwithlabel '
export const IMPACT_CIRCULARPROGRESS_CLASS = ' impact-circularprogress '