export const getColumnDefs = (columnData) => {
  columnData.filter((obj) => {
    if(!obj['cellStyle']) {
        obj.cellStyle = function (params) {
          
            if (params.context && params.context.editedColumns) {
              const data = params.context.editedColumns.filter((obj) => {
                console.log(
                  obj.rowIndex,
                  params.rowIndex,
                  obj.colId,
                  params.column.colId
                );
                if (
                  obj.rowIndex == params.rowIndex &&
                  obj.colId == params.column.colId
                ) {
                  return obj;
                }
              });
              if (data.length > 0) {
                return {
                  background: '#f6c6c8',
                };
              }
            }
          };
    } 
    
    if(obj['children']){
        obj['children'].filter(nestedObj=>{
            if(!nestedObj['cellStyle']) {
                nestedObj.cellStyle = function (params) {
                    if (params.context && params.context.editedColumns) {
                      const nestedData = params.context.editedColumns.filter((obj) => {
                        console.log(
                          obj.rowIndex,
                          params.rowIndex,
                          obj.colId,
                          params.column.colId
                        );
                        if (
                          obj.rowIndex == params.rowIndex &&
                          obj.colId == params.column.colId
                        ) {
                          return obj;
                        }
                      });
                      if (nestedData.length > 0) {
                        return {
                          background: '#f6c6c8',
                        };
                      }
                    }
                  };
            }
            
        })
    }
    return obj;
  });
  return columnData;
};
