const initialState = {
  checkboxFilters: {},
  workflow: 0,
};

const orderManagement = (state = initialState, action) => {
  switch (action.type) {
    case 'CHECKBOX_FILTERS':
      return {
        ...state,
        checkboxFilters: action.payload,
      };
    case 'WORKFLOW':
      return {
        ...state,
        workflow: action.payload,
      };
    default:
      return state;
  }
};

export default orderManagement;
