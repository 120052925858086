import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export default function OrderAlertColumns() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const changeFunction = (cell) => {
    if (cell.data.action_name === 'Generate orders for new products') {
      dispatch({
        type: 'SHOW_ORDER_REVIEW_TABLE',
        payload: true,
      });
    } else if (cell.data.action_name.includes('Approve orders')) {
      dispatch({
        type: 'WORKFLOW',
        payload: 1,
      });
      navigate('/inventory-smart/order-repository');
    } else {
      navigate('/inventory-smart/order-management');
    }
  };
  return [
    { field: 'action_name', headerName: 'Action Name', minWidth: 520 },
    { field: 'description', headerName: 'Description', minWidth: 520 },
    { field: 'skus', headerName: "# SKU's" },
    { field: 'roq', headerName: 'ROQ' },
    {
      field: 'action',
      headerName: 'Actions',
      minWidth: 200,
      cellRendererFramework: (cell) => {
        return (
          <u
            style={{ color: '#541a8b', cursor: 'pointer' }}
            onClick={() => changeFunction(cell)}
            className='table_url'
          >
            Review Recommendation
          </u>
        );
      },
    },
  ];
}
