import React, { Component } from 'react';
import moment from 'moment';
import { fetchHierarchyData } from './api';
import SelectRenderer from '../../components/Select/SelectRenderer';
import { Grid } from '@mui/material';
import CustomButton from '../../components/CustomButton';
import FilterAltOutlined from '@mui/icons-material/FilterAltOutlined';
import Refresh from '@mui/icons-material/Refresh';

import CustomDatePicker from '../../components/CustomDatePicker';
import CustomDateRangePicker from '../../components/CustomDateRangePicker';
import CheckboxGroup from '../../components/CheckboxGroup';

export default class DynamicFilters extends Component {
  constructor(props) {
    super(props);
    console.log('props', this.props);
    this.state = {
      ...this.props.options,
    };
  }

  async componentDidMount() {
    const promises = [];
    console.log('props', this.props);
    if (this.props.hierarchyApiCalls.length > 0) {
      this.props.hierarchyApiCalls.forEach((obj) => {
        let promise;
        // if (obj.UrlName != '') {
        promise = fetchHierarchyData(obj.UrlName, {
          screenName: this.props.screenName,
          data: this.props.filterMasterValues,
        });
        // }
        promises.push(promise);
      });
    }
    this.props.openLoader();
    try {
      const res = await Promise.all(promises);
      let optionsStateObj = {};
      this.props.hierarchyApiCalls.forEach((obj, index) => {
        this.props.closeLoader();
        const data = res[index];
        optionsStateObj[`${obj.optionsMappingKey}Options`] = data.map(
          (val) => ({
            value: val.value,
            label: val.label,
            ...val,
          })
        );
      });
      this.setState({
        ...optionsStateObj,
      });

      if (this.props.accessChildState) {
        this.props.accessChildState(this.state);
      }
    } catch (error) {
      this.props.closeLoader();
    }
  }

  getFiltersData = async (type, currentStateObj, isDependentOn) => {
    const dependentFilters = this.props.filtersList
      .filter((obj) => obj.is_dependent_on)
      .map((obj) => obj.label);
    if (currentStateObj.length > 0) {
      try {
        let dependentObj = isDependentOn[0];
        let reqObj = {
          ...dependentObj,
          filter_values: currentStateObj.map((obj) => obj.value),
        };
        let parentObj = {};
        let selectedValue = {};
        parentObj[`${dependentObj.label}Options`] = [];
        selectedValue[`${dependentObj.label}`] = [];
        this.props.openLoader();
        const data = await fetchHierarchyData(currentStateObj, {
          screenName: this.props.screenName,
          ...reqObj,
          data: this.props.filterMasterValues,
        });
        parentObj[`${dependentObj.label}Options`] = data;
        this.setState(parentObj);
        this.updateState(selectedValue);
        this.props.closeLoader();
      } catch (error) {
        this.props.closeLoader();
      }
    } else {
      let dependentObj = isDependentOn[0];
      let startIndex = dependentFilters.indexOf(dependentObj.label);
      const postFilters = dependentFilters.splice(
        startIndex,
        dependentFilters.length
      );
      let parentObj = {};
      let selectedValue = {};
      postFilters.forEach(async (obj) => {
        parentObj[`${obj}Options`] = [];
        selectedValue[`${obj}`] = [];
      });

      this.setState({
        ...parentObj,
      });

      this.updateState({
        ...selectedValue,
      });
    }
  };
  updateState = (params) => {
    console.log(params);
    this.props.updateParentState({
      ...params,
    });
  };

  reset() {
    let resetObj = Object.assign({}, this.props.options);
    this.props.hierarchyApiCalls.forEach((obj) => {
      delete resetObj[`${obj.optionsMappingKey}Options`];
    });

    this.setState({
      ...resetObj,
    });
    this.props.onReset();
  }

  handleCheckboxChange = (e, info, filterObj) => {
    const { name, value } = e.target;
    const isExistedData = filterObj.state.filter((obj) => obj.label === name);
    const notExistedData = filterObj.state.filter((obj) => obj.label !== name);
    const isNewData = info.filter((obj) => obj.label === name);
    let params = {};
    if (isExistedData.length > 0) {
      params[`${filterObj.label}`] = notExistedData;
    } else {
      params[`${filterObj.label}`] = filterObj.state.concat(isNewData);
    }
    this.updateState(params);
  };
  handleDateRangeChange = (values, filterObj) => {
    let params = {};
    params[`${filterObj.label}`] = values;
    this.updateState(params);
  };

  renderFilters(obj) {
    switch (obj.type) {
      case 'multi-select':
        return (
          <>
            <SelectRenderer
              options={this.state[`${obj.mappingKey}Options`]}
              selectedItems={obj.state}
              isMandatory={false}
              isMulti={true}
              placeholder={obj.placeholder}
              filterLabel={obj.label}
              updateSelected={(params) => {
                console.log('Updated Obj', obj);
                let updateData = {};
                updateData[`${obj.mappingKey}`] = params.selectedItems;
                this.updateState(updateData);
                if (obj.isDependentOn.length > 0) {
                  this.getFiltersData(
                    obj.mappingKey,
                    params.selectedItems,
                    obj.isDependentOn
                  );
                }
              }}
            />
          </>
        );
      case 'single-select':
        return (
          <>
            <SelectRenderer
              options={this.state[`${obj.mappingKey}Options`]}
              selectedItems={obj.state}
              isMandatory={false}
              isMulti={false}
              filterLabel={obj.label}
              placeholder={obj.placeholder}
              updateSelected={(params) => {
                let updateData = {};
                updateData[`${obj.mappingKey}`] = params.selectedItems;
                this.updateState(updateData);
                if (obj.isDependentOn.length > 0) {
                  this.getFiltersData(
                    obj.mappingKey,
                    params.selectedItems,
                    obj.isDependentOn
                  );
                }
              }}
            />
          </>
        );
        break;
      case 'date':
        <>
          {/* <CustomDatePicker
                label={obj.label}
                labeldirection={'column'}
                values={selectedFilters[filter.index]}
                isMandatory={obj.is_required}
                // onChange={(value) => handleDateChange(value, filter.index)}
              /> */}
        </>;
        break;
      case 'datetime':
        return (
          <>
            <CustomDateRangePicker
              label={obj.label}
              labeldirection={'column'}
              values={obj.state}
              isMandatory={false}
              onChange={(values) => this.handleDateRangeChange(values, obj)}
            />
          </>
        );
      case 'checkboxes':
        return (
          <>
            <CheckboxGroup
              label={obj.label}
              options={this.state[`${obj.mappingKey}Options`]}
              row={true}
              values={obj.id}
              handleChange={(e) =>
                this.handleCheckboxChange(
                  e,
                  this.state[`${obj.mappingKey}Options`],
                  obj
                )
              }
            />
          </>
        );
      default:
        return <></>;
    }
  }
  render() {
    return (
      <React.Fragment>
        <div class='filter_form_component_container' style={{ zIndex: 9999 }}>
          <h2 class='filter_sub_heading'>Basic Filters</h2>
          <Grid container spacing={3} columns={15}>
            {this.props.filtersList.map((obj, index) => (
              <>
                <Grid
                  item
                  md={3}
                  key={`decision-dashboard_filter-${obj.label}`}
                >
                  {this.renderFilters(obj)}
                </Grid>
              </>
            ))}
          </Grid>
        </div>
        <div class='btn_bottom_container_filter'>
          <div className='primary_btn'>
            <CustomButton
              className='primaryBtn'
              isPrimary={true}
              variant='contained'
              height={36}
              width={104}
              label='Filter'
              startIcon={<FilterAltOutlined />}
              onButtonClick={() => this.props.appliedFilters()}
            />
          </div>
          <div class='secondary_btn'>
            <CustomButton
              isPrimary={false}
              variant='outlined'
              height={36}
              width={104}
              label='Reset'
              startIcon={<Refresh />}
              onButtonClick={() => this.reset()}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

DynamicFilters.defaultProps = {
  isDefaultSelectedAll: true,
  isFilterButtonRequired: true,
};
