import React from "react";
const OrderRepositoryWhatIfComponent = () => {


  return (
    <>
      <div className="page-content">
      <iframe width="1440" height="840" src="https://xd.adobe.com/embed/791362ad-2881-48af-a0f0-0082c8c48a3a-2c40/screen/1d35744d-d678-4be8-8295-319a27a53b35" frameborder="0" allowfullscreen></iframe>
      </div>
    </>
  );
};

export default OrderRepositoryWhatIfComponent;
