export const tableObject = {
  title: 'Review Recommendations',
  label: 'Daily Allocation Summary',
  value: 'review_recommendations',
  data: [],
};

export const modalData = {
  title: 'Store Transfer',
  key: 'tb_rr_store_transfer',
  selectList: [
    { label: 'ST001', value: 'ST001' },
    { label: 'ST002', value: 'ST002' },
    { label: 'ST003', value: 'ST003' },
    { label: 'ST004', value: 'ST004' },
    { label: 'ST005', value: 'ST005' },
    { label: 'ST006', value: 'ST006' },
    { label: 'ST007', value: 'ST007' },
    { label: 'ST008', value: 'ST008' },
    { label: 'ST009', value: 'ST009' },
    { label: 'ST0010', value: 'ST0010' },
    { label: 'ST0011', value: 'ST0011' },
    { label: 'ST0012', value: 'ST0012' },
    { label: 'ST0013', value: 'ST0013' },
    { label: 'ST0014', value: 'ST0014' },
  ],
  column: [
    {
      field: 'scenario',
      headerName: 'Scenario',
      headerCheckboxSelection: false,
      checkboxSelection: true,
      width: 350,
    },
    { field: 'store', headerName: 'Store', width: 150 },
    { field: 'units', headerName: 'Units', width: 150 },
    { field: 'sales', headerName: 'Sales $', width: 150 },
    { field: 'sales_unit', headerName: 'Sales Units', width: 200 },
    { field: 'gm', headerName: 'GM$', width: 150 },
  ],
  data: [
    {
      scenario: 'IA recommendations',
      store: 1025,
      units: 200,
      sales: '$25000',
      sales_unit: 1008,
      gm: '$5000',
    },
    {
      scenario: 'No transfer',
      store: '-',
      units: '-',
      sales: '$15000',
      sales_unit: 502,
      gm: '-$500',
    },
  ],
};
