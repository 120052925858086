import React from 'react';

export default function DCReview() {
  return [
    {
    headerName: 'Pinned',
      children: [
    {
      field: 'dc_id',
      headerName: 'DC ID',
      pinned: 'left',
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      pinned: 'left',
    },
    {
      field: 'style_id',
      headerName: 'Style ID',
      pinned: 'left',
      columnGroupShow: 'closed',
    },
    {
      field: 'style_id',
      headerName: 'Style ID',
      pinned: 'left',
      columnGroupShow: 'open',
    },
    {
      field: 'style_description',
      headerName: 'Style Description',
      width: 330,
      pinned: 'left',
      columnGroupShow: 'open',
    },
    {
      field: 'inventory',
      headerName: 'Inventory',
      pinned: 'left',
      columnGroupShow: 'open',
    },
    {
      field: 'target_wos',
      headerName: 'Target WOS',
      pinned: 'left',
      columnGroupShow: 'open',
    },
    {
      field: 'wos',
      headerName: 'WOS',
      pinned: 'left',
      columnGroupShow: 'open',
    }]},
    {
      headerName: 'Recommended Sources',
      children: [
        { field: 'rs_dc_id', headerName: 'DC ID' },
        { field: 'rs_inventory', headerName: 'Inventory' },
        { field: 'rs_wos', headerName: 'WOS' },
        { field: 'rs_ia_recommended_transfer', headerName: 'IA Rec. Transfer', width: 300 },
        { field: 'rs_edited', headerName: 'Edited' },
      ],
    },
    {
      field: 'select',
      headerName: 'Select',
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
  ];
}
