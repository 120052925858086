const initialState = {
  showReviewTable: false,
  showOrderReviewTable: false,
  showStoreModal: false,
  showProductDetailsModal: false,
  showChildSkuTable: false,
  showChildStoreSkuTable: false,
  showReplenishmentChart: false,
  showPreAllocationTable: false,
  showProductTable: false,
  selectedRows: null,
  showReplenishmentAlertModal:false,
  singleAllocationSelected: false,
  singleProductSelected: false,
  jcPennyData: false
};

const decisionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_REVIEW_TABLE':
      return {
        ...state,
        showReviewTable: action.payload,
      };
    case 'SHOW_ORDER_REVIEW_TABLE':
      return {
        ...state,
        showOrderReviewTable: action.payload,
      };
    case 'SHOW_STORE_TRANSFER_POPUP':
      return {
        ...state,
        showStoreModal: action.payload,
      };
    case 'SHOW_PRODUCT_DETAILS_POPUP':
      return {
        ...state,
        showProductDetailsModal: action.payload,
      };
    case 'SHOW_CHILD_SKU_TABLE':
      return {
        ...state,
        showChildSkuTable: action.payload,
      };
    case 'SHOW_CHILD_STORE_SKU_TABLE':
      return {
        ...state,
        showChildStoreSkuTable: action.payload,
      };
    case 'SHOW_REPLENISHMENT_CHART':
      return {
        ...state,
        showReplenishmentChart: action.payload,
      };
    case 'SHOW_PREALLOCATION_TABLE':
      return {
        ...state,
        showPreAllocationTable: action.payload,
      };
    case 'SHOW_PRODUCT_TABLE':
      return {
        ...state,
        showProductTable: action.payload,
      };
    case 'SELECTED_ROWS':
      return {
        ...state,
        selectedRows: action.payload,
      };
    case 'SHOW_REPLENISHMENT_ALERT_MODAL':
      return {
        ...state,
        showReplenishmentAlertModal:action.payload,
      }
    case 'SINGLE_ALLOCATION_SELECTED':
      return {
        ...state,
        singleAllocationSelected: action.payload,
      };
    case 'SINGLE_PRODUCT_SELECTED':
      return {
        ...state,
        singleProductSelected: action.payload,
      };
    case 'JC_PENNY_DATA':
      return {
        ...state,
        jcPennyData: action.payload,
      };
    default:
      return state;
  }
};

export default decisionReducer;
