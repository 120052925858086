import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Chart from '../../../../utils/charts/Chart';
import { Table } from '../../../../components/Table';
import CustomCard from '../../../../components/CustomCard';
import { initialData, filterData, tabsList } from './data';
import { fetchHierarchyData } from './action';
import Filters from '../../../../components/FilterChips';
import TabPanel from '../Decision/TabPanel';
import { IAForecast } from './IAForecast';
import { Comparison } from './Comparison';
import { Scenario } from './Scenario';
import {
  getModelData,
  getMultipleModelsData,
} from '../../services/decisionDashboardService';
import FiltersList from '../../../../molecules/MultiFiltersList';
import moment from 'moment';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import { ScenarioModal } from './scenarioModal';
import CustomButton from '../../../../components/CustomButton';
import * as css_constants from '../../../../constants/cssConstants';
import { AdjForecast } from './AdjForecast';

const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  headerCard: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
});

const useStyles = makeStyles(styles);

const ADAVisuals = () => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [curentObject, setCurentObject] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [dynamicTabs, setDynamicTabs] = useState(tabsList);
  const [size, setSize] = useState(tabsList.length);

  const [scenarioData, setScenarioData] = useState([
    {
      driver_forecast: [],
      sales_units_forecast: [],
    },
  ]);
  const [iaForecastData, setIaForecastData] = useState({
    forecastAdj: [],
    forecastChart: [],
  });

  const getData = async () => {
    openLoader();
    const result = await getMultipleModelsData({
      model_names: [
        'tb_driver_forecast',
        'tb_sales_units_forecast',
        'tb_ia_forecast_multiplier',
        'tb_ia_forecast_view_hierarchy',
        'tb_ia_forecast_chart',
        'tb_ia_forecast_visualization',
        // 'tb_ia_forecast_cost_chart',
        'tb_ia_forecast_visualization_cost',
        'tb_ia_forecast_view_cost_hierarchy',
      ],
      selectedFilters,
    });
    const {
      tb_driver_forecast,
      tb_sales_units_forecast,
      tb_ia_forecast_multiplier,
      tb_ia_forecast_chart,
      tb_ia_forecast_view_hierarchy,
      tb_ia_forecast_cost_chart,
      tb_ia_forecast_view_cost_hierarchy,
      tb_ia_forecast_visualization,
      // tb_ia_forecast_visualization_cost
    } = result;
    setScenarioData([
      {
        driver_forecast: tb_driver_forecast,
        // sales_units_forecast: tb_sales_units_forecast,
        // sales_units_forecast: tb_ia_forecast_chart,
        // sales_units_forecast: tb_ia_forecast_visualization,
        // 'tb_ia_forecast_visualization':tb_ia_forecast_visualization,
        sales_units_forecast: tb_ia_forecast_chart,
        view_hierarchy: tb_ia_forecast_view_hierarchy,
        forecastAdj: tb_ia_forecast_multiplier,
      },
    ]);
    setIaForecastData({
      forecastAdj: tb_ia_forecast_multiplier,
      view_hierarchy: tb_ia_forecast_view_hierarchy,
      view_cost_hierarchy: tb_ia_forecast_view_cost_hierarchy,
      // forecastChart: tb_ia_forecast_chart,
      forecastChart: tb_ia_forecast_visualization,
      forecastChartCopy: tb_ia_forecast_visualization,
      // forecastCostChart: tb_ia_forecast_cost_chart,
      // forecastCostChart:tb_ia_forecast_visualization_cost,
      // forecastCostChartCopy:tb_ia_forecast_visualization_cost,
      driver_forecast: tb_driver_forecast,
    });
    setDynamicTabs(tabsList);
    closeLoader();
  };

  useEffect(async () => {
    await getData();
  }, []);

  useEffect(async () => {
    setSize(dynamicTabs.length);
  }, [dynamicTabs]);

  const handleChange = (event, newValue) => {
    setCurentObject(initialData[newValue]);
    setValue(newValue);
    setIsLoading(true);
  };

  const handleTabChange = (_, newValue) => {
    setTabIndex(newValue);
  };

  // useEffect(() => {
  //   if (value) getData(`tb_${value}`);
  // }, [value]);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const setBackToInitialTab = () => {
    setTabIndex(0);
  };
  const renderTabContent = (tabDetails) => {
    const { label, value } = tabDetails;
    switch (label) {
      case 'IA Forecast':
        return (
          <>
            <IAForecast data={iaForecastData} />{' '}
          </>
        );
        break;
      case 'Adjusted Forecast':
        return (
          <>
            <IAForecast data={iaForecastData} />{' '}
            {/* <AdjForecast data={iaForecastData} />{' '} */}
          </>
        );
        break;
      case 'Comparison':
        return (
          <>
            <Comparison
              setBackToInitialTab={setBackToInitialTab}
              scenarioTabs={dynamicTabs.length - 2}
            />{' '}
          </>
        );
        break;

      default:
        let index = parseInt(label.split(' ')[1]) - 1;
        return (
          <>
            <Scenario data={scenarioData[index]} />{' '}
          </>
        );
        break;
    }
  };
  const reloadFilters = async () => {
    setScenarioData([
      {
        driver_forecast: [],
        sales_units_forecast: [],
      },
    ]);
    setDynamicTabs(tabsList);
    setIaForecastData([]);
    setSelectedFilters({});
  };

  const openLoader = () => {
    console.log('Open Loader');
    setLoader(true);
  };
  const closeLoader = () => {
    setLoader(false);
  };
  const updateState = (params) => {
    console.log('Updating state', params);
    setSelectedFilters(params);
  };
  const appliedFiltersAction = async (params) => {
    console.log(selectedFilters);
    getData();
  };

  const onReset = () => {
    reloadFilters();
  };

  const handleOpen = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const submitScenario = (newData) => {
    const data = [...dynamicTabs];
    console.log('Size of he ', size);
    if (size === 1) {
      data.splice(
        size,
        0,
        {
          label: `Scenario ${size}`,
          value: `scenario_${size}`,
        },
        {
          label: 'Comparison',
          value: 'Comparison',
        }
      );
    } else {
      data.splice(size - 1, 0, {
        label: `Scenario ${size - 2}`,
        value: `scenario_${size - 2}`,
      });
    }
    // setDynamicTabs(data.concat({
    //   label: `Scenario ${size}`,
    //   value: `scenario_${size}`,
    // },))
    setDynamicTabs(data);
    const scenario_data = [...scenarioData].concat([
      {
        driver_forecast: [...newData],
        sales_units_forecast: scenarioData[0].sales_units_forecast,
        forecastAdj: scenarioData[0].forecastAdj,
        view_hierarchy: scenarioData[0].view_hierarchy,
      },
    ]);
    console.log(scenario_data, scenarioData);
    setScenarioData(scenario_data);
    handleClose();
  };
  return (
    <>
      <LoadingOverlay
        loader={loader}
        className={
          css_constants.IMPACT_CSS_CLASS +
          css_constants.IMPACT_LOADINGOVERLAY_CLASS
        }
      >
        {show ? (
          <ScenarioModal
            show={show}
            data={[...scenarioData[0]['driver_forecast']]}
            handleClose={handleClose}
            submitScenario={submitScenario}
            type={scenarioData.length}
            className={
              css_constants.IMPACT_CSS_CLASS +
              css_constants.IMPACT_SCENARIOMODAL_CLASS
            }
          />
        ) : null}
        <Grid
          container
          spacing={2}
          className={
            css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_GRID_CSS_CLASS
          }
        >
          <Grid
            item
            xs={12}
            className={
              css_constants.IMPACT_CSS_CLASS +
              css_constants.IMPACT_GRID_CSS_CLASS
            }
          >
            {/* <FiltersList
              openLoader={openLoader}
              closeLoader={closeLoader}
              updateParentState={updateState}
              appliedFilters={appliedFiltersAction}
              onReset={onReset}
              screenName={'ADA Visual'}
              className={
                css_constants.IMPACT_CSS_CLASS +
                css_constants.IMPACT_FILTERSLIST_CLASS
              }
            /> */}
            {iaForecastData?.forecastAdj.length > 0 ? (
              <>
                <div className='impact_grid_sub_container'>
                  <CustomCard cardStyles={classes.headerCard}>
                    <div className='spacer'></div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {/* <Grid container spacing={2}> */}
                      {/* <Grid item xs={11} md={10} sm={9} lg={10} xl={10}> */}
                      <Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        aria-label='basic tabs example'
                      >
                        {dynamicTabs.map((obj, index) => (
                          <Tab
                            label={obj.label}
                            {...a11yProps(0)}
                            style={{
                              textTransform: 'none',
                            }}
                            className={
                              css_constants.IMPACT_CSS_CLASS +
                              css_constants.IMPACT_TAB_CLASS
                            }
                          />
                        ))}
                      </Tabs>
                      {/* </Grid> */}
                      {/* <Grid item xs={1} md={2} sm={3} lg={2} xl={2}> */}
                      <div style={{ marginRight: '20px' }}>
                        <CustomButton
                          isPrimary={true}
                          variant='contained'
                          height={36}
                          width={150}
                          label='Add Scenario'
                          padding='100'
                          onButtonClick={handleOpen}
                          className={
                            css_constants.IMPACT_CSS_CLASS +
                            css_constants.IMPACT_BUTTON_CSS_CLASS
                          }
                          //   onButtonClick={}
                        />
                      </div>
                      {/* </Grid> */}
                      {/* </Grid> */}
                    </div>
                    <div className='spacer'></div>
                    <Grid
                      item
                      xs={12}
                      className={
                        css_constants.IMPACT_CSS_CLASS +
                        css_constants.IMPACT_GRID_CSS_CLASS
                      }
                    >
                      {dynamicTabs.map((tabObj, index) => (
                        <>
                          <TabPanel
                            value={tabIndex}
                            index={index}
                            className={
                              css_constants.IMPACT_CSS_CLASS +
                              css_constants.IMPACT_TABPANEL_CLASS
                            }
                          >
                            {renderTabContent(tabObj)}
                          </TabPanel>
                        </>
                      ))}
                    </Grid>
                  </CustomCard>
                </div>
              </>
            ) : null}
          </Grid>
        </Grid>
      </LoadingOverlay>
    </>
  );
};

export default ADAVisuals;
