import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import * as css_constants from '../../../../constants/cssConstants';
export default function CostStoreTablesCol() {
  const dispatch = useDispatch();
  const openPopup = () => {
    dispatch({
      type: 'SHOW_CHILD_SKU_TABLE',
      payload: true,
    });
  };
  return [
    {
        field: 'store_sku',
        headerName: "Stores/SKU's",
        editable: false,
        cellRendererFramework: (cell) => (
          <Button
            style={{ textTransform: 'none' }}
            onClick={openPopup}
            autoFocus
            variant='text'
            className={css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_BUTTON_CSS_CLASS}
          >
            {cell.data.store_sku}
          </Button>
        ),
      },
      { field: 'all_weeks', headerName: 'All Weeks', className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_FIELD_CSS_CLASS },
      { field: 'week_1', headerName: 'Week 1' , className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_FIELD_CSS_CLASS},
      { field: 'week_2', headerName: 'Week 2' , className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_FIELD_CSS_CLASS},
      { field: 'week_3', headerName: 'Week 3' , className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_FIELD_CSS_CLASS},
      { field: 'week_4', headerName: 'Week 4' , className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_FIELD_CSS_CLASS},
      { field: 'week_5', headerName: 'Week 5' , className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_FIELD_CSS_CLASS},
      { field: 'week_6', headerName: 'Week 6' , className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_FIELD_CSS_CLASS},
      { field: 'week_7', headerName: 'Week 7' , className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_FIELD_CSS_CLASS},
      { field: 'week_8', headerName: 'Week 8' , className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_FIELD_CSS_CLASS},
  ];
}
