import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../../components/Table';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import DataLabels from '../../../../components/DataLabels';
import CustomCard from '../../../../components/CustomCard';
import { Grid, Paper } from '@mui/material';

import {
  alert_columns1,
  alert_columns2,
  alert_data1,
  alert_data2,
  allocation_alert_columns,
  allocation_alert_data,
  order_alert_columns,
  replenishment_alert,
  replenishment_alert_review,
  replenishment_alert_modal,
  allocation_alert_review_recommendation_columns,
  productDetails,
  order_review_table_columns,
  productsData,
  productLabels,
  clickAction
} from './data';
import { fetchHierarchyData } from './action';
import getAllocationReviewRecommendationCol from './AllocationReviewRecommendationCol';
import getAllocationAlertColumns from './AllocationAlertColumns';
import getPreAllocationReviewColumns from './PreAllocationReview';
import getOrderAlertColumns from './OrderAlertColumns';
import GenericTable from '../../../../components/GenericTable/GenericTable';
const styles = (theme) => ({
  title: {
    margin: '30px 10px 10px 10px',
    textAlign: 'left',
    fontSize: '0.9rem',
    fontWeight: theme.typography.fontWeight.normal,
  },
  headerCardActive: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
    border: `1px solid ${theme.palette.strokeLightBlue}`,
  },
  headerCardMetricsActive: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
    cursor: theme.content.cursor.pointer,
    border: `1px solid ${theme.palette.strokeLightBlue}`,
  },
  headerCardMetrics: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    cursor: theme.content.cursor.pointer,
    overflow: theme.content.overflow.visible,
  },
  headerCard: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
  dateContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    height: '100%',
  },
  tabIndicator: {
    height: '4px',
    borderRadius: '4px',
    background: '#44677b',
  },
  tab: {
    color: `${theme.palette.stroke}`,
    fontSize: '1.2rem',
    fontWeight: theme.typography.fontWeight.medium,
    textTransform: theme.typography.textTransform.capitalize,
    padding: '20px 16px',
  },
  activeTab: {
    color: `#44677b !important`,
    fontSize: '1.2rem',
    fontWeight: theme.typography.fontWeight.semiBold,
    textTransform: theme.typography.textTransform.capitalize,
    padding: '20px 16px',
  },
  headerBox: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
  },
  iconButton: {
    fontSize: '24px !important',
  },
  startIcon: {
    display: 'inherit',
    margin: 0,
  },
  selectTitle: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textDark,
    margin: 0,
    marginRight: 16,
  },
  treeMapContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
  },
  legendContainer: {
    ...theme.content.flexStyles.flexCol,
    ...theme.content.flexStyles.flexAlignCenterStart,
  },
  legend: {
    ...theme.typography.fontSizes.normalText,
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    margin: '8px 0',
  },
  legendMarker1: {
    background: theme.palette.bgSuccess,
    borderRadius: '50%',
    marginRight: 8,
    height: 8,
    width: 8,
  },
  legendMarker2: {
    background: '#B1BEFF',
    borderRadius: '50%',
    marginRight: 8,
    height: 8,
    width: 8,
  },
  legendMarker3: {
    background: theme.palette.bgDanger,
    borderRadius: '50%',
    marginRight: 8,
    height: 8,
    width: 8,
  },
  finalizeButtonContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    padding: '8px 0 24px 0',
  },
});
const useStyles = makeStyles(styles);

export const DecisionDashboardAlert = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [detailsData, setDetailsData] = useState([]);
  const [reviewTableData, setReviewTableData] = useState([]);
  const [productsTableData, setProductsTableData] = useState([]);
  const [preAllocationData, setpreAllocationData] = useState([]);
  const [orderReviewData, setOrderReviewData] = useState([]);
  const [dataLabelObj, setDataLableObj] = useState({});
  const [allocationsSelected, setAllocationsSelected] = React.useState(false);
  const [showReplenishmentAlertReview, setShowReplenishmentAlertReview] = useState(false)
  const {
    showReviewTable,
    showProductDetailsModal,
    showOrderReviewTable,
    showPreAllocationTable,
    showProductTable,
    showReplenishmentAlertModal
  } = useSelector((state) => state.decision);
  const columnsReview = useMemo(() => getAllocationAlertColumns());
  const orderColumnsReview = useMemo(() => getOrderAlertColumns());
  const preAllocationColumnsReview = useMemo(() =>
    getPreAllocationReviewColumns()
  );
  const columnsReviewRecommendation = useMemo(() =>
    getAllocationReviewRecommendationCol()
  );

  const getData = async () => {
    const productDetails = await fetchHierarchyData(
      'tb_al_al_product_details_modal'
    );
    const reviewRecommendation = await fetchHierarchyData(
      'tb_al_al_review_recommendation'
    );
    const orderReview = await fetchHierarchyData('tb_order_review');
    const preAllocationData = await fetchHierarchyData('tb_pre_allocation');
    const productsTableData = await fetchHierarchyData('tb_products_table');
    if (productDetails) setDetailsData(productDetails);
    if (reviewRecommendation) setReviewTableData(reviewRecommendation);
    if (orderReview) setOrderReviewData(orderReview);
    if (preAllocationData) setpreAllocationData(preAllocationData);
    if (productsTableData) setProductsTableData(productsTableData);
  };

  useEffect(() => {
    if (props.kpiData.length > 0) {
      setDataLableObj(props.kpiData[0]);
    }
  }, [props.kpiData]);


  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (Object.keys(preAllocationData[0] || {})?.includes("jcpenny")) {
      dispatch({
        type: 'JC_PENNY_DATA',
        payload: true,
      });
    }
  }, [preAllocationData]);

  const handleClose = () => {
    dispatch({
      type: 'SHOW_PRODUCT_DETAILS_POPUP',
      payload: false,
    });
    dispatch({
      type: 'SHOW_REPLENISHMENT_ALERT_MODAL',
      payload: false,
    });
  };

  const handlePopUpClose = () => {
    dispatch({
      type: 'SHOW_PREALLOCATION_TABLE',
      payload: false,
    });
    dispatch({
      type: 'SHOW_PRODUCT_TABLE',
      payload: false,
    });
  };

  const OnAllocate = () => {
    dispatch({
      type: 'SHOW_PREALLOCATION_TABLE',
      payload: false,
    });
    dispatch({
      type: 'SHOW_PRODUCT_TABLE',
      payload: false,
    });
    navigate(
      '/inventory-smart/new-product-allocation?alert-flow=pre-allocation'
    );
  };

  const finalReview = () => {
    if (allocationsSelected === 1)
    dispatch({
      type: 'SINGLE_PRODUCT_SELECTED',
      payload: true,
    });
    dispatch({
      type: 'SHOW_PRODUCT_DETAILS_POPUP',
      payload: false,
    });
    dispatch({
      type: 'SHOW_REPLENISHMENT_ALERT_MODAL',
      payload: false,
    });
    navigate('/inventory-smart/review-allocation');
  };
  const classes = useStyles();
  const renderView = () => {
    switch (props.parentLabel) {
      case 'Forecast':
        return (
          <div>
            <Grid item xs={12} marginBottom={2}>
                    <div class="datalabel_container">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} xl={12}>
                        <div class='card_bottom_container'>
                        <CustomCard
                          cardStyles={classes.headerCard}
                          fullHeight={true}
                        >
                        
                          <div
                            className={classes.container}
                            style={{
                              borderRadius: 3,
                            }}
                          >
                             
                            {productLabels?.map((productLabel, index) => (
                              <>
                             
                                <DataLabels
                                  key={productLabel.id}
                                  label={productLabel.label}
                                  value={
                                    productLabel.value
                                      ? dataLabelObj[`${productLabel.id}`]
                                      : null
                                  }
                                  isLast={index === productLabels.length - 1}
                                />
                              </>
                            ))}
                            </div>
                          
                        </CustomCard>
                        </div>
                      </Grid>
                    </Grid>
                    </div>
                  </Grid>
            <Table
              className='impact impact-decision-forecast-alert-table'
              rowData={props.data.alert1}
              columnDefs={alert_columns1}
              pageSize={5}
              rowsPerPageOptions={[5]}
              floatingFilter={false}
              height={'300px'}
              rowSelection={'multiple'}
              columnFit={'sizeColumnsToFit'}
              tableCssStyle='sizeColumnsToFit'
            />
            {/* <Table
              rowData={props.data.alert2}
              columnDefs={alert_columns2}
              pageSize={5}
              rowsPerPageOptions={[5]}
              floatingFilter={false}
              height={'300px'}
              rowSelection={'multiple'}
              columnFit={'sizeColumnsToFit'}
            /> */}
          </div>
        );
        break;
      case 'Allocation':
        return (
          <>
            <Grid item xs={12} marginBottom={2}>
                    <div class="datalabel_container">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} xl={12}>
                        <div class='card_bottom_container'>
                        <CustomCard
                          cardStyles={classes.headerCard}
                          fullHeight={true}
                        >
                        
                          <div
                            className={classes.container}
                            style={{
                              borderRadius: 3,
                            }}
                          >
                             
                            {productLabels?.map((productLabel, index) => (
                              <>
                             
                                <DataLabels
                                  key={productLabel.id}
                                  label={productLabel.label}
                                  value={
                                    productLabel.value
                                      ? dataLabelObj[`${productLabel.id}`]
                                      : null
                                  }
                                  isLast={index === productLabels.length - 1}
                                />
                              </>
                            ))}
                            </div>
                          
                        </CustomCard>
                        </div>
                      </Grid>
                    </Grid>
                    </div>
                  </Grid>
            <Table
              className='impact impact-decision-allocation-alert-table'
              rowData={props.data.alert1}
              columnDefs={columnsReview}
              pageSize={5}
              rowsPerPageOptions={[5]}
              floatingFilter={false}
              height={'300px'}
              rowSelection={'multiple'}
              columnFit={'sizeColumnsToFit'}
              tableCssStyle='sizeColumnsToFit'
            />
            {showReviewTable && (
              <div style={{ marginTop: 10 }}>
                <div className='spacer'></div>
                <Typography
                  className='impact impact-decision-allocation-alert-title'
                  variant='h6subtitle2'
                >
                  Review Recommendation
                </Typography>
                <Table
                  className='impact impact-decision-allocation-alert-table-2'
                  rowData={reviewTableData}
                  columnDefs={columnsReviewRecommendation}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  floatingFilter={false}
                  height={'300px'}
                  rowSelection={'multiple'}
                  columnFit={'sizeColumnsToFit'}
                  tableCssStyle='sizeColumnsToFit'
                />
              </div>
            )}
          </>
        );
        break;
      case 'Replenishment':
        return (
          <>
            <Grid item xs={12} marginBottom={2}>
                    <div class="datalabel_container">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} xl={12}>
                        <div class='card_bottom_container'>
                        <CustomCard
                          cardStyles={classes.headerCard}
                          fullHeight={true}
                        >
                        
                          <div
                            className={classes.container}
                            style={{
                              borderRadius: 3,
                            }}
                          >
                             
                            {productLabels?.map((productLabel, index) => (
                              <>
                             
                                <DataLabels
                                  key={productLabel.id}
                                  label={productLabel.label}
                                  value={
                                    productLabel.value
                                      ? dataLabelObj[`${productLabel.id}`]
                                      : null
                                  }
                                  isLast={index === productLabels.length - 1}
                                />
                              </>
                            ))}
                            </div>
                          
                        </CustomCard>
                        </div>
                      </Grid>
                    </Grid>
                    </div>
                  </Grid>
            <Table
              className='impact impact-decision-replenishment-alert-table'
              rowData={props.data.alert1}
              columnDefs={replenishment_alert}
              pageSize={5}
              rowsPerPageOptions={[5]}
              floatingFilter={false}
              height={'300px'}
              rowSelection={'multiple'}
              columnFit={'sizeColumnsToFit'}
              tableCssStyle='sizeColumnsToFit'
            />
            {showReplenishmentAlertReview ?
              <>
                <GenericTable
              title={"Review Recommendation"}
              apiKey={'tb_replenishment_alerts_review'}
              columnDefs={replenishment_alert_review}
              style={{marginTop:"20px"}}
                />
                <div style={{display:'flex',justifyContent:'center',gap:'20px'}}>
          <Button
            style={{ textTransform: 'none' }}
            onClick={()=>(navigate('/inventory-smart/deep-dive-vs'))}
            autoFocus
            variant='contained'
          >
            Deep Dive
          </Button>
          <Button
            style={{ textTransform: 'none' }}
            onClick={()=>(navigate('/inventory-smart/create-new-order'))}
            autoFocus
            variant='contained'
          >
            Create New Order
          </Button>
                </div>
              </>
              : null}
          </>
        );
        break;
      case 'Order':
        return (
          <>
            <Table
              className='impact impact-decision-order-alert-table'
              rowData={props.data.alert1}
              columnDefs={orderColumnsReview}
              pageSize={5}
              rowsPerPageOptions={[5]}
              floatingFilter={false}
              height={'300px'}
              // rowSelection={'multiple'}
              //columnFit={'sizeColumnsToFit'}
              tableCssStyle='autoSizeAllColumns'
            />
            {showOrderReviewTable && (
              <div style={{ marginTop: 10 }}>
                <div className='spacer'></div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography
                    className='impact impact-decision-order-alert-title'
                    variant='h6subtitle2'
                  >
                    Review Recommendation
                  </Typography>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      navigate('/inventory-smart/order-management');
                    }}
                    style={{ textTransform: 'none' }}
                  >
                    Review Order
                  </Button>
                </div>
                <div className='spacer'></div>
                <Table
                  className='impact impact-decision-order-alert-table-2'
                  rowData={orderReviewData}
                  columnDefs={order_review_table_columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  floatingFilter={false}
                  height={'300px'}
                  rowSelection={'multiple'}
                  tableCssStyle='sizeColumnsToFit'
                />
              </div>
            )}
          </>
        );
        break;

      default:
        break;
    }
  };

  const onSelectionChanged = (rows, gridApiObj) => {
    setAllocationsSelected(gridApiObj.getSelectedRows().length);
  };

  useEffect(() => {
    clickAction['replenishment_alert_action'] = () => {
      setShowReplenishmentAlertReview(true)
    }
    clickAction['replenishment_alert_child_action'] = () => {
      dispatch({
        type: "SHOW_REPLENISHMENT_ALERT_MODAL",
        payload:true
      })
    }
  }, [])
  
  return (
    <>
      {renderView()}

      <Dialog
        className='impact impact-decision-alert-dialog'
        open={showProductDetailsModal}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        maxWidth={'lg'}
        fullWidth
      >
        <DialogTitle
          className='impact impact-decision-alert-dialog-title'
          id='alert-dialog-title'
        >
          {productDetails.title}
        </DialogTitle>
        <DialogContent>
          <Table
            className='impact impact-decision-alert-dialog-table'
            rowData={detailsData}
            columnDefs={productDetails.column}
            floatingFilter={false}
            height={'240px'}
            rowSelection={'multiple'}
            onRowselectionChanged={onSelectionChanged}
          />
        </DialogContent>
        <DialogActions style={{ marginRight: 5 }}>
          <Button
            style={{ textTransform: 'none' }}
            onClick={handleClose}
            autoFocus
            variant='text'
          >
            Cancel
          </Button>
          <Button
            style={{ textTransform: 'none' }}
            onClick={handleClose}
            autoFocus
            variant='outlined'
          >
            Release
          </Button>
          <Button
            style={{ textTransform: 'none' }}
            onClick={finalReview}
            autoFocus
            variant='contained'
          >
            Review
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showPreAllocationTable}
        onClose={handlePopUpClose}
        aria-labelledby='alert-dialog-title'
        maxWidth={'lg'}
        fullWidth
      >
        <DialogTitle id='alert-dialog-title'>{'Pre Allocation'}</DialogTitle>
        <DialogContent>
          <Table
            rowData={preAllocationData}
            columnDefs={preAllocationColumnsReview}
            floatingFilter={false}
            height={'240px'}
            rowSelection={'multiple'}
            tableCssStyle='sizeColumnsToFit'
          />
          <div className='spacer' />
          {showProductTable && (
            <Table
              rowData={productsTableData}
              columnDefs={productsData.column}
              floatingFilter={false}
              height={'240px'}
              rowSelection={'multiple'}
              tableCssStyle='sizeColumnsToFit'
            />
          )}
        </DialogContent>
        {showProductTable && (
          <DialogActions style={{ marginRight: 5 }}>
            <Button
              style={{ textTransform: 'none' }}
              onClick={handlePopUpClose}
              autoFocus
              variant='contained'
            >
              Close
            </Button>
            <Button
              style={{ textTransform: 'none' }}
              onClick={OnAllocate}
              autoFocus
              variant='contained'
            >
              Allocate
            </Button>
          </DialogActions>
        )}
      </Dialog>
      

      <Dialog
        open={showReplenishmentAlertModal}
        aria-labelledby='alert-dialog-title'
        maxWidth={'lg'}
        fullWidth
      >
        <DialogTitle id='alert-dialog-title'>{'Details'}</DialogTitle>
        <DialogContent>
          <Table
            rowData={props.data.alertModal}
            columnDefs={replenishment_alert_modal}
            floatingFilter={false}
            height={'300px'}
            rowSelection={'multiple'}
          />
        </DialogContent>
        <DialogActions style={{ marginRight: 5 }}>
          <Button
            style={{ textTransform: 'none' }}
            onClick={handleClose}
            autoFocus
            variant='text'
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
