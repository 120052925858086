import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DynamicFilters from './DynamicFilters';
import CustomCard from '../components/CustomCard';
import CustomAccordion from '../components/CustomAccordion';
import FiltersHead from './FiltersHead';
import moment from 'moment';
import LoadingOverlay from '../components/LoadingOverlay';
import { getData } from './DynamicFilters/api';
import Filters from '../components/FilterChips';
import { getMultipleModelsData } from '../modules/InventorySmart/services/decisionDashboardService';
const styles = (theme) => ({
  headerCard: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
});

const useStyles = makeStyles(styles);
const FiltersList = (props) => {
  const { openLoader, closeLoader, screenName } = props;
  const classes = useStyles();
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [loader, setLoader] = useState(false);
  const [filterMenu, setFilterMenu] = useState([]);
  const [filterMasterValues, setFilterMasterValues] = useState([]);
  const [dynamicFiltersProps, setDynamicFiltersProps] = useState([]);
  const [dynamicFiltersOptions, setDynamicFiltersOptions] = useState([]);
  const [independentApiCalls, setIndependentApiCalls] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [selectedFilterChips, setSelectedFilterChips] = useState({});
  useEffect(async () => {
    openLoader();
    const result = await getMultipleModelsData({
      model_names: ['tb_filter_master', 'tb_filter_master_values'],
    });
    const { tb_filter_master, tb_filter_master_values } = result;
    setFilterMenu(tb_filter_master);
    setFilterMasterValues(tb_filter_master_values);
    closeLoader();
  }, []);

  const reloadFilters = async () => {
    const filterObj = {};
    await filterMenu.forEach((obj) => {
      if (obj.type === 'datetime') {
        filterObj[`${obj.master_category}`] = [
          new Date(moment().subtract(1, 'years')),
          new Date(moment()),
        ];
      } else {
        const newData = filterMasterValues.filter(
          (childObj) =>
            parseInt(childObj.parent_id) === parseInt(obj.categoryid) &&
            childObj.is_default_value === 'Y'
        );
        newData.filter((obj) => {
          obj['label'] = obj.name;
          obj['value'] = obj.name;
        });

        filterObj[`${obj.master_category}`] = newData;
        // filterObj[`${obj.master_category}`] = [];
      }
    });
    setFilterData(filterObj);
    setSelectedFilterChips({});
  };
  useEffect(async () => {
    reloadFilters();
  }, [filterMasterValues]);

  useEffect(() => {
    if (Object.keys(filterData).length > 0) {
      const filtersPropsList = [];
      const filtersOptionsList = {};
      const independentApis = [];

      filterMenu.forEach((obj) => {
        let isDependentOn = filterMenu.filter((filterObj) => {
          return filterObj.is_dependent_on === obj.categoryid;
        });

        filtersPropsList.push({
          type: obj.type,
          placeholder: `Select ${obj.master_category}`,
          state: filterData[`${obj.master_category}`],
          mappingKey: obj.master_category,
          label: obj.label,
          required: obj.is_required,
          is_dependent_on: obj.is_dependent_on,
          id: obj.categoryid,
          isDependentOn,
        });
        filtersOptionsList[`${obj.master_category}Options`] = [];
        if (obj.is_dependent === 'FALSE') {
          independentApis.push({
            optionsMappingKey: obj.master_category,
            UrlName: obj.categoryid,
          });
        }
      });

      setDynamicFiltersProps(filtersPropsList);
      setDynamicFiltersOptions(filtersOptionsList);
      setIndependentApiCalls(independentApis);
    }
  }, [filterData]);

  const updateState = (params) => {
    let reqParams = {
      ...filterData,
      ...params,
    };
    setFilterData(reqParams);
    props.updateParentState(reqParams);
  };

  const onReset = () => {
    reloadFilters();
    props.onReset();
  };
  const appliedFiltersAction = async () => {
    props.appliedFilters(filterData);
    let reqObj = {};
    Object.keys(filterData).filter((obj, index) => {
      if (filterData[`${obj}`].length > 0) {
        reqObj[`${obj}`] = {
          label: obj,
          section: filterData[`${obj}`].map((obj) => obj.value),
        };
      }
    });
    setSelectedFilterChips(reqObj);
    setFiltersExpanded(false);
  };

  return (
    <div
      style={{
        height: props.height || 90,
        backgroundColor: 'white',
      }}
    >
      {/* <LoadingOverlay loader={loader}> */}
      <div className='filter_main_container'>
        <div
          className='filter_main_container_sub_container'
          style={{
            position: 'fixed',
            width: '100%',
            zIndex: 9999,
            backgroundColor: 'white',
          }}
        >
          <Grid>
            <CustomCard cardStyles={classes.headerCard}>
              {dynamicFiltersProps.length > 0 ? (
                <>
                  <CustomAccordion
                    summary={
                      <div class='filter_heading_container'>
                        <FiltersHead
                          filtersExpanded={filtersExpanded}
                          setFiltersExpanded={setFiltersExpanded}
                          screenName={screenName}
                          filtersSummary={selectedFilterChips}
                        />
                      </div>
                    }
                    details={
                      <div class='filter_dd_main_container'>
                        <DynamicFilters
                          filtersList={dynamicFiltersProps}
                          options={dynamicFiltersOptions}
                          hierarchyApiCalls={independentApiCalls}
                          openLoader={props.openLoader}
                          closeLoader={props.closeLoader}
                          updateParentState={updateState}
                          appliedFilters={appliedFiltersAction}
                          onReset={onReset}
                          screenName={screenName}
                          filterMasterValues={filterMasterValues}
                          filtersSummary={selectedFilterChips}
                          filtersExpanded={filtersExpanded}
                        />
                      </div>
                    }
                    expanded={filtersExpanded}
                  />
                </>
              ) : null}
            </CustomCard>
          </Grid>

          {/* </LoadingOverlay> */}
        </div>
      </div>
    </div>
  );
};

export default FiltersList;
