export const subClassSummaryColumns = [
  { field: 'subClass', headerName: 'Subclass', pinned: 'left' },
  { field: 'totalSKUs', headerName: 'Total SKUs' },
  { field: 'total30DaysDemand', headerName: 'Total 30 days demand' },
  { field: 'recommendedOrders', headerName: '# recommended orders', width: 250 },
  { field: 'approvedOrders', headerName: '# approved orders' },
  { field: 'DCOH', headerName: 'DC OH (units)' },
  { field: 'SL%', headerName: 'SL%' },
  { field: 'unconstrainedOrderQty', headerName: 'Unconstrained Order qty (units)', width: 280  },
  { field: 'constrainedOrderQty', headerName: 'Constrained Order qty (units)', width: 280  },
  { field: 'userEditedOrderQty', headerName: 'User edited Order qty (units)', width: 280  },
  {
    field: 'unconstrainedOrderCost',
    headerName: 'Unconstrained Order cost (USD)',
    width: 300
  },
  {
    field: 'constrainedOrderCost',
    headerName: 'Constrained Order cost (USD)',
    width: 280
  },
  { field: 'userEditedOrderCost', headerName: 'User edited Order cost (USD)', width: 280 },
  { field: 'totalOpenToBuy', headerName: 'Total open to buy' },
];

export const OrderSummaryColumns = [
  { field: 'ia_po_id', headerName: 'IA PO id',
  //  pinned: 'left', 
   width: 150,rowGroup:false , hide: false,
   headerCheckboxSelection: true,
   checkboxSelection: true},
  { field: 'vendor', headerName: 'Vendor', 
    // pinned: 'left',
   width: 130,rowGroup:false},
  { field: 'dc', headerName: 'DC',
  //  pinned: 'left',
     width: 110,rowGroup:false },
  { field: 'parent_sku', headerName: 'Parent SKU', 
    // pinned: 'left',
     width: 130},
  { field: 'sku', headerName: 'SKU',
  //  pinned: 'left', 
    width: 110 },
  { field: 'optimized_order_quantity', headerName: 'Optimized Order Quantity', width: 250,aggFunc: 'sum'},
  { field: 'order_quantity', headerName: 'Order Quantity',aggFunc: 'sum'},
  { field: 'sku_info', headerName: 'SKU Info',
  children:[
  { field: 'subclass', headerName: 'Subclass', width: 170},
  { field: 'item_grading', headerName: 'Item Grading', width: 170},
  { field: 'store_inventory', headerName: 'Store Inventory',aggFunc: 'sum'},
  { field: 'dc_inventory', headerName: 'DC Inventory',aggFunc: 'sum'},
  { field: 'system_inventory', headerName: 'System Inventory',aggFunc: 'sum'},
  ]},
  { field: 'recommendation', headerName: 'Recommendation',
  children:[
    { field: 'order_placement_date', headerName: 'Order Placement Date'},
    { field: 'ideal_order_placement_date', headerName: 'Ideal Order Placement Date', width: 250},
    { field: 'lead_time_weeks', headerName: 'Lead time (weeks)'},
    { field: 'order_receipt_date', headerName: 'Order Receipt date'},
    { field: 'order_cost', headerName: 'Order Cost',aggFunc: 'sum'},
  ]},
  { field: 'constraints', headerName: 'Constraints',
  children:[
    { field: 'open_to_buy', headerName: 'Open to Buy',aggFunc: 'sum'},
  { field: 'transaction_parent_moq_units', headerName: 'Transaction & Parent MOQ units', width: 350,aggFunc: 'sum'},
  { field: 'transaction_parent_contract_moq', headerName: 'Transaction & Parent Contract MOQ remaining', width: 400,aggFunc: 'sum'},
  { field: 'contract_moq_period', headerName: 'Contract MOQ Period',aggFunc: 'sum'},
  { field: 'max_supplier_capacity_units', headerName: 'Max Supplier Capacity Units', width: 250,aggFunc: 'sum'},
  { field: 'container_requirement', headerName: 'Container Requirement', width: 250,aggFunc: 'sum'},
  { field: 'available_dc_capacity_units', headerName: 'Available DC Capacity (units)', width: 250,aggFunc: 'sum'},
  { field: 'case_pack_size', headerName: 'Case Pack Size'}
  ]
}
  
];


export const SKUSummaryColumns = [
  {
    field: 'skuID',
    headerName: 'SKU Id',
    pinned: 'left',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: 'skuLevelGraph',
    // rowGroup: true,
    // hide:true
  },
  {
    field: 'subclass',
    headerName: 'Subclass',
    // pinned: 'left',
  },
  {
    field: 'itemGrading',
    headerName: 'Item Grading',
    // pinned: 'left',
  },
  {
    field: 'DCLocation',
    headerName: 'DC Location',
    // pinned: 'left',
  },
  {
    field: 'vendor',
    headerName: 'Vendor',
    // pinned: 'left',
  },
  { field: 'storeOH', headerName: 'Store OH' },
  { field: 'DCOH', headerName: 'DC OH' },
  {
    field: 'expeditedOrders',
    headerName: 'Expedited orders',
  },
  { field: 'orderQty', headerName: 'Order qty' },
  {
    field: 'IASuggestedORderQty',
    headerName: 'IA suggested order qty',
  },
  {
    field: 'constrainedOrderQty',
    headerName: 'Constrained order qty',
  },
  {
    field: 'orderCost',
    headerName: 'Order cost',
  },
  {
    field: 'orderPlacementDate',
    headerName: 'Order placement date',
  },
  {
    field: 'expectedReceiptDate',
    headerName: 'Expected receipt date',
  },
  {
    headerName: 'Safety stock at DC',
    children: [
      { field: 'invInDC', headerName: 'Inv in DC' },
      { field: 'recSS', headerName: 'Rec SS' },
    ],
  },
  {
    headerName: 'WOS',
    children: [
      { field: 'WOS', headerName: 'WOS' },
      { field: 'WOSLC', headerName: 'WOS LC' },
    ],
  },
  {
    headerName: 'Service Level',
    children: [
      { field: 'SL', headerName: 'SL'},
      { field: 'targetSL', headerName: 'Target SL' },
    ],
  },
  {
    headerName: 'Lead Time',
    children: [
      {
        field: 'effectiveLT',
        headerName: 'Effective LT',
      },
      {
        field: 'effectiveLTLC',
        headerName: 'Effective LT LC',
      },
    ],
  },
  {
    headerName: 'Demand Forecast',
    children: [
      {
        field: 'last120Days',
        headerName: 'Last 120 days',
      },
      {
        field: 'last90Days',
        headerName: 'Last 90 days',
      },
      {
        field: 'last30Days',
        headerName: 'Last 30 days',
      },
      {
        field: '120DayFCST',
        headerName: '120 day fcst',
      },
      {
        field: 'varLC120Days',
        headerName: 'var LC (120 days)',
      },
      { field: '90DayFCST', headerName: '90 day fcst' },
      {
        field: 'varLC90Days',
        headerName: 'var LC (90 days)',
      },
      { field: '30DayFCST', headerName: '30 day fcst' },
      {
        field: 'varLC30Days',
        headerName: 'var LC (30 days)',
      },
    ],
  },
];

export const DeepDiveColumns = [
    {
      field: 'item',
      headerName: 'Item #',
      pinned: 'left',
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
        field: 'month',
        headerName: 'Month',
        pinned: 'left',
      },
    {
      field: 'week_wise_date',
      headerName: 'Week Wise Date',
    },
    {
      field: 'bom_units',
      headerName: 'BOM Units',
    },
    { field: 'storeOH', headerName: 'Store OH' },
    { field: 'DCOH', headerName: 'DC OH' },

    {
      field: 'oo_units',
      headerName: 'OO Units',
    },
    {
      field: 'late_on_order',
      headerName: 'Late On Order',
    },
    {
      field: 'roq',
      headerName: 'ROQ',
    },
    { field: 'sales_forecast', headerName: 'Sales Forecast' },
    { field: 'safety_stock', headerName: 'Safety Stock' },
    {
      field: 'aur',
      headerName: 'AUR',
    },
    {
      field: 'wos',
      headerName: 'WOS',
    },
    {
      field: 'sell_through',
      headerName: 'Sell Through',
    },
    {
      field: 'sales_units_to_period',
      headerName: 'Sales Units % To Period',
    },
    {
      field: 'discount_off',
      headerName: 'Disc. Off',
    },
    {
        field: 'md_percentage',
        headerName: 'MD %',
      },
      {
        field: 'md_dollar',
        headerName: 'MD $',
      },
      
    
  ];
  

  export const getColorCoding = (value)=>{
    const performance = {
      below: {
        background: '#c7e7d0',
      },
      between: {
        background: '#fee8bf',
      },
      above: {
        background: '#f6c6c8',
      },
    };
    let label =  ''
    if (value < 10) {
      label = 'below';
    } else if (value > 10 && value < 25) {
      label = 'between';
    } else if (value >= 25) {
      label = 'above';
    }

    return performance[`${label}`];
  }

  export const OrderSummaryColumnsConvertedToInteger = {
    "optimized_order_quantity":"optimized_order_quantity",
    "order_quantity" : "order_quantity",
    "store_inventory" : "store_inventory",
    "dc_inventory" : "dc_inventory",
    "system_inventory" : "system_inventory",
    "order_cost" : "order_cost",
    "open_to_buy" : "open_to_buy",
    "transaction_parent_moq_units" : "transaction_parent_moq_units",
    "transaction_parent_contract_moq" : "transaction_parent_contract_moq",
    "max_supplier_capacity_units" : "max_supplier_capacity_units",
    "available_dc_capacity_units" : "available_dc_capacity_units",
    "case_pack_size" : "case_pack_size"
  }
  
  export const deepDiveColumnsConvertedToInteger = {
    "bom_units" : "bom_units",
    "store_oh" : "store_oh",
    "oo_units" : "oo_units",
    "late_on_order" : "late_on_order",
    "roq" : "roq",
    "sales_forecast" : "sales_forecast",
    "safety_stock" : "safety_stock",
    "aur" : "aur",
    "wos" : "wos",
    "sell_through" : "sell_through",
    "sales_units_to_period" : "sales_units_to_period",
    "discount_off" : "discount_off",
    "md_percentage" : "md_percentage",
    "md_dollar" : "md_dollar"
  }


  export const convertToInteger = (data,columns)=>{
    data.filter(obj=>{
      Object.keys(obj).filter(nestedObj=>{
        if(columns[`${nestedObj}`]){
          obj[`${nestedObj}`] = parseInt(obj[`${nestedObj}`])
        }
      })
    })
    console.log(data)
    return data;
  }
  export const getLTLCColorCoding = (value)=>{
    const performance = {
      below: {
        background: '#c7e7d0',
      },
      between: {
        background: '#fee8bf',
      },
      above: {
        background: '#f6c6c8',
      },
    };
    let label =  ''
    if (value < 0) {
      label = 'below';
    } else if (value > -1 && value < 21) {
      label = 'between';
    } else if (value > 20) {
      label = 'above';
    }

    return performance[`${label}`];
  }