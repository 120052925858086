import { Step, StepButton, Stepper } from '@mui/material';

const steps = ['Product & Store', 'Recommendation'];

const ReplenishmentStepper = ({ activeStep = 0 }) => {
  return (
    <Stepper nonLinear activeStep={activeStep} alternativeLabel>
      {steps.map((label, index) => (
        <Step key={label}>
          <StepButton>{label}</StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export default ReplenishmentStepper;
