import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import SelectRenderer from '../../../../components/Select/SelectRenderer';
import { CircularProgress } from '@mui/material';
import CustomButton from '../../../../components/CustomButton';
import Table from '../../../../components/Table/Table';
import { al_details_columns, sku_ids } from './data';
import Card from '@mui/material/Card';
import { fetchHierarchyData } from './action';
import { Grid } from '@mui/material';

const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
  },
});

const useStyles = makeStyles(styles);

const ForecastAlertReview = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dateRange = '02-01-2022 - 03-30-2022';
  const [isLoading, setIsLoading] = React.useState(true);
  const [metrics, setMetrics] = React.useState([]);
  const [reviewTable, setReviewTable] = React.useState([]);

  const getData = async (label, content) => {
    setIsLoading(true);
    const data = await fetchHierarchyData(label);
    if (data.length !== 0) {
      setIsLoading(false);
      if (content === 'metrics') setMetrics(data);
      else setReviewTable(data);
    }
  };

  useEffect(() => {
    getData('tb_review_metrics', 'metrics');
    getData('tb_allocation_details_review', 'reviewTable');
  }, []);

  const selectedModule = (e) => {
    return null;
  };

  const filterHandler = () => {
    return null;
  };

  return (
    <>
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress className={classes.loader} />
        </div>
      )}

      {!isLoading && (
        <Card
          style={{
            display: 'block',
            height: '50vw',
          }}
        >
          <div style={{ padding: '0.75rem' }}>
            <p style={{ fontWeight: 'bold' }}>Review Recommendation</p>
            <div style={{ display: 'flex' }}>
              <SelectRenderer
                options={sku_ids}
                isMandatory={true}
                isMulti={false}
                width='15rem'
                filterLabel={'Style ID'}
                updateSelected={selectedModule}
              />
              <div style={{ marginLeft: '2rem', marginTop: '1rem' }}>
                <CustomButton
                  isPrimary={true}
                  variant='contained'
                  height={32}
                  width={75}
                  label='Filter'
                  onButtonClick={() => filterHandler()}
                />
              </div>
              <div style={{ marginLeft: '1rem', marginTop: '1rem' }}>
                <CustomButton
                  isPrimary={true}
                  variant='outlined'
                  height={32}
                  width={75}
                  label='Reset'
                  onButtonClick={() => filterHandler()}
                />
              </div>
            </div>
            <div className="spacer"></div>
            <Grid container spacing={2}>
              <Grid item xs={7} sm={7} md={5} lg={3} xl={3}>
                 <span> <span style={{ marginTop: '2rem', fontSize: 'small' }}>
                    Date Range:
                  </span> &nbsp;&nbsp;
                  <span
                    style={{
                      marginLeft: '0.25rem',
                      marginTospan: '2rem',
                      fontSize: 'small',
                      color: 'grey',
                    }}
                  >
                    {dateRange}
                  </span>
                  </span>
              </Grid>

              <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
              <SelectRenderer
                  options={metrics}
                  isMulti={false}
                  width='15rem'
                  updateSelected={selectedModule}
                />
               </Grid>
              </Grid>
            {/* <div style={{ display: 'flex' }}>
              <p style={{ marginTop: '2rem', fontSize: 'small' }}>
                Date Range:
              </p>
              <p
                style={{
                  marginLeft: '0.25rem',
                  marginTop: '2rem',
                  fontSize: 'small',
                  color: 'grey',
                }}
              >
                {dateRange}
              </p> */}
              {/* <div style={{ margin: '1.25rem', marginLeft: '25rem' }}>
                <SelectRenderer
                  options={metrics}
                  isMulti={false}
                  width='15rem'
                  updateSelected={selectedModule}
                />
              </div>
            </div> */}
            <div className="spacer"></div>
            <Table
              rowData={reviewTable}
              columnDefs={al_details_columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              floatingFilter={false}
              height={'450px'}
              rowSelection={'multiple'}
            />
            <div className="spacer"></div>
            
            <div className={classes.saveButton} style={{ display: 'flex', justifyContent: 'right' }}>
              <CustomButton
                isPrimary={true}
                variant='contained'
                height={36}
                width={150}
                label='Review Forecast'
                padding='100'
                onButtonClick={() => 
                  navigate('/inventory-smart/ada_visuals')
                }
              /> &nbsp;&nbsp;
              <CustomButton
                isPrimary={true}
                variant='outlined'
                height={36}
                width={150}
                label='Approve Forecast'
                padding='100'
                onButtonClick={() => 
                  navigate('/inventory-smart/decision-dashboard')
                  //navigate('https://sandbox.inventorysmart.impactsmartsuite.com/inventory-smart/decision-dashboard')
                }
              />
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default ForecastAlertReview;
