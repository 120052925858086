import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import { makeStyles } from '@mui/styles';
import { Grid, CircularProgress, Button } from '@mui/material';
import ReplenishmentStepper from '../../../../components/ReplenishmentStepper/ReplenishmentStepper';
import Filters from '../../../../components/FilterChips';
import CustomCard from '../../../../components/CustomCard';
import FiltersList from '../../../../molecules/FiltersList';
import {
  filterData,
  replenishment_alert_columns,
  rep_alert_deep_dive,
} from './data';
import Table from '../../../../components/Table/Table';
import CustomButton from '../../../../components/CustomButton';
import { fetchHierarchyData } from './action';
import Snackbar from '../../../../components/SnackBar';
import replenishmentAlertTableCol from './ReplenishmentAlertTableCol';
import SkuGraphModel from './SkuGraphModal';
import SkuLevelGraph from './skuLevelGraph';

const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  headerCard: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
  },
});

const useStyles = makeStyles(styles);

const ReplenishmentAlert = () => {
  const dispatch = useDispatch();
  const actionBtn = useRef(null);
  const tableRef = useRef();
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [reviewtableData, setReviewTableData] = React.useState([]);
  const [skuChartData, setSkuChartData] = React.useState([]);
  const [deepDivetableData, setDeepDiveTableData] = React.useState([]);
  const [showDeepDive, setShowDeepDive] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [colData, setColData] = useState([
    {
      field: 'product_type',
      headerName: 'Product Type',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      pinned: 'left',
    },
  ]);

  const [displayMessage, setDisplayMessage] = React.useState(false);
  const [displayDraftMessage, setDisplayDraftMessage] = React.useState(false);
  const { showReplenishmentChart } = useSelector((state) => state.decision);

  useEffect(() => {
    if (skuChartData.length > 0) {
      setColData([
        {
          field: 'product_type',
          headerName: 'Product Type',
          headerCheckboxSelection: true,
          checkboxSelection: true,
          pinned: 'left',
        },
        {
          field: 'styel_color_id',
          headerName: 'Style Color ID',
          pinned: 'left',
          cellRenderer: 'skuLevelGraph',
          cellRendererParams: {
            skuSummaryGraph: skuChartData,
          },
        },
        { field: 'style_id', headerName: 'Style Id' },
        { field: 'color_id', headerName: 'Color ID' },
        { field: 'style_description', headerName: 'Style Description' },
        { field: 'color', headerName: 'Color' },
        { field: 'channel', headerName: 'Channel' },
        { field: 'department', headerName: 'Department' },
        { field: 'class', headerName: 'Class' },
        { field: 'style_group', headerName: 'Style Group' },
        { field: 'silhouette', headerName: 'Silhouette' },
        { field: 'sub_collect', headerName: 'Sub Collect' },
        {
          headerName: 'Mapped stores & DC',
          children: [
            {
              field: 'store_eligibility_group',
              headerName: 'Store Eligibility Group',
              width: 240,
            },
            {
              field: 'no_of_stores',
              headerName: 'No of stores',
              width: 160,
            },
            { field: 'mapped_dc', headerName: 'Mapped DC', width: 150 },
          ],
        },
        {
          headerName: 'Replenishment recommendation',
          children: [
            {
              field: 'recommended_qty',
              headerName: 'Recommended qty',
              width: 180,
            },
            {
              field: 'order_type',
              headerName: 'Order type',
              width: 160,
            },
          ],
        },
        {
          headerName: 'Configuration & constraints',
          children: [
            {
              field: 'product_profile',
              headerName: 'Product Profile',
              width: 160,
            },
            {
              field: 'size_profile',
              headerName: 'Size Profile',
              width: 170,
            },
            { field: 'demand_type', headerName: 'Demand Type', width: 160 },
            { field: 'average_WOS', headerName: 'Average WOS', width: 160 },
            { field: 'average_min', headerName: 'Aggregated Min', width: 170 },
            { field: 'average_max', headerName: 'Aggregated Max', width: 170 },
          ],
        },
        {
          headerName: 'Safety stock',
          children: [
            {
              field: 'inv_in_dc',
              headerName: 'Inv in DC',
              width: 120,
              cellStyle: (cell) => {
                let color = { background: '#c7e7d0' };
                let value = parseInt(
                  ((parseInt(cell.data.wos_lc) - parseInt(cell.value)) /
                    parseInt(cell.data.wos_lc)) *
                    100
                );
                if (value < 10) {
                  color.background = '#c7e7d0';
                } else if (value > 10 && value < 25) {
                  color.background = '#fee8bf';
                } else if (value > 25) {
                  color.background = '#f6c6c8';
                }
                return color;
              },
            },
            {
              field: 'rec_ss',
              headerName: 'Rec SS',
              width: 120,
            },
          ],
        },
        {
          headerName: 'WOS',
          children: [
            {
              field: 'wos',
              headerName: 'WOS',
              width: 120,
              cellStyle: (cell) => {
                let color = { background: '#c7e7d0' };
                let value = parseInt(
                  ((parseInt(cell.data.wos_lc) - parseInt(cell.value)) /
                    parseInt(cell.data.wos_lc)) *
                    100
                );
                if (value < 10) {
                  color.background = '#c7e7d0';
                } else if (value > 10 && value < 25) {
                  color.background = '#fee8bf';
                } else if (value > 25) {
                  color.background = '#f6c6c8';
                }
                return color;
              },
            },
            {
              field: 'wos_lc',
              headerName: 'WOS LC',
              width: 120,
            },
          ],
        },
        {
          headerName: 'Service Level',
          children: [
            {
              field: 'sl',
              headerName: 'SL',
              width: 120,
              cellStyle: (cell) => {
                let color = { background: '#c7e7d0' };
                let value = parseInt(
                  ((parseInt(cell.data.wos_lc) - parseInt(cell.value)) /
                    parseInt(cell.data.wos_lc)) *
                    100
                );
                if (value < 10) {
                  color.background = '#c7e7d0';
                } else if (value > 10 && value < 25) {
                  color.background = '#fee8bf';
                } else if (value > 25) {
                  color.background = '#f6c6c8';
                }
                return color;
              },
            },
            {
              field: 'target_sl',
              headerName: 'Target SL',
              width: 120,
            },
          ],
        },
        {
          headerName: 'Lead Time',
          children: [
            { field: 'effective_lt', headerName: 'Effective LT', width: 160 },
            {
              field: 'effective_lt_lc',
              headerName: 'Effective LT LC',
              width: 180,
            },
          ],
        },
        {
          headerName: 'Demand Forecast',
          children: [
            { field: '30_day_fcst', headerName: '30 day fcst', width: 160 },
            { field: 'last_30_days', headerName: 'Last 30 days', width: 160 },
            {
              field: 'var_lc_30_days',
              headerName: '30D var% LC',
              width: 170,
              cellStyle: function (params) {
                const percentage = {
                  low: {
                    background: '#f6c6c8',
                  },
                  medium: {
                    background: '#fee8bf',
                  },
                  high: {
                    background: '#c7e7d0',
                  },
                };

                let label = '';
                let value = parseInt(params.data.var_lc_30_days);
                if (value > -10 && value < 10) {
                  label = 'high';
                } else if (value > -30 && value < 30) {
                  label = 'medium';
                } else {
                  label = 'low';
                }

                return percentage[`${label}`];
              },
            },
            { field: '90_day_fcst', headerName: '90 day fcst', width: 160 },
            { field: 'last_90_days', headerName: 'Last 90 days', width: 160 },
            {
              field: 'var_lc_90_days',
              headerName: '90D var% LC',
              width: 170,
              cellStyle: function (params) {
                const percentage = {
                  low: {
                    background: '#f6c6c8',
                  },
                  medium: {
                    background: '#fee8bf',
                  },
                  high: {
                    background: '#c7e7d0',
                  },
                };

                let label = '';
                let value = parseInt(params.data.var_lc_90_days);
                if (value > -10 && value < 10) {
                  label = 'high';
                } else if (value > -30 && value < 30) {
                  label = 'medium';
                } else {
                  label = 'low';
                }

                return percentage[`${label}`];
              },
            },
            { field: '120_day_fcst', headerName: '120 day fcst', width: 160 },
            { field: 'last_120_days', headerName: 'Last 120 days', width: 180 },
            {
              field: 'var_lc_120_days',
              headerName: '120D var% LC',
              width: 170,
              cellStyle: function (params) {
                const percentage = {
                  low: {
                    background: '#f6c6c8',
                  },
                  medium: {
                    background: '#fee8bf',
                  },
                  high: {
                    background: '#c7e7d0',
                  },
                };

                let label = '';
                let value = parseInt(params.data.var_lc_120_days);
                if (value > -10 && value < 10) {
                  label = 'high';
                } else if (value > -30 && value < 30) {
                  label = 'medium';
                } else {
                  label = 'low';
                }

                return percentage[`${label}`];
              },
            },
          ],
        },
      ]);
    }
  }, [skuChartData]);

  const getChartData = async () => {
    const chartData = await fetchHierarchyData(
      'tb_replenishment_summary_graph'
    );
    if (chartData) setSkuChartData(chartData);
  };

  const handleChartClose = () => {
    dispatch({
      type: 'SHOW_REPLENISHMENT_CHART',
      payload: false,
    });
  };
  // const columnsTable = useMemo(() => {
  //   return replenishmentAlertTableCol({ skuChartData });
  // }, [skuChartData]);

  const getData = async (label, content) => {
    setIsLoading(true);
    const data = await fetchHierarchyData(label);
    if (data.length !== 0) {
      setIsLoading(false);
      if (content === 'review') setReviewTableData(data);
      else setDeepDiveTableData(data);
    }
  };

  useEffect(() => {
    getData('tb_replenishment_recommendations', 'review');
    getData('tb_rep_alert_deep_dive', 'deep_dive');
    getChartData();
  }, []);

  const clickHandler = () => {
    setShowDeepDive(true);
    setTimeout(() => {
      actionBtn.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  };

  const onBtStartEditing = useCallback((key, char, pinned) => {
    console.log('tableRef', tableRef);
    console.log('tableRef.current', tableRef.current);
    tableRef.current.gridApi.setFocusedCell(2, 'sales_forecast', pinned);
    tableRef.current.gridApi.startEditingCell({
      rowIndex: 2,
      colKey: 'sales_forecast',
      rowPinned: pinned,
      key: key,
      charPress: char,
    });
  }, []);

  const handleTableEdit = () => {
    console.log('Action!!');
    console.log('rep_alert_deep_dive', rep_alert_deep_dive);
    onBtStartEditing();
  };

  const reloadFilters = async () => {};

  const openLoader = () => {
    console.log('Open Loader');
    setLoader(true);
  };
  const closeLoader = () => {
    setLoader(false);
  };
  const updateState = (params) => {
    console.log('Updating state', params);
    setSelectedFilters(params);
  };
  const appliedFiltersAction = async () => {
    console.log('Seldcted filters', selectedFilters);
  };
  const onReset = () => {
    reloadFilters();
  };

  const clickFinalize = () => {
    setDisplayMessage(true);
  };

  const handleClose = () => {
    setDisplayMessage(false);
    setDisplayDraftMessage(false);
  };

  return (
    <LoadingOverlay loader={loader}>
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress className={classes.loader} />
        </div>
      )}

      {!isLoading && (
        <>
          {displayDraftMessage && (
            <Snackbar
              message='Saved as draft'
              variant='success'
              handleClose={handleClose}
            />
          )}
          {/* {displayMessage && (
            <Snackbar
              message="Navigate to 'Warehouse to Store' Page"
              variant='info'
              handleClose={handleClose}
            />
          )} */}
          <p style={{ fontWeight: 'bold', fontSize: 'large' }}>
            Replenishment Recommendations
          </p>
            <ReplenishmentStepper className='impact impact-replenishment-stepper' activeStep={0} />
          <CustomCard cardStyles={classes.headerCard}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FiltersList
                  className='impact impact-replenishment-alert-filter'
                  openLoader={openLoader}
                  closeLoader={closeLoader}
                  updateParentState={updateState}
                  appliedFilters={appliedFiltersAction}
                  onReset={onReset}
                  screenName={'Filter Products'}
                />
              </Grid>
            </Grid>
            <Table
              className='impact impact-replenishment-table-1'
              rowData={reviewtableData}
              columnDefs={colData}
              pageSize={5}
              rowsPerPageOptions={[5]}
              floatingFilter={false}
              height={'450px'}
              rowSelection={'multiple'}
              frameworkComponents={{
                skuLevelGraph: SkuLevelGraph,
              }}
              skuLevelGraphData={skuChartData}
            />
          </CustomCard>
          <div className={classes.saveButton} style={{ display: 'flex', justifyContent: 'right' }}>
            <CustomButton
              isPrimary={true}
              variant='contained'
              height={36}
              width={110}
              margin={10}
              label='Deep Dive'
              padding='100'
              onButtonClick={clickHandler}
            />
            <CustomButton
              isPrimary={true}
              variant='contained'
              height={36}
              width={100}
              margin={10}
              label='Finalize'
              padding='100'
              onButtonClick={() =>
                navigate('/inventory-smart/order-repository-whatif')
              }
            />
          </div>
          {showDeepDive && (
            <>
              <CustomCard cardStyles={classes.headerCard}>
                <Grid container>
                  <Grid item xs={10}>
                    <p style={{ fontWeight: 'bold', fontSize: 'medium' }}>
                      Deep Dive
                    </p>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      style={{
                        margin: 12,
                        bottom: 0,
                        textTransform: 'none',
                        float: 'right',
                      }}
                      size='small'
                      variant='contained'
                      onClick={() => handleTableEdit()}
                    >
                      Edit
                    </Button>
                  </Grid>
                </Grid>
                <Table
                  className='impact impact-replenishment-deepdive-table'
                  ref={tableRef}
                  rowData={deepDivetableData}
                  columnDefs={rep_alert_deep_dive}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  floatingFilter={false}
                  height={'450px'}
                  rowSelection={'multiple'}
                  defaultColDef={{
                    flex: 1,
                    editable: true,
                  }}
                  editType='fullRow'
                  columnFit={'sizeColumnsToFit'}
                />
              </CustomCard>
              <div className={classes.saveButton} style={{ display: 'flex', justifyContent: 'right' }}>
                <CustomButton
                  isPrimary={true}
                  variant='outlined'
                  height={36}
                  width={160}
                  margin={10}
                  label='Save as Draft'
                  padding='100'
                  onButtonClick={() => setDisplayDraftMessage(true)}
                />
                <CustomButton
                      isPrimary={true}
                      variant='contained'
                      height={36}
                      width={150}
                      margin={10}
                      label='Review forecast'
                      padding='100'
                      onButtonClick={() =>
                        navigate('/inventory-smart/ada_visuals')
                      }
                    />
                <CustomButton
                  isPrimary={true}
                  variant='contained'
                  height={36}
                  width={200}
                  margin={10}
                  label='Recommendations'
                  padding='100'
                  onButtonClick={() =>
                    navigate('/inventory-smart/review-replenishment')
                  }
                />
              </div>
            </>
          )}
          {/* {showReplenishmentChart && (
            <SkuGraphModel
              show={showReplenishmentChart}
              handleClose={handleChartClose}
              data={skuChartData}
            />
          )} */}
        </>
      )}
    </LoadingOverlay>
  );
};

export default ReplenishmentAlert;
