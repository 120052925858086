import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import * as css_constants from '../../../../constants/cssConstants';
export default function IAForecastTablesCol() {
  const dispatch = useDispatch();
  const openPopup = () => {
    dispatch({
      type: 'SHOW_CHILD_SKU_TABLE',
      payload: true,
    });
  };
  return {
    accordion: {
      label: 'Forecast adjustment and Edit hierarchy forecasts',
      id: 'Forecast Adjustments',
    },
    tabs: [
      {
        label: 'Forecast Multiplier',
        value: 'ia_forecast_multiplier',
        className:
          css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TAB_CLASS,
        column: [
          { field: 'drivers', headerName: '' , className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TAB_FIELD_CSS_CLASS },
          { field: 'fw_202405', headerName: 'FW-202405' },
          { field: 'fw_202406', headerName: 'FW-202406' },
          { field: 'fw_202407', headerName: 'FW-202407' },
          { field: 'fw_202408', headerName: 'FW-202408' },
          { field: 'fw_202409', headerName: 'FW-202409' },
          { field: 'fw_202410', headerName: 'FW-202410' },
          { field: 'fw_202411', headerName: 'FW-202411' },
          { field: 'fw_202412', headerName: 'FW-202412' }
          // { field: 'all_weeks', headerName: 'All Weeks' , className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TAB_FIELD_CSS_CLASS },
          // { field: 'week_1', headerName: 'Week 1', className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TAB_FIELD_CSS_CLASS},
          // { field: 'week_2', headerName: 'Week 2', className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TAB_FIELD_CSS_CLASS},
          // { field: 'week_3', headerName: 'Week 3', className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TAB_FIELD_CSS_CLASS},
          // { field: 'week_4', headerName: 'Week 4', className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TAB_FIELD_CSS_CLASS},
          // { field: 'week_5', headerName: 'Week 5', className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TAB_FIELD_CSS_CLASS},
          // { field: 'week_6', headerName: 'Week 6', className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TAB_FIELD_CSS_CLASS},
          // { field: 'week_7', headerName: 'Week 7', className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TAB_FIELD_CSS_CLASS},
          // { field: 'week_8', headerName: 'Week 8', className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TAB_FIELD_CSS_CLASS},
        ],
        data: [],
      },
      {
        label: 'View/Edit Hierachy Forecast',
        value: 'ia_forecast_view_hierarchy',
        column: [
          {
            field: 'store_sku',
            headerName: "Stores/SKU's",
            editable: false,
            cellRendererFramework: (cell) => (
              <Button
                style={{ textTransform: 'none' }}
                onClick={openPopup}
                autoFocus
                variant='text'
                className={
                  css_constants.IMPACT_CSS_CLASS +
                  css_constants.IMPACT_BUTTON_CSS_CLASS
                }
              >
                {cell.data.store_sku}
              </Button>
            ),
          },
          { field: 'fw_202405', headerName: 'FW-202405' },
          { field: 'fw_202406', headerName: 'FW-202406' },
          { field: 'fw_202407', headerName: 'FW-202407' },
          { field: 'fw_202408', headerName: 'FW-202408' },
          { field: 'fw_202409', headerName: 'FW-202409' },
          { field: 'fw_202410', headerName: 'FW-202410' },
          { field: 'fw_202411', headerName: 'FW-202411' },
          { field: 'fw_202412', headerName: 'FW-202412' }
          // { field: 'all_weeks', headerName: 'All Weeks', className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_FIELD_CSS_CLASS},
          // { field: 'week_1', headerName: 'Week 1' , className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_FIELD_CSS_CLASS},
          // { field: 'week_2', headerName: 'Week 2' , className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_FIELD_CSS_CLASS},
          // { field: 'week_3', headerName: 'Week 3' , className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_FIELD_CSS_CLASS},
          // { field: 'week_4', headerName: 'Week 4' , className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_FIELD_CSS_CLASS},
          // { field: 'week_5', headerName: 'Week 5' , className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_FIELD_CSS_CLASS},
          // { field: 'week_6', headerName: 'Week 6' , className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_FIELD_CSS_CLASS},
          // { field: 'week_7', headerName: 'Week 7' , className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_FIELD_CSS_CLASS},
          // { field: 'week_8', headerName: 'Week 8' , className : css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_FIELD_CSS_CLASS},
        ],
        data: [],
      },
    ],

    chart: {
      type: 'linechart',
      // title: 'Sales Unit Forecasts',
      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'middle',
      },
      yaxis: {
        title: 'Sales Units',
      },
      xaxis: {
        categories: ['W 01', 'W 02', 'W 03', 'W 04', 'W 05'],
      },
      showLegend: true,
      data: [
        {
          name: 'LY',
          data: [0, 30, 50, 25, 20],
        },
        {
          name: 'Forecasts',
          data: [10, 60, 75, 50, 65],
        },
      ],
    },
  };
}
