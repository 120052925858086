import callApi from "../../utils/callApi";

export const fetchHierarchyData = (filterProperty, reqObj) => {
    // console.log(filterProperty,reqObj)
    let url = encodeURIComponent(filterProperty)
    let newData = []
    if(reqObj['is_dependent'] === "TRUE"){
       newData = reqObj.data.filter(obj=> filterProperty.map(filerObj=> filerObj.id ).indexOf(obj.parent_id)!==-1 && obj.parent_type_id === reqObj.categoryid )
    }else{
       newData = reqObj.data.filter(obj=> obj.parent_id === filterProperty )
      
    }
    newData.filter(obj=> {
      obj['label'] = obj.name
      obj['value'] = obj.name
    })
    return newData;
    /*
    return callApi.post(`${process.env.REACT_APP_API_ENDPOINT}filter/${url}`)
      .then(res => {
        return res.data.data;
      })
      .catch(err => {
        return err;
      });
      */
  };

  export const getData = (modelName) => {
    return callApi
    .post(`get_data_model?model_name=${modelName}`)
      .then(res => {
        console.log(res,res.data)
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };