import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from './TabPanel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import {
  detailsTabData,
  order_details_columns,
  radioData,
  forecast_details_column,
} from './data';
import { Grid } from '@mui/material';
import { Table } from '../../../../components/Table';
const Details = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [radioValue, setRadioValue] = useState('30_days');
  const [forecastDetails, setForecastDetails] = useState([]);

  useEffect(() => {
    if (props.data.length > 0) {
      const store = props.data.filter(
        (obj) => obj.details_name === detailsTabData[tabIndex]['id']
      );
      setForecastDetails(store);
    }
  }, [props.data]);

  const handleTabChange = (_, newValue) => {
    if (props.data.length > 0) {
      const store = props.data.filter(
        (obj) => obj.details_name === detailsTabData[newValue]['id']
      );
      setForecastDetails(store);
    }
    setTabIndex(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setRadioValue(newValue);
  };
  const onSelectionChanged = (rows, gridApiObj) => {
    // console.log('selctedrows', gridApiObj.getSelectedRows());
    dispatch({
      type: 'SELECTED_ROWS',
      payload: gridApiObj.getSelectedRows(),
    });
  };

  const renderView = () => {
    switch (props.parentLabel) {
      case 'Forecast':
        return (
          <>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 3, sm: 3, md: 3 }}
            >
              <Grid xs={2}>
                <div className='tab-text-align'>
                  <Box sx={{ width: '100%' }}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                      }}
                    >
                      <div className='spacer'></div>
                      <Tabs
                        className='impact impact-forecast-details-tabs'
                        orientation='vertical'
                        variant='scrollable'
                        value={tabIndex}
                        onChange={handleTabChange}
                        aria-label='Vertical tabs example'
                        sx={{ width: '90%' }}
                        // sx={{ borderRight: 1, borderColor: 'divider' }}
                      >
                        {detailsTabData.map((obj, index) => (
                          <Tab
                            label={obj.label}
                            {...a11yProps(0)}
                            sx={{
                              alignItems: 'flex-start',
                              textTransform: 'none',
                            }}
                          />
                        ))}
                      </Tabs>
                    </Box>
                  </Box>
                </div>
              </Grid>
              <Grid xs={10}>
                <>
                  {detailsTabData.map((mainTab, index) => (
                    <>
                      <TabPanel value={tabIndex} index={index}>
                        {index == 0 && (
                          <FormControl>
                            <RadioGroup
                              className='impact impact-forecast-details-radio-group'
                              row
                              aria-labelledby='demo-row-radio-buttons-group-label'
                              name='row-radio-buttons-group'
                              onChange={handleChange}
                              value={radioValue}
                            >
                              {radioData?.map((item) => (
                                <FormControlLabel
                                  className='impact impact-forecast-details-form-label'
                                  value={item.value}
                                  control={<Radio />}
                                  label={item.label}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        )}
                        <Grid container rowSpacing={1} spacing={2}>
                          <Grid xs={6}>
                            <>
                              <p>
                                {' '}
                                <b>Top 5 Stores</b>{' '}
                              </p>
                              <Table
                                className='impact impact-forecast-details-stores-table'
                                rowData={forecastDetails}
                                columnDefs={
                                  forecast_details_column[mainTab.key].store
                                }
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                floatingFilter={false}
                                height={'300px'}
                                tableCssStyle='sizeColumnsToFit'
                                columnFit={'sizeColumnsToFit'}
                              />
                            </>
                          </Grid>
                          <Grid xs={6}>
                            <>
                              <p>
                                {' '}
                                <b>Top 5 Products</b>
                              </p>
                              <Table
                                className='impact impact-forecast-details-products-table'
                                rowData={forecastDetails}
                                columnDefs={
                                  forecast_details_column[mainTab.key].product
                                }
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                floatingFilter={false}
                                height={'300px'}
                                tableCssStyle='sizeColumnsToFit'
                                columnFit={'sizeColumnsToFit'}
                              />
                            </>
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </>
                  ))}
                </>
              </Grid>
            </Grid>
          </>
        );
        break;

      case 'Allocation':
      case 'Replenishment':
      case 'Order':
        return (
          <>
            <>
              <div style={{ textAlign: 'right' }}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    let path =
                      props.parentLabel === 'Order'
                        ? '/inventory-smart/create-new-order'
                        : '/inventory-smart/new-product-allocation';
                    navigate(path);
                  }}
                  style={{ textTransform: 'none' }}
                >
                  {props.parentLabel === 'Order'
                    ? 'Create Order'
                    : 'Create Allocation'}
                </Button>
              </div>
              <div className='spacer' />
            </>
            <Table
              className='impact impact-details-table'
              rowData={props.data}
              columnDefs={order_details_columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              floatingFilter={false}
              height={'300px'}
              rowSelection={'multiple'}
              onRowselectionChanged={onSelectionChanged}
            />
          </>
        );
        break;

      default:
        break;
    }
  };
  return <>{renderView()}</>;
};

Details.propTypes = {};

export default Details;
