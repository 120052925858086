import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import SelectRenderer from '../../../../components/Select/SelectRenderer';
import { CircularProgress, Grid } from '@mui/material';
import CustomButton from '../../../../components/CustomButton';
import Table from '../../../../components/Table/Table';
import {
  forecast_alert_columns,
  meioRecommendationColumns,
  sku_ids,
} from './data';
import Card from '@mui/material/Card';
import { fetchHierarchyData } from './action';
import FiltersList from '../../../../molecules/FiltersList';
import Snackbar from '../../../../components/SnackBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from './TabPanel';

const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
  },
});

const useStyles = makeStyles(styles);

const tabsData = [
  {
    id: 'Dashboard',
    label: 'Dashboard',
  },
  {
    id: 'Recommended',
    label: 'Recommended',
  },
];

const MeioRecommendation = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dateRange = '02-01-2022 - 03-30-2022';
  const [isLoading, setIsLoading] = React.useState(true);
  const [metrics, setMetrics] = React.useState([]);
  const [reviewTable, setReviewTable] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [selectedFilters, setSelectedFilters] = React.useState({});
  const [displayMessage, setDisplayMessage] = React.useState(false);
  const [tabIndex, setTabIndex] = useState(1);
  const location = useLocation()
  
  const [tableName] = useState(() => {
    if (location?.state?.hasOwnProperty("#nav_arg")) {
      return location.state['#nav_arg']||'tb_meio_recommendation'
    }
    return 'tb_meio_recommendation'
  })
  const getData = async (label, content) => {
    setIsLoading(true);
    const data = await fetchHierarchyData(label);
    if (data.length !== 0) {
      setIsLoading(false);
      setReviewTable(data);
    }
  };

  const reloadFilters = async () => {
    setSelectedFilters({});
  };
  const openLoader = () => {
    setLoader(true);
  };
  const closeLoader = () => {
    setLoader(false);
  };
  const updateState = (params) => {
    setSelectedFilters(params);
  };
  const appliedFiltersAction = async (params) => {
    console.log(selectedFilters);
  };

  const onReset = () => {
    reloadFilters();
  };
  
  useEffect(() => {
    getData(tableName, 'review_table');
  }, []);

  const handleTabChange = (_, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <>
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress className={classes.loader} />
        </div>
      )}
      {!isLoading && (
        <Card
          style={{
            display: 'block',
            height: '50vw',
          }}
        >
          <Grid>
            <FiltersList
              openLoader={openLoader}
              closeLoader={closeLoader}
              updateParentState={updateState}
              appliedFilters={appliedFiltersAction}
              onReset={onReset}
              screenName={'MEIO : Recommendation'}
            />
          </Grid>
          <div class='tab_heading_main_container'>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label='basic tabs example'
              >
                {tabsData.map((obj, index) => (
                  <Tab
                    label={obj.label}
                    style={{
                      textTransform: 'none',
                    }}
                  />
                ))}
              </Tabs>
            </Box>
          </div>
          <TabPanel value={tabIndex} index={1}>
            <div style={{ padding: '0.75rem' }}>
              <Table
                className='impact impact-forecast-alert-review-table'
                rowData={reviewTable||[]}
                columnDefs={meioRecommendationColumns}
                floatingFilter={false}
                height={'450px'}
                rowSelection={'multiple'}
              />
              <div
                className={classes.saveButton}
                style={{ display: 'flex', justifyContent: 'right' }}
              >
                <CustomButton
                  isPrimary={true}
                  variant='outlined'
                  height={36}
                  width={150}
                  label='Cancel'
                  padding='100'
                  margin={10}
                />
                <CustomButton
                  isPrimary={true}
                  variant='contained'
                  height={36}
                  width={200}
                  label='Send for Approval'
                  padding='100'
                  margin={10}
                  onButtonClick={() => navigate('/inventory-smart/ada_visuals')}
                />
              </div>
            </div>
          </TabPanel>
        </Card>
      )}
    </>
  );
};

export default MeioRecommendation;
