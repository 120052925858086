import React, { Component, lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Dashboard from '../modules/InventorySmart/pages/dashboard';
import Decision from '../modules/InventorySmart/pages/Decision';
import Report from '../modules/InventorySmart/pages/Report';
import ADAVisuals from '../modules/InventorySmart/pages/ADAVisuals';
import OrderRepository from '../modules/InventorySmart/pages/order-repository';
import NewOrderCreation from '../modules/InventorySmart/pages/NewOrderCreation';
import OrderRepositoryWhatif from '../modules/InventorySmart/pages/order-repository-whatif';
import CreateNewAllocationComponent from '../modules/InventorySmart/pages/Create-Allocation';
import ReviewAllocation from '../modules/InventorySmart/pages/ReviewAllocation';
import CreateNewAllocation from '../modules/InventorySmart/pages/CreateNewAllocation';
import ReviewRecommendation from '../modules/InventorySmart/pages/ReviewRecommendation';
import ExecutiveSummary from '../modules/InventorySmart/pages/ExecutiveSummary/ExecutiveSummary';
import WhatIf from '../modules/InventorySmart/pages/WhatIf/WhatIf';
import ForecastAlertReview from '../modules/InventorySmart/pages/Decision/ForecastAlertReview';
import NewProductAllocation from '../modules/InventorySmart/pages/Decision/NewProductAllocation';
import WholesaleNewProductAllocation from '../modules/InventorySmart/pages/Decision/WholesaleNewProductAllocation';
import AllocationDetailsReview from '../modules/InventorySmart/pages/Decision/AllocationDetailsReview';
import ReplenishmentAlert from '../modules/InventorySmart/pages/Decision/ReplenishmentAlert';
import ReviewReplenishment from '../modules/InventorySmart/pages/ReviewReplenishment';
import { ProductProfile } from '../modules/InventorySmart/pages/ProductProfile';
import OrderManagement from '../modules/InventorySmart/pages/Order-Management';
import { DeepDive } from '../modules/InventorySmart/pages/Order-Management/DeepDive';
import AllocationAlertReviewRecommendation from '../modules/InventorySmart/pages/AllocationAlertReviewRecommendation';
import MeioRecommendation from '../modules/InventorySmart/pages/Decision/MeioRecommendation';
import DeepDiveVs from '../modules/InventorySmart/pages/Decision/DeepDiveVs';
import WholesaleRecommendations from '../modules/InventorySmart/pages/WholesaleRecommendations';
const InventorySmartDashboard = lazy(() =>
  import('../modules/InventorySmart/pages/Home')
);
export const AppRoutingModule = () => {
  return (
    <div style={{ height: '100vh' }}>
      <Router>
        <Routes>
          <Route
            exact
            path='/'
            element={<Navigate to='/inventory-smart' replace />}
          />
          <Route
            exact
            path='/inventory-smart'
            element={<InventorySmartDashboard />}
          >
            <Route
              exact
              path=''
              element={<Navigate to='decision-dashboard' replace />}
            />
            <Route exact path='decision-dashboard' element={<Decision />} />
            <Route
              exact
              path='executive-summary'
              element={<ExecutiveSummary />}
            />
            <Route exact path='what-if' element={<WhatIf />} />
            <Route exact path='report' element={<Report />} />
            <Route exact path='ada_visuals' element={<ADAVisuals />} />
            <Route exact path='product-profile' element={<ProductProfile />} />
            <Route
              exact
              path='order-repository'
              element={<OrderRepository />}
            />
            <Route
              exact
              path='create-new-order'
              element={<NewOrderCreation />}
            />
            <Route
              exact
              path='order-repository-whatif'
              element={<OrderRepositoryWhatif />}
            />
            <Route
              exact
              path='create-new-allocation'
              element={<CreateNewAllocationComponent />}
            />
            <Route
              exact
              path='forecast-alert-review'
              element={<ForecastAlertReview />}
            />
            <Route
              exact
              path='meio-recommendation'
              element={<MeioRecommendation />}
            />
            <Route
              exact
              path='deep-dive-vs'
              element={<DeepDiveVs />}
            />
            <Route
              exact
              path='allocation-details-review'
              element={<AllocationDetailsReview />}
            />
            <Route
              exact
              path='order-management'
              element={<OrderManagement />}
            />
            <Route exact path='deep-dive' element={<DeepDive />} />
            <Route
              exact
              path='create-new-allocation-review'
              element={<CreateNewAllocation />}
            />
            <Route
              exact
              path='wholesale-recommendations'
              element={<WholesaleRecommendations
                 />}
            />
            <Route
              exact
              path='new-product-allocation'
              element={<NewProductAllocation />}
            />
            <Route
              exact
              path='wholesale-new-product-allocation'
              element={<WholesaleNewProductAllocation />}
            />
            <Route
              exact
              path='replenishment-alert'
              element={<ReplenishmentAlert />}
            />
            <Route
              exact
              path='review-replenishment'
              element={<ReviewReplenishment />}
            />
            <Route
              exact
              path='review-allocation'
              element={<ReviewAllocation />}
            />
            <Route
              exact
              path='review-recommendation'
              element={<ReviewRecommendation />}
            />
            <Route
              exact
              path='allocation-alert-review-recommendation'
              element={<AllocationAlertReviewRecommendation />}
            />
            <Route
              exact
              path='*'
              element={<Navigate to='decision-dashboard' replace />}
            />
          </Route>
          <Route
            exact
            path='*'
            element={<Navigate to='/inventory-smart' replace />}
          />
        </Routes>
      </Router>
    </div>
  );
};
