import callApi from "../../../utils/callApi";

export const ORDER_MANAGEMENT_DEEP_DIVE = "ORDER_MANAGEMENT_DEEP_DIVE"

export const getFiltersList = (modelName) => {
  return callApi
      .post(`get_data_model?model_name=${modelName}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err)
        return err;
      });
  };

  export const getFilteredData = (reqParams) => {
    return callApi
      .post(`filter/list`, reqParams)
      .then(res => {
        // return res.data.data;
      })
      .catch(err => {
        return err;
      });
  };

  export const getForecastVisualisationData = (modelName,reqParams) => {
    return callApi
    .post(`get_data_model?model_name=${modelName}`,reqParams)
      .then(res => {
        console.log(res,res.data)
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

export const getModelData = (modelName,reqParams) => {
    return callApi
    .post(`get_data_model?model_name=${modelName}`,reqParams)
      .then(res => {
        console.log(res,res.data)
        return res.data;
      })
      .catch(err => {
        return err;
      });
};

export const getMultipleModelsData = (reqParams) => {
  console.log("API Call -" , reqParams)
  return callApi
  .post(`get_mutliple_data_models`,reqParams)
    .then(res => {
      console.log(res,res.data)
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const orderMgtFinalizeAction = (payload) => dispatch => {
  dispatch({ type: ORDER_MANAGEMENT_DEEP_DIVE , payload: payload });
};