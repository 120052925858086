import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import InventoryIcon from '../../../../assets/svg/inventory_white.svg';
import { Grid, Typography } from '@mui/material';
const styles = (theme) => ({
  container: {
    padding: '16px 24px',
  },
  headerContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
  header: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
  },
  headerIconContainer: (props) => ({
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    background: props.background,
    height: 40,
    width: 40,
    borderRadius: '50%',
    marginRight: 8,
  }),
  headerIcon: {
    fontSize: 16,
  },
  headerText: {
    margin: 0,
    fontSize: '1.15rem',
    fontWeight: 500,
    color: theme.palette.textDark,
  },
  trendUp: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    color: '#2abc79',
  },
  trendDown: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    color: '#ef605f',
  },
  trendIcon: {
    color: 'inherit',
    fontSize: 40,
    marginRight: -6,
  },
  trendText: {
    margin: 0,
    fontWeight: 500,
    color: 'inherit',
  },
  subHeaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    paddingBottom: '8px',
    borderBottom: `1px solid #deecf4`,
  },
  subHeaderText: {
    fontSize: '0.85rem',
    fontWeight: 400,
    letterSpacing: '0.2px',
    margin: '8px 0 0 0',
    color: theme.palette.textDark,
  },
  subHeaderValue: {
    fontSize: '1.35rem',
    fontWeight: 500,
    margin: '0px 0',
    color: '#3a5a6e',
  },
  changeContainerUp: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    color: '#2abc79',
    background: '#f5f6f7',
    padding: '0px 8px 0px 0',
    borderRadius: '4px',
  },
  changeContainerDown: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    color: '#ef605f',
    background: '#f5f6f7',
    padding: '0px 8px 0px 0',
    borderRadius: '4px',
  },
  changeIcon: {
    color: 'inherit',
    fontSize: '32px',
    marginRight: -4,
  },
  changeText: {
    color: theme.palette.textDark,
    margin: 0,
    fontSize: '0.9rem',
    letterSpacing: '0.2px',
  },
  dataContainer: {
    marginTop: 4,
  },
  data: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
  dataHeader: {
    fontSize: '0.8rem',
    color: theme.palette.stroke,
    margin: '4px 0',
  },
  dataValue: {
    margin: '4px 0',
    color: '#3a5a6e',
  },
  stockHeader: {
    marginBottom: '12px',
    marginTop: '0px',
  },
  stockValue: {
    fontSize: '1.2rem',
    lineHeight: '2rem',
    fontWeight: 600,
    color: '#0055AF',
    marginLeft: '120px',
    marginBottom: '0px',
    marginTop: '0px',
  },
  value: {
    // margin: 0,
    margin: '4px 0',
    fontSize: '1.1rem',
    lineHeight: '1.5rem',
    fontWeight: 500,
    // background: '#eaf3fc',
    color: '#0055AF',
    padding: '0px 2px',
    borderRadius: 4,
  },
  dataLabel: {
    paddingLeft: '20px',
  },
  gridContainer: {
    verticalAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 2,
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textFontSize: {
    fontSize: 14,
  },
});

const useStyles = makeStyles(styles);

const backgrounds = {
  revenue: 'rgb(208 193 14 / 14%)',
  sales_units: 'rgb(252 179 98 / 14%)',
  margin: 'rgb(235, 249, 241)',
  sell_through: 'rgb(68 103 123 / 14%)',
};

const DataCard = (props) => {
  const { data } = props;
  const classes = useStyles({
    background: backgrounds[data.type],
    changeColor: data.changeColor,
  });

  const renderSubContainer = () => {
    switch (props.type) {
      case 'Allocation':
        return (
          <>
            {data.sub_label1 ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    verticalAlign: 'center',
                  }}
                >
                  <Typography>{data.sub_label1}</Typography>
                  <Typography color='#0055AF' fontWeight='600'>
                    {data.sub_label_value1}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    verticalAlign: 'center',
                  }}
                >
                  <Typography>{data.sub_label2}</Typography>

                  <Typography color='#0055AF' fontWeight='600'>
                    {data.sub_label_value2}
                  </Typography>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    verticalAlign: 'center',
                  }}
                >
                  <Typography>Cost - {data.cost}</Typography>
                  <Typography color={'#0055AF'} fontWeight='600'>
                    {data.value}
                  </Typography>
                </div>
              </>
            )}
          </>
        );
        break;
      case 'Replenishment':
        return (
          <>
            <Grid container rowSpacing={1} spacing={2}>
              {data.sub_label1 ? (
                <>
                  <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                      <Typography>{data.sub_label1}</Typography>

                      <Typography>{data.sub_label2}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.value}>
                        {data.sub_label_value1}
                      </Typography>

                      <Typography className={classes.value}>
                        {data.sub_label_value2}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  {data.label === 'In Stock %' ? (
                    <Grid
                      container
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      className={classes.gridContainer}
                    >
                      <div class='card_header'>
                        <Typography className={classes.headerText}>
                          &nbsp;
                        </Typography>
                      </div>
                      <div
                        className={classes.textContainer}
                        style={{ width: '100%' }}
                      >
                        <Typography className={classes.textFontSize}>
                          {data.label}
                        </Typography>
                        <Typography
                          color={'#0055AF'}
                          className={classes.textFontSize}
                          fontWeight='600'
                        >
                          {data.value}
                        </Typography>
                      </div>
                      <div
                        className={classes.textContainer}
                        style={{ width: '100%' }}
                      >
                        <Typography className={classes.textFontSize}>
                          Sell through%
                        </Typography>
                        <Typography
                          color={'#0055AF'}
                          className={classes.textFontSize}
                          fontWeight='600'
                        >
                          {data.sell_through}
                        </Typography>
                      </div>
                      <div
                        className={classes.textContainer}
                        style={{ width: '100%' }}
                      >
                        <Typography className={classes.textFontSize}>
                          Size Integrity%
                        </Typography>
                        <Typography
                          textFontSize
                          color={'#0055AF'}
                          fontWeight='600'
                        >
                          {data.size_integrity}
                        </Typography>
                      </div>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      className={classes.gridContainer}
                    >
                      <div
                        className={classes.textContainer}
                        style={{ width: '100%' }}
                      >
                        <Typography
                          color='black'
                          className={classes.textFontSize}
                        >
                          Actual
                        </Typography>
                        <Typography
                          color={'#0055AF'}
                          className={classes.textFontSize}
                          fontWeight='600'
                        >
                          {data.value}
                        </Typography>
                      </div>
                      <div
                        className={classes.textContainer}
                        style={{ width: '100%' }}
                      >
                        <Typography
                          color='gray'
                          className={classes.textFontSize}
                        >
                          LY
                        </Typography>
                        <Typography
                          color={'#0055AF'}
                          fontWeight='600'
                          sx={{ opacity: 0.7 }}
                          className={classes.textFontSize}
                        >
                          {data.ly}
                        </Typography>
                      </div>
                      <div
                        className={classes.textContainer}
                        style={{ width: '100%' }}
                      >
                        <Typography
                          color='gray'
                          className={classes.textFontSize}
                        >
                          Planned{' '}
                        </Typography>
                        <Typography
                          color={'#0055AF'}
                          fontWeight='600'
                          sx={{ opacity: 0.7 }}
                          className={classes.textFontSize}
                        >
                          {data.planned}
                        </Typography>
                      </div>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </>
        );
        break;
      case 'Order':
        return (
          <>
            <Grid container rowSpacing={1} spacing={2}>
              <Grid xs={12}>
                <div className={classes.subHeader}>
                  <Typography
                    color={'#0055AF'}
                    fontWeight='600'
                    style={{ marginBottom: 5 }}
                  >
                    {data.value}
                  </Typography>
                  <div
                    className={classes.textContainer}
                    style={{ paddingRight: 20 }}
                  >
                    <Typography>{data.sub_label1}</Typography>
                    <Typography color={'#0055AF'} fontWeight='600'>
                      {data.sub_label_value1}
                    </Typography>
                  </div>
                  <div
                    className={classes.textContainer}
                    style={{ paddingRight: 20 }}
                  >
                    <Typography>{data.sub_label2}</Typography>
                    <Typography color={'#0055AF'} fontWeight='600'>
                      {data.sub_label_value2}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        );
        break;

      default:
        return (
          <Grid
            container
            rowSpacing={1}
            spacing={2}
            className={classes.gridContainer}
          >
            <Grid xs={6}>
              <Typography>{'Actual'}</Typography>
              <Typography color={'#0055AF'} fontWeight='600'>
                {data.actual}
              </Typography>
            </Grid>
            <Grid xs={6}>
              <div style={{ display: 'felx', verticalAlign: 'center' }}>
                <Typography>IA Forecast </Typography>
                <Typography color={'#0055AF'} fontWeight='600'>
                  {data.ia_forecast} (
                  {(
                    (parseInt(data.actual.replace(/,/g, '')) -
                      parseInt(data.ia_forecast.replace(/,/g, ''))) /
                    parseInt(data.actual.replace(/,/g, ''))
                  ).toFixed(2) * 100}
                  % )
                </Typography>
                <Typography style={{ marginTop: 4 }}>
                  IA Adj Forecast
                </Typography>{' '}
                <Typography color={'#0055AF'} fontWeight='600'>
                  {data.ia_adjested_forcast} (
                  {(
                    (parseInt(data.actual.replace(/,/g, '')) -
                      parseInt(data.ia_adjested_forcast.replace(/,/g, ''))) /
                    parseInt(data.actual.replace(/,/g, ''))
                  ).toFixed(2) * 100}
                  % )
                </Typography>
              </div>
            </Grid>
          </Grid>
        );
        break;
    }
  };
  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        {!(props.type === 'Replenishment' && data.label === 'In Stock %') && (
          <div class='card_header'>
            <Typography className={classes.headerText}>{data.label}</Typography>
          </div>
        )}
      </div>
      <div className='spacer'></div>
      {renderSubContainer()}
    </div>
  );
};

export default DataCard;
