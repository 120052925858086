import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import CustomButton from '../../../../components/CustomButton';
import GenericTable from '../../../../components/GenericTable/GenericTable';
import { deepDiveSkuSummary, deepDiveVs} from './data';
import Card from '@mui/material/Card';
import { Grid } from '@mui/material';
import FiltersList from '../../../../molecules/FiltersList';
import Button from '@mui/material/Button';


const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
  },
});
const useStyles = makeStyles(styles);

const DeepDive = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [loader, setLoader] = React.useState(false);

  const openLoader = () => {
    setLoader(true);
  };
  const closeLoader = () => {
    setLoader(false);
  };
  const reloadFilters = async () => {
    setSelectedFilters({});
  };
  const updateState = (params) => {
    setSelectedFilters(params);
  };
  const appliedFiltersAction = async (params) => {
    console.log(selectedFilters);
  };

  const onReset = () => {
    reloadFilters();
  };
  return (
    <>
      <Card
          style={{
            display: 'block',
          }}
      >
          <Grid>
          <FiltersList
            openLoader={openLoader}
            closeLoader={closeLoader}
              updateParentState={updateState}
              appliedFilters={appliedFiltersAction}
              onReset={onReset}
              screenName={'Deep Dive'}
            />
          </Grid>
          <GenericTable
            title={'SKU Summary'}
            apiKey={'tb_deep_dive_sku_summary'}
            columnDefs={deepDiveSkuSummary}
        />
        <GenericTable
          title={'Deep Dive'}
          apiKey={'tb_deep_dive_vs'}
          columnDefs={deepDiveVs}
          style={{ marginTop: '50px' }}
          height={'400px'}
        />
        <div style={{
          display: "flex",
          justifyContent: "right",
          margin:"20px"
        }}>
        <Button
            style={{ textTransform: 'none' }}
            onClick={()=>(navigate('/inventory-smart/decision-dashboard'))}
            variant='contained'
          >
            Back
          </Button>
        </div>
        </Card>
    </>
  );
};

export default DeepDive;
