export const scenarioOneData = [
  {
    id: 'Planned',
    name: 'Planned',
    dashStyle: 'Solid',
    color: '#6F7F8F',
    type: 'line',
    data: [],
    visible: true,
  },
  {
    id: 'Scenario 01',
    name: 'Scenario 01',
    dashStyle: 'Dash',
    color: '#6F7F8F',
    type: 'line',
    data: [],
    visible: true,
  },
];
