import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import CustomButton from '../../../../components/CustomButton';
import SelectRenderer from '../../../../components/Select/SelectRenderer';
import SelectedLabels from './SelectedLabels';
import { SimulationTable } from './SimulationTable';
import { fetchHierarchyData } from './action';
import Filters from '../../../../components/FilterChips';
import { filterData } from './WhatIfData';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import FiltersList from '../../../../molecules/FiltersList';
import Card from '@mui/material/Card';
import CustomCard from '../../../../components/CustomCard';

const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  borderCard: {
    ...theme.content.borderCard,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
  },
  headerCard: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
  },
});
const useStyles = makeStyles(styles);

const WhatIf = () => {
  const [timePeriod, setTimePeriod] = useState([]);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState([]);
  const classes = useStyles();
  const [attributeSelected, setAttributeSelected] = React.useState(false);
  const [module, setModule] = React.useState({});
  const [modules, setModules] = React.useState([]);
  const [attribute, setAttribute] = React.useState({});
  const [simulate, setSimulate] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const [attributes, setAttributes] = React.useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [flow, setFlow] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const updateTimePeriod = (e) => {
    setSelectedTimePeriod(e.selectedItems[0].label);
  };

  const getData = async (label, content) => {
    let data = [];
    data = await fetchHierarchyData(label);
    if (data.length !== 0) {
      setIsLoading(false)
      if (content === 'modules') setModules(data);
      else if (content === 'attributes') setAttributes(data);
      else setTimePeriod(data);
    }
  };

  useEffect(() => {
    const qs = window.location.search
    const flow = new URLSearchParams(qs).get('flow')
    if(flow === 'order') {
      setModule({
        label: 'Order Management',
        id: '3'
      });
      setFlow(flow);
    }
    getData('tb_whatif_modules', 'modules');
    getData('tb_whatif_attributes', 'attributes');
    getData('tb_time_period', 'timePeriod');
  }, []);

  const filtered_attributes = attributes.filter(
    (attribute) => attribute.module_id === module.id
  );

  const proceedClickHandler = () => {
    if (Object.keys(attribute).length && Object.keys(module).length)
      setAttributeSelected(true);
  };

  const simulateClickHandler = (max, min, step, time) => {
    //if (max && min && step && time.length > 0) 
    setSimulate(true);
  };

  const selectedModule = (e) => {
    setModule(e.selectedItems[0]);
    setAttribute({});
    setAttributeSelected(false);
    setSimulate(false);
  };

  const selectedAttribute = (e) => {
    setAttribute(e.selectedItems);
  };
  const reloadFilters = async () => {
    setSelectedFilters({});
  };

  const openLoader = () => {
    console.log('Open Loader');
    setLoader(true);
  };
  const closeLoader = () => {
    setLoader(false);
  };
  const updateState = (params) => {
    console.log('Updating state', params);
    setSelectedFilters(params);
  };
  const appliedFiltersAction = async (params) => {
    getData('tb_whatif_modules', 'modules');
    getData('tb_whatif_attributes', 'attributes');
    getData('tb_time_period', 'timePeriod');
  };



  const onReset = () => {
    reloadFilters();
  };

  return (
    <>
      <LoadingOverlay loader={loader}>
        <FiltersList
          openLoader={openLoader}
          closeLoader={closeLoader}
          updateParentState={updateState}
          appliedFilters={appliedFiltersAction}
          onReset={onReset}
          screenName={'What If'}
        />
        {!loader && (
          <Card
            style={{
              display: 'block',
              height: '80vw'
            }}
          >
            <div style={{ padding: '1rem' }}>
            <CustomCard cardStyles={classes.borderCard}>
                <div style={{ display: 'flex',marginTop: '2rem', marginLeft: '1rem'}}>
                
                  <Grid item md={3}>
                    <SelectRenderer
                      options={modules}
                      isMandatory={true}
                      isMulti={false}
                      width='15rem'
                      filterLabel={'Run WhatIf For'}
                      updateSelected={selectedModule}
                      selectedItems={flow === 'order' ? [module] : null}
                    />
                  </Grid>
                  <div style={{ marginLeft: '4rem' }}>
                    <Grid item md={3}>
                      <SelectRenderer
                        options={filtered_attributes}
                        isMandatory={true}
                        isMulti={true}
                        width='15rem'
                        filterLabel={'Select Attribute'}
                        updateSelected={selectedAttribute}
                      />
                    </Grid>
                  </div>
                  <div style={{ marginLeft: '4rem' }}>
                  <Grid item md={3}>
                        <SelectRenderer
                          filterLabel={'Time Period'}
                          isMandatory={true}
                          height='20rem'
                          width='8rem'
                          overflow={true}
                          isMulti={false}
                          options={timePeriod}
                          updateSelected={updateTimePeriod}
                        />
                        </Grid>
                      </div>
                 
                </div>
                <div style={{ padding: '0.5rem',  display: 'flex', justifyContent: 'right'  }}>
                    <CustomButton
                      isPrimary={true}
                      variant='contained'
                      height={32}
                      width={104}
                      label='Proceed'
                      onButtonClick={() => proceedClickHandler()}
                    />
                  </div>
              </CustomCard>
              <div style={{ marginTop: '1.5rem' }}>
                {attributeSelected &&
                  Object.keys(attribute).length &&
                  Object.keys(module).length && (
                    <>
                    <CustomCard cardStyles={classes.borderCard}>
                    {attribute.map (attr => {
                    
                     return <Grid item xs={12} md={4}>
                        <SelectedLabels
                          label={attr.label}
                          onClick={simulateClickHandler}
                          isLoading = {isLoading}
                        />
                      </Grid>
                    
                    })}
                     <div style={{ padding: '0.5rem',  display: 'flex', justifyContent: 'right'  }}>
                      <CustomButton
                        isPrimary={true}
                        variant='contained'
                        height={32}
                        width={104}
                        label='Simulate'
                        onButtonClick={simulateClickHandler}
                      />
                    </div>
                    </CustomCard>
                    </>
                  )}
               
              </div>
              <CustomCard cardStyles={classes.borderCard}>
                {simulate && <SimulationTable module={module}  isLoading = {isLoading} />}
              </CustomCard>
            </div>
          </Card>
        )}
      </LoadingOverlay>
    </>
  );
};

export default WhatIf;
