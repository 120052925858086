import React from "react";

export default function CreateNewAllocation() {


  return (
    <div>
      <div className= "page-content">
      <iframe width="1440" height="840" src="https://xd.adobe.com/embed/8aef52b5-53a3-45a7-81fa-35c10036dab7-dfbc/" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
  );
}
