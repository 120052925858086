import callApi from '../../../../utils/callApi';

export const fetchHierarchyData = (filterProperty, reqObj) => {
  let url = encodeURIComponent(filterProperty);
  return callApi
    .post(`get_data_model?model_name=${url}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getMultipleModelsData = (reqParams) => {
  return callApi
    .post(`get_mutliple_data_models`, reqParams)
    .then((res) => {
      console.log(res, res.data);
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
