export const tabListData = {
  product_view: {
    label: 'Product View',
    value: 'product_view',
    tables: [
      {
        title: 'Replenishment order summary',
        key: 'tb_product_view_replenishment_order_summary_flow',
        column: [
          {
            field: '#_style_color',
            headerName: '# Style Color',
            width: 180,
            pinned: 'left',
          },
          {
            field: '#_store',
            headerName: '# Store',
            width: 120,
            pinned: 'left',
          },
          {
            field: 'Stores/style_color',
            headerName: 'Stores/ Style Color',
            width: 250,
            valueFormatter: function (params) {
              return parseFloat(params.value).toFixed(2);
            },
          },
          {
            field: 'allocated_quantity',
            headerName: 'Allocated Quantity',
            width: 180,
          },
          {
            field: 'net_dc_available',
            headerName: 'Net DC Available',
            width: 200,
          },
          {
            field: 'wos',
            headerName: 'WOS',
            width: 200,
          },
          {
            headerName: 'Replenishment recommended Qty(by size)',
            children: [
              { field: 'qty_6B', headerName: '6B', width: 120 },
              { field: 'qty_7B', headerName: '7B', width: 120 },
              { field: 'qty_8B', headerName: '8B', width: 120 },
              { field: 'qty_9B', headerName: '9B', width: 120 },
              { field: 'qty_10B', headerName: '10B', width: 120 },
              { field: 'qty_11B', headerName: '11B', width: 120 },
            ],
          },
          {
            headerName: 'Replenishment WOS by Size',
            children: [
              { field: '%_6B', headerName: '6B', width: 120 },
              { field: '%_7B', headerName: '7B', width: 120 },
              { field: '%_8B', headerName: '8B', width: 120 },
              { field: '%_9B', headerName: '9B', width: 120 },
              { field: '%_10B', headerName: '10B', width: 120 },
              { field: '%_11B', headerName: '11B', width: 120 },
            ],
          },
        ],
        data: [],
      },
      {
        title: 'Product Details',
        key: 'tb_replenishment_product_view_product_details',
        column: [
          {
            field: 'style_color_id',
            headerName: 'Style Color ID',
            pinned: 'left',
            headerCheckboxSelection: true,
            checkboxSelection: true,
          },
          { field: 'style_description', headerName: 'Style Description', pinned: 'left', width: 200 },
          { field: 'color_id', headerName: 'Color ID', pinned: 'left' },
          { field: 'demand_type', headerName: 'Demand Type' },
          { field: 'store_group', headerName: 'Store Group' },
          { field: 'store', headerName: '# Store' },
          { field: 'channel', headerName: 'Channel' },
          { field: 'department', headerName: 'Department' },
          { field: 'class', headerName: 'Class' },
          { field: 'style_group', headerName: 'Style Group' },
          { field: 'silhouette', headerName: 'Silhouette' },
          { field: 'sub_collect', headerName: 'Sub Collect' },
          { field: 'allocated_stores', headerName: 'Allocated Stores' },
          { field: 'minimum_constraint', headerName: 'Minimum Constraint' },
          {
            headerName: 'Allocated Total Qty.',
            children: [
              { field: 'allocated_qty_DC01', headerName: 'DC01', width: 120 },
              { field: 'allocated_qty_DC02', headerName: 'DC02', width: 120 },
              { field: 'allocated_qty_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc_DC01', headerName: 'DC01', width: 120 },
              { field: 'net_dc_DC02', headerName: 'DC02', width: 120 },
              { field: 'net_dc_DC03', headerName: 'DC03', width: 120 },
            ],
          },
          { field: 'original_aps', headerName: 'Original APS' },
          { field: 'forecasted_aps', headerName: 'Forecasted APS' },
          { field: 'target_wos', headerName: 'Target WOS' },
          { field: 'forecasted_wos', headerName: 'Forecasted WOS' },
          {
            headerName: 'Size',
            children: [
              { field: 'size_6b', headerName: '6B', width: 120 },
              { field: 'size_7b', headerName: '7B', width: 120 },
              { field: 'size_8b', headerName: '8B', width: 120 },
              { field: 'size_9b', headerName: '9B', width: 120 },
              { field: 'size_10b', headerName: '10B', width: 120 },
              { field: 'size_11b', headerName: '11B', width: 120 },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_replenishment_product_view_stores',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 180,
              },
              { field: 'total_units', headerName: 'Total Units', width: 150 },
              {
                field: 'min_qty',
                headerName: 'Min Qty.',
                width: 150,
              },
              {
                field: 'replenishment_qty',
                headerName: 'Replenishment qty.',
                width: 200,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 200,
              },
              {
                field: 'size_integrity_in_%',
                headerName: 'Size Integrity in %',
                width: 200,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_replenishment_product_view_store_group',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  { field: 'climate', headerName: 'Climate', pinned: 'left' },
                  { field: 'country', headerName: 'Country', pinned: 'left' },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 180,
              },
              { field: 'total_units', headerName: 'Total Units', width: 150 },
              {
                field: 'min_qty',
                headerName: 'Min Qty.',
                width: 150,
              },
              {
                field: 'replenishment_qty',
                headerName: 'Replenishment qty.',
                width: 200,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 200,
              },
              {
                field: 'size_integrity_in_%',
                headerName: 'Size Integrity in %',
                width: 200,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_replenishment_product_view_store_grade',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Grade Information',
                pinned: 'left',
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# style colors' },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 180,
              },
              { field: 'total_units', headerName: 'Total Units', width: 150 },
              {
                field: 'min_qty',
                headerName: 'Min Qty.',
                width: 150,
              },
              {
                field: 'replenishment_qty',
                headerName: 'Replenishment qty.',
                width: 200,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 200,
              },
              {
                field: 'size_integrity_in_%',
                headerName: 'Size Integrity in %',
                width: 200,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  store_view: {
    label: 'Store View',
    value: 'store_view',
    tables: [
      {
        title: 'Replenishment order summary',
        isRadio: false,
        height: '350px',
        key: 'tb_store_view_replenishment_order_summary',
        column: [
          {
            field: '#_style_color',
            headerName: '# Style Color',
            width: 180,
            pinned: 'left',
          },
          {
            field: '#_store',
            headerName: '# Store',
            width: 120,
            pinned: 'left',
          },
          {
            field: 'style_color_depth/store',
            headerName: 'Style Color Depth/Store',
            width: 220,
            valueFormatter: function (params) {
              return parseFloat(params.value).toFixed(2);
            },
          },
          {
            headerName: 'Replenishment recommended qty',
            children: [
              { field: 'recom_qty_DC01', headerName: 'DC01', width: 120 },
              { field: 'recom_qty_DC02', headerName: 'DC02', width: 120 },
              { field: 'recom_qty_DC03', headerName: 'DC03', width: 120 },
              { field: 'recom_total', headerName: 'Total', width: 120 },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc_DC01', headerName: 'DC01', width: 120 },
              { field: 'net_dc_DC02', headerName: 'DC02', width: 120 },
              { field: 'net_dc_DC03', headerName: 'DC03', width: 120 },
              { field: 'net_total', headerName: 'Total', width: 120 },
            ],
          },
          {
            headerName: 'Store Grade - Replenishment recommended Qty',
            children: [
              { field: 'recom_qty_AAA', headerName: 'AAA', width: 120 },
              { field: 'recom_qty_AA', headerName: 'AA', width: 120 },
              { field: 'recom_qty_A', headerName: 'A', width: 120 },
              { field: 'recom_qty_B', headerName: 'B', width: 120 },
              { field: 'recom_qty_C', headerName: 'C', width: 120 },
              { field: 'recom_qty_D', headerName: 'D', width: 120 },
            ],
          },
          {
            headerName: 'Store Grade - Replenishment recommended Index',
            children: [
              { field: 'recom_index_AAA', headerName: 'AAA', width: 120 },
              { field: 'recom_index_AA', headerName: 'AA', width: 120 },
              { field: 'recom_index_A', headerName: 'A', width: 120 },
              { field: 'recom_index_B', headerName: 'B', width: 120 },
              { field: 'recom_index_C', headerName: 'C', width: 120 },
              { field: 'recom_index_D', headerName: 'D', width: 120 },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_replenishment_store_view_stores',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              { field: 'oh+oo+it', headerName: 'OH+OO+IT', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'dc', headerName: 'DC', width: 100 },
              {
                field: 'min_qty',
                headerName: 'Min Qty',
                width: 150,
              },
              {
                field: 'replenishment_qty',
                headerName: 'Replenishment Qty',
                width: 200,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 120 },
                  { field: 'dc_02', headerName: 'DC02', width: 120 },
                  { field: 'dc_03', headerName: 'DC03', width: 120 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_replenishment_store_view_store_groups',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: '150',
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: '150',
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 150,
              },
              { field: 'dc', headerName: 'DC', width: 100 },
              {
                field: 'avg_min_qty',
                headerName: 'Avg. Min Qty',
                width: 150,
              },
              {
                field: 'avg_replenishment_qty',
                headerName: 'Avg. replenishment qty',
                width: 250,
              },
              { field: 'targat_wos', headerName: 'Target WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 150 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 100 },
                  { field: 'dc_02', headerName: 'DC02', width: 100 },
                  { field: 'dc_03', headerName: 'DC03', width: 100 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_replenishment_store_view_store_grade',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Information',
                pinned: 'left',
                children: [
                  { field: 'store_grade', headerName: 'Store Grade' },
                  { field: 'style_color', headerName: '# Style Color' },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 180,
              },
              { field: 'dc', headerName: 'DC', width: 100 },
              {
                field: 'min_qty',
                headerName: 'Min Qty',
                width: 150,
              },
              {
                field: 'replenishment_qty',
                headerName: 'Replenishment Qty',
                width: 200,
              },
              { field: 'targat_wos', headerName: 'Targat WOS', width: 150 },
              { field: 'forecast_wos', headerName: 'Forecast WOS', width: 180 },
              {
                headerName: 'Shipping Dates',
                children: [
                  { field: 'dc_01', headerName: 'DC01', width: 120 },
                  { field: 'dc_02', headerName: 'DC02', width: 120 },
                  { field: 'dc_03', headerName: 'DC03', width: 120 },
                ],
              },
            ],
            data: [],
          },
        },
      },
      {
        title: 'Style',
        isRadio: false,
        key: 'tb_replenishment_articles',
        height: '350px',
        column: [
          {
            field: 'style_color_#',
            headerName: 'Style Color #',
            width: 180,
            pinned: 'left',
          },
          { field: 'color', headerName: 'Color', width: 120, pinned: 'left' },
          {
            field: 'description',
            headerName: 'Description',
            width: 400,
            pinned: 'left',
          },
          { field: 'constraint', headerName: 'Constraint', width: 120 },
          {
            headerName: 'Replenishment recommended qty',
            children: [
              { field: 'recom_qty_DC01', headerName: 'DC01', width: 120 },
              { field: 'recom_qty_DC02', headerName: 'DC02', width: 120 },
              { field: 'recom_qty_DC03', headerName: 'DC03', width: 120 },
              { field: 'style_recom_total', headerName: 'Total', width: 120 },
            ],
          },
          {
            headerName: 'Net DC Available',
            children: [
              { field: 'net_dc_DC01', headerName: 'DC01', width: 120 },
              { field: 'net_dc_DC02', headerName: 'DC02', width: 120 },
              { field: 'net_dc_DC03', headerName: 'DC03', width: 120 },
              { field: 'style_net_total', headerName: 'Total', width: 120 },
            ],
          },
          { field: 'original_aps', headerName: 'Original Aps', width: 180 },
          { field: 'forecasted_aps', headerName: 'Forecasted Aps', width: 180 },
          { field: 'target_wos', headerName: 'Target WOS', width: 150 },
          { field: 'forecasted_wos', headerName: 'Forecasted WOS', width: 180 },
          {
            headerName: 'Size',
            children: [
              { field: '6B', headerName: '6B', width: 120 },
              { field: '7B', headerName: '7B', width: 120 },
              { field: '8B', headerName: '8B', width: 120 },
              { field: '9B', headerName: '9B', width: 120 },
              { field: '10B', headerName: '10B', width: 120 },
              { field: '11B', headerName: '11B', width: 120 },
            ],
          },
        ],
        data: [],
      },
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_replenishment_articles_stores',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 50,
              },
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    pinned: 'left',
                    width: 300,
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: 150,
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                    width: 150,
                  },
                ],
              },
              { field: 'original_aps', headerName: 'Original APS', width: 150 },
              { field: 'actual_aps', headerName: 'Actual APS', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              { field: 'oh+oo+it', headerName: 'OH+OO+IT', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 180,
              },
              { field: 'total_units', headerName: 'Total Units', width: 180 },
              {
                field: 'min_qty',
                headerName: 'Min Qty.',
                width: 150,
              },
              {
                field: 'replenishment_qty',
                headerName: 'Replenishment qty.',
                width: 200,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 220,
              },
              {
                field: 'size_integrity_in_%',
                headerName: 'Size Integrity in %',
                width: 200,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_replenishment_articles_store_group',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_grp_id',
                    headerName: 'Store Grp ID',
                    pinned: 'left',
                    width: '150',
                  },
                  {
                    field: 'store_group_name',
                    headerName: 'Store Group Name',
                    pinned: 'left',
                  },
                  {
                    field: 'climate',
                    headerName: 'Climate',
                    pinned: 'left',
                    width: '150',
                  },
                  {
                    field: 'country',
                    headerName: 'Country',
                    pinned: 'left',
                    width: '150',
                  },
                  {
                    field: 'style_color',
                    headerName: '# Style Color',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 180,
              },
              { field: 'total_units', headerName: 'Total Units', width: 150 },
              {
                field: 'min_qty',
                headerName: 'Min Qty.',
                width: 150,
              },
              {
                field: 'replenishment_qty',
                headerName: 'Replenishment qty.',
                width: 200,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 200,
              },
              {
                field: 'size_integrity_in_%',
                headerName: 'Size Integrity in %',
                width: 200,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_replenishment_articles_store_grade',
            column: [
              {
                field: '',
                headerName: '',
                pinned: 'left',
                headerCheckboxSelection: () => true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                width: 60,
              },
              {
                headerName: 'Store Grade Information',
                children: [
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    width: '200',
                    pinned: 'left',
                  },
                  {
                    field: 'style_color',
                    headerName: '# style colors',
                    width: '200',
                    pinned: 'left',
                  },
                ],
              },
              { field: 'original_ap', headerName: 'Original AP', width: 150 },
              { field: 'actual_ap', headerName: 'Actual AP', width: 150 },
              { field: 'on_hand', headerName: 'On Hand', width: 150 },
              { field: 'on_order', headerName: 'On Order', width: 150 },
              { field: 'in_transit', headerName: 'In Transit', width: 150 },
              {
                field: 'min_constraint',
                headerName: 'Min Constraint',
                width: 180,
              },
              { field: 'total_units', headerName: 'Total Units', width: 150 },
              {
                field: 'min_qty',
                headerName: 'Min Qty.',
                width: 150,
              },
              {
                field: 'replenishment_qty',
                headerName: 'Replenishment qty.',
                width: 200,
              },
              { field: 'dc_name', headerName: 'DC Name', width: 150 },
              {
                field: 'net_dc_available',
                headerName: 'Net DC Available',
                width: 200,
              },
              {
                field: 'size_integrity_in_%',
                headerName: 'Size Integrity in %',
                width: 200,
              },
              {
                headerName: '6B',
                children: [
                  {
                    field: '6B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '6B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '7B',
                children: [
                  {
                    field: '7B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '7B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '8B',
                children: [
                  {
                    field: '8B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '8B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '9B',
                children: [
                  {
                    field: '9B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '9B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '10B',
                children: [
                  {
                    field: '10B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '10B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
              {
                headerName: '11B',
                children: [
                  {
                    field: '11B_allocated_qty',
                    headerName: 'Allocated Qty.',
                    width: 150,
                  },
                  { field: '11B_OH+OO+IT', headerName: 'OH+OO+IT', width: 150 },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  capacity_breach: {
    label: 'Capacity Breach',
    value: 'capacity_breach',
    tables: [
      {
        title: '',
        isRadio: false,
        value: 'capacity_breach',
        height: '450px',
        key: 'tb_replenishment_capacity_breach',
        autoGroupColumnDef: { headerName: 'Store Name' },
        column: [
          {
            headerName: 'Store Name',
            field: 'store_name',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: 'Department',
            field: 'department',
            aggFunc: (params) => {
              let final = null;
              params.values.forEach((value, index) => {
                if (index === 0) {
                  final = value;
                }
              });
              return final;
            },
          },
          {
            headerName: 'Style Colors',
            field: 'style_colors',
            aggFunc: 'count',
          },
          {
            headerName: 'Capacity',
            field: 'capacity',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'OH+IT+OO',
            field: 'oh_it_oo',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Forecasted sales',
            field: 'forecasted_sales',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Allocated Units in  current batch',
            field: 'allocated_units_current_batch',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Current Allocation recommendation',
            field: 'current_allocation_recommendation',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Net available capacity',
            field: 'net_available_capacity',
            valueFormatter: (params) =>
              params?.node?.rowIndex === 0
                ? params?.node?.aggData?.oh_it_oo +
                  params?.node?.aggData?.current_allocation_recommendation -
                  params?.node?.aggData?.forecasted_sales
                : 0,
          },
          {
            headerName: 'Current WOS (OH+IT+OO)',
            field: 'current_wos',
            width: 250,
          },
          {
            headerName: 'Margin/Unit',
            field: 'margin_per_unit',
          },
          {
            headerName: 'Product grade',
            field: 'product_grade',
          },
          {
            headerName: 'Small',
            field: 'small',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Medium',
            field: 'medium',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Large',
            field: 'large',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
        ],
        editColumn: [
          {
            headerName: 'Store Name',
            field: 'store_name',
            hide: true,
            rowGroup: true,
          },
          {
            headerName: 'Department',
            field: 'department',
          },
          {
            headerName: 'Style Colors',
            field: 'style_colors',
          },
          {
            headerName: 'Capacity',
            field: 'capacity',
            aggFunc: 'sum',
          },
          {
            headerName: 'OH+IT+OO',
            field: 'oh_it_oo',
            aggFunc: 'sum',
          },
          {
            headerName: 'Forecasted sales',
            field: 'forecasted_sales',
            aggFunc: 'sum',
          },
          {
            headerName: 'Allocated Units in  current batch',
            field: 'allocated_units_current_batch',
            aggFunc: 'sum',
          },
          {
            headerName: 'Current Allocation recommendation',
            field: 'current_allocation_recommendation',
            aggFunc: 'sum',
          },
          {
            headerName: 'Net available capacity',
            field: 'net_available_capacity',
          },
          {
            headerName: 'Current WOS (OH+IT+OO)',
            field: 'current_wos',
            width: 300,
          },
          {
            headerName: 'Margin/Unit',
            field: 'margin_per_unit',
          },
          {
            headerName: 'Product grade',
            field: 'product_grade',
          },
          {
            headerName: 'Small',
            field: 'small',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Medium',
            field: 'medium',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
          {
            headerName: 'Large',
            field: 'large',
            cellRenderer: 'cellTextField',
            aggFunc: (params) => {
              let total = 0;
              params.values.forEach((value) => (total += parseInt(value)));
              return total;
            },
          },
        ],
        data: [],
      },
    ],
  },
};

export const bulk_edit_table = {
  column: [
    { field: 'name', headerName: '' },
    { field: 'b6', headerName: '6B' },
    { field: 'b7', headerName: '7B' },
    { field: 'b8', headerName: '8B' },
    { field: 'b9', headerName: '9B' },
    { field: 'b10', headerName: '10B' },
    { field: 'b11', headerName: '11B' },
  ],
  data: [
    {
      name: 'Eaches per store',
      b6: '3',
      b7: '3',
      b8: '3',
      b9: '3',
      b10: '3',
      b11: '3',
    },
    {
      name: 'Eaches Available',
      b6: '10',
      b7: '12',
      b8: '15',
      b9: '10',
      b10: '10',
      b11: '10',
    },
  ],
};

export const re_al_label_data = [
  { label: 'Plan Name:', value: 'DC_Auto_Allocate_012345' },
  { label: 'Creation Date', value: '07-22-2022' },
];
