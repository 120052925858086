import React, { useEffect, useState, useMemo } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { makeStyles, propsToClassKey } from '@mui/styles';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { fetchSheetData } from './action';
import { Table } from '../../../../components/Table';
import CustomCard from '../../../../components/CustomCard';
import Snackbar from '../../../../components/SnackBar';
import CellTextField from '../../../../components/Table/CellTextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextFieldWithLabel from '../../../../components/TextField/TextFieldWithLabel';
import CustomDatePicker from '../../../../components/CustomDatePicker/CustomDatePicker';

const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  headerCard: {
    // ...theme.content.card,
    // width: '100%',
    // height: '100%',
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
});

const useStyles = makeStyles(styles);

const TableVariation = ({ data, bulkEdit, index }) => {
  const classes = useStyles();
  const { radio, key } = data;
  const [isLoading, setIsLoading] = useState(false);
  const [curentObject, setCurentObject] = useState({});
  const [open, setOpen] = useState(false);
  const [radioValue, setRadioValue] = useState('stores');
  const [tableData, setTableData] = useState([]);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [editCapacity, setEditCapacity] = useState(false);
  const [DC01, setDC01] = useState();
  const [DC02, setDC02] = useState();
  const [DC03, setDC03] = useState();

  const editableHeaders = ['Total Allocated Units', 'DC01', 'DC02', 'DC03'];

  const handleSetAllButton = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setDisplayMessage(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleApply = () => {
    setOpen(false);
    setDisplayMessage(true);
  };

  const handleDateChange = (value, data) => {
    if (data === 'dc01') setDC01(value);
    else if (data === 'dc02') setDC02(value);
    else setDC03(value);
  };

  useEffect(() => {
    setCurentObject(data);
    if (data?.value === 'capacity_breach') bulkEdit(false);
  }, [data]);

  const getData = async (sheet) => {
    const apiData = await fetchSheetData(sheet);
    setTableData(apiData);
  };
  useEffect(() => {
    if (key) {
      getData(key);
    } else if (radio && Object.keys(radio).length > 0) {
      getData(radio[radioValue].key);
    }
  }, [key, radioValue]);

  const handleChange = (event, newValue) => {
    setRadioValue(newValue);
  };

  const onEditCapacity = () => {
    if (editCapacity) setDisplayMessage(true);
    setEditCapacity(!editCapacity);
  };
  return (
    <CustomCard
      cardStyles={classes.headerCard}
      className='impact_table_variation_custom_card'
    >
      {displayMessage && (
        <Snackbar
          message='Changes Applied'
          variant='success'
          handleClose={handleClose}
        />
      )}

      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress className={classes.loader} />
        </div>
      )}
      {!isLoading && Object.keys(curentObject).length > 0 && (
        <div className='impact_table_variation_container'>
          {!curentObject.isRadio && (
            <div style={{ padding: 10 }}>
              <Typography variant='h6'>{curentObject?.title}</Typography>
              {curentObject.value === 'capacity_breach' ? (
                <>
                  <div style={{ paddingTop: 0, textAlign: 'right' }}>
                    <Button
                      style={{
                        marginRight: 5,
                        bottom: 10,
                        textTransform: 'none',
                      }}
                      size='small'
                      variant='contained'
                      onClick={() => onEditCapacity()}
                    >
                      {editCapacity ? 'Save' : 'Edit'}
                    </Button>
                  </div>

                  <Table
                    rowData={tableData}
                    columnDefs={
                      editCapacity
                        ? curentObject?.editColumn
                        : curentObject?.column
                    }
                    height={
                      curentObject?.height ? curentObject?.height : '400px'
                    }
                    tableCssStyle={curentObject?.tableCssStyle}
                    suppressRowClickSelection={true}
                    groupSelectsChildren={true}
                    rowSelection={'multiple'}
                    editable={true}
                    groupDefaultExpanded={curentObject.defaultExpansion}
                    frameworkComponents={{
                      cellTextField: CellTextField,
                    }}
                    autoGroupColumnDef={curentObject.autoGroupColumnDef}
                  />
                </>
              ) : (
                <Table
                  rowData={tableData}
                  tableCssStyle={curentObject?.tableCssStyle}
                  columnDefs={curentObject?.column}
                  height={curentObject?.height ? curentObject?.height : '400px'}
                  suppressRowClickSelection={true}
                  groupSelectsChildren={true}
                  rowSelection={'multiple'}
                  editable={true}
                  autoGroupColumnDef={curentObject.autoGroupColumnDef}
                />
              )}
            </div>
          )}
          {curentObject.isRadio && radioValue && (
            <div style={{}}>
              <Grid
                container
                className='impact_table_variation_grid_container'
                sx={{ paddingLeft: 1 }}
              >
                <Grid item xs={10} className='impact_radio_group_container'>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby='demo-row-radio-buttons-group-label'
                      name='row-radio-buttons-group'
                      onChange={handleChange}
                      value={radioValue}
                    >
                      {curentObject?.radio?.checks.map((item) => (
                        <FormControlLabel
                          value={item.value}
                          control={<Radio />}
                          label={item.label}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={2} className='impact_able_variation_edit_button'>
                  <div style={{ float: 'right' }}>
                    {curentObject?.showSetAllButton && (
                      <Button
                        style={{
                          marginRight: 5,
                          bottom: 0,
                          textTransform: 'none',
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => handleSetAllButton()}
                      >
                        Set All
                      </Button>
                    )}
                    <Button
                      style={{
                        marginRight: 5,
                        bottom: 0,
                        textTransform: 'none',
                      }}
                      size='small'
                      variant='contained'
                    >
                      Edit
                    </Button>
                  </div>
                </Grid>
                <Table
                  rowData={tableData}
                  columnDefs={curentObject?.radio[radioValue].column}
                  floatingFilter={false}
                  height={curentObject?.height ? curentObject?.height : '400px'}
                  rowSelection={'multiple'}
                  autoGroupColumnDef={curentObject.autoGroupColumnDef}
                  tableCssStyle={curentObject?.tableCssStyle}
                />
              </Grid>
              <div className='spacer'></div>
            </div>
          )}
          <Dialog
            open={open}
            onClose={handleCancel}
            aria-labelledby='alert-dialog-title'
            maxWidth={'lg'}
            PaperProps={{
              style: {
                minHeight: '52%',
                maxHeight: '52%',
              },
            }}
          >
            <DialogTitle id='alert-dialog-title'>{'Set All'}</DialogTitle>
            <DialogContent>
              <div
                style={{ color: '#758498', marginLeft: 30, marginBottom: 10 }}
              >
                <Typography variant='h6'>Shipping Dates</Typography>
              </div>
              <div style={{ display: 'flex' }}>
                <>
                  <div style={{ marginBottom: '2rem', marginLeft: '2rem' }}>
                    <CustomDatePicker
                      label={editableHeaders[1]}
                      labeldirection={'column'}
                      values={DC01}
                      width={200}
                      fieldWidth={200}
                      onChange={(value) => handleDateChange(value, 'dc01')}
                    />
                  </div>
                  <div style={{ marginBottom: '2rem', marginLeft: '2rem' }}>
                    <CustomDatePicker
                      label={editableHeaders[2]}
                      labeldirection={'column'}
                      values={DC02}
                      width={200}
                      fieldWidth={200}
                      onChange={(value) => handleDateChange(value, 'dc02')}
                    />
                  </div>
                  <div style={{ marginBottom: '2rem', marginLeft: '2rem' }}>
                    <CustomDatePicker
                      label={editableHeaders[3]}
                      labeldirection={'column'}
                      values={DC03}
                      width={200}
                      fieldWidth={200}
                      onChange={(value) => handleDateChange(value, 'dc03')}
                    />
                  </div>
                </>
              </div>
              <div style={{ marginLeft: '2rem', marginTop: '2rem' }}>
                <TextFieldWithLabel
                  type='text'
                  label={editableHeaders[0]}
                  width='15rem'
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button style={{ textTransform: 'none' }} onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                style={{ textTransform: 'none' }}
                onClick={handleApply}
                autoFocus
              >
                Apply
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </CustomCard>
  );
};

export default TableVariation;
