import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Chart from '../../../../utils/charts/Chart';
import {
  ALLOCATION,
  FORECAST,
  getChartOptions,
  REPLENISHMENT,
  ORDER,
  ALLOCATION_VISUALISATION_INVENTORY_OVERVIEW,
  tabForecastData,
} from './data';
import Select from 'react-select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CustomDateRangePicker from '../../../../components/CustomDateRangePicker';

export const Visualisation = (props) => {
  const graphOptions = [
    { value: 'Inventory', label: 'Inventory' },
    { value: 'Safety Stock', label: 'Safety Stock' },
    { value: 'Days of supply', label: 'Days of supply' },
    { value: 'Service Level', label: 'Service Level' },
    { value: 'MAPE', label: 'MAPE' },
    { value: 'Fill Rate', label: 'Fill Rate' },
    { value: 'Lost sales%', label: 'Lost sales%' },
    { value: 'Excess Inv($)', label: 'Excess Inv($)' },
    { value: 'GM%', label: 'GM%' },
    { value: 'ST%', label: 'ST%' },
    { value: 'sales $ vs sale ($)', label: 'sales $ vs sale ($)' },
    { value: 'Total Demand', label: 'Total Demand' },
  ];

  const graphOptions_order = [
    { value: 'Inventory', label: 'Inventory' },
    { value: 'Safety Stock', label: 'Safety Stock' },
    { value: 'Days of supply', label: 'Days of supply' },
    { value: 'Service Level', label: 'Service Level' },
    { value: 'MAPE', label: 'MAPE' },
    { value: 'Fill Rate', label: 'Fill Rate' },
    { value: 'GM%', label: 'GM%' },
    { value: 'ST%', label: 'ST%' },
    { value: 'sales $ vs sale ($)', label: 'sales $ vs sale ($)' },
  ];

  const [selectedLocation, setSelectedLocation] = useState(graphOptions[0]);
  const [selectedProduct, setSelectedProduct] = useState(graphOptions[0]);
  const [selectedSize, setSelectedSize] = useState(graphOptions[0]);
  const [selectedLevel, setSelectedLevel] = useState('Week');
  const [value, setValue] = useState('Week');
  const [dates, setDates] = useState([new Date(), new Date()]);

  useEffect(() => {
    setSelectedLocation(graphOptions[0]);
    setSelectedProduct(graphOptions[0]);
    setSelectedSize(graphOptions[0]);
  }, []);

  const handleDateRangeChange = (values) => {
    setDates([...values]);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderForecastChart = (options) => {
    return (
      <>
        <Grid className='tile' container spacing={2}>
          <Grid item xs={10}>
            <Tabs
              className='impact impact-forecast-visualisation-tabs'
              value={value}
              onChange={handleChange}
              aria-label='wrapped label tabs'
            >
              {tabForecastData.map((item) => (
                <Tab
                  className='impact impact-forecast-visualisation-tab'
                  value={item.value}
                  label={item.label}
                  style={{
                    textTransform: 'none',
                  }}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={2}>
            <CustomDateRangePicker
              className='impact impact-forecast-visualisation-date-picker'
              labeldirection={'row'}
              values={dates}
              width={400}
              fieldWidth={300}
              isMandatory={false}
              onChange={handleDateRangeChange}
            />
          </Grid>
        </Grid>
        <Chart {...options} />
      </>
    );
  };

  const renderView = () => {
    let options = {};
    let categories = [];
    let text = '';
    let yAxisTitle = '';
    switch (props.parentLabel) {
      case FORECAST:
        console.log(props.data);
        const data = props.data.filter((obj) => obj.level === value);
        categories = data.map((obj) => obj.week);
        text = 'Forecast Visualisation';
        yAxisTitle = 'Units';
        options = getChartOptions({
          ...props,
          categories,
          text,
          yAxisTitle,
          data,
        });
        return renderForecastChart(options);

        break;
      case ALLOCATION_VISUALISATION_INVENTORY_OVERVIEW:
        categories = props.data.map((obj) => obj.Date);
        yAxisTitle = 'Inventory';
        options = getChartOptions({ ...props, categories, text, yAxisTitle });
        return <Chart {...options} />;
        break;
      case ALLOCATION:
      case REPLENISHMENT:
      case ORDER:
        let stacking = 'normal';
        yAxisTitle =
          selectedLocation.value === 'Service Level' ||
          selectedLocation.value === 'Fill Rate' ||
          selectedLocation.value === 'Lost sales%'
            ? '%'
            : selectedLocation.value;
        categories = props.data
          .filter(
            (obj) =>
              obj.type === 'Location' && obj.level === selectedLocation.value
          )
          .map((obj) => obj.label);
        text = 'Location wise metrics';

        options = getChartOptions({
          ...props,
          categories,
          text,
          stacking,
          yAxisTitle,
          selectedOption: selectedLocation.value,
          data: props.data.filter(
            (obj) =>
              obj.type === 'Location' && obj.level === selectedLocation.value
          ),
        });

        let categories1 = props.data
          .filter(
            (obj) =>
              obj.type === 'Product' && obj.level === selectedLocation.value
          )
          .map((obj) => obj.label);
        let text1 = 'Product grade wise metrics';
        let yAxisTitle1 =
          selectedProduct.value === 'Service Level' ||
          selectedProduct.value === 'Fill Rate' ||
          selectedProduct.value === 'Lost sales%'
            ? '%'
            : selectedProduct.value;

        let options1 = getChartOptions({
          ...props,
          categories: categories1,
          text: text1,
          yAxisTitle: yAxisTitle1,
          selectedOption: selectedProduct.value,
          stacking: stacking,
          data: props.data.filter(
            (obj) =>
              obj.type === 'Product' && obj.level === selectedProduct.value
          ),
        });

        let categories2 = props.data
          .filter(
            (obj) =>
              obj.type === 'Size' && obj.level === selectedLocation.value
          )
          .map((obj) => obj.label);
        let text2 = 'Size';
        let yAxisTitle2 =
          selectedSize.value === 'Service Level' ||
          selectedSize.value === 'Fill Rate' ||
          selectedSize.value === 'Lost sales%'
            ? '%'
            : selectedSize.value;

        let options2 = getChartOptions({
          ...props,
          categories: categories2,
          text: text2,
          yAxisTitle: yAxisTitle2,
          selectedOption: selectedSize.value,
          stacking: stacking,
          data: props.data.filter(
            (obj) =>
              obj.type === 'Size' && obj.level === selectedSize.value
          ),
        });
        return (
          <>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <b>{text}</b>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      value={selectedLocation}
                      onChange={setSelectedLocation}
                      options={
                        props.parentLabel === 'Order'
                          ? [...graphOptions_order]
                          : [...graphOptions]
                      }
                    />
                  </Grid>
                </Grid>
                <div className='spacer'></div>
                <Chart {...options} />
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <b>{text1}</b>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      value={selectedProduct}
                      onChange={setSelectedProduct}
                      options={
                        props.parentLabel === 'Order'
                          ? [...graphOptions_order]
                          : [...graphOptions]
                      }
                    />
                  </Grid>
                </Grid>
                <div className='spacer'></div>
                <Chart {...options1} />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={2}>
                    <b>{text2}</b>
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      value={selectedSize}
                      onChange={setSelectedSize}
                      options={
                        props.parentLabel === 'Order'
                          ? [...graphOptions_order]
                          : [...graphOptions]
                      }
                      width={"14rem"}
                    />
                  </Grid>
                </Grid>
                <div className='spacer'></div>
                <Chart {...options2} />
              </Grid>
            </Grid>
          </>
        );
        break;
      default:
        break;
    }
  };
  return <>{renderView()}</>;
};
