import { TextField, InputLabel } from "@mui/material";
import { makeStyles } from '@mui/styles';


const styles = (theme) => ({
  inputLabel: {
    lineHeight: "1.5",
    color: '#8596a9',
    fontSize: '0.9rem',
    marginBottom: '0.2rem'
  },
});
const useStyles = makeStyles(styles);

const TextFieldWithLabel = (props) => {
  const classes = useStyles();
 const inputProps = props.inputProps || {max: 99, min: 5}
  return (
    <>{
      props.label ? ( <>
          <InputLabel
              className={classes.inputLabel}
              required={props.required}
              placeholder={props?.label}
            >
              <span 
              title={props.label}>
                {props.label}
              </span>
            </InputLabel>
          </> ) : null 
          }
        
        <TextField
          InputProps={{ sx: { height: 30, width: props.width,fontSize:"13px",fontFamily:"-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif" }, endAdornment: props.endAdornment, inputProps: inputProps  }}
          hiddenLabel
          size="small"
          id="filled-hidden-label-normal"
          variant="outlined"
          value={props.value}
          onChange={props.onChange}
          onBlur={props.handleOnBlur}
          defaultValue={props.defaultValue}
          name={props.name}
          type={props.type}
          disabled={props.disabled}
        />
    </>
  );
};

export default TextFieldWithLabel;
