import React from "react";
import TextFieldWithLabel from "../TextField/TextFieldWithLabel";

const CellTextField = (props) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextFieldWithLabel
        type="text"
        defaultValue={props.value}
        disabled={props.disabled}
      />
    </div>
  );
};

export default CellTextField;
