import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import Chart from '../../../../utils/charts/Chart';
import { Table } from '../../../../components/Table';
import CustomCard from '../../../../components/CustomCard';
import FilterSection from './FilterSection';
import { getMultipleModelsData } from './action';
import FiltersList from '../../../../molecules/FiltersList';
import ListComponent from './List';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import SnackBar from '../../../../components/SnackBar';
import { OrderSummaryFilters } from '../Order-Management/orderSummaryFilters';

const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  headerCard: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
});

const useStyles = makeStyles(styles);

const Report = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { tabListData } = props;
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [curentObject, setCurentObject] = useState({});
  const [curentObjectData, setCurentObjectData] = useState({});
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [displayMessage, setDisplayMessage] = useState(false);
  const [isOptimized, setIsOptimized] = useState(false);
  const [message, setMessage] = useState(null);
  const [diable, setDiabled] = useState(false);
  const {
    checkboxFilters: {
      openToBudget,
      containerCapacity,
      costTier,
      selectedItems,
    },
    workflow,
  } = useSelector((state) => state.orderManagement);

  const getData = async (key) => {
    const apidata = await getMultipleModelsData({
      model_names: key,
      selectedFilters,
    });
    if (Object.keys(curentObject).length > 0 && data) {
      setData(apidata);
      setCurentObjectData({
        ...apidata,
        tb_supplier_to_warehouse_pending:
          apidata.tb_supplier_to_warehouse_pending &&
          apidata.tb_supplier_to_warehouse_pending.filter(
            (item) => parseInt(item.workflow) === workflow
          ),
        tb_supplier_to_warehouse_approved:
          apidata.tb_supplier_to_warehouse_approved &&
          apidata.tb_supplier_to_warehouse_approved.filter(
            (item) => parseInt(item.workflow) === workflow
          ),
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setCurentObject(tabListData[Object.keys(tabListData)[0]]);
    setValue(Object.keys(tabListData)[0]);
  }, []);

  useEffect(() => {
    if (workflow && data.length > 0) {
      setCurentObjectData({
        ...data,
        tb_supplier_to_warehouse_pending:
          data?.tb_supplier_to_warehouse_pending &&
          data?.tb_supplier_to_warehouse_pending.filter(
            (item) => parseInt(item?.workflow) === workflow
          ),
        tb_supplier_to_warehouse_approved:
          data?.tb_supplier_to_warehouse_approved &&
          data?.tb_supplier_to_warehouse_approved.filter(
            (item) => parseInt(item?.workflow) === workflow
          ),
      });
    }
  }, [workflow, data]);

  const handleChange = (event, newValue) => {
    setCurentObject(tabListData[newValue]);
    setValue(newValue);
    setIsLoading(true);
  };

  useEffect(() => {
    if (value) getData(curentObject?.apiKey);
  }, [value]);

  const reloadFilters = async () => {
    setSelectedFilters({});
  };

  const openLoader = () => {
    setLoader(true);
  };
  const closeLoader = () => {
    setLoader(false);
  };
  const updateState = (params) => {
    setSelectedFilters(params);
  };
  const appliedFiltersAction = async (params) => {
    console.log(selectedFilters);
  };

  const onReset = () => {
    reloadFilters();
  };

  useEffect(() => {
    if (
      !(
        openToBudget ||
        containerCapacity ||
        (costTier && selectedItems.length > 0)
      )
    ) {
      setDiabled(true);
    } else {
      setDiabled(false);
    }
  }, [openToBudget, containerCapacity, costTier, selectedItems]);

  const onAction = async (type) => {
    if (type === 'optimize') {
      setMessage(
        'Request has been submitted for optimization  will notify soon'
      );
      setCurentObjectData({
        ...data,
        tb_supplier_to_warehouse_pending:
          await data.tb_supplier_to_warehouse_pending.filter(
            (item) => parseInt(item.workflow) === 100
          ),
        tb_supplier_to_warehouse_approved:
          await data.tb_supplier_to_warehouse_approved.filter(
            (item) => parseInt(item.workflow) === 100
          ),
      });
      setTimeout(() => {
        setIsOptimized(true);
        setMessage(
          'Request has been optimized  Successfully, reloading the data..'
        );
        setDisplayMessage(true);
      }, 3000);

      setDisplayMessage(true);
    } else if (type === 'approve') {
      setMessage('Selected items has been approved successfully');
      setDisplayMessage(true);
    } else if (type === 'deep_dive') {
      navigate('/inventory-smart/deep-dive');
    } else if (type === 'what_if') {
      navigate('/inventory-smart/what-if?flow=order');
    } else if (type === 'create') {
      navigate('/inventory-smart/create-new-order');
    } else if (type === 'daily_allocation_summary') {
      navigate('/inventory-smart/report');
    } else {
    }
  };

  const handleMessageClose = () => {
    setDisplayMessage(false);
    setMessage(null);
  };

  return (
    <LoadingOverlay>
      {displayMessage && (
        <SnackBar
          message={message}
          variant='success'
          handleClose={handleMessageClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {curentObject?.filters && (
            <FiltersList
              openLoader={openLoader}
              closeLoader={closeLoader}
              updateParentState={updateState}
              appliedFilters={appliedFiltersAction}
              onReset={onReset}
              screenName={'Filters'}
            />
          )}
          <div className='impact_grid_sub_container'>
          <CustomCard cardStyles={classes.headerCard}>
            <div className='spacer'></div>
            <Grid item xs={12}>
              {curentObject && (
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label='wrapped label tabs'
                >
                  {Object.values(tabListData).map((item) => (
                    <Tab
                      value={item.value}
                      label={item.label}
                      style={{
                        textTransform: 'none',
                      }}
                    />
                  ))}
                </Tabs>
              )}
              {isLoading && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress className={classes.loader} />
                </div>
              )}
              {!isLoading && (
                <>
                  {curentObject?.card && (
                    <>
                      <Typography gutterBottom variant='h5'>
                        {curentObject?.card.title}
                      </Typography>
                      <Grid container xs={12}>
                        {curentObjectData[curentObject?.card.key]?.map(
                          (item) => (
                            <Card
                              sx={{
                                minWidth: 350,
                                margin: 3,
                                display: 'flex',
                              }}
                            >
                              <CardContent sx={{ flex: '1 0 auto' }}>
                                <div
                                  style={{
                                    display: 'flex',
                                  }}
                                >
                                  <Inventory2OutlinedIcon color={'gray'} />
                                  <Typography
                                    sx={{ fontSize: 16, marginLeft: 2 }}
                                    color='text.secondary'
                                    gutterBottom
                                  >
                                    {item.label}
                                  </Typography>
                                </div>
                                <ListComponent
                                  data={[
                                    { name: item.l1, count: item.c1 },
                                    { name: item.l2, count: item.c2 },
                                    { name: item.l3, count: item.c3 },
                                  ]}
                                />
                              </CardContent>
                            </Card>
                          )
                        )}
                      </Grid>
                    </>
                  )}

                  {curentObject?.chart && (
                    <>
                      {curentObject?.chart?.filters && (
                        <FilterSection filters={curentObject.chart.filters} />
                      )}
                      <Chart
                        chartType={curentObject.chart.type}
                        title={curentObject.chart.title}
                        yaxis={curentObject.chart.yaxis}
                        xaxis={curentObject.chart.xaxis}
                        showLegend={curentObject.chart.showLegend}
                        data={curentObject.chart.data}
                      />
                      <div className='spacer'></div>
                    </>
                  )}

                  {Object.keys(curentObjectData).length > 0 &&
                    curentObject?.table.map((item) => (
                      <>
                        <div className='spacer'></div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: 10,
                          }}
                        >
                          <Typography variant='h6'>{item.title}</Typography>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: 10,
                          }}
                        >
                          {item.enableCheckbox && <OrderSummaryFilters />}
                          <div>
                            {item?.actionButton?.map((itm) => (
                              <Button
                                style={{ textTransform: 'none', margin: 5 }}
                                variant={itm.varient}
                                size={itm.size}
                                disabled={itm.disableFeat && diable}
                                onClick={() => onAction(itm.navigation)}
                              >
                                {itm.name}
                              </Button>
                            ))}
                          </div>
                        </div>
                        <Table
                          rowData={curentObjectData[item.key]}
                          columnDefs={item.column}
                          floatingFilter={false}
                          height={item.height || '450px'}
                          rowSelection={'multiple'}
                        />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'right',
                            margin: 10,
                          }}
                        >
                          {item?.bottomActionButton?.map((itm) => (
                            <Button
                              style={{ textTransform: 'none', margin: 5 }}
                              variant={itm.varient}
                              size={itm.size}
                              disabled={itm.disableFeat && diable}
                              onClick={() => onAction(itm.navigation)}
                            >
                              {itm.name}
                            </Button>
                          ))}
                        </div>
                      </>
                    ))}
                </>
              )}
            </Grid>
          </CustomCard>
          </div>
        </Grid>
      </Grid>
    </LoadingOverlay>
  );
};

export default Report;
