import { data } from '../../../../pages/ExecutiveSummary/data';

const tableData = {
  title: 'Lost Sales - Detailed View',
  defaultExpansion: 1,
  column: [
    {
      field: 'week',
      headerName: 'Week',
      width: 120,
      rowGroup: true,
      hide: true,
    },
    {
      field: 'total_lost_sales',
      headerName: 'Total Lost Sales',
      width: 120,
    },
    {
      field: 'store',
      headerName: 'Store',
      width: 120,
    },
    {
      field: 'description',
      headerName: 'Style Description',
      width: 455,
    },
    { field: 'week_opening_balance', headerName: 'Week Opening Balance' },
    { field: 'weekly_allocation', headerName: 'Weekly Allocation', width: 170 },
    { field: 'sales_units', headerName: 'Sales (Units)', width: 150 },
    {
      field: 'avg_stores_cluster_sales',
      headerName: 'Avg. Store Cluster Sales',
      width: 220,
    },
    { field: 'lost_sales_u', headerName: 'Lost Sales (U)', width: 150 },
    { field: 'lost_sales_usd', headerName: 'Lost Sales (USD)', width: 170 },
  ],
  data: [
    {
      storeId: 166718,
      description: 'New articles received in DC',
      week: 6,
      week_opening_balance: 20,
      weekly_allocation: 456,
      sales: 234,
      cluster_sales: 234,
      lost_sales_u: 345,
      lost_sale_usd: 245,
    },
    {
      storeId: 166715,
      description: 'Articles with Clearance status',
      week: 6,
      week_opening_balance: 20,
      weekly_allocation: 456,
      sales: 234,
      cluster_sales: 234,
      lost_sales_u: 345,
      lost_sale_usd: 245,
    },
    {
      storeId: 366182,
      description: 'Articles having excess inventory in mostly',
      week: 6,
      week_opening_balance: 20,
      weekly_allocation: 456,
      sales: 234,
      cluster_sales: 234,
      lost_sales_u: 345,
      lost_sale_usd: 245,
    },
    {
      storeId: 234718,
      description: 'Articles having inventory stockout in mostly',
      week: 6,
      week_opening_balance: 20,
      weekly_allocation: 456,
      sales: 234,
      cluster_sales: 234,
      lost_sales_u: 345,
      lost_sale_usd: 245,
    },
    {
      storeId: 233434,
      description: 'Automated allocations for manual review',
      week: 6,
      week_opening_balance: 20,
      weekly_allocation: 456,
      sales: 234,
      cluster_sales: 234,
      lost_sales_u: 345,
      lost_sale_usd: 245,
    },
    {
      storeId: 980434,
      description: 'Automated allocations for manual review in mostly',
      week: 6,
      week_opening_balance: 20,
      weekly_allocation: 456,
      sales: 234,
      cluster_sales: 234,
      lost_sales_u: 345,
      lost_sale_usd: 245,
    },
    {
      storeId: 824434,
      description: 'Automated allocations for manual review',
      week: 6,
      week_opening_balance: 20,
      weekly_allocation: 456,
      sales: 234,
      cluster_sales: 234,
      lost_sales_u: 345,
      lost_sale_usd: 245,
    },
    {
      storeId: 890034,
      description: 'Automated allocations for manual review',
      week: 6,
      week_opening_balance: 20,
      weekly_allocation: 456,
      sales: 234,
      cluster_sales: 234,
      lost_sales_u: 345,
      lost_sale_usd: 245,
    },
  ],
};

const tableDataTwo = {
  title: 'Under forecasting threshold',
  thresholdValue: 20,
  column: [
    { field: 'description', headerName: 'Style Description', width: 300 },
    { field: 'actuals', headerName: 'Actuals', width: 120 },
    { field: 'ia_forecast', headerName: 'IA forecast (var%)', width: 180 },
    { field: 'adj_forecast', headerName: 'Adj. forecast (var%)', width: 190 },
  ],
  data: [
    {
      description: 'S10 SILVER HAND & FOOT PRINT FRAME',
      actuals: 710,
      ia_forecast: '547 (23%)',
      adj_forecast: '438 (38%))',
    },
    {
      description: 'IB 1pc ao navy fishes',
      actuals: 570,
      ia_forecast: '456 (20%)',
      adj_forecast: '423 (26%)',
    },
    {
      description: "S12 CARTER'S SUNNY SAFARI 3D WALL ART",
      actuals: 280,
      ia_forecast: '224 (20%)',
      adj_forecast: '186 (34%)',
    },
    {
      description: 'Carters Giftbox all UPCs all years',
      actuals: 280,
      ia_forecast: '204 (27%)',
      adj_forecast: '190 (32%)',
    },
    {
      description: 'Eco Canvas Bag',
      actuals: 770,
      ia_forecast: '585 (24%)',
      adj_forecast: '502 (35%)',
    },
    {
      description: 'mf jumpsuit grey heather star',
      actuals: 720,
      ia_forecast: '504 (30%)',
      adj_forecast: '432 (40%)',
    },
    {
      description: 'IB 1pc tiger navy-ivory stripe w foot art',
      actuals: 750,
      ia_forecast: '585 (22%)',
      adj_forecast: '522 (30%)',
    },
    {
      description: 'IB Mittens- Red Explorer',
      actuals: 780,
      ia_forecast: '554 (29%)',
      adj_forecast: '487 (38%)',
    },
    {
      description: 'SHAUNA TURQ ALOHA FLORAL',
      actuals: 760,
      ia_forecast: '608 (20%)',
      adj_forecast: '516 (32%)',
    },
    {
      description: 'S16 Sams Boy 1pc blue gry stripe whale app',
      actuals: 850,
      ia_forecast: '621 (27%)',
      adj_forecast: '585 (31%)',
    },
  ],
};

const tableDataThree = {
  title: 'Under forecasting threshold',
  thresholdValue: 20,
  column: [
    { field: 'description', headerName: 'Style Description', width: 300 },
    { field: 'actuals', headerName: 'Actuals', width: 120 },
    { field: 'ia_forecast', headerName: 'IA forecast (var%)', width: 180 },
    { field: 'adj_forecast', headerName: 'Adj. forecast (var%)', width: 190 },
  ],
  data: [
    {
      description: 'GRAPHIC OKO BL15252 STARY NT MERMAID',
      actuals: 787,
      ia_forecast: '976 (24%)',
      adj_forecast: '1025 (30%)',
    },
    {
      description: 'GRAPHIC OKO OR25816 PAPAYA ICE CREAM',
      actuals: 758,
      ia_forecast: '985 (30%)',
      adj_forecast: '1044 (38%)',
    },
    {
      description: '2PK 1PC S20 PROMO UNICORN STRIPE',
      actuals: 715,
      ia_forecast: '908 (27%)',
      adj_forecast: '953 (33%)',
    },
    {
      description: '2PK 1PC COTTON-POLY D1 - FLORAL HEARTS',
      actuals: 752,
      ia_forecast: '940 (25%)',
      adj_forecast: '1006 (34%)',
    },
    {
      description: '4 PC COTTON D1 - CONSTRUCTION',
      actuals: 781,
      ia_forecast: '1015 (30%)',
      adj_forecast: '1076 (38%)',
    },
    {
      description: '1PC MONSTER 1PC',
      actuals: 794,
      ia_forecast: '1000 (26%)',
      adj_forecast: '1060 (34%)',
    },
    {
      description: '1PC SHARK 1PC',
      actuals: 747,
      ia_forecast: '971 (30%)',
      adj_forecast: '1020 (37%)',
    },
    {
      description: 'CLUBS S20 COSTCO 2PK SHEEP PUR',
      actuals: 732,
      ia_forecast: '893 (22%)',
      adj_forecast: '938 (28%)',
    },
    {
      description: '2PK HOODIE 2PK PINK GREY HOODIE',
      actuals: 775,
      ia_forecast: '961 (24%)',
      adj_forecast: '1038 (34%)',
    },
    {
      description: '2PK JOGGER 2PK PINK GREY JOGGER',
      actuals: 768,
      ia_forecast: '937 (22%)',
      adj_forecast: '1003 (31%)',
    },
  ],
};

export const tabListData = {
  lost_sales: {
    orderBy: ['chart', 'table'],
    dataMapping: ['table'],
    apiCall: ['tb_lost_sales'],
    label: 'Lost Sales',

    value: 'lost_sales',
    chart: [
      {
        type: 'linechart',
        title: 'Lost Sales Opportunity',
        yaxis: {
          title: 'Lost Sales Units',
        },
        xaxis: {
          categories: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
            37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
          ],
        },
        showLegend: true,
        data: [
          {
            name: 'Weeks',
            data: [
              3, 6, 4, 12, 27, 8, 3, 5, 8, 3, 4, 7, 8, 10, 11, 14, 9, 12, 10, 8,
              10, 12, 32, 2, 3, 6, 41, 3, 10, 12, 1, 2, 4, 5, 3, 1, 2, 4, 7, 6,
              8, 9, 10, 8, 10, 9, 7, 6, 8, 9, 10, 11, 10,
            ],
            type: 'column',
          },
        ],
      },
    ],
    table: [{ ...tableData }],
  },
  excess_inventory: {
    orderBy: ['chart', 'table'],
    dataMapping: ['table'],
    apiCall: ['tb_excess_inventory'],
    label: 'Excess Inventory',
    value: 'excess_inventory',
    table: [
      {
        title: 'Excess Inventory Positions',
        column: [
          { field: 'store', headerName: 'Store' },
          { field: 'description', headerName: 'Store Description' },
          { field: 'week_closing_balance', headerName: 'Week Closing Balance' },
          { field: 'ros', headerName: 'ROS' },
          { field: 'target_wos', headerName: 'Target WOS' },
          { field: 'wos', headerName: 'WOS' },
          { field: 'excess_inv_u', headerName: 'Excess Inv.(U)' },
          { field: 'excess_inv_usd', headerName: 'Excess Inv.(USD)' },
        ],
        data: [],
      },
    ],
    chart: [
      {
        type: 'linechart',
        title: 'Excess Inventory',
        yaxis: {
          title: 'Units',
        },
        xaxis: {
          categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
        },
        showLegend: true,
        data: [
          {
            name: 'Total Inventory',
            data: [40, 30, 50, 25, 20],
            type: 'line',
          },
          {
            name: 'ST%',
            data: [70, 60, 75, 50, 65],
            type: 'line',
          },
        ],
      },
    ],
  },
  store_stock_drilldown: {
    orderBy: ['table'],
    dataMapping: ['table'],
    apiCall: ['tb_store_stock_drilldown'],
    label: 'Store Stock Drill Down',
    value: 'store_stock_drilldown',
    table: [
      {
        title: 'Store Stock Drill Down',
        column: [
          { field: 'channel', headerName: 'Channel',floatingFilter:true },
          { field: 'department', headerName: 'Department',floatingFilter:true  },
          { field: 'category', headerName: 'Category',floatingFilter:true  },
          { field: 'collection', headerName: 'Collection' },
          { field: 'gender', headerName: 'Gender' },
          { field: 'store_grade', headerName: 'Store Grade' },
          { field: 'store_number', headerName: 'Store Number' },
          { field: 'region', headerName: 'Region',floatingFilter:true },
          { field: 'climate', headerName: 'Climate' },
          { field: 'style_color', headerName: 'Style Color',floatingFilter:true },
          { field: 'description', headerName: 'Description', pinned: 'none',floatingFilter:true },
          { field: 'material', headerName: 'Material' },
          { field: 'in_transit', headerName: 'In Transit' },
          { field: 'total_inventory', headerName: 'Total Inventory' },
          { field: 'lw_units', headerName: 'LW Units' },
          { field: 'wos', headerName: 'WOS' },
          {
            headerName: 'Size Integration',
            children: [
              { field: 'size_integration_on_hand', headerName: 'On Hand' },
              {
                field: 'size_integration_in_transit',
                headerName: 'In Transit',
              },
              { field: 'size_integration_dc', headerName: 'DC' },
              { field: 'size_integration_total', headerName: 'Total' },
            ],
          },
          {
            headerName: 'DC Inventory',
            children: [
              { field: 'dc_inventory_dc_01', headerName: 'DC 01' },
              { field: 'dc_inventory_dc_02', headerName: 'DC 02' },
              { field: 'dc_inventory_dc_03', headerName: 'DC 03' },
            ],
          },
          { field: 'bulk_remaining', headerName: 'Bulk Remaining' },
          {
            field: 'style_color_status',
            headerName: 'Style Color Status',
          },
          { field: 'store_status', headerName: 'Store Status' },
          { field: 'clearance', headerName: 'Clearance' },
        ],
        data: [],
      },
    ],
  },
  allocation_deep_dive: {
    orderBy: ['table'],
    dataMapping: ['table'],
    apiCall: ['tb_allocation_deep_dive'],
    label: 'Allocation Deep Dive',
    value: 'allocation_deep_dive',
    table: [
      {
        title: 'Allocation Deep Dive',
        column: [
          {
            field: 'category',
            headerName: 'Category',
            pinned: 'left',
            width: 150,
          },
          {
            field: 'collection',
            headerName: 'Collection',
            pinned: 'left',
            width: 150,
          },
          { field: 'gender', headerName: 'Gender', pinned: 'left', width: 150 },
          {
            field: 'material',
            headerName: 'Material',
            pinned: 'left',
            width: 150,
          },
          {
            field: 'style_id',
            headerName: 'Style ID',
            pinned: 'left',
            width: 150,
          },
          { field: 'style_desc', headerName: 'Style Description' },
          { field: 'store_id', headerName: 'Store ID' },
          { field: 'store_grade', headerName: 'Store Grade' },
          { field: 'planned_allocation', headerName: 'Planned Allocation' },
          { field: 'actual_allocation', headerName: 'Actual Allocation' },
          { field: 'unit_sales', headerName: 'Unit Sales' },
          {
            headerName: 'Plan vs Actual Allocation',
            children: [
              {
                field: 'plan_vs_actual_allocation_qty_match',
                headerName: 'Qty Match %',
              },
              {
                field: 'plan_vs_actual_allocation_size_match',
                headerName: 'Size Match %',
              },
            ],
          },
          {
            headerName: 'Suggestion vs Actual Allocation',
            children: [
              {
                field: 'suggestion_vs_actual_allocation_qty_match',
                headerName: 'Qty Match %',
              },
              {
                field: 'suggestion_vs_actual_allocation_size_match',
                headerName: 'Size Match %',
              },
            ],
          },
          {
            headerName: 'Actual Allocation vs Sales',
            children: [
              {
                field: 'actual_allocation_vs_sales_qty_match',
                headerName: 'Qty Match %',
              },
              {
                field: 'actual_allocation_vs_sales_size_match',
                headerName: 'Size Match %',
              },
              {
                field: 'actual_allocation_vs_sales_sku_depth_per_store',
                headerName: 'SKU Depth per Store',
              },
            ],
          },
          {
            headerName: 'Sales Performance',
            children: [
              {
                field: 'sales_performance_avg_grade_sell_through',
                headerName: 'Avg Grade Sell Through %',
              },
              {
                field: 'sales_performance_store_sell_through',
                headerName: 'Store Sell Through %',
              },
              {
                field: 'sales_performance_avg_grade_sales',
                headerName: 'Avg Grade Sales $',
              },
              {
                field: 'sales_performance_store_sales',
                headerName: 'Store Sales $',
              },
              {
                field: 'sales_performance_avg_grade_margin',
                headerName: 'Avg Grade Margin $',
              },
              {
                field: 'sales_performance_store_margin',
                headerName: 'Store Margin $',
              },
            ],
          },
        ],
        data: [],
      },
    ],
  },
  daily_allocation_summary: {
    orderBy: ['card', 'table'],
    dataMapping: [],
    apiCall: [
      'tb_daily_allocation_summary_store',
      'tb_daily_allocation_summary_product',
    ],
    label: 'Daily Allocation Summary',
    value: 'daily_allocation_summary',
    card: [
      {
        data: [
          { label: '# of Allocation', count: 12 },
          { label: '# of Style Allocation', count: 32 },
          { label: '# of Unit Allocation', count: 64 },
          { label: 'DC Net Available Inv', count: 120 },
          { label: 'Reserved Qty', count: 154 },
        ],
      },
    ],
    tableWithFilter: [
      {
        title: 'Store',
        key: 'tb_daily_allocation_summary_store',
        column: [
          {
            field: 'storeid',
            headerName: 'Store ID',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            pinned: 'left',
            width: '140',
          },
          {
            field: 'store_description',
            headerName: 'Store Description',
            pinned: 'left',
            width: '380',
          },
          {
            field: 'material',
            headerName: 'Material',
            pinned: 'left',
            width: '130',
          },
          {
            field: 'store_grade',
            headerName: 'Store Grade',
            width: '130',
          },
          { field: 'region', headerName: 'Region', width: '130' },
          { field: 'climate', headerName: 'Climate', width: '130' },
          {
            headerName: 'Units Allocated',
            children: [
              { field: 'total_allocation_quantity', headerName: 'Total Allocation Quantity', width: '180' },
              { field: 'mins_allocation', headerName: 'Mins Allocation', width: '180' },
              { field: 'wos_allocation', headerName: 'WOS Allocation', width: '180' },
            ],
          },
          {
            headerName: 'DC Net Avail Inv.',
            field: 'dc_net_avail_inc',
          },
          {
            headerName: 'reserved Qty.',
            field: 'reserved_qty'
          },
        ],
        data: [],
      },
      {
        title: 'Product',
        key: 'tb_daily_allocation_summary_product',
        column: [
          {
            field: 'style_id',
            headerName: 'Style ID',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            pinned: 'left',
            width: '140',
          },
          {
            field: 'style_description',
            headerName: 'Style Description',
            pinned: 'left',
            width: '320',
          },
          {
            field: 'material',
            headerName: 'Material',
            pinned: 'left',
            width: '130',
          },
          {
            field: 'department',
            headerName: 'Department',
          },
          { field: 'category', headerName: 'Category' },
          { field: 'collections', headerName: 'Collections' },
          { field: 'gender', headerName: 'Gender' },
          { field: 'plan_name', headerName: 'Plan Name' },
          {
            headerName: 'Units Allocated',
            children: [
              { field: 'total_allocation_quantity', headerName: 'Total Allocation Quantity', width: '180' },
              { field: 'mins_allocation', headerName: 'Mins Allocation', width: '180' },
              { field: 'wos_allocation', headerName: 'WOS Allocation', width: '180' },
            ],
          },
          {
            headerName: 'DC Net Avail Inv.',
            field: 'dc_net_avail_inc',
          },
          {
            headerName: 'reserved Qty.',
            field: 'reserved_qty'
          },
        ],
        data: [],
      },
    ],
  },
  forecasted_units: {
    orderBy: ['card', 'table', 'chart'],
    apiCall: [
      'tb_rpt_warehouse_stock_forecasts',
      'tb_rpt_store_stock_forecast',
    ],
    dataMapping: ['chart', 'chart'],
    label: 'Forecasted Units',
    value: 'forecasted_units',
    card: [
      {
        title: 'Forecast(%)',
        data: [
          {
            label: "Under forecasted",
            count:"20%",
          }, {
            label: "Over forecasted",
            count:"25%"
          }
        ],
      },
    ],
    table: [
      tableDataTwo,
      {
        ...tableDataThree,
        title: 'Over forecasting threshold',
        thresholdValue: 25,
      },
    ],
    chart: [
      {
        filters: {
          label: 'Warehouse stock forecasts',
          setions: [
            {
              label: 'Warehouse',
              value: [
                { label: 'WH1', value: 'WH1' },
                { label: 'WH2', value: 'WH2' },
                { label: 'WH3', value: 'WH3' },
                { label: 'WH4', value: 'WH4' },
              ],
              options: [
                { label: 'WH1', value: 'WH1' },
                { label: 'WH2', value: 'WH2' },
                { label: 'WH3', value: 'WH3' },
                { label: 'WH4', value: 'WH4' },
              ],
            },
            {
              label: 'Forecast',
              value: [
                { label: 'Outbound', value: 'Outbound' },
                { label: 'Inbound', value: 'Inbound' },
              ],
              options: [
                { label: 'Outbound', value: 'Outbound' },
                { label: 'Inbound', value: 'Inbound' },
              ],
            },
            {
              label: 'Time Period',
              value: [{ label: 'Weekly', value: 'Weekly' }],
              options: [
                { label: 'Weekly', value: 'Weekly' },
                { label: 'Daily', value: 'Daily' },
              ],
            },
          ],
          filterButtons: true,
          action: {
            name: 'Warehouse stock forecasts',
            key: 'Warehouse',
          },
        },
        type: 'linechart',
        title: 'warehouse stock forecasts',
        yaxis: {
          title: 'outbound forecast units',
        },
        xaxis: {
          categories: [
            'W1',
            'W2',
            'W3',
            'W4',
            'W5',
            'W6',
            'W7',
            'W8',
            'W9',
            'W10',
            'W11',
            'W12',
            'W13',
            'W14',
            'W15',
            'W16',
            'W17',
            'W18',
            'W19',
            'W20',
            'W21',
            'W22',
            'W23',
            'W24',
            'W25',
            'W26',
            'W27',
            'W28',
            'W29',
            'W30',
            'W31',
            'W32',
            'W33',
            'W34',
            'W35',
            'W36',
            'W37',
            'W38',
            'W39',
            'W40',
            'W41',
            'W42',
            'W43',
            'W44',
            'W45',
            'W46',
            'W47',
            'W48',
            'W49',
            'W50',
            'W51',
            'W52',
          ],
        },
        showLegend: true,
        data: [
          {
            name: 'Total Inventory',
            data: [40, 30, 50, 25, 20],
          },
          {
            name: 'ST%',
            data: [70, 60, 75, 50, 65],
          },
        ],
      },
      {
        filters: {
          label: 'Store stock forecast',
          setions: [
            {
              label: 'Store Id',
              value: [
                { label: '10', value: '10' },
                { label: '26', value: '26' },
              ],
              options: [
                { label: '10', value: '10' },
                { label: '26', value: '26' },
                { label: '30', value: '30' },
                { label: '45', value: '45' },
              ],
            },
            {
              label: 'SKU Id',
              value: [
                { label: '10608', value: '10608' },
                { label: '51951', value: '51951' },
              ],
              options: [
                { label: '10608', value: '10608' },
                { label: '51951', value: '51951' },
                { label: '66952', value: '66952' },
                { label: '67506', value: '67506' },
                { label: '67547', value: '67547' },
              ],
            },
            {
              label: 'Time',
              value: [{ label: 'Weekly', value: 'Weekly' }],
              options: [
                { label: 'Weekly', value: 'Weekly' },
                { label: 'Daily', value: 'Daily' },
              ],
            },
            {
              label: 'Stock Type',
              value: [{ label: 'Single', value: 'Single' }],
              options: [
                { label: 'Single', value: 'Single' },
                { label: 'UODS', value: 'UODS' },
              ],
            },
            {
              label: 'Secure',
              value: [{ label: 'Normal', value: 'Normal' }],
              options: [
                { label: 'Normal', value: 'Normal' },
                { label: 'Promotional', value: 'Promotional' },
                { label: 'New', value: 'New' },
              ],
            },
          ],
          filterButtons: true,
        },
        type: 'linechart',
        title: 'Store stock forecasts',
        yaxis: {
          title: 'Units',
        },
        xaxis: {
          categories: [
            'W1',
            'W2',
            'W3',
            'W4',
            'W5',
            'W6',
            'W7',
            'W8',
            'W9',
            'W10',
            'W11',
            'W12',
            'W13',
            'W14',
            'W15',
            'W16',
            'W17',
            'W18',
            'W19',
            'W20',
            'W21',
            'W22',
            'W23',
            'W24',
            'W25',
            'W26',
            'W27',
            'W28',
            'W29',
            'W30',
            'W31',
            'W32',
            'W33',
            'W34',
            'W35',
            'W36',
            'W37',
            'W38',
            'W39',
            'W40',
            'W41',
            'W42',
            'W43',
            'W44',
            'W45',
            'W46',
            'W47',
            'W48',
            'W49',
            'W50',
            'W51',
            'W52',
          ],
        },
        showLegend: true,
        data: [
          {
            name: 'Total Inventory',
            data: [40, 30, 50, 25, 20],
          },
          {
            name: 'ST%',
            data: [70, 60, 75, 50, 65],
          },
        ],
      },
    ],
  },
  future_receipts: {
    orderBy: ['table'],
    apiCall: ['tb_future_receipts', 'tb_future_receipts_supplier'],
    dataMapping: ['table', 'table'],
    label: 'Future Receipts',
    value: 'future_receipts',
    table: [
      {
        title: 'Store - Future Receipts',
        column: [
          {
            field: 'store',
            headerName: 'Store',
            // headerCheckboxSelection: false,
            // checkboxSelection: false,
            rowGroup: false,
            hide: false,
            // pinned: 'left',
            // width: 120,
          },
          {
            field: 'business_category',
            headerName: 'Business Category',
            rowGroup: false,
            //  width: 120
          },
          { field: 'dept', headerName: 'Dept', rowGroup: false },
          {
            field: 'class',
            headerName: 'Class',
            // pinned: 'left',
            // width: 455,
          },
          {
            field: 'style_description',
            headerName: 'Sku Description',
            width: 455,
          },
          {
            headerName: 'Scheduled Receipt Units',
            children: [
              {
                field: 'week_1',
                headerName: 'Week 1',
                children: [
                  {
                    headerName: 'Total',
                    field: 'week_1',
                    columnGroupShow: 'closed',
                    cellStyle: function (params) {
                      const units = {
                        normal: {
                          background: '#ffffff',
                        },
                        high: {
                          background: '#fee8bf',
                        },
                      };

                      let label = '';
                      let value = parseInt(params.data.week_1);
                      if (value > 700) {
                        label = 'high';
                      } else {
                        label = 'normal';
                      }

                      return units[`${label}`];
                    },
                  },
                  {
                    headerName: 'Day 1',
                    field: 'week_1_d_1',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 2',
                    field: 'week_1_d_2',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 3',
                    field: 'week_1_d_3',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 4',
                    field: 'week_1_d_4',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 5',
                    field: 'week_1_d_5',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 6',
                    field: 'week_1_d_6',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 7',
                    field: 'week_1_d_7',
                    columnGroupShow: 'open',
                  },
                ],
              },
              {
                field: 'week_2',
                headerName: 'Week 2',
                children: [
                  {
                    headerName: 'Total',
                    field: 'week_2',
                    columnGroupShow: 'closed',
                    cellStyle: function (params) {
                      const units = {
                        normal: {
                          background: '#ffffff',
                        },
                        high: {
                          background: '#fee8bf',
                        },
                      };

                      let label = '';
                      let value = parseInt(params.data.week_2);
                      if (value > 700) {
                        label = 'high';
                      } else {
                        label = 'normal';
                      }

                      return units[`${label}`];
                    },
                  },
                  {
                    headerName: 'Day 1',
                    field: 'week_2_d_1',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 2',
                    field: 'week_2_d_2',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 3',
                    field: 'week_2_d_3',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 4',
                    field: 'week_2_d_4',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 5',
                    field: 'week_2_d_5',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 6',
                    field: 'week_2_d_6',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 7',
                    field: 'week_2_d_7',
                    columnGroupShow: 'open',
                  },
                ],
              },
              {
                field: 'week_3',
                headerName: 'Week 3',
                children: [
                  {
                    headerName: 'Total',
                    field: 'week_3',
                    columnGroupShow: 'closed',
                    cellStyle: function (params) {
                      const units = {
                        normal: {
                          background: '#ffffff',
                        },
                        high: {
                          background: '#fee8bf',
                        },
                      };

                      let label = '';
                      let value = parseInt(params.data.week_3);
                      if (value > 700) {
                        label = 'high';
                      } else {
                        label = 'normal';
                      }

                      return units[`${label}`];
                    },
                  },
                  {
                    headerName: 'Day 1',
                    field: 'week_3_d_1',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 2',
                    field: 'week_3_d_2',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 3',
                    field: 'week_3_d_3',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 4',
                    field: 'week_3_d_4',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 5',
                    field: 'week_3_d_5',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 6',
                    field: 'week_3_d_6',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 7',
                    field: 'week_3_d_7',
                    columnGroupShow: 'open',
                  },
                ],
              },
              {
                field: 'week_4',
                headerName: 'Week 4',
                children: [
                  {
                    headerName: 'Total',
                    field: 'week_4',
                    columnGroupShow: 'closed',
                    cellStyle: function (params) {
                      const units = {
                        normal: {
                          background: '#ffffff',
                        },
                        high: {
                          background: '#fee8bf',
                        },
                      };

                      let label = '';
                      let value = parseInt(params.data.week_4);
                      if (value > 700) {
                        label = 'high';
                      } else {
                        label = 'normal';
                      }

                      return units[`${label}`];
                    },
                  },
                  {
                    headerName: 'Day 1',
                    field: 'week_4_d_1',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 2',
                    field: 'week_4_d_2',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 3',
                    field: 'week_4_d_3',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 4',
                    field: 'week_4_d_4',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 5',
                    field: 'week_4_d_5',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 6',
                    field: 'week_4_d_6',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 7',
                    field: 'week_4_d_7',
                    columnGroupShow: 'open',
                  },
                ],
              },
              {
                field: 'week_5',
                headerName: 'Week 5',
                children: [
                  {
                    headerName: 'Total',
                    field: 'week_5',
                    columnGroupShow: 'closed',
                    cellStyle: function (params) {
                      const units = {
                        normal: {
                          background: '#ffffff',
                        },
                        high: {
                          background: '#fee8bf',
                        },
                      };

                      let label = '';
                      let value = parseInt(params.data.week_5);
                      if (value > 700) {
                        label = 'high';
                      } else {
                        label = 'normal';
                      }

                      return units[`${label}`];
                    },
                  },
                  {
                    headerName: 'Day 1',
                    field: 'week_5_d_1',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 2',
                    field: 'week_5_d_2',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 3',
                    field: 'week_5_d_3',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 4',
                    field: 'week_5_d_4',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 5',
                    field: 'week_5_d_5',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 6',
                    field: 'week_5_d_6',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 7',
                    field: 'week_5_d_7',
                    columnGroupShow: 'open',
                  },
                ],
              },
              {
                field: 'week_6',
                headerName: 'Week 6',
                children: [
                  {
                    headerName: 'Total',
                    field: 'week_6',
                    columnGroupShow: 'closed',
                    cellStyle: function (params) {
                      const units = {
                        normal: {
                          background: '#ffffff',
                        },
                        high: {
                          background: '#fee8bf',
                        },
                      };

                      let label = '';
                      let value = parseInt(params.data.week_6);
                      if (value > 700) {
                        label = 'high';
                      } else {
                        label = 'normal';
                      }

                      return units[`${label}`];
                    },
                  },
                  {
                    headerName: 'Day 1',
                    field: 'week_6_d_1',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 2',
                    field: 'week_6_d_2',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 3',
                    field: 'week_6_d_3',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 4',
                    field: 'week_6_d_4',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 5',
                    field: 'week_6_d_5',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 6',
                    field: 'week_6_d_6',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 7',
                    field: 'week_6_d_7',
                    columnGroupShow: 'open',
                  },
                ],
              },
              {
                field: 'week_7',
                headerName: 'Week 7',
                children: [
                  {
                    headerName: 'Total',
                    field: 'week_7',
                    columnGroupShow: 'closed',
                    cellStyle: function (params) {
                      const units = {
                        normal: {
                          background: '#ffffff',
                        },
                        high: {
                          background: '#fee8bf',
                        },
                      };

                      let label = '';
                      let value = parseInt(params.data.week_7);
                      if (value > 700) {
                        label = 'high';
                      } else {
                        label = 'normal';
                      }

                      return units[`${label}`];
                    },
                  },
                  {
                    headerName: 'Day 1',
                    field: 'week_7_d_1',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 2',
                    field: 'week_7_d_2',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 3',
                    field: 'week_7_d_3',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 4',
                    field: 'week_7_d_4',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 5',
                    field: 'week_7_d_5',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 6',
                    field: 'week_7_d_6',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 7',
                    field: 'week_7_d_7',
                    columnGroupShow: 'open',
                  },
                ],
              },
              {
                field: 'week_8',
                headerName: 'Week 8',
                children: [
                  {
                    headerName: 'Total',
                    field: 'week_8',
                    columnGroupShow: 'closed',
                    cellStyle: function (params) {
                      const units = {
                        normal: {
                          background: '#ffffff',
                        },
                        high: {
                          background: '#fee8bf',
                        },
                      };

                      let label = '';
                      let value = parseInt(params.data.week_8);
                      if (value > 700) {
                        label = 'high';
                      } else {
                        label = 'normal';
                      }

                      return units[`${label}`];
                    },
                  },
                  {
                    headerName: 'Day 1',
                    field: 'week_8_d_1',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 2',
                    field: 'week_8_d_2',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 3',
                    field: 'week_8_d_3',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 4',
                    field: 'week_8_d_4',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 5',
                    field: 'week_8_d_5',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 6',
                    field: 'week_8_d_6',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Day 7',
                    field: 'week_8_d_7',
                    columnGroupShow: 'open',
                  },
                ],
              },
            ],
          },
          { field: 'oh_inventory', headerName: 'OH Inventory' },
          {
            field: 'forecast_next_6_weeks',
            headerName: 'Forecast for next 6 weeks',
          },
          { field: 'target_wos', headerName: 'Target WOS' },
          { field: 'current_ros', headerName: 'Current ROS' },
        ],
        data: [
          {
            storeId: 166718,
            description: 'New articles received in DC',
            week: 6,
            week_opening_balance: 20,
            weekly_allocation: 456,
            sales: 234,
            cluster_sales: 234,
            lost_sales_u: 345,
            lost_sale_usd: 245,
          },
          {
            storeId: 166715,
            description: 'Articles with Clearance status',
            week: 6,
            week_opening_balance: 20,
            weekly_allocation: 456,
            sales: 234,
            cluster_sales: 234,
            lost_sales_u: 345,
            lost_sale_usd: 245,
          },
          {
            storeId: 366182,
            description: 'Articles having excess inventory in mostly',
            week: 6,
            week_opening_balance: 20,
            weekly_allocation: 456,
            sales: 234,
            cluster_sales: 234,
            lost_sales_u: 345,
            lost_sale_usd: 245,
          },
          {
            storeId: 234718,
            description: 'Articles having inventory stockout in mostly',
            week: 6,
            week_opening_balance: 20,
            weekly_allocation: 456,
            sales: 234,
            cluster_sales: 234,
            lost_sales_u: 345,
            lost_sale_usd: 245,
          },
          {
            storeId: 233434,
            description: 'Automated allocations for manual review',
            week: 6,
            week_opening_balance: 20,
            weekly_allocation: 456,
            sales: 234,
            cluster_sales: 234,
            lost_sales_u: 345,
            lost_sale_usd: 245,
          },
          {
            storeId: 980434,
            description: 'Automated allocations for manual review in mostly',
            week: 6,
            week_opening_balance: 20,
            weekly_allocation: 456,
            sales: 234,
            cluster_sales: 234,
            lost_sales_u: 345,
            lost_sale_usd: 245,
          },
          {
            storeId: 824434,
            description: 'Automated allocations for manual review',
            week: 6,
            week_opening_balance: 20,
            weekly_allocation: 456,
            sales: 234,
            cluster_sales: 234,
            lost_sales_u: 345,
            lost_sale_usd: 245,
          },
          {
            storeId: 890034,
            description: 'Automated allocations for manual review',
            week: 6,
            week_opening_balance: 20,
            weekly_allocation: 456,
            sales: 234,
            cluster_sales: 234,
            lost_sales_u: 345,
            lost_sale_usd: 245,
          },
        ],
      },
      {
        title: 'DC - Future Receipts',
        column: [
          {
            field: 'store',
            headerName: 'DC',
            // headerCheckboxSelection: false,
            // checkboxSelection: false,
            rowGroup: false,
            hide: false,
            // pinned: 'left',
            // width: 120,
          },
          {
            field: 'business_category',
            headerName: 'Business Category',
            rowGroup: false,
            //  width: 120
          },
          { field: 'dept', headerName: 'Dept', rowGroup: false },
          {
            field: 'class',
            headerName: 'Class',
            // pinned: 'left',
            // width: 455,
          },
          {
            field: 'style_description',
            headerName: 'Sku Description',
            width: 455,
          },
          {
            headerName: 'Scheduled Receipt Units',
            children: [
              {
                field: 'may',
                headerName: 'May',
                children: [
                  {
                    headerName: 'Total',
                    field: 'may',
                    columnGroupShow: 'closed',
                    cellStyle: function (params) {
                      const units = {
                        normal: {
                          background: '#ffffff',
                        },
                        high: {
                          background: '#fee8bf',
                        },
                      };

                      let label = '';
                      let value = parseInt(params.data.may);
                      if (value > 2500) {
                        label = 'high';
                      } else {
                        label = 'normal';
                      }

                      return units[`${label}`];
                    },
                  },
                  {
                    headerName: 'Week 1',
                    field: 'may_week_1',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 2',
                    field: 'may_week_2',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 3',
                    field: 'may_week_3',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 4',
                    field: 'may_week_4',
                    columnGroupShow: 'open',
                  },
                ],
              },
              {
                field: 'jun',
                headerName: 'Jun',
                children: [
                  {
                    headerName: 'Total',
                    field: 'jun',
                    columnGroupShow: 'closed',
                    cellStyle: function (params) {
                      const units = {
                        normal: {
                          background: '#ffffff',
                        },
                        high: {
                          background: '#fee8bf',
                        },
                      };

                      let label = '';
                      let value = parseInt(params.data.jun);
                      if (value > 2500) {
                        label = 'high';
                      } else {
                        label = 'normal';
                      }

                      return units[`${label}`];
                    },
                  },
                  {
                    headerName: 'Week 1',
                    field: 'jun_week_1',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 2',
                    field: 'jun_week_2',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 3',
                    field: 'jun_week_3',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 4',
                    field: 'jun_week_4',
                    columnGroupShow: 'open',
                  },
                ],
              },
              {
                field: 'jul',
                headerName: 'Jul',
                children: [
                  {
                    headerName: 'Total',
                    field: 'jul',
                    columnGroupShow: 'closed',
                    cellStyle: function (params) {
                      const units = {
                        normal: {
                          background: '#ffffff',
                        },
                        high: {
                          background: '#fee8bf',
                        },
                      };

                      let label = '';
                      let value = parseInt(params.data.jul);
                      if (value > 2500) {
                        label = 'high';
                      } else {
                        label = 'normal';
                      }

                      return units[`${label}`];
                    },
                  },
                  {
                    headerName: 'Week 1',
                    field: 'jul_week_1',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 2',
                    field: 'jul_week_2',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 3',
                    field: 'jul_week_3',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 4',
                    field: 'jul_week_4',
                    columnGroupShow: 'open',
                  },
                ],
              },
              {
                field: 'aug',
                headerName: 'Aug',
                children: [
                  {
                    headerName: 'Total',
                    field: 'aug',
                    columnGroupShow: 'closed',
                    cellStyle: function (params) {
                      const units = {
                        normal: {
                          background: '#ffffff',
                        },
                        high: {
                          background: '#fee8bf',
                        },
                      };

                      let label = '';
                      let value = parseInt(params.data.aug);
                      if (value > 2500) {
                        label = 'high';
                      } else {
                        label = 'normal';
                      }

                      return units[`${label}`];
                    },
                  },
                  {
                    headerName: 'Week 1',
                    field: 'aug_week_1',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 2',
                    field: 'aug_week_2',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 3',
                    field: 'aug_week_3',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 4',
                    field: 'aug_week_4',
                    columnGroupShow: 'open',
                  },
                ],
              },
              {
                field: 'sept',
                headerName: 'Sept',
                children: [
                  {
                    headerName: 'Total',
                    field: 'sept',
                    columnGroupShow: 'closed',
                    cellStyle: function (params) {
                      const units = {
                        normal: {
                          background: '#ffffff',
                        },
                        high: {
                          background: '#fee8bf',
                        },
                      };

                      let label = '';
                      let value = parseInt(params.data.sept);
                      if (value > 2500) {
                        label = 'high';
                      } else {
                        label = 'normal';
                      }

                      return units[`${label}`];
                    },
                  },
                  {
                    headerName: 'Week 1',
                    field: 'sept_week_1',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 2',
                    field: 'sept_week_2',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 3',
                    field: 'sept_week_3',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 4',
                    field: 'sept_week_4',
                    columnGroupShow: 'open',
                  },
                ],
              },
              {
                field: 'oct',
                headerName: 'October',
                children: [
                  {
                    headerName: 'Total',
                    field: 'oct',
                    columnGroupShow: 'closed',
                    cellStyle: function (params) {
                      const units = {
                        normal: {
                          background: '#ffffff',
                        },
                        high: {
                          background: '#fee8bf',
                        },
                      };

                      let label = '';
                      let value = parseInt(params.data.oct);
                      if (value > 2500) {
                        label = 'high';
                      } else {
                        label = 'normal';
                      }

                      return units[`${label}`];
                    },
                  },
                  {
                    headerName: 'Week 1',
                    field: 'oct_week_1',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 2',
                    field: 'oct_week_2',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 3',
                    field: 'oct_week_3',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 4',
                    field: 'oct_week_4',
                    columnGroupShow: 'open',
                  },
                ],
              },
              {
                field: 'nov',
                headerName: 'Nov',
                children: [
                  {
                    headerName: 'Total',
                    field: 'nov',
                    columnGroupShow: 'closed',
                    cellStyle: function (params) {
                      const units = {
                        normal: {
                          background: '#ffffff',
                        },
                        high: {
                          background: '#fee8bf',
                        },
                      };

                      let label = '';
                      let value = parseInt(params.data.nov);
                      if (value > 2500) {
                        label = 'high';
                      } else {
                        label = 'normal';
                      }

                      return units[`${label}`];
                    },
                  },
                  {
                    headerName: 'Week 1',
                    field: 'nov_week_1',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 2',
                    field: 'nov_week_2',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 3',
                    field: 'nov_week_3',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 4',
                    field: 'nov_week_4',
                    columnGroupShow: 'open',
                  },
                ],
              },
              {
                field: 'dec',
                headerName: 'Dec',
                children: [
                  {
                    headerName: 'Total',
                    field: 'dec',
                    columnGroupShow: 'closed',
                    cellStyle: function (params) {
                      const units = {
                        normal: {
                          background: '#ffffff',
                        },
                        high: {
                          background: '#fee8bf',
                        },
                      };

                      let label = '';
                      let value = parseInt(params.data.dec);
                      if (value > 2500) {
                        label = 'high';
                      } else {
                        label = 'normal';
                      }

                      return units[`${label}`];
                    },
                  },
                  {
                    headerName: 'Week 1',
                    field: 'dec_week_1',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 2',
                    field: 'dec_week_2',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 3',
                    field: 'dec_week_3',
                    columnGroupShow: 'open',
                  },
                  {
                    headerName: 'Week 4',
                    field: 'dec_week_4',
                    columnGroupShow: 'open',
                  },
                ],
              },
            ],
          },
          { field: 'oh_inventory', headerName: 'OH Inventory' },
          {
            field: 'forecast_next_6_weeks',
            headerName: 'Forecast for next 6 weeks',
          },
          { field: 'target_wos', headerName: 'Target WOS' },
          { field: 'current_ros', headerName: 'Current ROS' },
        ],
        data: [
          {
            storeId: 166718,
            description: 'New articles received in DC',
            week: 6,
            week_opening_balance: 20,
            weekly_allocation: 456,
            sales: 234,
            cluster_sales: 234,
            lost_sales_u: 345,
            lost_sale_usd: 245,
          },
          {
            storeId: 166715,
            description: 'Articles with Clearance status',
            week: 6,
            week_opening_balance: 20,
            weekly_allocation: 456,
            sales: 234,
            cluster_sales: 234,
            lost_sales_u: 345,
            lost_sale_usd: 245,
          },
          {
            storeId: 366182,
            description: 'Articles having excess inventory in mostly',
            week: 6,
            week_opening_balance: 20,
            weekly_allocation: 456,
            sales: 234,
            cluster_sales: 234,
            lost_sales_u: 345,
            lost_sale_usd: 245,
          },
          {
            storeId: 234718,
            description: 'Articles having inventory stockout in mostly',
            week: 6,
            week_opening_balance: 20,
            weekly_allocation: 456,
            sales: 234,
            cluster_sales: 234,
            lost_sales_u: 345,
            lost_sale_usd: 245,
          },
          {
            storeId: 233434,
            description: 'Automated allocations for manual review',
            week: 6,
            week_opening_balance: 20,
            weekly_allocation: 456,
            sales: 234,
            cluster_sales: 234,
            lost_sales_u: 345,
            lost_sale_usd: 245,
          },
          {
            storeId: 980434,
            description: 'Automated allocations for manual review in mostly',
            week: 6,
            week_opening_balance: 20,
            weekly_allocation: 456,
            sales: 234,
            cluster_sales: 234,
            lost_sales_u: 345,
            lost_sale_usd: 245,
          },
          {
            storeId: 824434,
            description: 'Automated allocations for manual review',
            week: 6,
            week_opening_balance: 20,
            weekly_allocation: 456,
            sales: 234,
            cluster_sales: 234,
            lost_sales_u: 345,
            lost_sale_usd: 245,
          },
          {
            storeId: 890034,
            description: 'Automated allocations for manual review',
            week: 6,
            week_opening_balance: 20,
            weekly_allocation: 456,
            sales: 234,
            cluster_sales: 234,
            lost_sales_u: 345,
            lost_sale_usd: 245,
          },
        ],
      },
    ],
  },
  dIY_reports: {
    orderBy: ['chart', 'table'],
    apiCall: ['tb_dIY_reports'],
    dataMapping: ['table'],
    label: 'DIY Reports',
    value: 'dIY_reports',
    chart: [
      {
        filters: {
          label: '',
          setions: [
            {
              label: 'Select Rows/X axis',
              value: [
                { label: 'Style', value: 'Style' },
                { label: 'Store', value: 'Store' },
              ],
              options: [
                { label: 'SKU', value: 'SKU' },
                { label: 'Style color', value: 'Style color' },
                { label: 'Brand', value: 'Brand' },
                { label: 'Style', value: 'Style' },
                { label: 'Sub Class', value: 'Sub Class' },
                { label: 'Class', value: 'Class' },
                { label: 'Category', value: 'Category' },
                { label: 'Department', value: 'Department' },
                { label: 'Channel', value: 'Channel' },
                { label: 'Store', value: 'Store' },
                { label: 'Store Group', value: 'Store Group' },
                { label: 'Product Group', value: 'Product Group' },
                { label: 'Store Grade', value: 'Store Grade' },
                { label: 'DC', value: 'DC' },
              ],
            },
            {
              label: 'Metric 1',
              value: [
                { label: 'Total Inventory', value: 'Total Inventory' },
                { label: 'ST%', value: 'ST%' },
              ],
              options: [
                { label: 'IT inventory', value: 'IT inventory' },
                { label: 'OO inventory', value: 'OO inventory' },
                { label: 'Total Inventory', value: 'Total Inventory' },
                { label: '$ Sales', value: '$ Sales' },
                { label: '$ Margin', value: '$ Margin' },
                { label: '$ Total Inventory', value: '$ Total Inventory' },
                { label: 'Forecast Units', value: 'Forecast Units' },
                { label: 'ST%', value: 'ST%' },
                { label: 'Order Qty', value: 'Order Qty' },
                { label: 'Order % value', value: 'Order % value' },
              ],
            },
            {
              label: 'Metric 1 Value',
              value: [{ label: '$ Total', value: '$ Total' }],
              options: [
                { label: 'Sum', value: 'Sum' },
                { label: 'Avg', value: 'Avg' },
                { label: '$ Total', value: '$ Total' },
                { label: '% column', value: '% column' },
                { label: '% row', value: '% row' },
              ],
            },
          ],
          filterButtons: true,
          action: {
            name: 'dIY_reports',
            key: 'Metric 1',
          },
        },
        type: 'barchart',
        title: 'Total Inventory & ST%',
        yaxis: {
          title: 'Percentage %',
        },
        xaxis: {
          categories: ['320-478', '423A445', '423A229', '424-553', '118-769'],
        },
        showLegend: true,
        data: [
          {
            name: 'Total Inventory',
            data: [23, 32, 42, 21, 45],
          },
          {
            name: 'ST%',
            data: [70, 60, 75, 50, 65],
          },
        ],
        data_copy: [
          {
            name: 'Total Inventory',
            data: [40, 30, 50, 25, 20],
            data: [75, 80, 68, 75, 73],
          },
          {
            name: 'ST%',
            data: [70, 60, 75, 50, 65],
          },
          {
            name: 'IT inventory',
            data: [40, 30, 50, 25, 20],
          },
          {
            name: 'OO inventory',
            data: [70, 60, 75, 50, 65],
          },
          {
            name: '$ Sales',
            data: [40, 30, 50, 25, 20],
          },
          {
            name: '$ Margin',
            data: [70, 60, 75, 50, 65],
          },
          {
            name: '$ Total Inventory',
            data: [40, 30, 50, 25, 20],
          },
          {
            name: 'Forecast Units',
            data: [70, 60, 75, 50, 65],
          },
          {
            name: 'Order Qty',
            data: [70, 60, 75, 50, 65],
          },
          {
            name: 'Order % value',
            data: [40, 30, 50, 25, 20],
          },
        ],
      },
    ],
    table: [
      {
        title: 'Total Inventory & ST%',
        height: '750px',
        column: [
          { field: 'style', headerName: 'Style' },
          { field: 'style_description', headerName: 'Style Description' },
          { field: 'store', headerName: 'Store' },
          { field: 'store_name', headerName: 'Store Name' },
          { field: 'store_grade', headerName: 'Store Grade' },
          { field: 'total_inventory', headerName: 'Total Inventory' },
          { field: 'st', headerName: 'ST%' },
        ],
        data: [],
      },
    ],
  },
};

export const getChartData = (data, tabName) => {
  let series = [];
  switch (tabName) {
    case 'forecasted_units_0':
      console.log(
        'checking ',
        data.filter(
          (obj) =>
            obj.warehouse === 'WH-01' &&
            obj.forecast_bound === 'Inbound' &&
            obj.time_period === 'Weekly (52 weeks)'
        ),
        data
      );
      series = [
        {
          name: 'WH1',
          data: data
            .filter(
              (obj) =>
                obj.warehouse === 'WH-01' &&
                obj.forecast_bound === 'Inbound' &&
                obj.time_period === 'Weekly (52 weeks)'
            )
            .map((obj) => parseInt(obj.forecast_units)),
          color: '#00ACCB',
          type: 'line',
        },
        {
          name: 'WH2',
          data: data
            .filter(
              (obj) =>
                obj.warehouse === 'WH-02' &&
                obj.forecast_bound === 'Inbound' &&
                obj.time_period === 'Weekly (52 weeks)'
            )
            .map((obj) => parseInt(obj.forecast_units)),
          color: '#EFF4FA',
          type: 'line',
        },
        {
          name: 'WH3',
          data: data
            .filter(
              (obj) =>
                obj.warehouse === 'WH-03' &&
                obj.forecast_bound === 'Inbound' &&
                obj.time_period === 'Weekly (52 weeks)'
            )
            .map((obj) => parseInt(obj.forecast_units)),
          color: '#FC8761',
          type: 'line',
        },
        {
          name: 'WH4',
          data: data
            .filter(
              (obj) =>
                obj.warehouse === 'WH-04' &&
                obj.forecast_bound === 'Inbound' &&
                obj.time_period === 'Weekly (52 weeks)'
            )
            .map((obj) => parseInt(obj.forecast_units)),
          color: '#FFA502',
          type: 'line',
        },
      ];
      break;
    case 'forecasted_units_1':
      series = [
        {
          name: 'Store Stock',
          data: data
            .filter(
              (obj) =>
                obj.warehouse === 'WH-03' &&
                obj.forecast_bound === 'Inbound' &&
                obj.time_period === 'Weekly (52 weeks)'
            )
            .map((obj) => parseInt(obj.forecast_units)),
          color: '#FB764A',
          type: 'line',
        },
        {
          name: 'inbound forecast',
          data: data
            .filter(
              (obj) =>
                obj.warehouse === 'WH-04' &&
                obj.forecast_bound === 'Inbound' &&
                obj.time_period === 'Weekly (52 weeks)'
            )
            .map((obj) => parseInt(obj.forecast_units)),
          color: '#0055af',
          type: 'column',
        },
      ];
      break;

    default:
      break;
  }
  return series;
};
