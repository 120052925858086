// Arun Kumar - done
import * as css_constants from '../../../../constants/cssConstants';
import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Chart from '../../../../utils/charts/Chart';
import { Table } from '../../../../components/Table';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  driver_forecast_columns,
  ia_forecast,
  chartRadioData,
  tableRadioData,
  convertToDropdownValues,
  driver_contribution_columns
} from './data';
import { childTableColumn, childCostTableColumn } from './data';
import { fetchHierarchyData } from './action';
import { Card, CardContent, CardHeader } from '@mui/material';
import iAForecastTablesCol from './IAForecastTablesCol';
import costStoreTablesCol from './CostStoreTablesCol';
import childStoreTablesCol from './ChildStoreTablesCol';
import childCostStoreTablesCol from './ChildCostStoreTablesCol';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { CssClassApplier } from 'ag-grid-community';
import Select from 'react-select';
import _ from 'lodash'
import SelectRenderer from '../../../../components/Select/SelectRenderer';
import { getMultipleModelsData } from '../../services/decisionDashboardService';
export const IAForecast = (props) => {
  const dispatch = useDispatch();
  const { showChildSkuTable, showChildStoreSkuTable } = useSelector(
    (state) => state.decision
  );
  const [expanded, setExpanded] = useState(false);
  const [childTableData, setChildTableData] = useState([]);
  const [childStoreTableData, setChildStoreTableData] = useState([]);
  const [costChildTableData, setCostChildTableData] = useState([]);
  const [costChildStoreTableData, setCostChildStoreTableData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [tableData, setTableData] = useState(props.data.forecastAdj);
  const [driverExpanded, setDriverExpanded] = useState(false);
  const [driverContributionExpanded, setDriverContributionExpanded] = useState(false);
  const [channelContributionExpanded, setChannelContributionExpanded] = useState(false);
  const [chartOptions, setChartOptions] = useState({});
  const [chartRadioValue, setChartRadioValue] = useState('units');
  const [tableRadioValue, setTableRadioValue] = useState('units');
  const iaForecastTables = useMemo(() => iAForecastTablesCol());
  const childStoreCol = useMemo(() => childStoreTablesCol());
  const childCostStoreCol = useMemo(() => childCostStoreTablesCol());
  const costStoreCol = useMemo(() => costStoreTablesCol());
  const [dropdownOptions,setDropdownOptions] =useState({
    department:[],
    sku:[],
    store_id:[]
  });
  const [selectedValues,setSelectedValues] =useState({
    department:[],
    sku:[],
    store_id:[]
  });
  const [tableOnChange, setTableOnChange] = useState([])
  const [allTablesData, setAllTablesData] = useState({
    tb_drivers_contribution: [],
    tb_channel_contribution : []
  })
  const [iAForecast, setIAForecast] = useState({})
  useEffect(() => {
    setIAForecast(props.data)
  }, [])
  
  const handleTabChange = (_, newValue) => {
    setTabIndex(newValue);
  };

  const hideChildSkuTable = () => {
    dispatch({
      type: 'SHOW_CHILD_SKU_TABLE',
      payload: false,
    });
    dispatch({
      type: 'SHOW_CHILD_STORE_SKU_TABLE',
      payload: false,
    });
  };
  useEffect(() => {
    if (tabIndex === 0) hideChildSkuTable();
  }, [tabIndex]);

  const getData = async (table, name) => {
    const data = await fetchHierarchyData(table);
    if (data && name === 'child_sku') setChildTableData(data);
    if (data && name === 'child_store_sku') setChildStoreTableData(data);
    if (data && name === 'cost_child_sku') setCostChildTableData(data);
    if (data && name === 'cost_child_store_sku')
      setCostChildStoreTableData(data);
    if (data && name === 'tb_ia_forecast_multiplier_on_change')
      setTableOnChange(data)
  };

  useEffect(async() => {
    getData('tb_child_sku_store', 'child_sku');
    getData('tb_child_sku_child_store', 'child_store_sku');
    getData('tb_cost_child_sku_store', 'cost_child_sku');
    getData('tb_cost_child_sku_child_store', 'cost_child_store_sku');
    getData('tb_ia_forecast_multiplier_on_change','tb_ia_forecast_multiplier_on_change')
    const result = await getMultipleModelsData({
      model_names: ["tb_drivers_contribution","tb_channel_contribution"]
    })
    setAllTablesData({
      ...result
    })

  }, []);

  const updateChartData = (chartData) => {
    let categories = _.uniq(chartData.map((obj) => obj.week));
     let newData = _.groupBy(chartData,'week')
     let lyData = []
     let forecastData = []
    let adjForecast = []
    let merchPlan = []
    let amazon = []
    let salesOrder = []
    let seasonality = []

     Object.keys(newData).forEach(obj=>{
      //  console.log("=======",newData[`${obj}`].map((obj) => obj.ly))
        let ly = _.sum(newData[`${obj}`].map((obj) => parseFloat(obj.ly)))
        let forecast = _.sum(newData[`${obj}`].map((obj) => parseFloat(obj.forecast)))
       let adj = _.sum(newData[`${obj}`].map((obj) => parseFloat(obj.unconstrained_forecast)))
       let merch = _.sum(newData[`${obj}`].map((obj) => parseFloat(obj.merch_plan)))
       let amzn = _.sum(newData[`${obj}`].map((obj) => parseFloat(obj.amazon)))
       let sales = _.sum(newData[`${obj}`].map((obj) => parseFloat(obj.sales_order)))
       let season = _.sum(newData[`${obj}`].map((obj) => parseFloat(obj.seasonality)))
       
        lyData.push(ly)
        forecastData.push(forecast)
       adjForecast.push(adj)
       merchPlan.push(merch)
       amazon.push(amzn)
       salesOrder.push(sales)
       seasonality.push(season)

     })
    //  console.log("========",lyData,forecastData,adjForecast)
      let data = [
        {
          name: 'LY',
          // data: chartData.map((obj) => parseInt(obj.ly)),
          data:lyData,
          type: 'spline',
        },
        {
          name: 'Forecast',
          // data: chartData.map((obj) => parseInt(obj.forecast)),
          data:forecastData,
          type: 'spline',
        },
        {
          name: 'Adj. Forecast',
          data:adjForecast,
          // data: chartData.map((obj) =>
          //   parseInt(obj.unconstrained_forecast)
          // ),
          type: 'spline',
        },
        {
          name: 'Merch Plan',
          data: merchPlan,
          type: 'spline',

        },
        {
          name: 'Amazon',
          data: amazon,
          type: 'spline',

        },
        {
          name: 'Sales Order',
          data: salesOrder,
          type: 'spline',

        },
        {
          name: 'Seasonality',
          data: seasonality,
          type: 'spline',

        }
      ];
      const options = {
        chartType: 'linechart',
        yaxis: {
          title: chartRadioValue === 'units' ? 'Sales Units' : 'Sales Cost ($)',
        },
        xaxis: { categories },
        showLegend: true,
        data: data,
        // plotOptionEnable: true,
        // Symbol:"circle"
      };
      setChartOptions(options);
  }
  useEffect(() => {
    const metric =
      chartRadioValue === 'units' ? 'forecastChart' : 'forecastCostChart';
    if (props.data[metric].length > 0) {
      updateChartData(props.data[metric])
      let departmentValues = _.uniq(props.data.forecastChart.map(obj=> obj.department))
      let skuValues = _.uniq(props.data.forecastChart.map(obj=> obj.sku))
      let storeIdValues = _.uniq(props.data.forecastChart.map(obj=> obj.store_id))
      setDropdownOptions({
        department: convertToDropdownValues(departmentValues),
        sku: convertToDropdownValues(skuValues),
        store_id: convertToDropdownValues(storeIdValues)
      })
      let cloneData = {...selectedValues}
      cloneData[`department`] = convertToDropdownValues([departmentValues[0]]);
      cloneData[`store_id`] = convertToDropdownValues([storeIdValues[0]]);
      cloneData[`sku`] = convertToDropdownValues([skuValues[0]]);
      setSelectedValues({
        ...cloneData
      })
    }
    setTableData(props.data.forecastAdj);
  }, [props.data, chartRadioValue]);

  const handleChartRadioChange = (event, newValue) => {
    setChartRadioValue(newValue);
  };

  const handleTableRadioChange = (event, newValue) => {
    setTableRadioValue(newValue);
  };
  const handleDropdownchange = (type,params) => {
    console.log(params)
    let cloneData = {...selectedValues}
    cloneData[`${type}`] = params.selectedItems;
    setSelectedValues({
      ...cloneData
    })
    let metric = chartRadioValue === 'units' ? 'forecastChartCopy' : 'forecastCostChartCopy';
    let chartData =[]
    let optionsObj = {...dropdownOptions}
    switch (type) {
      case "department":
        chartData = props.data[metric].filter(obj=> params.selectedItems.map(selectedObj=>selectedObj.value ).indexOf(obj.department) !==-1)
        optionsObj[`sku`] = convertToDropdownValues(_.uniq(chartData.map(obj=> obj.sku)));
        optionsObj[`store_id`] = convertToDropdownValues(_.uniq(chartData.map(obj=> obj.store_id)));
        setDropdownOptions({
          ...optionsObj
        })
        updateChartData(chartData)
        break;

      case "sku":
        chartData = props.data[metric].filter(obj=> params.selectedItems.map(selectedObj=>selectedObj.value ).indexOf(obj.sku) !==-1)
        optionsObj[`department`] = convertToDropdownValues(_.uniq(chartData.map(obj=> obj.department)));
        optionsObj[`store_id`] = convertToDropdownValues(_.uniq(chartData.map(obj=> obj.store_id)));
        setDropdownOptions({
          ...optionsObj
        })
        updateChartData(chartData)
        break;
      case "store_id":
          chartData = props.data[metric].filter(obj=> params.selectedItems.map(selectedObj=>selectedObj.value ).indexOf(obj.store_id) !==-1)
          optionsObj[`department`] = convertToDropdownValues(_.uniq(chartData.map(obj=> obj.department)));
          optionsObj[`sku`] = convertToDropdownValues(_.uniq(chartData.map(obj=> obj.sku)));
        setDropdownOptions({
          ...optionsObj
        })
          updateChartData(chartData)
          break;
    
      default:
        break;
    }
    // setTableRadioValue(newValue);
  };
	const cellValueChanged = async (params) => {
    const orgTableData = [...tableData]
    const colId = params.column.colId
    // if(updatedObj.length > 0){
      orgTableData.filter(tableObj=> {
        const updatedObj = tableOnChange.filter(obj=> obj.percentage === params.newValue && obj.level === tableObj.drivers  )
        if(updatedObj.length > 0){
          if( ['Adj. Forecast','Forecast','Multiplier'].indexOf(tableObj.drivers) !==-1){
            tableObj[`${colId}`] = updatedObj[0][`${colId}`]
          }
        }
        
        return tableObj;
      })
    // }
    setTableData([...orgTableData])

	};
  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs:
          tableRadioValue == 'units'
            ? childTableColumn.column
            : childCostTableColumn.column,
        defaultColDef: {
          flex: 1,
        },
        rowData:
          tableRadioValue === 'units'
            ? childStoreTableData
            : costChildStoreTableData,
      },
    };
  }, [tableRadioValue, tabIndex]);

  const forecastMultiplierCellChange = async(params)=>{
    const orgTableData = [...tableData]
    const colId = params.column.colId
      orgTableData.filter(tableObj=> {
        const updatedObj = orgTableData.filter(obj=> obj.drivers === "Multiplier" )
        if(updatedObj.length > 0){
          if( ['Adj. Forecast','Retail','Oulet','eCom'].indexOf(tableObj.drivers) !==-1){
            let updatedValue = tableObj[`${colId}`]?.replaceAll(",", "")
            tableObj[`${colId}`] = parseInt(parseInt(updatedValue) * parseFloat(updatedObj[0][`${colId}`]))
          }
        }
        
        return tableObj;
      })
    setTableData([...orgTableData])
  }
  return (
    <>
      <Accordion
        expanded={driverExpanded}
        onChange={() => setDriverExpanded(!driverExpanded)}
        className={
          css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_ACCORDION_CLASS
        }
        style={{
          borderLeft: '2px solid rgb(0, 85, 175)',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          className={
            css_constants.IMPACT_CSS_CLASS +
            css_constants.IMPACT_ACCORDION_SUMMARY_CLASS
          }
          id='panel1bh-header'
        >
          <Typography
            sx={{ width: '33%', flexShrink: 0 }}
            className={
              css_constants.IMPACT_CSS_CLASS +
              css_constants.IMPACT_TYPOGRAPHY_CLASS
            }
          >
            Drivers of Forecast
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className={
            css_constants.IMPACT_CSS_CLASS +
            css_constants.IMPACT_ACCORDIONDETAILS_CLASS
          }
        >
          <div className='spacer'></div>
          <Table
            rowData={props.data.driver_forecast}
            columnDefs={driver_forecast_columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            floatingFilter={false}
            height={'200px'}
            rowSelection={'multiple'}
            columnFit={'sizeColumnsToFit'}
            tableCssStyle='sizeColumnsToFit'
            className={css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_CLASS }
            onCellValueChanged={cellValueChanged}
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            // enableBrowserTooltips={true}
          />
        </AccordionDetails>
      </Accordion>
      <div className={'spacer' + css_constants.IMPACT_CSS_CLASS}></div>
      <Accordion
        expanded={driverContributionExpanded}
        onChange={() => setDriverContributionExpanded(!driverContributionExpanded)}
        className={
          css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_ACCORDION_CLASS
        }
        style={{
          borderLeft: '2px solid rgb(0, 85, 175)',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          className={
            css_constants.IMPACT_CSS_CLASS +
            css_constants.IMPACT_ACCORDION_SUMMARY_CLASS
          }
          id='panel1bh-header'
        >
          <Typography
            sx={{ width: '33%', flexShrink: 0 }}
            className={
              css_constants.IMPACT_CSS_CLASS +
              css_constants.IMPACT_TYPOGRAPHY_CLASS
            }
          >
            Drivers Contribution
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className={
            css_constants.IMPACT_CSS_CLASS +
            css_constants.IMPACT_ACCORDIONDETAILS_CLASS
          }
        >
          <div className='spacer'></div>
          <Table
            rowData={allTablesData.tb_drivers_contribution}
            columnDefs={driver_contribution_columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            floatingFilter={false}
            height={'200px'}
            rowSelection={'multiple'}
            columnFit={'sizeColumnsToFit'}
            tableCssStyle='sizeColumnsToFit'
            className={css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_CLASS }
            onCellValueChanged={cellValueChanged}
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            // enableBrowserTooltips={true}
          />
        </AccordionDetails>
      </Accordion>
      <div className={'spacer' + css_constants.IMPACT_CSS_CLASS}></div>
      <Accordion
        expanded={channelContributionExpanded}
        onChange={() => setChannelContributionExpanded(!channelContributionExpanded)}
        className={
          css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_ACCORDION_CLASS
        }
        style={{
          borderLeft: '2px solid rgb(0, 85, 175)',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          className={
            css_constants.IMPACT_CSS_CLASS +
            css_constants.IMPACT_ACCORDION_SUMMARY_CLASS
          }
          id='panel1bh-header'
        >
          <Typography
            sx={{ width: '33%', flexShrink: 0 }}
            className={
              css_constants.IMPACT_CSS_CLASS +
              css_constants.IMPACT_TYPOGRAPHY_CLASS
            }
          >
            Channel Contribution
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className={
            css_constants.IMPACT_CSS_CLASS +
            css_constants.IMPACT_ACCORDIONDETAILS_CLASS
          }
        >
          <div className='spacer'></div>
          <Table
            rowData={allTablesData.tb_channel_contribution}
            columnDefs={driver_contribution_columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            floatingFilter={false}
            height={'200px'}
            rowSelection={'multiple'}
            columnFit={'sizeColumnsToFit'}
            tableCssStyle='sizeColumnsToFit'
            className={css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_CLASS }
            onCellValueChanged={cellValueChanged}
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            // enableBrowserTooltips={true}
          />
        </AccordionDetails>
      </Accordion>
      <div className={'spacer' + css_constants.IMPACT_CSS_CLASS}></div>
      <Accordion
        className={
          css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_ACCORDION_CLASS
        }
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        style={{
          borderLeft: '2px solid rgb(0, 85, 175)',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          className={
            css_constants.IMPACT_CSS_CLASS +
            css_constants.IMPACT_ACCORDION_SUMMARY_CLASS
          }
        >
          <Typography
            sx={{ width: '33%', flexShrink: 0 }}
            className={
              css_constants.IMPACT_CSS_CLASS +
              css_constants.IMPACT_TYPOGRAPHY_CLASS
            }
          >
            {iaForecastTables.accordion.label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className={
            css_constants.IMPACT_CSS_CLASS +
            css_constants.IMPACT_ACCORDIONDETAILS_CLASS
          }
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label='wrapped label tabs example'
              className={
                css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TAB_CLASS
              }
            >
              {iaForecastTables.tabs.map((item, index) => (
                <Tab
                  value={index}
                  label={item.label}
                  style={{
                    textTransform: 'none',
                  }}
                />
              ))}
            </Tabs>
            {/* {tabIndex === 1 && (
              <FormControl
                className={
                  css_constants.IMPACT_CSS_CLASS +
                  css_constants.IMPACT_FORMCONTROL_CLASS
                }
              >
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='row-radio-buttons-group'
                  onChange={handleTableRadioChange}
                  value={tableRadioValue}
                  className={
                    css_constants.IMPACT_CSS_CLASS +
                    css_constants.IMPACT_RADIOGROUP_CLASS
                  }
                >
                  {tableRadioData?.map((item) => (
                    <FormControlLabel
                      value={item.value}
                      control={<Radio />}
                      label={item.label}
                      className={
                        css_constants.IMPACT_CSS_CLASS +
                        css_constants.IMPACT_FORMCONTROLLABEL_CLASS
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )} */}
          </div>
          <div className='spacer' />
          <Table
            rowData={
              tabIndex === 0
                ? tableData
                : tableRadioValue === 'units'
                ? props.data.view_hierarchy
                : props.data.view_cost_hierarchy
            }
            columnDefs={
              tableRadioValue === 'units'
                ? iaForecastTables?.tabs[tabIndex]?.column
                : costStoreCol
            }
            pageSize={5}
            rowsPerPageOptions={[5]}
            floatingFilter={false}
            height={'300px'}
            rowSelection={'multiple'}
            columnFit={'sizeColumnsToFit'}
            tableCssStyle='sizeColumnsToFit'
            className={
              css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_CLASS
            }
            onCellValueChanged={forecastMultiplierCellChange}
          />

          {showChildSkuTable && (
            <>
              <Grid
                container
                className={
                  css_constants.IMPACT_CSS_CLASS +
                  css_constants.IMPACT_GRID_CSS_CLASS
                }
              >
                <Typography
                  sx={{ flexShrink: 0, marginTop: 5, marginBottom: 2 }}
                  className={
                    css_constants.IMPACT_CSS_CLASS +
                    css_constants.IMPACT_TYPOGRAPHY_CLASS
                  }
                >
                  {childTableColumn.label}
                </Typography>
                <Button
                  style={{
                    textTransform: 'none',
                    marginTop: 20,
                    marginLeft: 30,
                  }}
                  onClick={hideChildSkuTable}
                  autoFocus
                  variant='text'
                  className={
                    css_constants.IMPACT_CSS_CLASS +
                    css_constants.IMPACT_BUTTON_CSS_CLASS
                  }
                >
                  {`Hide ${childTableColumn.label}`}
                </Button>
              </Grid>
              <Table
                rowData={
                  tableRadioValue === 'units'
                    ? childTableData
                    : costChildTableData
                }
                columnDefs={
                  tableRadioValue === 'units'
                    ? childStoreCol
                    : childCostStoreCol
                }
                pageSize={5}
                rowsPerPageOptions={[5]}
                floatingFilter={false}
                height={'auto'}
                rowSelection={'multiple'}
                columnFit={'sizeColumnsToFit'}
                tableCssStyle='sizeColumnsToFit'
                className={
                  css_constants.IMPACT_CSS_CLASS +
                  css_constants.IMPACT_TABLE_CLASS
                }
                domLayout='autoHeight'
                detailCellRendererParams={detailCellRendererParams}
              />
            </>
          )}
          {/* {showChildStoreSkuTable && (
            <Grid
              container
              className={
                css_constants.IMPACT_CSS_CLASS +
                css_constants.IMPACT_GRID_CSS_CLASS
              }
            >
              <Table
                rowData={
                  tableRadioValue === 'units'
                    ? childStoreTableData
                    : costChildStoreTableData
                }
                columnDefs={
                  tableRadioValue === 'units'
                    ? childTableColumn.column
                    : childCostTableColumn.column
                }
                pageSize={5}
                rowsPerPageOptions={[5]}
                floatingFilter={false}
                height={'300px'}
                rowSelection={'multiple'}
                columnFit={'sizeColumnsToFit'}
                tableCssStyle='sizeColumnsToFit'
                className={
                  css_constants.IMPACT_CSS_CLASS +
                  css_constants.IMPACT_TABLE_CLASS
                }
              />
            </Grid>
          )} */}
        </AccordionDetails>
      </Accordion>
      <div className={'spacer' + css_constants.IMPACT_CSS_CLASS}></div>
      <div className={'spacer' + css_constants.IMPACT_CSS_CLASS}></div>
      <Card
        className={
          css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_CARD_CSS_CLASS
        }
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader
            title='Visualization'
            className={
              css_constants.IMPACT_CSS_CLASS +
              css_constants.IMPACT_CARDHEADER_CLASS
            }
          />
          {/* <FormControl
            className={
              css_constants.IMPACT_CSS_CLASS +
              css_constants.IMPACT_FORMCONTROL_CLASS
            }
          >
            <RadioGroup
              row
              aria-labelledby='demo-row-radio-buttons-group-label'
              name='row-radio-buttons-group'
              onChange={handleChartRadioChange}
              value={chartRadioValue}
              className={
                css_constants.IMPACT_CSS_CLASS +
                css_constants.IMPACT_RADIOGROUP_CLASS
              }
            >
              {chartRadioData?.map((item) => (
                <FormControlLabel
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                  className={
                    css_constants.IMPACT_CSS_CLASS +
                    css_constants.IMPACT_FORMCONTROLLABEL_CLASS
                  }
                />
              ))}
            </RadioGroup>
          </FormControl> */}
        </div>
        <CardContent
          className={
            css_constants.IMPACT_CSS_CLASS +
            css_constants.IMPACT_CARDCONTENT_CLASS
          }
        >
          <Grid
                  container
                  columnSpacing={{ md:2,xl:2,lg:2 }}
                >
                  <Grid item md={2} lg={2} xl={2}>
                    <SelectRenderer
                      options={dropdownOptions.department}
                      selectedItems={selectedValues.department}
                      // isMandatory={false}
                      updateSelected={(params)=>{
                        handleDropdownchange('department',params)
                      }}
                      isMulti={true}
                      placeholder={"Select Department"}
                      filterLabel={"Department"}
                      
                    />
                  </Grid>
                  <Grid item md={2} lg={2} xl={2}>
                    <SelectRenderer
                      options={dropdownOptions.sku}
                      selectedItems={selectedValues.sku}
                      // isMandatory={false}
                      updateSelected={(params)=>{
                        handleDropdownchange('sku',params)
                      }}
                      isMulti={true}
                      placeholder={"Select SKU #"}
                      filterLabel={"SKU #"}
                      
                    />
                  </Grid>
                  <Grid item md={2} lg={2} xl={2}>
                  <SelectRenderer
                      options={dropdownOptions.store_id}
                      selectedItems={selectedValues.store_id}
                      // isMandatory={false}
                      updateSelected={(params)=>{
                        handleDropdownchange('store_id',params)
                      }}
                      isMulti={true}
                      placeholder={"Select Store Id"}
                      filterLabel={"Store Id"}
                      
                    />
                  </Grid>
          </Grid>
          <div className="spacer"></div>
          {iaForecastTables.chart && Object.keys(chartOptions).length > 0 && (
            <Chart
              {...chartOptions}
              className={
                css_constants.IMPACT_CSS_CLASS +
                css_constants.IMPACT_CHART_CLASS
              }
              // chartType={ia_forecast.chart.type}
              // title={ia_forecast.chart.title}
              // yaxis={ia_forecast.chart.yaxis}
              // xaxis={{categories: chartOptions.categories}}
              // showLegend={ia_forecast.chart.showLegend}
              // data={chartOptions.data}
              // legend={ia_forecast.chart.legend}
              // pointStart={ia_forecast.chart.pointStart}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
};
