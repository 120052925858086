import React, { useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Table } from '../../../../components/Table';
import CustomCard from '../../../../components/CustomCard';
import getDCReviewColumns from '../Decision/DCReview';
import { fetchHierarchyData } from './action';
import LoadingOverlay from '../../../../components/LoadingOverlay';
const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  headerCard: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
});

const useStyles = makeStyles(styles);

const AllocationAlertReviewRecommendation = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const columns = useMemo(() =>
    getDCReviewColumns()
  );
  const navigate = useNavigate();
  const getData = async () => {
    const data = await fetchHierarchyData('tb_allocation_dc');
    if (data) setTableData(data);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  return (
    <>
      <LoadingOverlay loader={isLoading}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomCard cardStyles={classes.headerCard}>
              <div className='spacer'></div>
              {!isLoading && (
                <>
                  <Typography variant='h6'>Review Recommendation</Typography>
                  <Table
                    rowData={tableData}
                    columnDefs={columns}
                    floatingFilter={false}
                    height={'450px'}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    rowSelection={'multiple'}
                  />
                </>
              )}
            </CustomCard>
          </Grid>
        </Grid>
        <div className='spacer'></div>
        <div
          className={classes.saveButton}
          style={{ padding: 10, display: 'flex', justifyContent: 'right' }}
        >
          <Button
            style={{ textTransform: 'none', marginRight: "1rem" }}
            onClick={() => navigate('/inventory-smart/decision-dashboard')}
            autoFocus
            variant='outlined'
          >
            Back
          </Button>
          <Button
            style={{ textTransform: 'none' }}
            autoFocus
            variant='contained'
          >
            Release
          </Button>
        </div>
        <div className='spacer'></div>
      </LoadingOverlay>
    </>
  );
};

export default AllocationAlertReviewRecommendation;
