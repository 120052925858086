import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';

export default function ListComponent({ data }) {
  return (
    <List
      dense
      sx={{ width: '100%', maxWidth: 460, bgcolor: 'background.paper' }}
    >
      {data?.map((item) => (
        <ListItem
          key={item}
          secondaryAction={
            <Typography
              sx={{ fontSize: 14, color: 'blue' }}
              color='text.secondary'
              gutterBottom
            >
              {item.count}
            </Typography>
          }
          disablePadding
        >
          <ListItemButton>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              {item.name}
            </Typography>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
