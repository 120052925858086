import { FILTERS_LIST , OPEN_LOADER, CLOSE_LOADER, MASTER_CATEGORY_DATA } from "../actions/executiveSummaryActions";

const initialState = {
  filterMenu: [],
  masterCategoryData:[]
};

  const   executiveSummaryReducer = (state=initialState, action) => {
    switch (action.type) {
        case FILTERS_LIST:
          return {
            ...state,
            filterMenu: action.payload,
          };
        case MASTER_CATEGORY_DATA:
            return {
              ...state,
              masterCategoryData: action.payload,
            };
        case OPEN_LOADER:
          return {
            ...state,
            loader: action.payload,
          };
        case CLOSE_LOADER:
          return {
            ...state,
            loader: action.payload,
          };
      default:
        return state;
    }
};

export default executiveSummaryReducer
