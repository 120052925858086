export const tabListData = {
  apiKey: ['tb_create_new_order'],
  filters: true,
  table: [
    {
      title: 'Create new order',
      key: 'tb_create_new_order',
      column: [
        {
          field: 'styel_color_Id',
          headerName: 'Style Color ID',
          headerCheckboxSelection: true,
          checkboxSelection: true,
          pinned: 'left',
        },
        {
          field: 'style_id',
          headerName: 'Style ID',
          pinned: 'left',
        },
        { field: 'style_description', headerName: 'Style Description' },
        { field: 'department', headerName: 'Department' },
        { field: 'class', headerName: 'Class' },
        { field: 'product_grading', headerName: 'Product Grading' },
        { field: 'asset_vs_memo', headerName: 'Asset vs Memo' },
        { field: 'location', headerName: 'Location' },
        { field: 'vendor', headerName: 'Vendor' },
        { field: 'store_inc', headerName: 'Store Inc.' },
        { field: 'dc_inc', headerName: 'DC Inc.' },
        { field: 'system_inc', headerName: 'System Inc.' },
        { field: 'open_receipts', headerName: 'Open Receipts' },
        { field: 'order_placement_date', headerName: 'Order Placement Date' },
        { field: 'order_qty', headerName: 'Order Qty.' },
        { field: 'landed_cost', headerName: 'Landed Cost' },
        { field: 'order_cost', headerName: 'Order Cost' },
        { field: 'lead_time_days', headerName: 'Lead Time (days)' },
        { field: 'wos', headerName: 'WOS' },
        { field: 'safety_stock', headerName: 'Safety Stock' },
        { field: 'service_level', headerName: 'Service Level' },
      ],
      data: [],
    },
  ],
  bottomActionButton: [
    {
      name: 'Send for Approval',
      varient: 'contained',
      size: 'medium',
      navigation: '/inventory-smart/order-repository',
    },
    {
      name: 'Review Forecast',
      varient: 'contained',
      size: 'medium',
      navigation: '/inventory-smart/ada_visuals',
    },
  ],
  topRightActionButton: [
    {
      name: 'Deep dive',
      varient: 'contained',
      size: 'medium',
      navigation: '/inventory-smart/deep-dive',
    },
  ],
};
