import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import Chart from '../../../../utils/charts/Chart';
import { Table } from '../../../../components/Table';
import CustomCard from '../../../../components/CustomCard';
import FilterSection from './FilterSection';
import { getMultipleModelsData } from './action';
import FiltersList from '../../../../molecules/FiltersList';
import ListComponent from './List';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import { tabListData } from './data';

const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  headerCard: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
});

const useStyles = makeStyles(styles);

const Report = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [curentObject, setCurentObject] = useState({});
  const [curentObjectData, setCurentObjectData] = useState({});
  const [loader, setLoader] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const { selectedRows } = useSelector((state) => state.decision);
  console.log('selectedRows', selectedRows);
  const getData = async (key) => {
    const data = await getMultipleModelsData({
      model_names: key,
      selectedFilters,
    });
    if (Object.keys(curentObject).length > 0 && data) {
      setCurentObjectData(data);
      console.log('data', data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setCurentObject(tabListData);
  }, []);

  useEffect(() => {
    getData(tabListData?.apiKey);
  }, [curentObject]);

  const reloadFilters = async () => {
    setSelectedFilters({});
  };

  const openLoader = () => {
    setLoader(true);
  };
  const closeLoader = () => {
    setLoader(false);
  };
  const updateState = (params) => {
    setSelectedFilters(params);
  };
  const appliedFiltersAction = async (params) => {
    console.log(selectedFilters);
  };

  const onReset = () => {
    reloadFilters();
  };

  return (
    <LoadingOverlay loader={loader}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {curentObject?.filters && (
            <FiltersList
              openLoader={openLoader}
              closeLoader={closeLoader}
              updateParentState={updateState}
              appliedFilters={appliedFiltersAction}
              onReset={onReset}
              screenName={'Filter Products'}
            />
          )}
          <div className='impact_grid_sub_container'>
            <CustomCard cardStyles={classes.headerCard}>
              <div className='spacer'></div>
              <Grid item xs={12}>
                {isLoading && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress className={classes.loader} />
                  </div>
                )}
                {!isLoading && (
                  <>
                    {curentObject?.card && (
                      <>
                        <Typography gutterBottom variant='h5'>
                          {curentObject?.card.title}
                        </Typography>
                        <Grid container xs={12}>
                          {curentObjectData[curentObject?.card.key]?.map(
                            (item) => (
                              <Card
                                sx={{
                                  minWidth: 350,
                                  margin: 3,
                                  display: 'flex',
                                }}
                              >
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                    }}
                                  >
                                    <Inventory2OutlinedIcon color={'gray'} />
                                    <Typography
                                      sx={{ fontSize: 16, marginLeft: 2 }}
                                      color='text.secondary'
                                      gutterBottom
                                    >
                                      {item.label}
                                    </Typography>
                                  </div>
                                  <ListComponent
                                    data={[
                                      { name: item.l1, count: item.c1 },
                                      { name: item.l2, count: item.c2 },
                                      { name: item.l3, count: item.c3 },
                                    ]}
                                  />
                                </CardContent>
                              </Card>
                            )
                          )}
                        </Grid>
                      </>
                    )}

                    {curentObject?.chart && (
                      <>
                        {curentObject?.chart?.filters && (
                          <FilterSection filters={curentObject.chart.filters} />
                        )}
                        <Chart
                          chartType={curentObject.chart.type}
                          title={curentObject.chart.title}
                          yaxis={curentObject.chart.yaxis}
                          xaxis={curentObject.chart.xaxis}
                          showLegend={curentObject.chart.showLegend}
                          data={curentObject.chart.data}
                        />
                        <div className='spacer'></div>
                      </>
                    )}

                    {Object.keys(curentObjectData).length > 0 &&
                      curentObject?.table.map((item) => (
                        <>
                          <div className='spacer'></div>

                          <div
                            style={{
                              margin: 10,
                            }}
                          >
                            <Typography
                              variant='h6'
                              style={{ justifyContent: 'left' }}
                            >
                              {item.title}
                            </Typography>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'right',
                                paddingRight: '15px',
                                marginBottom: '5px',
                              }}
                            >
                              {Object.keys(curentObjectData).length > 0 &&
                                curentObject?.topRightActionButton && (
                                  <div>
                                    {curentObject?.topRightActionButton?.map(
                                      (itm) => (
                                        <Button
                                          style={{
                                            textTransform: 'none',
                                            margin: 5,
                                          }}
                                          variant={itm.varient}
                                          size={itm.size}
                                          onClick={() =>
                                            navigate(itm.navigation)
                                          }
                                        >
                                          {itm.name}
                                        </Button>
                                      )
                                    )}
                                  </div>
                                )}
                            </div>

                            <div>
                              {item?.actionButton?.map((itm) => (
                                <Button
                                  style={{ textTransform: 'none', margin: 5 }}
                                  variant={itm.varient}
                                  size={itm.size}
                                >
                                  {itm.name}
                                </Button>
                              ))}
                            </div>
                          </div>
                          <Table
                            rowData={
                              selectedRows != null
                                ? selectedRows
                                : curentObjectData[item.key]
                            }
                            columnDefs={item.column}
                            floatingFilter={false}
                            height={'450px'}
                            rowSelection={'multiple'}
                          />
                          <div className='spacer'></div>
                        </>
                      ))}
                    {Object.keys(curentObjectData).length > 0 &&
                      curentObject?.bottomActionButton && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'right',
                            paddingRight: '15px',
                            marginBottom: '5px',
                          }}
                        >
                          {curentObject?.bottomActionButton?.map((itm) => (
                            <Button
                              style={{ textTransform: 'none', margin: 5 }}
                              variant={itm.varient}
                              size={itm.size}
                              onClick={() => navigate(itm.navigation)}
                            >
                              {itm.name}
                            </Button>
                          ))}
                        </div>
                      )}
                    <div className='spacer'></div>
                  </>
                )}
              </Grid>
            </CustomCard>
          </div>
        </Grid>
      </Grid>
    </LoadingOverlay>
  );
};

export default Report;
