import { propsToClassKey } from '@mui/styles';
import { Link } from 'react-router-dom';
export const FORECAST = 'Forecast';
export const ALLOCATION = 'Allocation';
export const REPLENISHMENT = 'Replenishment';
export const ORDER = 'Order';
export const SKU_LEVEL_SUMMARY = 'Sku Level Summary';
export const REPL_ALERT_COL = 'REPL_ALERT_COL';

export const ALLOCATION_VISUALISATION_INVENTORY_OVERVIEW =
  'ALLOCATION_INVENTORY_OVERVIEW';
export const REPLENISHMENT_VISUALISATION_INVENTORY_OVERVIEW =
  'REPLENISHMENT_INVENTORY_OVERVIEW';
export const ORDER_INVENTORY_OVERVIEW = 'ORDER_INVENTORY_OVERVIEW';

export const VISUALISATION = 'Visualisation';
export const KPI = 'KPIs';
export const ALERTS = 'Alerts';
export const DETAILS = 'Details';

export const tabsData = [
  {
    label: 'Forecast',
    id: 'Forecast',
  },
  {
    label: 'Allocation',
    id: 'Allocation',
  },
  {
    label: 'Replenishment',
    id: 'Replenishment',
  },
  {
    label: 'Order',
    id: 'Order',
  },
];

export const accordionData = [
  {
    label: 'Visualisation',
    id: 'Visualisation',
    isExpanded: 'Visualisation',
  },
  {
    label: 'KPIs',
    id: 'KPIs',
  },
  {
    label: 'Alerts',
    id: 'Alerts',
  },
  {
    label: 'Details',
    id: 'Details',
  },
];

export const alert_columns1 = [
  { field: 'alert', headerName: 'Alert', minWidth: 350 },
  { field: 'SKU_count', headerName: 'SKU Count' },
  { field: '90_days_demand', headerName: '90 Day Demand' },
  { field: '90_days_forecast', headerName: '90 Day Forecast' },
  { field: '180_days_forecast', headerName: '180 Day Forecast' },
  { field: '365_days_forecast', headerName: '365 Day Forecast' },
  { field: 'description', headerName: 'Description', minWidth: 350 },
  {
    field: 'action',
    headerName: 'Action',
    width: 140,
    cellRendererFramework: (cell) => (
      <Link className='table_url' to={`/inventory-smart/forecast-alert-review`}>
        Review Alert
      </Link>
    ),
  },
];
export const alert_columns2 = [
  { field: 'alert', headerName: 'Alert' },
  { field: 'actual_sales_last_month', headerName: 'Actual Sales Last Month' },
  { field: 'actual', headerName: 'Actual (Comparable Period )' },
  { field: 'forecasted_uints', headerName: 'Forecasted Units' },
  { field: 'description', headerName: 'Description' },
  {
    field: 'action',
    headerName: 'Action',
    width: 140,
    cellRendererFramework: (cell) => (
      <Link className='table_url' to={`/inventory-smart/forecast-alert-review`}>
        Review Alert
      </Link>
    ),
  },
];

export const alert_data2 = [
  {
    alert: 'SKU With Limited Data',
    actual_sales_last_month: 32000,
    actual_comaprable_period: 10000,
    forecasted_units: 15000,
    description: 'SKU With Limited Data',
  },
];
export const clickAction = {};

export const replenishment_alert = [
  { field: 'action_item', headerName: 'Action Item', minWidth: 320 },
  { field: 'description', headerName: 'Description', minWidth: 320 },
  { field: '#style_color', headerName: '# Style Color' },
  { field: 'bulk_remaining', headerName: 'DC OH U' },
  {
    field: 'action',
    headerName: 'Actions',
    cellRendererFramework: (cell) => {
      const { data } = cell;
      let action = null;
      if (data.hasOwnProperty('#action_type') && data['#action_type']) {
        action = data['#action_type'];
      }
      switch (action) {
        case 'click':
          return (
            <span
              className='link-style'
              onClick={() => {
                clickAction['replenishment_alert_action']();
              }}
            >
              Review Recommendation
            </span>
          );
        default: {
          let path = 'replenishment-alert';
          if (data.hasOwnProperty('#path') && data['#path']) {
            path = data['#path'];
          }
          return (
            <Link
              className='table_url'
              to={`/inventory-smart/${path}`}
              state={data}
            >
              Review Recommendation
            </Link>
          );
        }
      }
    },
  },
];
export const replenishment_alert_review = [
  { field: 'sku_id', headerName: 'SKU ID' },
  { field: 'sku_description', headerName: 'SKU Description'},
  { field: 'department', headerName: 'Department' },
  { field: 'class', headerName: 'Class' },
  { field: 'subclass', headerName: 'Subclass' },
  { field: 'product_grade', headerName: 'Product Grade' },
  { field: 'asset_vs_memo', headerName: 'Asset vs Memo' },
  { field: 'primary_warehouse', headerName: 'Primary Warehouse' },
  { field: 'store_inventory', headerName: 'Store Inventory' },
  { field: 'dc_inventory', headerName: 'DC Inventory' },
  { field: 'mrpc', headerName: 'MRPC' },
  { field: 'total_inventory', headerName: 'Total Inventory' },
  { field: 'open_receipts', headerName: 'Open Receipts' },
  { field: 'safety_stock', headerName: 'Safety Stock' },
  { field: 'recom_receipt_date', headerName: 'Recom Receipt Date' },
  { field: 'po_count', headerName: 'PO Count' },
  { field: 'order_quantity', headerName: 'Order Quantity' },
  {
    field: 'action',
    headerName: 'Action',
    cellRendererFramework: (cell) => {
      const { data } = cell;
      let action = null;
      if (data.hasOwnProperty('#action_type') && data['#action_type']) {
        action = data['#action_type'];
      }
      switch (action) {
        case 'click':
          return (
            <span
              className='link-style'
              onClick={() => clickAction['replenishment_alert_child_action']()}
            >
              Review
            </span>
          );
        default: {
          let path = 'replenishment-alert';
          if (data.hasOwnProperty('#path') && data['#path']) {
            path = data['#path'];
          }
          return (
            <Link
              className='table_url'
              to={`/inventory-smart/${path}`}
              state={data}
            >
              Review
            </Link>
          );
        }
      }
    },
  },
];
export const replenishment_alert_modal = [
  { field: 'po_code', headerName: 'PO Code'},
  { field: 'sku_id', headerName: 'SKU ID'},
  { field: 'po_creation_date', headerName: 'PO Creation Date' },
  { field: 'channel', headerName: 'Channel' },
  { field: 'store_banner', headerName: 'Store Banner' },
  { field: 'store_type', headerName: 'Store Type' },
  { field: 'primary_warehouse', headerName: 'Primary Warehouse' },
  { field: 'quantity_ordered', headerName: 'Quantity Ordered' },
  { field: 'quantity_received', headerName: 'Quantity Received' },
  { field: 'open_receipts', headerName: 'Open Receipts' },
  { field: 'dc_not_before_date', headerName: 'DC Not Before Date' },
  { field: 'dc_not_after_date', headerName: 'DC Not After Date' }
];
export const detailsTabData = [
  {
    label: '30/90/180 Unit Trend',
    id: '30/90/180 unit trends',
    key: 1,
  },
  {
    label: 'Forecast Demand',
    id: 'Forcasted demand $',
    key: 2,
  },
  {
    label: 'Lost Sales',
    id: 'Lost sales',
    key: 3,
  },
  // {
  //   label: 'Forecast Unit',
  //   id: 'Forcasted uint',
  // },
  {
    label: '4 Week Error',
    id: '4 week error ',
    key: 4,
  },
  {
    label: '8 Week Error',
    id: '8 week error',
    key: 5,
  },
];

export const tabForecastData = [
  { value: 'Quarter', label: 'Quarter' },
  { value: 'Month', label: 'Month' },
  { value: 'Week', label: 'Week' },
];

export const forecast_details_data = {
  1: {
    store: [
      {
        store: 'Newcastle Jesmond St Georges Terrace',
        account_value: '2,432',
        diff_per: '+37%',
      },
      { store: 'Nottingham Beeston', account_value: '3,290', diff_per: '+32%' },
      {
        store: 'Woodbridge Thoroughfare',
        account_value: '893',
        diff_per: '+29%',
      },
      {
        store: 'Opticians - Sheffield Crystal Peaks',
        account_value: '4,589',
        diff_per: '+27%',
      },
      {
        store: 'Witney Edington Road',
        account_value: '12,201',
        diff_per: '+24%',
      },
    ],
    product: [
      {
        product: 'Protect Wellness Vitamin C 1000 mg & Rosehip 30 Tablets',
        account_value: 432,
        diff_per: '+73%',
      },
      {
        product: 'Sambucol Liquid Kids 120 ml',
        account_value: '1,203',
        diff_per: '+51%',
      },
      {
        product: 'Boots Vegan Omega 3 Oil 60 Capsules',
        account_value: 456,
        diff_per: '+44%',
      },
      {
        product: 'Sambucol Liquid Extract Immuno Forte Formula - 120 ml',
        account_value: 321,
        diff_per: '+37%',
      },
      {
        product: 'Healthspan Opti-Turmeric - 30 Capsules',
        account_value: 288,
        diff_per: '+33%',
      },
    ],
  },
  2: {
    store: [
      {
        store: 'Newcastle Jesmond St Georges Terrace',
        account_value: '10,450',
        diff_per: 156750,
      },
      { store: 'Nottingham Beeston', account_value: '8,900', diff_per: 133500 },
      {
        store: 'Woodbridge Thoroughfare',
        account_value: 7322,
        diff_per: 109830,
      },
      {
        store: 'Opticians - Sheffield Crystal Peaks',
        account_value: '5,400',
        diff_per: 81000,
      },
      {
        store: 'Witney Edington Road',
        account_value: '4,301',
        diff_per: 64515,
      },
    ],
    product: [
      {
        product: 'Protect Wellness Vitamin C 1000 mg & Rosehip 30 Tablets',
        account_value: 2731,
        diff_per: 40965,
      },
      {
        product: 'Sambucol Liquid Kids 120 ml',
        account_value: '2,354',
        diff_per: 18832,
      },
      {
        product: 'Boots Vegan Omega 3 Oil 60 Capsules',
        account_value: 2104,
        diff_per: 10520,
      },
      {
        product: 'Sambucol Liquid Extract Immuno Forte Formula - 120 ml',
        account_value: 1943,
        diff_per: 23316,
      },
      {
        product: 'Healthspan Opti-Turmeric - 30 Capsules',
        account_value: 1721,
        diff_per: 17210,
      },
    ],
  },
  3: {
    store: [
      {
        store: 'Newcastle Jesmond St Georges Terrace',
        account_value: '2,345',
        diff_per: 35175,
      },
      { store: 'Nottingham Beeston', account_value: '3,203', diff_per: 48045 },
      { store: 'Woodbridge Thoroughfare', account_value: 788, diff_per: 11820 },
      {
        store: 'Opticians - Sheffield Crystal Peaks',
        account_value: 321,
        diff_per: 4815,
      },
      { store: 'Witney Edington Road', account_value: 123, diff_per: 1845 },
    ],
    product: [
      {
        product: 'Protect Wellness Vitamin C 1000 mg & Rosehip 30 Tablets',
        account_value: 455,
        diff_per: 6825,
      },
      {
        product: 'Sambucol Liquid Kids 120 ml',
        account_value: 322,
        diff_per: 2576,
      },
      {
        product: 'Boots Vegan Omega 3 Oil 60 Capsules',
        account_value: 211,
        diff_per: 1055,
      },
      {
        product: 'Sambucol Liquid Extract Immuno Forte Formula - 120 ml',
        account_value: 165,
        diff_per: 1980,
      },
      {
        product: 'Healthspan Opti-Turmeric - 30 Capsules',
        account_value: 1721,
        diff_per: 17210,
      },
    ],
  },
  4: {
    store: [
      {
        store: 'Newcastle Jesmond St Georges Terrace',
        account_value: 322,
        diff_per: '45%',
      },
      { store: 'Nottingham Beeston', account_value: 218, diff_per: '43%' },
      { store: 'Woodbridge Thoroughfare', account_value: 211, diff_per: '32%' },
      {
        store: 'Opticians - Sheffield Crystal Peaks',
        account_value: 109,
        diff_per: '31%',
      },
      { store: 'Witney Edington Road', account_value: 78, diff_per: '29%' },
    ],
    product: [
      {
        product: 'Protect Wellness Vitamin C 1000 mg & Rosehip 30 Tablets',
        account_value: 225,
        diff_per: '31%',
      },
      {
        product: 'Sambucol Liquid Kids 120 ml',
        account_value: 153,
        diff_per: '29%',
      },
      {
        product: 'Boots Vegan Omega 3 Oil 60 Capsules',
        account_value: 148,
        diff_per: '22%',
      },
      {
        product: 'Sambucol Liquid Extract Immuno Forte Formula - 120 ml',
        account_value: 76,
        diff_per: '21%',
      },
      {
        product: 'Healthspan Opti-Turmeric - 30 Capsules',
        account_value: 55,
        diff_per: '20%',
      },
    ],
  },
  5: {
    store: [
      {
        store: 'Newcastle Jesmond St Georges Terrace',
        account_value: 483,
        diff_per: '36%',
      },
      { store: 'Nottingham Beeston', account_value: 327, diff_per: '34%' },
      { store: 'Woodbridge Thoroughfare', account_value: 317, diff_per: '26%' },
      {
        store: 'Opticians - Sheffield Crystal Peaks',
        account_value: 164,
        diff_per: '25%',
      },
      { store: 'Witney Edington Road', account_value: 117, diff_per: '23%' },
    ],
    product: [
      {
        product: 'Protect Wellness Vitamin C 1000 mg & Rosehip 30 Tablets',
        account_value: 383.18,
        diff_per: '24%',
      },
      {
        product: 'Sambucol Liquid Kids 120 ml',
        account_value: 259.42,
        diff_per: '23%',
      },
      {
        product: 'Boots Vegan Omega 3 Oil 60 Capsules',
        account_value: 251.09,
        diff_per: '17%',
      },
      {
        product: 'Sambucol Liquid Extract Immuno Forte Formula - 120 ml',
        account_value: 129.71,
        diff_per: '17%',
      },
      {
        product: 'Healthspan Opti-Turmeric - 30 Capsules',
        account_value: 92.82,
        diff_per: '16%',
      },
    ],
  },
};

export const radioData = [
  { value: '30_days', label: '30 days' },
  { value: '90_days', label: '90 days' },
  { value: '180_days', label: '180 days' },
];

const top5Accounts_columns = [
  { field: 'store', headerName: 'Store Name' },
  { field: 'account_value', headerName: 'Units forecast' },
  { field: 'diff_per', headerName: 'Var% (over LY)' },
];
export const top5Accounts_data = [
  { store: 'Store 1', account_value: 6222000, diff_per: '5%' },
];
const top5Products_columns = [
  { field: 'product', headerName: 'Product Name' },
  { field: 'account_value', headerName: 'Units forecast' },
  { field: 'diff_per', headerName: 'Var% (over LY)' },
];

export const forecast_details_column = {
  1: {
    store: [...top5Accounts_columns],
    product: [...top5Products_columns],
  },
  2: {
    store: [
      { field: 'store', headerName: 'Store Name' },
      { field: 'account_value', headerName: 'Units forecast' },
      { field: 'diff_per', headerName: '$ value' },
    ],
    product: [
      { field: 'product', headerName: 'Product Name' },
      { field: 'account_value', headerName: 'Units forecast' },
      { field: 'diff_per', headerName: '$ value' },
    ],
  },
  3: {
    store: [
      { field: 'store', headerName: 'Store Name' },
      { field: 'account_value', headerName: 'Lost sales - units' },
      { field: 'diff_per', headerName: 'Lost sales - $ value' },
    ],
    product: [
      { field: 'product', headerName: 'Product Name' },
      { field: 'account_value', headerName: 'Lost sales - units' },
      { field: 'diff_per', headerName: 'Lost sales - $ value' },
    ],
  },
  4: {
    store: [
      { field: 'store', headerName: 'Store Name' },
      { field: 'account_value', headerName: 'Forecast error - units' },
      { field: 'diff_per', headerName: 'WAPE' },
    ],
    product: [
      { field: 'product', headerName: 'Product Name' },
      { field: 'account_value', headerName: 'Forecast error - units' },
      { field: 'diff_per', headerName: 'WAPE' },
    ],
  },
  5: {
    store: [
      { field: 'store', headerName: 'Store Name' },
      { field: 'account_value', headerName: 'Lost sales - units' },
      { field: 'diff_per', headerName: 'WAPE' },
    ],
    product: [
      { field: 'product', headerName: 'Product Name' },
      { field: 'account_value', headerName: 'Lost sales - units' },
      { field: 'diff_per', headerName: 'WAPE' },
    ],
  },
};

export const top5Products_data = [
  { product: 'Product 1', value: 6222000, diff: '5%' },
];

export const getChartOptions = (chartObj) => {
  let series = [];
  let customSeries = [];
  switch (chartObj.parentLabel) {
    case FORECAST:
      series = [
        {
          name: 'Management Forecast',
          data: chartObj.data.map((obj) => parseInt(obj.management_forecast)),
          color: '#102542',
        },
        {
          name: 'Customer Forecast',
          data: chartObj.data.map((obj) => parseInt(obj.customer_forecast)),
          color: '#913B80',
        },
        {
          name: 'IA Forecast',
          data: chartObj.data.map((obj) => parseInt(obj.ia_forecast)),
          color: '#B2D360',
        },
        {
          name: 'Outside Forecast',
          data: chartObj.data.map((obj) => parseInt(obj.outside_forecast)),
          color: '#0055AF',
        },
      ];
      break;
    case ALLOCATION_VISUALISATION_INVENTORY_OVERVIEW:
      series = [
        {
          name: 'Safety Stock',
          data: chartObj.data.map((obj) => parseInt(obj.safety_stock)),
          color: '#745F5E',
          type: 'line',
        },
        {
          name: 'Final Minimum Quantity',
          data: chartObj.data.map((obj) =>
            parseInt(obj.final_minimum_quantity)
          ),
          color: '#B2D360',
          type: 'line',
        },
        {
          name: 'Final Maximum Quantity',
          data: chartObj.data.map((obj) =>
            parseInt(obj.final_maximum_quantity)
          ),
          color: '#E8AC87',
          type: 'line',
        },
        {
          name: 'On Order',
          data: chartObj.data.map((obj) => parseInt(obj.on_order)),
          color: '#102542',
          type: 'column',
        },
        {
          name: 'Planned Replenishment by Due Date',
          data: chartObj.data.map((obj) =>
            parseInt(obj.planned_replenishment_by_due_date)
          ),
          color: '#963564',
          type: 'column',
        },
        {
          name: 'Begining Inventory Position',
          data: chartObj.data.map((obj) =>
            parseInt(obj.begining_inventory_position)
          ),
          color: '#0055AF',
          type: 'line',
        },
        {
          name: 'Projected Available Balance',
          data: chartObj.data.map((obj) =>
            parseInt(obj.projected_available_balance)
          ),
          color: '#90AEEE',
          type: 'line',
        },
      ];
      break;
    case ALLOCATION:
    case REPLENISHMENT:
    case ORDER:
      series = [
        // {
        //   name: 'Actual(Small)',
        //   data: chartObj.data.map((obj) => parseInt(obj.actualSmall)),
        //   //[4056, 3867, 5467, 7565, 6345],
        //   stack: 1,
        //   color: '#CCE5FE',
        // },
        // {
        //   name: 'Actual(Medium)',
        //   data: chartObj.data.map((obj) => parseInt(obj.actualMedium)),
        //   //[656, 1867, 3167, 4565, 5345],
        //   color: '#3496FE',
        //   stack: 1,
        // },
        // {
        //   name: 'Actual(Large)',
        //   data: chartObj.data.map((obj) => parseInt(obj.actualLarge)),
        //   //[3156, 4867, 5267, 7565, 9345],
        //   color: '#0055AF',
        //   stack: 1,
        // },
        {
          name: 'Actual',
          data: chartObj.data.map((obj) => parseInt(obj.actual)),
          color: '#3496FE',
          stack: 1,
        },
        {
          name: 'Target/Forecast',
          data: chartObj.data.map((obj) => parseInt(obj.target_forecast)),
          color: '#1D1D1D',
          stack: 2,
        },
        // {
        //   name: 'Target/Forecast(Small)',
        //   data: chartObj.data.map((obj) => parseInt(obj.targetSmall)),
        //   //[4056, 2867, 3467, 4565, 9345],
        //   color: '#BFBFBF',
        //   stack: 2,
        // },
        // {
        //   name: 'Target/Forecast(Medium)',
        //   data: chartObj.data.map((obj) => parseInt(obj.targetMedium)),
        //   //[4456, 5867, 6467, 7565, 8345],
        //   color: '#8C8C8C',
        //   stack: 2,
        // },
        // {
        //   name: 'Target/Forecast(Large)',
        //   data: chartObj.data.map((obj) => parseInt(obj.targetLarge)),
        //   //[3056, 4867, 5467, 6565, 9345],
        //   color: '#1D1D1D',
        //   stack: 2,
        // },
      ];
      customSeries = [
        {
          name: 'Actual',
          data: chartObj.data.map((obj) => parseFloat(obj.actual)),
          color: '#3496FE',
          stack: 1,
        },
      ];
      break;
    case REPL_ALERT_COL:
    case SKU_LEVEL_SUMMARY:
      series = [
        {
          name: 'Forecast',
          data: chartObj.data.map((obj) => parseInt(obj.forecast)),
          color: '#ffa502',
          type: 'line',
        },
        {
          name: 'LY',
          data: chartObj.data.map((obj) => parseInt(obj.ly)),
          color: '#acacac',
          type: 'line',
        },
        {
          name: 'Receipt Date',
          data: chartObj.data.map((obj) => parseInt(obj.receipt_date)),
          color: '#fb764a',
          type: 'column',
        },
        {
          name: 'ROQ Units (days of stock)',
          data: chartObj.data.map((obj) => parseInt(obj.roq)),
          color: '#00accb',
          type: 'column',
        },
        {
          name: 'OH Units (days of stock)',
          data: chartObj.data.map((obj) => parseInt(obj.oh_units)),
          color: '#3b85ee',
          type: 'column',
        },
      ];
      break;

    default:
      break;
  }
  return {
    chartType: chartObj.type || 'barchart',
    title: chartObj.title,
    stacking: chartObj.stacking,
    yaxis: {
      title: chartObj.yAxisTitle,
    },
    xaxis: { categories: chartObj.categories },
    showLegend: chartObj.showLegend || true,
    data:
      chartObj.selectedOption === 'Lost sales%' ||
      chartObj.selectedOption === 'Excess Inv($)'
        ? customSeries
        : series,
  };
};

export const forecast_visualisation_options = {
  chartType: 'barchart',
  chart: {
    type: 'column',
  },
  title: {
    text: 'Forecast Visualisation',
  },
  xAxis: {
    categories: [
      'Week 1',
      'Week 2',
      'Week 3',
      'Week 4',
      'Week 5',
      'Week 6',
      'Week 7',
      'Week 8',
      'Week 9',
      'Week 10',
      'Week 11',
      'Week 12',
    ],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Units',
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
    footerFormat: '</table>',
    shared: true,
    useHTML: true,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  series: [],
};

export const alloation_visualisation_inventory_by_locaiton_options = {
  chartType: 'barchart',
  chart: {
    type: 'column',
  },
  title: {
    text: 'Inventory By Location Grading',
  },
  subtitle: {
    //   text: 'Source: WorldClimate.com'
  },
  xAxis: {
    categories: ['L1', 'L2', 'L3', 'L4', 'L5'],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    max: 100,
    title: {
      text: 'Units',
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
    footerFormat: '</table>',
    shared: true,
    useHTML: true,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  series: [
    {
      name: 'Actual',
      data: [49.9, 71.5, 106.4, 129.2, 144.0],
      color: '#0055af',
    },
    {
      name: 'Target Forecasted',
      data: [83.6, 78.8, 98.5, 93.4, 106.0],
      color: '#ffa402',
    },
  ],
};

export const alloation_visualisation_inventory_by_product_options = {
  chartType: 'barchart',
  chart: {
    type: 'column',
  },
  title: {
    text: 'Inventory By Product Grading',
  },
  subtitle: {
    //   text: 'Source: WorldClimate.com'
  },
  xAxis: {
    categories: ['A+', 'A', 'B', 'C', 'D'],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Units',
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
    footerFormat: '</table>',
    shared: true,
    useHTML: true,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  series: [
    {
      name: 'Actual',
      data: [49.9, 71.5, 106.4, 129.2, 144.0],
      color: '#0055af',
    },
    {
      name: 'Target Forecasted',
      data: [83.6, 78.8, 98.5, 93.4, 106.0],
      color: '#ffa402',
    },
  ],
};

export const allocation_alert_columns = [
  { field: 'action_item', headerName: 'Action Item' },
  { field: 'description', headerName: 'Description' },
  { field: 'style_color', headerName: '# Style Color' },
  { field: 'bulk_remaining', headerName: 'Bulk Remaining' },
  { field: 'action', headerName: 'Actions' },
];

export const allocation_alert_data = [
  {
    action_item: 'Allocation Required - New in DC',
    style_color: 10,
    bulik_remaining: 10000,
    action: 'Review Recommendation',
    description: 'New Articles Received in DC',
  },
];

export const allocation_details_columns = [
  {
    field: 'product_type',
    headerName: 'Product Type',
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  { field: 'styel_color_Id', headerName: 'Style Color ID' },
  { field: 'style_id', headerName: 'Style Id' },
  { field: 'color_id', headerName: 'Color ID' },
  { field: 'assortment_indicator', headerName: 'Assortment Indicator' },
  { field: 'store_group', headerName: 'Store Group' },
  { field: 'introduction_date', headerName: 'Introduction Date' },
  { field: 'last_allocated', headerName: 'Last Allocatedr' },
];

export const allocation_details_data = [
  {
    product_type: 'Inseason',
    styel_color_Id: 'C1344BLK',
    style_id: 'IME75',
    color_id: 'Black',
    assortment_indicator: '100 Intro',
    store_group: '1001 All Doors',
    introduction_date: '06/30/2022',
    last_allocated: '-',
  },
];

export const productLabels = [
  {
    id: '',
    label: 'Alerts',
    value: '',
  },
  {
    id: 'alerts_overall',
    label: 'Overall',
    value: '450',
  },
  {
    id: 'alerts_resolved',
    label: 'Resolved',
    value: '220',
  },
  {
    id: 'alerts_pending',
    label: 'Pending',
    value: '230',
  },
];

export const forecast_kpi_data = [
  {
    label: 'Sales Units (Last Week)',
    forecast: 49501020,
    other_value: 1020,
    percentage: 2,
    adj_forecast: 49501020,
    adj_other_value: 1020,
    adj_percentage: -2,
  },
  {
    label: 'Sales Units (Last 4 Weeks )',
    forecast: 49501020,
    other_value: 1020,
    percentage: 2,
    adj_forecast: 49501020,
    adj_other_value: 1020,
    adj_percentage: -2,
  },
  {
    label: 'Sales Units (Last 8 weeks )',
    forecast: 49501020,
    other_value: 1020,
    percentage: 2,
    adj_forecast: 49501020,
    adj_other_value: 1020,
    adj_percentage: -2,
  },
];
export const allocation_kpi_data = [
  {
    label: 'In Stock %',
    cost: 40240,
    first_label: 'In Stock %',
    first_value: 23,
    second_label: '#POs in DC',
    second_value: 23,
    third_label: 'Open POs',
    third_value: 30,
    other_value: 1020,
  },
  {
    label: 'MRPC',
    cost: 40240,
    other_value: 1020,
  },
  {
    label: 'PO Units',
    cost: 40240,
    other_value: 1020,
  },
  {
    label: 'DC OH Units',
    cost: 40240,
    other_value: 1020,
  },
  {
    label: 'Open Units',
    cost: 40240,
    other_value: 1020,
  },
];
export const order_kpi_data = [
  {
    label: 'Demand LM',
    cost: 40240,
    first_label: 'Demand LQ',
    second_label: 'Demand LY',
    first_value: 63000,
    second_value: 252000,
  },
  {
    label: 'Forecast NM',
    cost: 40240,
    first_label: 'Forecast NQ',
    second_label: 'Forecast NY',
    first_value: 63000,
    second_value: 252000,
  },
  {
    label: 'Service Lvl Store',
    cost: 40240,
    first_label: "Service Level DC's",
    second_label: 'Network Service Level',
    first_value: 63000,
    second_value: 252000,
  },
  {
    label: 'OH U Stores',
    cost: 40240,
    first_label: "OH U DC's",
    second_label: 'Network OH U',
    first_value: 63000,
    second_value: 252000,
  },
  {
    label: 'ROQ',
    cost: 40240,
    first_label: 'ROQ LQ',
    second_label: 'ROQ LY',
    first_value: 63000,
    second_value: 252000,
  },
];

export const order_details_columns = [
  {
    field: 'product_type',
    headerName: 'Product Type',
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  { field: 'style_id', headerName: 'Style Id' },
  {
    field: 'style_description',
    headerName: 'Style Description',
    minWidth: 320,
  },
  { field: 'styel_color_Id', headerName: 'Style Color ID' },
  { field: 'style_color', headerName: 'Style Color' },
  { field: 'color_id', headerName: 'Color ID' },
  {
    field: 'similar_style_id',
    headerName: 'Similar Style Id',
    cellRendererFramework: (cell) => (
      <Link to={`/inventory-smart/allocation-details-review`}>Map</Link>
    ),
  },
  { field: 'assortment_indicator', headerName: 'Assortment Indicator' },
  { field: 'store_group', headerName: 'Store Group' },
  { field: 'introduction_date', headerName: 'Introduction Date' },
  { field: 'last_allocated', headerName: 'Last Allocated' },
  { field: 'factory_type', headerName: 'Factoy Type' },
  {
    field: 'filters',
    headerName: 'Filters',
    children: [
      { field: 'category', headerName: 'Category' },
      { field: 'department', headerName: 'Department' },
      { field: 'class', headerName: 'Class' },
      { field: 'sub_class_collection', headerName: 'Sub Class Collection' },
    ],
  },
  {
    field: 'sales_performance',
    headerName: 'Sales Performance',
    children: [
      { field: 'lw_revenue', headerName: 'LW Revenue' },
      { field: 'lw_margin', headerName: 'LW Margin' },
      { field: 'promo', headerName: 'Promo %' },
      { field: 'price_point', headerName: 'Price Point' },
    ],
  },
  {
    field: 'inventory',
    headerName: 'Inventory',
    children: [
      { field: 'bulk_remaining', headerName: 'DC OH U' },
      { field: 'on_hand', headerName: 'On Hand' },
      { field: 'on_order', headerName: 'On Order' },
      { field: 'in_transit', headerName: 'In Transit' },
      { field: 'oh+oo+it', headerName: 'OH+OO+IT' },
      { field: 'wos', headerName: 'WOS' },
      { field: 'size_integrity', headerName: 'Size Integrity% (OH) ' },
    ],
  },
  {
    field: 'store_count',
    headerName: 'Store Count',
    children: [
      { field: 'stock_out', headerName: 'Stock out' },
      { field: 'shortfall', headerName: 'Shortfall' },
      { field: 'normal', headerName: 'Normal' },
      { field: 'excess', headerName: 'Excess' },
    ],
  },
];

export const forecast_alert_columns = [
  {
    field: 'style_id',
    headerName: 'Style ID',
    pinned: 'left',
    width: 160,
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  {
    field: 'description',
    headerName: 'Description',
    pinned: 'left',
    width: 450,
  },
  {
    field: 'product_grade',
    headerName: 'Product Grade',
    width: 170,
    pinned: 'left',
  },
  {
    field: 'available_sales_history_months',
    headerName: 'Available sales history (months)',
    width: 300,
  },
  {
    field: 'data_availability',
    headerName: 'Data availability',
    width: 170,
    cellStyle: function (params) {
      const data_availablity = {
        low: {
          background: '#f6c6c8',
        },
        medium: {
          background: '#fee8bf',
        },
        high: {
          background: '#c7e7d0',
        },
      };

      let label = '';
      if (params.data.data_availability === 'Low') {
        label = 'low';
      } else if (params.data.data_availability === 'Medium') {
        label = 'medium';
      } else if (params.data.data_availability === 'High') {
        label = 'high';
      }
      return data_availablity[`${label}`];
    },
  },
  {
    field: 'confidence_level',
    headerName: 'Confidence level',
    width: 170,
    cellStyle: function (params) {
      const confidence_level = {
        low: {
          background: '#f6c6c8',
        },
        medium: {
          background: '#fee8bf',
        },
        high: {
          background: '#c7e7d0',
        },
      };

      let label = '';
      if (params.data.confidence_level === 'Low') {
        label = 'low';
      } else if (params.data.confidence_level === 'Medium') {
        label = 'medium';
      } else if (params.data.confidence_level === 'High') {
        label = 'high';
      }

      return confidence_level[`${label}`];
    },
  },
  { field: '30D_inventory', headerName: 'Last 30D inventory', width: 170 },
  { field: '30D_forecast', headerName: '30D forecast', width: 170 },
  { field: 'last_30D_sales', headerName: 'Last 30D sales', width: 170 },
  {
    field: 'thirty_var_perc',
    headerName: '30D var% LC',
    width: 170,
    cellStyle: function (params) {
      const percentage = {
        low: {
          background: '#f6c6c8',
        },
        medium: {
          background: '#fee8bf',
        },
        high: {
          background: '#c7e7d0',
        },
      };

      let label = '';
      let value = parseInt(params.data.thirty_var_perc);
      if (value > -10 && value < 10) {
        label = 'high';
      } else if (value > -30 && value < 30) {
        label = 'medium';
      } else {
        label = 'low';
      }

      return percentage[`${label}`];
    },
  },
  { field: '60D_inventory', headerName: 'Last 60D inventory', width: 170 },
  { field: '60D_forecast', headerName: '60D forecast', width: 170 },
  { field: 'last_60D_sales', headerName: 'Last 60D sales', width: 170 },
  {
    field: 'sixty_var_perc',
    headerName: '60D var% LC',
    width: 170,
    cellStyle: function (params) {
      const percentage = {
        low: {
          background: '#f6c6c8',
        },
        medium: {
          background: '#fee8bf',
        },
        high: {
          background: '#c7e7d0',
        },
      };

      let label = '';
      let value = parseInt(params.data.sixty_var_perc);
      if (value > -10 && value < 10) {
        label = 'high';
      } else if (value > -30 && value < 30) {
        label = 'medium';
      } else {
        label = 'low';
      }

      return percentage[`${label}`];
    },
  },
  { field: '90D_inventory', headerName: 'Last 90D inventory', width: 170 },
  { field: '90D_forecast', headerName: '90D forecast', width: 170 },
  { field: 'last_90D_sales', headerName: 'Last 90D sales', width: 170 },
  {
    field: 'ninty_var_perc',
    headerName: '90D var% LC',
    width: 170,
    cellStyle: function (params) {
      const percentage = {
        low: {
          background: '#f6c6c8',
        },
        medium: {
          background: '#fee8bf',
        },
        high: {
          background: '#c7e7d0',
        },
      };

      let label = '';
      let value = parseInt(params.data.ninty_var_perc);
      if (value > -10 && value < 10) {
        label = 'high';
      } else if (value > -30 && value < 30) {
        label = 'medium';
      } else {
        label = 'low';
      }

      return percentage[`${label}`];
    },
  },
  { field: '120D_forecast', headerName: '120D forecast', width: 170 },
  { field: 'last_120D_sales', headerName: 'Last 120D sales', width: 170 },
  {
    field: 'onetwenty_var_perc',
    headerName: '120D var% LC',
    width: 170,
    cellStyle: function (params) {
      const percentage = {
        low: {
          background: '#f6c6c8',
        },
        medium: {
          background: '#fee8bf',
        },
        high: {
          background: '#c7e7d0',
        },
      };

      let label = '';
      let value = parseInt(params.data.onetwenty_var_perc);
      if (value > -10 && value < 10) {
        label = 'high';
      } else if (value > -30 && value < 30) {
        label = 'medium';
      } else {
        label = 'low';
      }

      return percentage[`${label}`];
    },
  },
];

export const sku_ids = [{ label: 'All', value: 0 }];

export const alert_metrics = [
  { label: 'All', value: 0 },
  { label: 'Vendor', value: 1 },
  { label: 'Sales Average', value: 2 },
  { label: 'Forecast', value: 3 },
  { label: '% Sales Contributing', value: 4 },
  { label: 'Forecast Confidence', value: 5 },
  { label: 'Sales History Months', value: 6 },
  { label: 'Data Availability', value: 7 },
  { label: 'Product Grade', value: 8 },
];

export const al_details_columns = [
  {
    field: 'style_id',
    headerName: 'Style ID',
    pinned: 'left',
    width: 160,
  },
  {
    field: 'description',
    headerName: 'Description',
    pinned: 'left',
    width: 350,
  },
  { field: 'sku_id', headerName: 'SKU ID', width: 130 },
  { field: 'vendor', headerName: 'Vendor', width: 120 },
  {
    headerName: 'Sales Avg',
    children: [
      { field: 'last_3w', headerName: 'Last 3W', width: 120 },
      {
        field: 'mapped_sku_last_3w',
        headerName: 'Mapped SKU Last 3W',
        width: 200,
      },
      { field: 'last_6w', headerName: 'Last 6W', width: 120 },
      {
        field: 'mapped_sku_last_6w',
        headerName: 'Mapped SKU Last 6W',
        width: 200,
      },
      { field: 'last_10w', headerName: 'Last 10W', width: 120 },
      {
        field: 'mapped_sku_last_10w',
        headerName: 'Mapped SKU Last 10W',
        width: 200,
      },
      { field: 'last_20w', headerName: 'Last 20W', width: 120 },
      {
        field: 'mapped_sku_last_20w',
        headerName: 'Mapped SKU Last 20W',
        width: 200,
      },
    ],
  },
  {
    headerName: 'Forecast',
    children: [
      { field: 'current', headerName: 'Current', width: 140 },
      {
        field: 'current_weekly_avg',
        headerName: 'Current Weekly Avg.',
        width: 200,
      },
      { field: 'updated', headerName: 'Updated', width: 140 },
      {
        field: 'updated_weekly_avg',
        headerName: 'Updated Weekly Avg.',
        width: 200,
      },
    ],
  },
];

export const filterData = {
  channel: { label: 'Department', section: ['Womens Shoes'] },
  date_range: { label: 'Class', section: ['Casual'] },
  store: { label: 'Sub Class', section: ['All'] },
  merchanise_category: { label: 'Merchandise Category', section: ['All'] },
  asset_vs_memo: { label: 'Asset vs Memo', section: ['All'] },
  product_grade: { label: 'Product Grade', section: ['All'] },
};

// tb_replenishment_recommendations
export const replenishment_alert_columns = [
  {
    field: 'product_type',
    headerName: 'Product Type',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    pinned: 'left',
  },
  {
    field: 'styel_color_id',
    headerName: 'Style Color ID',
    pinned: 'left',
    // cellRenderer: 'skuLevelGraph',
    // cellRendererParams: {
    //   skuSummaryGraph: skuChartData,
    // },
  },
  { field: 'style_id', headerName: 'Style Id' },
  { field: 'color_id', headerName: 'Color ID' },
  { field: 'style_description', headerName: 'Style Description' },
  { field: 'color', headerName: 'Color' },
  { field: 'channel', headerName: 'Channel' },
  { field: 'department', headerName: 'Department' },
  { field: 'class', headerName: 'Class' },
  { field: 'style_group', headerName: 'Style Group' },
  { field: 'silhouette', headerName: 'Silhouette' },
  { field: 'sub_collect', headerName: 'Sub Collect' },
  {
    headerName: 'Mapped stores & DC',
    children: [
      {
        field: 'store_eligibility_group',
        headerName: 'Store Eligibility Group',
        width: 240,
      },
      {
        field: 'no_of_stores',
        headerName: 'No of stores',
        width: 160,
      },
      { field: 'mapped_dc', headerName: 'Mapped DC', width: 150 },
    ],
  },
  {
    headerName: 'Replenishment recommendation',
    children: [
      { field: 'recommended_qty', headerName: 'Recommended qty', width: 180 },
      {
        field: 'order_type',
        headerName: 'Order type',
        width: 160,
      },
    ],
  },
  {
    headerName: 'Configuration & constraints',
    children: [
      { field: 'product_profile', headerName: 'Product Profile', width: 160 },
      {
        field: 'size_profile',
        headerName: 'Size Profile',
        width: 170,
      },
      { field: 'demand_type', headerName: 'Demand Type', width: 160 },
      { field: 'average_WOS', headerName: 'Average WOS', width: 160 },
      { field: 'average_min', headerName: 'Average min', width: 160 },
      { field: 'average_max', headerName: 'Average max', width: 160 },
    ],
  },
  {
    headerName: 'Inventory position',
    children: [
      {
        field: 'average_days_in_stock',
        headerName: 'Average days in stock',
        width: 220,
      },
      {
        field: 'store_aggr_oh_units',
        headerName: 'Store aggr OH Units',
        width: 220,
      },
      {
        field: 'store_aggr_it_units',
        headerName: 'Store aggr IT Units',
        width: 220,
      },
      {
        field: 'store_aggr_oo_units',
        headerName: 'Store aggr OO Units',
        width: 220,
      },
      { field: 'dc_oh_units', headerName: 'DC OH Units', width: 160 },
    ],
  },
  {
    headerName: 'Safety stock',
    children: [
      {
        field: 'inv_in_dc',
        headerName: 'Inv in DC',
        width: 120,
        cellStyle: (cell) => {
          let color = { background: '#c7e7d0' };
          let value = parseInt(
            ((parseInt(cell.data.wos_lc) - parseInt(cell.value)) /
              parseInt(cell.data.wos_lc)) *
              100
          );
          if (value < 10) {
            color.background = '#c7e7d0';
          } else if (value > 10 && value < 25) {
            color.background = '#fee8bf';
          } else if (value > 25) {
            color.background = '#f6c6c8';
          }
          return color;
        },
      },
      {
        field: 'rec_ss',
        headerName: 'Rec SS',
        width: 120,
      },
    ],
  },
  {
    headerName: 'WOS',
    children: [
      {
        field: 'wos',
        headerName: 'WOS',
        width: 120,
        cellStyle: (cell) => {
          let color = { background: '#c7e7d0' };
          let value = parseInt(
            ((parseInt(cell.data.wos_lc) - parseInt(cell.value)) /
              parseInt(cell.data.wos_lc)) *
              100
          );
          if (value < 10) {
            color.background = '#c7e7d0';
          } else if (value > 10 && value < 25) {
            color.background = '#fee8bf';
          } else if (value > 25) {
            color.background = '#f6c6c8';
          }
          return color;
        },
      },
      {
        field: 'wos_lc',
        headerName: 'WOS LC',
        width: 120,
      },
    ],
  },
  {
    headerName: 'Service Level',
    children: [
      {
        field: 'sl',
        headerName: 'SL',
        width: 120,
        cellStyle: (cell) => {
          let color = { background: '#c7e7d0' };
          let value = parseInt(
            ((parseInt(cell.data.wos_lc) - parseInt(cell.value)) /
              parseInt(cell.data.wos_lc)) *
              100
          );
          if (value < 10) {
            color.background = '#c7e7d0';
          } else if (value > 10 && value < 25) {
            color.background = '#fee8bf';
          } else if (value > 25) {
            color.background = '#f6c6c8';
          }
          return color;
        },
      },
      {
        field: 'target_sl',
        headerName: 'Target SL',
        width: 120,
      },
    ],
  },
  {
    headerName: 'Lead Time',
    children: [
      { field: 'effective_lt', headerName: 'Effective LT', width: 160 },
      {
        field: 'effective_lt_lc',
        headerName: 'Effective LT LC',
        width: 180,
      },
    ],
  },
  {
    headerName: 'Demand Forecast',
    children: [
      { field: '30_day_fcst', headerName: '30 day fcst', width: 160 },
      { field: 'last_30_days', headerName: 'Last 30 days', width: 160 },
      {
        field: 'var_lc_30_days',
        headerName: '30D var% LC',
        width: 170,
        cellStyle: function (params) {
          const percentage = {
            low: {
              background: '#f6c6c8',
            },
            medium: {
              background: '#fee8bf',
            },
            high: {
              background: '#c7e7d0',
            },
          };

          let label = '';
          let value = parseInt(params.data.var_lc_30_days);
          if (value > -10 && value < 10) {
            label = 'high';
          } else if (value > -30 && value < 30) {
            label = 'medium';
          } else {
            label = 'low';
          }

          return percentage[`${label}`];
        },
      },
      { field: '90_day_fcst', headerName: '90 day fcst', width: 160 },
      { field: 'last_90_days', headerName: 'Last 90 days', width: 160 },
      {
        field: 'var_lc_90_days',
        headerName: '90D var% LC',
        width: 170,
        cellStyle: function (params) {
          const percentage = {
            low: {
              background: '#f6c6c8',
            },
            medium: {
              background: '#fee8bf',
            },
            high: {
              background: '#c7e7d0',
            },
          };

          let label = '';
          let value = parseInt(params.data.var_lc_90_days);
          if (value > -10 && value < 10) {
            label = 'high';
          } else if (value > -30 && value < 30) {
            label = 'medium';
          } else {
            label = 'low';
          }

          return percentage[`${label}`];
        },
      },
      { field: '120_day_fcst', headerName: '120 day fcst', width: 160 },
      { field: 'last_120_days', headerName: 'Last 120 days', width: 180 },
      {
        field: 'var_lc_120_days',
        headerName: '120D var% LC',
        width: 170,
        cellStyle: function (params) {
          const percentage = {
            low: {
              background: '#f6c6c8',
            },
            medium: {
              background: '#fee8bf',
            },
            high: {
              background: '#c7e7d0',
            },
          };

          let label = '';
          let value = parseInt(params.data.var_lc_120_days);
          if (value > -10 && value < 10) {
            label = 'high';
          } else if (value > -30 && value < 30) {
            label = 'medium';
          } else {
            label = 'low';
          }

          return percentage[`${label}`];
        },
      },
    ],
  },
];

export const rep_alert_deep_dive = [
  {
    field: 'item_#',
    headerName: 'Item #',
    minWidth: 150,
    pinned: 'left',
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  { field: 'month_+', headerName: 'Month +', minWidth: 120, pinned: 'left' },
  { field: 'week', headerName: 'Week', minWidth: 120, pinned: 'left' },
  { field: 'BOM_units', headerName: 'BOM Units', minWidth: 150 },
  { field: 'store_oh', headerName: 'Store OH', minWidth: 120 },
  { field: 'DC_OH', headerName: 'DC OH', minWidth: 120 },
  { field: 'OO_units', headerName: 'OO Units', minWidth: 120 },
  { field: 'late_on_order', headerName: 'Late On Order', minWidth: 180 },
  { field: 'ROQ', headerName: 'ROQ', minWidth: 120 },
  {
    field: 'sales_forecast',
    headerName: 'Sales Forecast',
    minWidth: 180,
    editable: true,
  },
  { field: 'safety_stock', headerName: 'Safety Stock', minWidth: 180 },
  { field: 'AUR', headerName: 'AUR', minWidth: 120 },
  { field: 'WOS', headerName: 'WOS', minWidth: 120 },
  { field: '%_sell_through', headerName: '% Sell Through', minWidth: 200 },
  {
    field: 'sales_units_%_to_period',
    headerName: 'Sales Units % to Period',
    minWidth: 250,
  },
  { field: 'disc_off', headerName: 'Disc. Off', minWidth: 180 },
  { field: 'MD%', headerName: 'MD%', minWidth: 120 },
  { field: 'MD$', headerName: 'MD$', minWidth: 120 },
];

export const wholesaleTabListData = {
  account_details: {
    label: 'Account Details',
    value: 'account_details',
    tables: [
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Accounts', value: 'stores' },
            { label: 'Territory', value: 'territory' },
            { label: 'Account grade', value: 'account_grade' },
          ],
          stores: {
            key: 'tb_wholesale_create_new_allocation_accounts',
            column: [
              {
                headerName: 'Account Information',
                children: [
                  {
                    field: 'account_id',
                    headerName: 'Account ID',
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                    width: 180,
                    pinned: 'left',
                  },
                  {
                    field: 'account_name',
                    headerName: 'Account Name',
                    width: 150,
                    pinned: 'left',
                  },
                  {
                    field: 'account_grade',
                    headerName: 'Account Grade',
                    width: 180,
                    pinned: 'left',
                  },
                  {
                    field: 'refrence_account',
                    headerName: 'Reference Account',
                    width: 180,
                    pinned: 'left',
                    editable: true,
                  },
                ],
              },
              {
                headerName: 'Product Profile Pen%',
                children: [
                  { field: 'overall', headerName: 'Overall', width: 120 },
                  { field: 'B6', headerName: '6B', width: 120 },
                  { field: 'B7', headerName: '7B', width: 120 },
                  { field: 'B8', headerName: '8B', width: 120 },
                  { field: 'B9', headerName: '9B', width: 120 },
                  { field: 'B10', headerName: '10B', width: 120 },
                  { field: 'B11', headerName: '11B', width: 120 },
                ],
              },
              {
                headerName: 'Constraints',
                children: [
                  {
                    field: 'sales_order',
                    headerName: 'Sales Order',
                    width: 180,
                  },
                  {
                    field: 'transit_time',
                    headerName: 'Transit time (Weeks)',
                    width: 200,
                  },
                  { field: 'account_oh', headerName: 'Account OH', width: 120 },
                  {
                    field: 'account_on_order',
                    headerName: 'Account on Order',
                    width: 180,
                  },
                  {
                    field: 'account_in_transit',
                    headerName: 'Account in Transit',
                    width: 180,
                  },
                ],
              },
              {
                field: 'inventory_target',
                headerName: 'Inventory Target',
              },
              {
                field: 'total_inventory',
                headerName: 'Total Inventory (OH+IT+OO)',
                width: 300,
                cellStyle: (cell) => {
                  let color = { background: '#ffffff' };
                  if (
                    parseInt(cell.data.total_inventory) >=
                    0.7 * parseInt(cell.data.inventory_target)
                  ) {
                    color.background = '#c7e7d0';
                  } else if (
                    parseInt(cell.data.total_inventory) <
                    0.7 * parseInt(cell.data.inventory_target)
                  ) {
                    color.background = '#fee8bf';
                  } else if (
                    parseInt(cell.data.total_inventory) >
                    parseInt(cell.data.inventory_target)
                  ) {
                    color.background = '#f6c6c8';
                  }
                  return color;
                },
              }
            ],
            data: [],
          },
          territory: {
            title: 'Territory',
            key: 'tb_wholesale_create_new_allocation_territory',
            column: [
              {
                headerName: 'Territory Information',
                children: [
                  {
                    field: 'territory_id',
                    headerName: 'Territory ID',
                    width: 180,
                    pinned: 'left',
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                  },
                  {
                    field: 'territory_name',
                    headerName: 'Territory Name',
                    width: 180,
                    pinned: 'left',
                  },
                  {
                    field: 'refrence_territory',
                    headerName: 'Reference Territory',
                    width: 220,
                    pinned: 'left',
                    editable: true,
                  },
                ],
              },
              {
                headerName: 'Product Profile Pen%',
                children: [
                  { field: 'overall', headerName: 'Overall', width: 120 },
                  { field: 'B6', headerName: '6B', width: 120 },
                  { field: 'B7', headerName: '7B', width: 120 },
                  { field: 'B8', headerName: '8B', width: 120 },
                  { field: 'B9', headerName: '9B', width: 120 },
                  { field: 'B10', headerName: '10B', width: 120 },
                  { field: 'B11', headerName: '11B', width: 120 },
                ],
              },
              {
                headerName: 'Constraints',
                children: [
                  {
                    field: 'avg_sales_order',
                    headerName: 'Avg. Sales Order',
                    width: 200,
                  },
                  {
                    field: 'avg_transit_time',
                    headerName: 'Avg. Transit time',
                    width: 250,
                  },
                  {
                    field: 'avg_account_oh',
                    headerName: 'Avg. Account OH',
                    width: 180,
                  },
                  {
                    field: 'avg_account_on_order',
                    headerName: 'Avg. Account OO',
                    width: 200,
                  },
                  {
                    field: 'avg_account_in_transit',
                    headerName: 'Avg. Account IT',
                    width: 200,
                  },
                ],
              },
            ],
            data: [],
          },
          account_grade: {
            title: 'Account grade',
            key: 'tb_wholesale_create_new_allocation_account_grade',
            column: [
              {
                headerName: 'Account Information',
                children: [
                  {
                    field: 'account_grade_id',
                    headerName: 'Account Grade ID',
                    width: 250,
                    pinned: 'left',
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                  },
                ],
              },
              {
                headerName: 'Product Profile Pen%',
                children: [
                  { field: 'overall', headerName: 'Overall', width: 120 },
                  { field: 'B6', headerName: '6B', width: 120 },
                  { field: 'B7', headerName: '7B', width: 120 },
                  { field: 'B8', headerName: '8B', width: 120 },
                  { field: 'B9', headerName: '9B', width: 120 },
                  { field: 'B10', headerName: '10B', width: 120 },
                  { field: 'B11', headerName: '11B', width: 120 },
                ],
              },
              {
                headerName: 'Constraints',
                children: [
                  {
                    field: 'avg_sales_order',
                    headerName: 'Avg. Sales order',
                    width: 200,
                  },
                  {
                    field: 'transit_time',
                    headerName: 'Transit time (Weeks)',
                    width: 200,
                  },
                  { field: 'account_oh', headerName: 'Account OH', width: 150 },
                  {
                    field: 'account_on_order',
                    headerName: 'Account OO',
                    width: 200,
                  },
                  {
                    field: 'account_in_transit',
                    headerName: 'Account IT',
                    width: 200,
                  },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  inventory_source: {
    label: 'Inventory Source',
    value: 'inventory_source',
    tables: [
      {
        key: 'tb_create_new_allocation_inventory',
        column: [
          {
            field: 'dc_id',
            headerName: 'DC ID',
            width: 150,
            pinned: 'left',
            headerCheckboxSelection: true,
            checkboxSelection: true,
          },
          {
            field: 'dc_name',
            headerName: 'DC Name',
            width: 120,
            pinned: 'left',
          },
          {
            headerName: 'DC Available Inventory',
            children: [
              { field: 'dc_overall', headerName: 'Overall', width: 120 },
              { field: 'dc_b6', headerName: '6B', width: 120 },
              { field: 'dc_b7', headerName: '7B', width: 120 },
              { field: 'dc_b8', headerName: '8B', width: 120 },
              { field: 'dc_b9', headerName: '9B', width: 120 },
              { field: 'dc_b10', headerName: '10B', width: 120 },
              { field: 'dc_b11', headerName: '11B', width: 120 },
            ],
          },
          {
            headerName: 'Quantity Reserved',
            children: [
              { field: 'qr_overall', headerName: 'Overall', width: 120 },
              { field: 'qr_b6', headerName: '6B', width: 120 },
              { field: 'qr_b7', headerName: '7B', width: 120 },
              { field: 'qr_b8', headerName: '8B', width: 120 },
              { field: 'qr_b9', headerName: '9B', width: 120 },
              { field: 'qr_b10', headerName: '10B', width: 120 },
              { field: 'qr_b11', headerName: '11B', width: 120 },
            ],
          },
          {
            headerName: 'Allocated Reserved Quantity',
            children: [
              { field: 'aqr_overall', headerName: 'Overall', width: 120 },
              { field: 'aqr_b6', headerName: '6B', width: 120 },
              { field: 'aqr_b7', headerName: '7B', width: 120 },
              { field: 'aqr_b8', headerName: '8B', width: 120 },
              { field: 'aqr_b9', headerName: '9B', width: 120 },
              { field: 'aqr_b10', headerName: '10B', width: 120 },
              { field: 'aqr_b11', headerName: '11B', width: 120 },
            ],
          },
        ],
        data: [],
      },
    ],
  },
};

export const tabListData = {
  store_details: {
    label: 'Store Details',
    value: 'store_details',
    tables: [
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_create_new_allocation_store_stores',
            column: [
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                    width: 180,
                    pinned: 'left',
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    width: 150,
                    pinned: 'left',
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    width: 180,
                    pinned: 'left',
                  },
                  {
                    field: 'refrence_store',
                    headerName: 'Reference Store',
                    width: 180,
                    pinned: 'left',
                    editable: true,
                  },
                ],
              },
              {
                headerName: 'Product Profile Pen%',
                children: [
                  { field: 'overall', headerName: 'Overall', width: 120 },
                  { field: 'B6', headerName: '6B', width: 120 },
                  { field: 'B7', headerName: '7B', width: 120 },
                  { field: 'B8', headerName: '8B', width: 120 },
                  { field: 'B9', headerName: '9B', width: 120 },
                  { field: 'B10', headerName: '10B', width: 120 },
                  { field: 'B11', headerName: '11B', width: 120 },
                ],
              },
              {
                headerName: 'Constraints',
                children: [
                  {
                    field: 'aps_user_defined_ros',
                    headerName: 'APS/User Defined ROS',
                    width: 230,
                    editable: true,
                  },
                  {
                    field: 'min',
                    headerName: 'Min',
                    width: 120,
                    editable: true,
                  },
                  {
                    field: 'max',
                    headerName: 'Max',
                    width: 120,
                    editable: true,
                  },
                  {
                    field: 'wos',
                    headerName: 'WOS',
                    width: 120,
                    editable: true,
                  },
                  {
                    field: 'direct_demand',
                    headerName: 'Direct demand',
                    width: 180,
                  },
                  { field: 'bopis', headerName: 'BOPIS', width: 120 },
                  {
                    field: 'sfs_fulfilment',
                    headerName: 'SFS fulfillment',
                    width: 180,
                  },
                  {
                    field: 'estimated_return',
                    headerName: 'Estimated returns',
                    width: 200,
                  },
                  {
                    field: 'transit_time',
                    headerName: 'Transit time (Weeks)',
                    width: 200,
                  },
                  { field: 'store_oh', headerName: 'Store OH', width: 120 },
                  {
                    field: 'store_on_order',
                    headerName: 'Store on Order',
                    width: 180,
                  },
                  {
                    field: 'store_in_transit',
                    headerName: 'Store in Transit',
                    width: 180,
                  },
                ],
              },
              {
                field: 'inventory_target',
                headerName: 'Inventory Target',
              },
              {
                field: 'total_inventory',
                headerName: 'Total Inventory (OH+IT+OO)',
                width: 300,
                cellStyle: (cell) => {
                  let color = { background: '#ffffff' };
                  if (
                    parseInt(cell.data.total_inventory) >=
                    0.7 * parseInt(cell.data.inventory_target)
                  ) {
                    color.background = '#c7e7d0';
                  } else if (
                    parseInt(cell.data.total_inventory) <
                    0.7 * parseInt(cell.data.inventory_target)
                  ) {
                    color.background = '#fee8bf';
                  } else if (
                    parseInt(cell.data.total_inventory) >
                    parseInt(cell.data.inventory_target)
                  ) {
                    color.background = '#f6c6c8';
                  }
                  return color;
                },
              },
              {
                field: 'total_estimated_demand',
                headerName: 'Total Estimated Demand',
                width: 250,
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_create_new_allocation_store_groups',
            column: [
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_group_id',
                    headerName: 'Store Grp ID',
                    width: 180,
                    pinned: 'left',
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                  },
                  {
                    field: 'stor_group_name',
                    headerName: 'Store Group Name',
                    width: 180,
                    pinned: 'left',
                  },
                  {
                    field: 'refrence_store_group',
                    headerName: 'Reference Store Group',
                    width: 220,
                    pinned: 'left',
                    editable: true,
                  },
                ],
              },
              {
                headerName: 'Product Profile Pen%',
                children: [
                  { field: 'overall', headerName: 'Overall', width: 120 },
                  { field: 'B6', headerName: '6B', width: 120 },
                  { field: 'B7', headerName: '7B', width: 120 },
                  { field: 'B8', headerName: '8B', width: 120 },
                  { field: 'B9', headerName: '9B', width: 120 },
                  { field: 'B10', headerName: '10B', width: 120 },
                  { field: 'B11', headerName: '11B', width: 120 },
                ],
              },
              {
                headerName: 'Constraints',
                children: [
                  {
                    field: 'aps_user_defined_ros',
                    headerName: 'APS/User Defined ROS',
                    width: 250,
                    editable: true,
                  },
                  {
                    field: 'min',
                    headerName: 'Min',
                    width: 120,
                    editable: true,
                  },
                  {
                    field: 'max',
                    headerName: 'Max',
                    width: 120,
                    editable: true,
                  },
                  {
                    field: 'wos',
                    headerName: 'WOS',
                    width: 120,
                    editable: true,
                  },
                  {
                    field: 'avg_direct_demand',
                    headerName: 'Avg. Direct demand',
                    width: 200,
                  },
                  { field: 'avg_bopis', headerName: 'Avg. BOPIS', width: 150 },
                  {
                    field: 'sfs_fulfillment',
                    headerName: 'SFS fulfillment',
                    width: 200,
                  },
                  {
                    field: 'estimated_returns',
                    headerName: 'Estimated returns',
                    width: 200,
                  },
                  {
                    field: 'avg_transit_time',
                    headerName: 'Avg. Transit time',
                    width: 250,
                  },
                  {
                    field: 'avg_store_oh',
                    headerName: 'Avg. Store OH',
                    width: 180,
                  },
                  {
                    field: 'avg_store_on_order',
                    headerName: 'Avg. Store on Order',
                    width: 200,
                  },
                  {
                    field: 'avg_store_in_transit',
                    headerName: 'Avg. Store in Transit',
                    width: 200,
                  },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_create_new_allocation_store_grade',
            column: [
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_grade_id',
                    headerName: 'Store Grade ID',
                    width: 250,
                    pinned: 'left',
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                  },
                ],
              },
              {
                headerName: 'Product Profile Pen%',
                children: [
                  { field: 'overall', headerName: 'Overall', width: 120 },
                  { field: 'B6', headerName: '6B', width: 120 },
                  { field: 'B7', headerName: '7B', width: 120 },
                  { field: 'B8', headerName: '8B', width: 120 },
                  { field: 'B9', headerName: '9B', width: 120 },
                  { field: 'B10', headerName: '10B', width: 120 },
                  { field: 'B11', headerName: '11B', width: 120 },
                ],
              },
              {
                headerName: 'Constraints',
                children: [
                  {
                    field: 'aps_user_defined_ros',
                    headerName: 'APS/User Defined ROS',
                    width: 250,
                  },
                  { field: 'min', headerName: 'Min', width: 120 },
                  { field: 'max', headerName: 'Max', width: 120 },
                  { field: 'wos', headerName: 'WOS', width: 120 },
                  {
                    field: 'direct_demand',
                    headerName: 'Direct demand',
                    width: 200,
                  },
                  { field: 'bopis', headerName: 'BOPIS', width: 120 },
                  {
                    field: 'sfs_fulfillment',
                    headerName: 'SFS fulfillment',
                    width: 200,
                  },
                  {
                    field: 'estimated_returns',
                    headerName: 'Estimated returns',
                    width: 200,
                  },
                  {
                    field: 'transit_time',
                    headerName: 'Transit time (Weeks)',
                    width: 200,
                  },
                  { field: 'store_oh', headerName: 'Store OH', width: 150 },
                  {
                    field: 'store_on_order',
                    headerName: 'Store on Order',
                    width: 200,
                  },
                  {
                    field: 'store_in_transit',
                    headerName: 'Store in Transit',
                    width: 200,
                  },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  inventory_source: {
    label: 'Inventory Source',
    value: 'inventory_source',
    tables: [
      {
        key: 'tb_create_new_allocation_inventory',
        column: [
          {
            field: 'dc_id',
            headerName: 'DC ID',
            width: 150,
            pinned: 'left',
            headerCheckboxSelection: true,
            checkboxSelection: true,
          },
          {
            field: 'dc_name',
            headerName: 'DC Name',
            width: 120,
            pinned: 'left',
          },
          {
            headerName: 'DC Available Inventory',
            children: [
              { field: 'dc_overall', headerName: 'Overall', width: 120 },
              { field: 'dc_b6', headerName: '6B', width: 120 },
              { field: 'dc_b7', headerName: '7B', width: 120 },
              { field: 'dc_b8', headerName: '8B', width: 120 },
              { field: 'dc_b9', headerName: '9B', width: 120 },
              { field: 'dc_b10', headerName: '10B', width: 120 },
              { field: 'dc_b11', headerName: '11B', width: 120 },
            ],
          },
          {
            headerName: 'Quantity Reserved',
            children: [
              { field: 'qr_overall', headerName: 'Overall', width: 120 },
              { field: 'qr_b6', headerName: '6B', width: 120 },
              { field: 'qr_b7', headerName: '7B', width: 120 },
              { field: 'qr_b8', headerName: '8B', width: 120 },
              { field: 'qr_b9', headerName: '9B', width: 120 },
              { field: 'qr_b10', headerName: '10B', width: 120 },
              { field: 'qr_b11', headerName: '11B', width: 120 },
            ],
          },
          {
            headerName: 'Allocated Reserved Quantity',
            children: [
              { field: 'aqr_overall', headerName: 'Overall', width: 120 },
              { field: 'aqr_b6', headerName: '6B', width: 120 },
              { field: 'aqr_b7', headerName: '7B', width: 120 },
              { field: 'aqr_b8', headerName: '8B', width: 120 },
              { field: 'aqr_b9', headerName: '9B', width: 120 },
              { field: 'aqr_b10', headerName: '10B', width: 120 },
              { field: 'aqr_b11', headerName: '11B', width: 120 },
            ],
          },
        ],
        data: [],
      },
    ],
  },
};

export const updatedTabListData = {
  store_details: {
    label: 'Store Details',
    value: 'store_details',
    tables: [
      {
        isRadio: true,
        key: '',
        radio: {
          checks: [
            { label: 'Stores', value: 'stores' },
            { label: 'Store groups', value: 'store_groups' },
            { label: 'Store grade', value: 'store_grade' },
          ],
          stores: {
            key: 'tb_create_new_allocation_updated_store_stores',
            column: [
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_id',
                    headerName: 'Store ID',
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                    width: 180,
                    pinned: 'left',
                  },
                  {
                    field: 'store_name',
                    headerName: 'Store Name',
                    width: 150,
                    pinned: 'left',
                  },
                  {
                    field: 'store_grade',
                    headerName: 'Store Grade',
                    width: 180,
                    pinned: 'left',
                  },
                  {
                    field: 'refrence_store',
                    headerName: 'Reference Store',
                    width: 180,
                    pinned: 'left',
                    editable: true,
                  },
                ],
              },
              {
                headerName: 'Product Profile Pen%',
                children: [
                  { field: 'overall', headerName: 'Overall', width: 120 },
                  { field: 'B6', headerName: '6B', width: 120 },
                  { field: 'B7', headerName: '7B', width: 120 },
                  { field: 'B8', headerName: '8B', width: 120 },
                  { field: 'B9', headerName: '9B', width: 120 },
                  { field: 'B10', headerName: '10B', width: 120 },
                  { field: 'B11', headerName: '11B', width: 120 },
                ],
              },
              {
                headerName: 'Constraints',
                children: [
                  {
                    field: 'aps_user_defined_ros',
                    headerName: 'APS/User Defined ROS',
                    width: 230,
                    editable: true,
                  },
                  {
                    field: 'min',
                    headerName: 'Min',
                    width: 120,
                    editable: true,
                  },
                  {
                    field: 'max',
                    headerName: 'Max',
                    width: 120,
                    editable: true,
                  },
                  {
                    field: 'wos',
                    headerName: 'WOS',
                    width: 120,
                    editable: true,
                  },
                  {
                    field: 'direct_demand',
                    headerName: 'Direct demand',
                    width: 180,
                  },
                  { field: 'bopis', headerName: 'BOPIS', width: 120 },
                  {
                    field: 'sfs_fulfilment',
                    headerName: 'SFS fulfillment',
                    width: 180,
                  },
                  {
                    field: 'estimated_return',
                    headerName: 'Estimated returns',
                    width: 200,
                  },
                  {
                    field: 'transit_time',
                    headerName: 'Transit time (Weeks)',
                    width: 200,
                  },
                  { field: 'store_oh', headerName: 'Store OH', width: 120 },
                  {
                    field: 'store_on_order',
                    headerName: 'Store on Order',
                    width: 180,
                  },
                  {
                    field: 'store_in_transit',
                    headerName: 'Store in Transit',
                    width: 180,
                  },
                ],
              },
              {
                field: 'inventory_target',
                headerName: 'Inventory Target',
              },
              {
                field: 'total_inventory',
                headerName: 'Total Inventory (OH+IT+OO)',
                width: 300,
                cellStyle: (cell) => {
                  let color = { background: '#ffffff' };
                  if (
                    parseInt(cell.data.total_inventory) >=
                    0.7 * parseInt(cell.data.inventory_target)
                  ) {
                    color.background = '#c7e7d0';
                  } else if (
                    parseInt(cell.data.total_inventory) <
                    0.7 * parseInt(cell.data.inventory_target)
                  ) {
                    color.background = '#fee8bf';
                  } else if (
                    parseInt(cell.data.total_inventory) >
                    parseInt(cell.data.inventory_target)
                  ) {
                    color.background = '#f6c6c8';
                  }
                  return color;
                },
              },
              {
                field: 'total_estimated_demand',
                headerName: 'Total Estimated Demand',
                width: 250,
              },
            ],
            data: [],
          },
          store_groups: {
            title: 'Store groups',
            key: 'tb_create_new_allocation_updated_store_groups',
            column: [
              {
                headerName: 'Store Group Information',
                children: [
                  {
                    field: 'store_group_id',
                    headerName: 'Store Grp ID',
                    width: 180,
                    pinned: 'left',
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                  },
                  {
                    field: 'stor_group_name',
                    headerName: 'Store Group Name',
                    width: 180,
                    pinned: 'left',
                  },
                  {
                    field: 'refrence_store_group',
                    headerName: 'Reference Store Group',
                    width: 220,
                    pinned: 'left',
                    editable: true,
                  },
                ],
              },
              {
                headerName: 'Product Profile Pen%',
                children: [
                  { field: 'overall', headerName: 'Overall', width: 120 },
                  { field: 'B6', headerName: '6B', width: 120 },
                  { field: 'B7', headerName: '7B', width: 120 },
                  { field: 'B8', headerName: '8B', width: 120 },
                  { field: 'B9', headerName: '9B', width: 120 },
                  { field: 'B10', headerName: '10B', width: 120 },
                  { field: 'B11', headerName: '11B', width: 120 },
                ],
              },
              {
                headerName: 'Constraints',
                children: [
                  {
                    field: 'aps_user_defined_ros',
                    headerName: 'APS/User Defined ROS',
                    width: 250,
                    editable: true,
                  },
                  {
                    field: 'min',
                    headerName: 'Min',
                    width: 120,
                    editable: true,
                  },
                  {
                    field: 'max',
                    headerName: 'Max',
                    width: 120,
                    editable: true,
                  },
                  {
                    field: 'wos',
                    headerName: 'WOS',
                    width: 120,
                    editable: true,
                  },
                  {
                    field: 'avg_direct_demand',
                    headerName: 'Avg. Direct demand',
                    width: 200,
                  },
                  { field: 'avg_bopis', headerName: 'Avg. BOPIS', width: 150 },
                  {
                    field: 'sfs_fulfillment',
                    headerName: 'SFS fulfillment',
                    width: 200,
                  },
                  {
                    field: 'estimated_returns',
                    headerName: 'Estimated returns',
                    width: 200,
                  },
                  {
                    field: 'avg_transit_time',
                    headerName: 'Avg. Transit time',
                    width: 250,
                  },
                  {
                    field: 'avg_store_oh',
                    headerName: 'Avg. Store OH',
                    width: 180,
                  },
                  {
                    field: 'avg_store_on_order',
                    headerName: 'Avg. Store on Order',
                    width: 200,
                  },
                  {
                    field: 'avg_store_in_transit',
                    headerName: 'Avg. Store in Transit',
                    width: 200,
                  },
                ],
              },
            ],
            data: [],
          },
          store_grade: {
            title: 'Store grade',
            key: 'tb_create_new_allocation_updated_store_grade',
            column: [
              {
                headerName: 'Store Information',
                children: [
                  {
                    field: 'store_grade_id',
                    headerName: 'Store Grade ID',
                    width: 250,
                    pinned: 'left',
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                  },
                ],
              },
              {
                headerName: 'Product Profile Pen%',
                children: [
                  { field: 'overall', headerName: 'Overall', width: 120 },
                  { field: 'B6', headerName: '6B', width: 120 },
                  { field: 'B7', headerName: '7B', width: 120 },
                  { field: 'B8', headerName: '8B', width: 120 },
                  { field: 'B9', headerName: '9B', width: 120 },
                  { field: 'B10', headerName: '10B', width: 120 },
                  { field: 'B11', headerName: '11B', width: 120 },
                ],
              },
              {
                headerName: 'Constraints',
                children: [
                  {
                    field: 'aps_user_defined_ros',
                    headerName: 'APS/User Defined ROS',
                    width: 250,
                  },
                  { field: 'min', headerName: 'Min', width: 120 },
                  { field: 'max', headerName: 'Max', width: 120 },
                  { field: 'wos', headerName: 'WOS', width: 120 },
                  {
                    field: 'direct_demand',
                    headerName: 'Direct demand',
                    width: 200,
                  },
                  { field: 'bopis', headerName: 'BOPIS', width: 120 },
                  {
                    field: 'sfs_fulfillment',
                    headerName: 'SFS fulfillment',
                    width: 200,
                  },
                  {
                    field: 'estimated_returns',
                    headerName: 'Estimated returns',
                    width: 200,
                  },
                  {
                    field: 'transit_time',
                    headerName: 'Transit time (Weeks)',
                    width: 200,
                  },
                  { field: 'store_oh', headerName: 'Store OH', width: 150 },
                  {
                    field: 'store_on_order',
                    headerName: 'Store on Order',
                    width: 200,
                  },
                  {
                    field: 'store_in_transit',
                    headerName: 'Store in Transit',
                    width: 200,
                  },
                ],
              },
            ],
            data: [],
          },
        },
      },
    ],
  },
  inventory_source: {
    label: 'Inventory Source',
    value: 'inventory_source',
    tables: [
      {
        key: 'tb_create_new_allocation_inventory',
        column: [
          {
            field: 'dc_id',
            headerName: 'DC ID',
            width: 150,
            pinned: 'left',
            headerCheckboxSelection: true,
            checkboxSelection: true,
          },
          {
            field: 'dc_name',
            headerName: 'DC Name',
            width: 120,
            pinned: 'left',
          },
          {
            headerName: 'DC Available Inventory',
            children: [
              { field: 'dc_overall', headerName: 'Overall', width: 120 },
              { field: 'dc_b6', headerName: '6B', width: 120 },
              { field: 'dc_b7', headerName: '7B', width: 120 },
              { field: 'dc_b8', headerName: '8B', width: 120 },
              { field: 'dc_b9', headerName: '9B', width: 120 },
              { field: 'dc_b10', headerName: '10B', width: 120 },
              { field: 'dc_b11', headerName: '11B', width: 120 },
            ],
          },
          {
            headerName: 'Quantity Reserved',
            children: [
              { field: 'qr_overall', headerName: 'Overall', width: 120 },
              { field: 'qr_b6', headerName: '6B', width: 120 },
              { field: 'qr_b7', headerName: '7B', width: 120 },
              { field: 'qr_b8', headerName: '8B', width: 120 },
              { field: 'qr_b9', headerName: '9B', width: 120 },
              { field: 'qr_b10', headerName: '10B', width: 120 },
              { field: 'qr_b11', headerName: '11B', width: 120 },
            ],
          },
          {
            headerName: 'Allocated Reserved Quantity',
            children: [
              { field: 'aqr_overall', headerName: 'Overall', width: 120 },
              { field: 'aqr_b6', headerName: '6B', width: 120 },
              { field: 'aqr_b7', headerName: '7B', width: 120 },
              { field: 'aqr_b8', headerName: '8B', width: 120 },
              { field: 'aqr_b9', headerName: '9B', width: 120 },
              { field: 'aqr_b10', headerName: '10B', width: 120 },
              { field: 'aqr_b11', headerName: '11B', width: 120 },
            ],
          },
        ],
        data: [],
      },
    ],
  },
};

export const optimization = [
  {
    value: 0,
    label: 'Min first + optimize $Margin',
  },
  {
    value: 1,
    label: 'Optimize $Margin ',
  },
  {
    value: 2,
    label: 'User defined - opt1 ',
  },
  {
    value: 3,
    label: 'User defined - opt2',
  },
];

export const style_colors = [
  {
    label: 'C1344 BLK',
    value: 0,
  },
];

const updateUserDefinedInv = (params) => {
  return Math.round(
    (parseInt(params.data.user_defined_inventory_per) / 100) *
      parseInt(params.data.net_available_inv.replace(/,/g, ''))
  );
};

const updateFinalTotalAvl = (params) => {
  return Math.round(
    parseInt(params.data.net_available_inv.replace(/,/g, '')) -
      parseInt(params.getValue('updatedUDI'))
  );
};

export const wholesale_create_new_allocation_columns = [
  {
    field: 'product_type',
    headerName: 'Product Type',
    minWidth: 150,
    pinned: 'left',
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  { field: 'style_id', headerName: 'Style ID', minWidth: 120, pinned: 'left' },
  {
    field: 'style_description',
    headerName: 'Style Description',
    minWidth: 400,
  },
  {
    field: 'style_color_id',
    headerName: 'Style Color ID',
    width: 200,
  },
  { field: 'color', headerName: 'Color', width: 120 },
  { field: 'color_id', headerName: 'Color ID', width: 150 },
  { field: 'channel', headerName: 'Channel', width: 120 },
  { field: 'department', headerName: 'Department', width: 180 },
  { field: 'class', headerName: 'Class', width: 120 },
  { field: 'style_group', headerName: 'Style Group', width: 180 },
  { field: 'sub_class', headerName: 'Sub Class', width: 180 },
  {
    field: 'assortment_indicator',
    headerName: 'Assortment Indicator',
    width: 200,
  },
  { field: 'introduction_date', headerName: 'Introduction Date', width: 200 },
  {
    field: 'demand_type',
    headerName: 'Demand Type',
    width: 180,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: function (params) {
      return {
        values: ['APS', 'Fixed', 'IA', 'Forecast'],
      };
    },
  },
  {
    field: 'product_profile',
    headerName: 'Product Profile',
    width: 180,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: function (params) {
      return {
        values: ['IA Recommended'],
      };
    },
  },
  { field: 'size_profile', headerName: 'Size Profile', width: 180 },
  { field: 'no_of_accounts', headerName: 'No Of Accounts', width: 180 },
  {
    field: 'inventory_available',
    headerName: 'Inventory Available',
    width: 200,
  },
  { field: 'user_reserved', headerName: 'User Reserved', width: 180 },
  { field: 'allocated_unites', headerName: 'Allocated Units', width: 200 },
  {
    field: 'inv_source',
    headerName: 'Inv. Source',
    width: 180,
    editable: true,
  },
  { field: 'net_available_inv', headerName: 'Net Available Inv.', width: 200 },
  {
    field: 'user_defined_inventory_per',
    headerName: 'User Defined Inventory(%)',
    width: 250,
  },
  {
    field: 'user_defined_inventory_units',
    headerName: 'User Defined Inventory(Units)',
    colId: 'updatedUDI',
    valueGetter: updateUserDefinedInv,
    width: 250,
  },
  {
    field: 'final_total_available',
    headerName: 'Final Total Available',
    valueGetter: updateFinalTotalAvl,
    width: 200,
  },
];

export const create_new_allocation_columns = [
  {
    field: 'product_type',
    headerName: 'Product Type',
    minWidth: 150,
    pinned: 'left',
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  { field: 'style_id', headerName: 'Style ID', minWidth: 120, pinned: 'left' },
  {
    field: 'style_description',
    headerName: 'Style Description',
    minWidth: 400,
  },
  {
    field: 'style_color_id',
    headerName: 'Style Color ID',
    width: 200,
  },
  { field: 'color', headerName: 'Color', width: 120 },
  { field: 'color_id', headerName: 'Color ID', width: 150 },
  { field: 'channel', headerName: 'Channel', width: 120 },
  { field: 'department', headerName: 'Department', width: 180 },
  { field: 'class', headerName: 'Class', width: 120 },
  { field: 'style_group', headerName: 'Style Group', width: 180 },
  { field: 'sub_class', headerName: 'Sub Class', width: 180 },
  {
    field: 'assortment_indicator',
    headerName: 'Assortment Indicator',
    width: 200,
  },
  { field: 'introduction_date', headerName: 'Introduction Date', width: 200 },
  { field: 'min', headerName: 'Min', width: 120 },
  { field: 'max', headerName: 'Max', width: 120 },
  { field: 'last_30D_sales', headerName: 'Last 30D sales', width: 170 },
  { field: '30D_forecast', headerName: '30D forecast', width: 170 },
  {
    field: 'thirty_var_perc',
    headerName: '30D var% LC',
    width: 170,
    cellStyle: function (params) {
      const percentage = {
        low: {
          background: '#f6c6c8',
        },
        medium: {
          background: '#fee8bf',
        },
        high: {
          background: '#c7e7d0',
        },
      };

      let label = '';
      let value = parseInt(params.data.thirty_var_perc);
      if (value > -10 && value < 10) {
        label = 'high';
      } else if (value > -30 && value < 30) {
        label = 'medium';
      } else {
        label = 'low';
      }

      return percentage[`${label}`];
    },
  },
  { field: 'last_60D_sales', headerName: 'Last 60D sales', width: 170 },
  { field: '60D_forecast', headerName: '60D forecast', width: 170 },
  {
    field: 'sixty_var_perc',
    headerName: '60D var% LC',
    width: 170,
    cellStyle: function (params) {
      const percentage = {
        low: {
          background: '#f6c6c8',
        },
        medium: {
          background: '#fee8bf',
        },
        high: {
          background: '#c7e7d0',
        },
      };

      let label = '';
      let value = parseInt(params.data.sixty_var_perc);
      if (value > -10 && value < 10) {
        label = 'high';
      } else if (value > -30 && value < 30) {
        label = 'medium';
      } else {
        label = 'low';
      }

      return percentage[`${label}`];
    },
  },
  {
    field: 'demand_type',
    headerName: 'Demand Type',
    width: 180,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: function (params) {
      return {
        values: ['APS', 'Fixed', 'IA', 'Forecast'],
      };
    },
  },
  { field: 'planned_aps', headerName: 'Planned APS', width: 180 },
  { field: 'average_wos', headerName: 'Average WOS', width: 180 },
  {
    field: 'product_profile',
    headerName: 'Product Profile',
    width: 180,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: function (params) {
      return {
        values: ['IA Recommended'],
      };
    },
  },
  { field: 'size_profile', headerName: 'Size Profile', width: 180 },
  {
    field: 'store_eligibility_group',
    headerName: 'Store Eligibility Groups',
    width: 250,
  },
  {
    field: 'dc',
    headerName: 'DC',
    width: 120,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: function (params) {
      return {
        values: ['DC01', 'DC02', 'DC03'],
      };
    },
  },
  { field: 'no_of_stores', headerName: 'No Of Stores', width: 180 },
  {
    field: 'inventory_available',
    headerName: 'Inventory Available',
    width: 200,
  },
  { field: 'user_reserved', headerName: 'User Reserved', width: 180 },
  { field: 'new_store_reserved', headerName: 'New Store Reserved', width: 200 },
  { field: 'allocated_unites', headerName: 'Allocated Units', width: 200 },
  {
    field: 'inv_source',
    headerName: 'Inv. Source',
    width: 180,
    editable: true,
  },
  { field: 'net_available_inv', headerName: 'Net Available Inv.', width: 200 },
  {
    field: 'user_defined_inventory_per',
    headerName: 'User Defined Inventory(%)',
    width: 250,
  },
  {
    field: 'user_defined_inventory_units',
    headerName: 'User Defined Inventory(Units)',
    colId: 'updatedUDI',
    valueGetter: updateUserDefinedInv,
    width: 250,
  },
  {
    field: 'final_total_available',
    headerName: 'Final Total Available',
    valueGetter: updateFinalTotalAvl,
    width: 200,
  },
];

export const create_pre_allocation_columns = [
  {
    field: 'product_type',
    headerName: 'Product Type',
    minWidth: 150,
    pinned: 'left',
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  { field: 'style_id', headerName: 'Style ID', minWidth: 120, pinned: 'left' },
  {
    field: 'style_description',
    headerName: 'Style Description',
    minWidth: 400,
  },
  {
    field: 'style_color_id',
    headerName: 'Style Color ID',
    width: 200,
  },
  { field: 'color', headerName: 'Color', width: 120 },
  { field: 'color_id', headerName: 'Color ID', width: 150 },
  { field: 'channel', headerName: 'Channel', width: 120 },
  { field: 'department', headerName: 'Department', width: 180 },
  { field: 'class', headerName: 'Class', width: 120 },
  { field: 'style_group', headerName: 'Style Group', width: 180 },
  { field: 'sub_class', headerName: 'Sub Class', width: 180 },
  {
    field: 'assortment_indicator',
    headerName: 'Assortment Indicator',
    width: 200,
  },
  { field: 'introduction_date', headerName: 'Introduction Date', width: 200 },
  { field: 'min', headerName: 'Min', width: 120 },
  { field: 'max', headerName: 'Max', width: 120 },
  { field: '30D_forecast', headerName: '30D forecast', width: 170 },
  { field: '60D_forecast', headerName: '60D forecast', width: 170 },
  {
    field: 'demand_type',
    headerName: 'Demand Type',
    width: 180,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: function (params) {
      return {
        values: ['APS', 'Fixed', 'IA', 'Forecast'],
      };
    },
  },
  { field: 'planned_aps', headerName: 'Planned APS', width: 180 },
  { field: 'average_wos', headerName: 'Average WOS', width: 180 },
  {
    field: 'product_profile',
    headerName: 'Product Profile',
    width: 180,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: function (params) {
      return {
        values: ['IA Recommended'],
      };
    },
  },
  {
    headerName: 'Size Penetration',
    children: [
      {
        field: 'size_profile',
        headerName: 'Size Profile',
        width: 180,
      },
      {
        field: 'size_6b',
        headerName: '6B',
        width: 120,
        columnGroupShow: 'open',
      },
      {
        field: 'size_7b',
        headerName: '7B',
        width: 120,
        columnGroupShow: 'open',
      },
      {
        field: 'size_8b',
        headerName: '8B',
        width: 120,
        columnGroupShow: 'open',
      },
      {
        field: 'size_9b',
        headerName: '9B',
        width: 120,
        columnGroupShow: 'open',
      },
      {
        field: 'size_10b',
        headerName: '10B',
        width: 120,
        columnGroupShow: 'open',
      },
      {
        field: 'size_11b',
        headerName: '11B',
        width: 120,
        columnGroupShow: 'open',
      },
    ],
  },
  {
    field: 'store_eligibility_group',
    headerName: 'Store Eligibility Groups',
    width: 250,
  },
  {
    field: 'dc',
    headerName: 'DC',
    width: 120,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: function (params) {
      return {
        values: ['DC01', 'DC02', 'DC03'],
      };
    },
  },
  { field: 'no_of_stores', headerName: 'No Of Stores', width: 180 },
  {
    field: 'inventory_available',
    headerName: 'Inventory Available',
    width: 200,
  },
  { field: 'user_reserved', headerName: 'User Reserved', width: 180 },
  { field: 'new_store_reserved', headerName: 'New Store Reserved', width: 200 },
  { field: 'allocated_unites', headerName: 'Allocated Units', width: 200 },
  {
    field: 'inv_source',
    headerName: 'Inv. Source',
    width: 180,
    editable: true,
  },
  { field: 'net_available_inv', headerName: 'Net Available Inv.', width: 200 },
  {
    field: 'user_defined_inventory_per',
    headerName: 'User Defined Inventory(%)',
    width: 250,
  },
  {
    field: 'user_defined_inventory_units',
    headerName: 'User Defined Inventory(Units)',
    colId: 'updatedUDI',
    valueGetter: updateUserDefinedInv,
    width: 250,
  },
  {
    field: 'final_total_available',
    headerName: 'Final Total Available',
    valueGetter: updateFinalTotalAvl,
    width: 200,
  },
  {
    field: 'similarity_item',
    headerName: 'Similarity Item',
    width: 200,
  },
  {
    field: 'similarity_item_description',
    headerName: 'Similarity Item Description',
    width: 200,
  },
  {
    field: 'source_of_allocation',
    headerName: 'Source Of Allocation',
    width: 200,
  },
];

export const productDetails = {
  title: 'Product Details',
  key: 'tb_al_al_product_details_modal',
  height: '128px',
  column: [
    {
      field: 'product_type',
      headerName: 'Product Type',
      pinned: 'left',
      width: 180,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: 'style_id',
      headerName: 'Style ID',
      pinned: 'left',
      width: 160,
    },
    {
      field: 'style_color_id',
      headerName: 'Style Color ID',
      pinned: 'left',
      width: 160,
    },
    {
      field: 'color',
      headerName: 'Color',
      width: 160,
    },
    {
      field: 'color_id',
      headerName: 'Color ID',
      width: 160,
    },
    {
      field: 'assortment_indicator',
      headerName: 'Assortment Indicator',
      width: 200,
    },
    { field: 'factor_type', headerName: 'Factor Type', width: 160 },
    { field: 'lw_units', headerName: 'LW Units', width: 160 },
    { field: 'bulk_remaining', headerName: 'Bulk Remaining', width: 160 },
    { field: 'allocated_qty', headerName: 'Allocated Qty.', width: 160 },
    { field: 'oh', headerName: 'OH', width: 160 },
    { field: 'wos', headerName: 'WOS', width: 160 },
    {
      headerName: 'Store Grade - Allocated Qty.',
      children: [
        { field: 'allocation_qty_aaa', headerName: 'AAA', minWidth: 220 },
        {
          field: 'allocation_qty_aa',
          headerName: 'AA',
          width: 110,
          columnGroupShow: 'open',
        },
        {
          field: 'allocation_qty_a',
          headerName: 'A',
          width: 110,
          columnGroupShow: 'open',
        },
        {
          field: 'allocation_qty_b',
          headerName: 'B',
          width: 110,
          columnGroupShow: 'open',
        },
        {
          field: 'allocation_qty_bb',
          headerName: 'BB',
          width: 110,
          columnGroupShow: 'open',
        },
      ],
    },
    {
      headerName: 'Store Grade - OH',
      children: [
        { field: 'oh_aaa', headerName: 'AAA', width: 180 },
        {
          field: 'oh_aa',
          headerName: 'AA',
          width: 110,
          columnGroupShow: 'open',
        },
        { field: 'oh_a', headerName: 'A', width: 110, columnGroupShow: 'open' },
        { field: 'oh_b', headerName: 'B', width: 110, columnGroupShow: 'open' },
        {
          field: 'oh_bb',
          headerName: 'BB',
          width: 110,
          columnGroupShow: 'open',
        },
      ],
    },
    {
      headerName: 'Store Grade - WOS',
      children: [
        { field: 'wos_aaa', headerName: 'AAA', width: 180 },
        {
          field: 'wos_aa',
          headerName: 'AA',
          width: 110,
          columnGroupShow: 'open',
        },
        {
          field: 'wos_a',
          headerName: 'A',
          width: 110,
          columnGroupShow: 'open',
        },
        {
          field: 'wos_b',
          headerName: 'B',
          width: 110,
          columnGroupShow: 'open',
        },
        {
          field: 'wos_bb',
          headerName: 'BB',
          width: 110,
          columnGroupShow: 'open',
        },
      ],
    },
  ],
  data: [],
};

export const productsData = {
  title: '',
  key: 'tb_products_table',
  height: '128px',
  column: [
    {
      field: 'product_type',
      headerName: 'Product Type',
      pinned: 'left',
      width: 180,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: 'style_color_id',
      headerName: 'Style Color ID',
      pinned: 'left',
      width: 160,
    },
    {
      field: 'style_id',
      headerName: 'Style ID',
      width: 160,
    },
    {
      field: 'color_id',
      headerName: 'Color ID',
      width: 160,
    },
    {
      field: 'style_description',
      headerName: 'Style Description',
      width: 200,
    },
    {
      field: 'color_description',
      headerName: 'Color Description',
      width: 200,
    },
    {
      field: 'assortment_indicator',
      headerName: 'Assortment Indicator',
      width: 200,
    },
    {
      field: 'OH+IT+OO',
      headerName: 'OH+IT+OO',
      width: 200,
    },
    { field: 'ecomm_sales_units', headerName: 'Ecomm Sales Units', width: 200 },
    { field: 'lw_sales_units', headerName: 'LW Sales Units', width: 160 },
    { field: 'target_quantity', headerName: 'Target Quantity', width: 160 },
    {
      field: 'open_target_quantity',
      headerName: 'Open Target Quantity',
      width: 200,
    },
  ],
  data: [],
};

const getColorCoding = (value) => {
  const performance = {
    below: {
      background: '#c7e7d0',
    },
    between: {
      background: '#fee8bf',
    },
    above: {
      background: '#f6c6c8',
    },
  };
  let label = '';
  if (value < 10) {
    label = 'below';
  } else if (value > 10 && value < 25) {
    label = 'between';
  } else if (value >= 25) {
    label = 'above';
  }

  return performance[`${label}`];
};

export const order_review_table_columns = [
  {
    field: 'skuID',
    headerName: 'SKU Id',
    pinned: 'left',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    width: 200,
  },
  {
    field: 'styleId',
    headerName: 'Style Id',
    pinned: 'left',
    width: 200,
  },
  {
    field: 'similarStyleId',
    pinned: 'left',
    headerName: 'Similar Style Id',
    cellRendererFramework: (cell) => (
      <Link to={`/inventory-smart/allocation-details-review`}>Map</Link>
    ),
  },
  {
    field: 'subclass',
    headerName: 'Subclass',
    width: 200,
    // pinned: 'left',
  },
  {
    field: 'itemGrading',
    headerName: 'Item Grading',
    width: 200,
    // pinned: 'left',
  },
  {
    field: 'DCLocation',
    headerName: 'DC Location',
    width: 200,
    // pinned: 'left',
  },
  {
    field: 'vendor',
    headerName: 'Vendor',
    width: 200,
    // pinned: 'left',
  },
  { field: 'storeOH', headerName: 'Store OH', width: 200 },
  { field: 'DCOH', headerName: 'DC OH', width: 200 },
  {
    field: 'expeditedOrders',
    headerName: 'Expedited orders',
    width: 200,
  },
  { field: 'orderQty', headerName: 'Order qty', width: 200 },
  {
    field: 'IASuggestedORderQty',
    headerName: 'IA suggested order qty',
    width: 280,
  },
  {
    field: 'constrainedOrderQty',
    headerName: 'Constrained order qty',
    width: 300,
  },
  {
    field: 'orderCost',
    headerName: 'Order cost',
    width: 200,
  },
  {
    field: 'orderPlacementDate',
    headerName: 'Order placement date',
    width: 250,
  },
  {
    field: 'expectedReceiptDate',
    headerName: 'Expected receipt date',
    width: 280,
  },
  {
    headerName: 'Safety stock at DC',
    width: 280,
    children: [
      {
        field: 'ss',
        headerName: 'SS',
        cellStyle: function (params) {
          let value = Math.abs(
            parseInt(
              ((parseInt(params.data.ss_lc) - parseInt(params.value)) /
                parseInt(params.data.ss_lc)) *
                100
            )
          );

          return getColorCoding(value);
        },
      },
      { field: 'ss_lc', headerName: 'SS LC' },
    ],
  },
  {
    headerName: 'WOS',
    width: 200,
    children: [
      {
        field: 'WOS',
        headerName: 'WOS',
        cellStyle: function (params) {
          let value = Math.abs(
            parseInt(
              ((parseInt(params.data.WOSLC) - parseInt(params.value)) /
                parseInt(params.data.WOSLC)) *
                100
            )
          );
          return getColorCoding(value);
        },
      },
      { field: 'WOSLC', headerName: 'WOS LC' },
    ],
  },
  {
    headerName: 'Service Level',
    width: 200,
    children: [
      {
        field: 'SL',
        headerName: 'SL',
        cellStyle: function (params) {
          let value = Math.abs(
            parseInt(
              ((parseInt(params.data.targetSL) - parseInt(params.value)) /
                parseInt(params.data.targetSL)) *
                100
            )
          );
          return getColorCoding(value);
        },
      },
      { field: 'targetSL', headerName: 'Target SL' },
    ],
  },
  {
    headerName: 'Lead Time',
    width: 200,
    children: [
      {
        field: 'effectiveLT',
        headerName: 'Effective LT',
      },
      {
        field: 'effectiveLTLC',
        headerName: 'Effective LT LC',
      },
    ],
  },
  {
    headerName: 'Demand Forecast',
    children: [
      {
        field: 'last120Days',
        headerName: 'Last 120 days',
        width: 200,
      },
      {
        field: 'last90Days',
        headerName: 'Last 90 days',
        width: 200,
      },
      {
        field: 'last30Days',
        headerName: 'Last 30 days',
        width: 200,
      },
      {
        field: '120DayFCST',
        headerName: '120 day fcst',
        width: 200,
      },
      {
        field: 'varLC120Days',
        headerName: 'var LC (120 days)',
        width: 200,
        cellStyle: function (params) {
          let value = Math.abs(
            parseInt(
              (Math.abs(params.value) / parseInt(params.data.last120Days)) * 100
            )
          );
          return getColorCoding(value);
        },
      },
      { field: '90DayFCST', headerName: '90 day fcst', width: 200 },
      {
        field: 'varLC90Days',
        headerName: 'var LC (90 days)',
        width: 200,
        cellStyle: function (params) {
          let value = Math.abs(
            parseInt(
              (Math.abs(params.value) / parseInt(params.data.last90Days)) * 100
            )
          );
          return getColorCoding(value);
        },
      },
      { field: '30DayFCST', headerName: '30 day fcst', width: 200 },
      {
        field: 'varLC30Days',
        headerName: 'var LC (30 days)',
        width: 200,
        cellStyle: function (params) {
          let value = Math.abs(
            parseInt(
              (Math.abs(params.value) / parseInt(params.data.last30Days)) * 100
            )
          );
          console.log(value);
          return getColorCoding(value);
        },
      },
    ],
  },
];

export const meioRecommendationColumns = [
  {
    headerName: 'SKU ID',
    field: 'sku_id',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    pinned: 'left',
    minWidth: 150,
  },
  {
    headerName: 'Subclass',
    field: 'sub_class',
    minWidth: 150,
  },
  {
    headerName: 'Item Grading',
    field: 'item_grading',
  },
  {
    headerName: 'Action Type',
    field: 'action_type',
  },
  {
    headerName: 'Source Location',
    field: 'source_location',
  },
  {
    headerName: 'Destination Location',
    field: 'destination_location',
    minWidth: 150,
  },
  {
    headerName: 'Order Qty',
    field: 'order_qty',
    minWidth: 150,
  },
  {
    headerName: 'Order Cost',
    field: 'order_cost',
  },
  {
    headerName: "Source Inventory",
    children: [
      {
        headerName: 'Source OH',
        field: 'source_oh',
      },
      {
        headerName: 'Source OO',
        field: 'source_oo',
      },
      {
        headerName: 'Source IT',
        field: 'source_it',
        minWidth: 150,
      },
      {
        headerName: 'Total',
        field: 'total_source_inventory'
      },
    ]
  },
  {
    headerName: "Destination Inventory",
    children: [
      {
        headerName: 'Destination OH',
        field: 'destination_oh',
        minWidth: 150,
      },
      
      {
        headerName: 'Destination OO',
        field: 'destination_oo',
        minWidth: 150,
      },
      {
        headerName: 'Destination IT',
        field: 'destination_it',
        minWidth: 150,
      },
      {
        headerName: 'Total',
        field: 'total_destination_inventory',
      },
    ]
  },
  {
    headerName: 'Order Placement Date',
    field: 'order_placement_date',
    minWidth: 150,
  },
  {
    headerName: 'Expected Receipt Date',
    field: 'expected_receipt_date',
    minWidth: 150,
  },
  {
    headerName: 'Pact Size',
    field: 'pact_size',
  },
  {
    headerName: 'Safety Stock at Destination',
    children: [
      {
        headerName: 'SS',
        field: 'ss',
      },
      {
        headerName: 'SS LC',
        field: 'ss_lc',
      },
    ],
  },
  {
    headerName: 'Destination WOS',
    children: [
      {
        headerName: 'WOS',
        field: 'wos',
      },
      {
        headerName: 'WOS LC',
        field: 'wos_lc',
      },
    ],
  },
  {
    headerName: 'Destination Service level',
    children: [
      {
        headerName: 'SL',
        field: 'sl',
      },
      {
        headerName: 'Target SL',
        field: 'target_sl',
      },
    ],
  },
  {
    headerName: 'Source to Dest. Lead Time',
    children: [
      {
        headerName: 'Effective LT',
        field: 'effective_lt',
      },
      {
        headerName: 'Effective LT LC',
        field: 'effective_lt_lc',
      },
    ],
  },
  {
    headerName: 'Destination Demand Forecast',
    children: [
      {
        headerName: 'Last 30 Days',
        field: 'last_30_days',
      },
      {
        headerName: '30 day Fcst',
        field: '30_day_fcst',
      },
      {
        headerName: 'Var LC (30 days)',
        field: 'var_lc_30_days',
      },
      {
        headerName: 'Last 90 Days',
        field: 'last_90_days',
      },
      {
        headerName: '90 day Fcst',
        field: '90_day_fcst',
      },
      {
        headerName: 'Var LC (90 days)',
        field: 'var_lc_90_days',
      },
      {
        headerName: 'Last 120 Days',
        field: 'last_120_days',
      },
      {
        headerName: '120 day Fcst',
        field: '120_day_fcst',
      },
      {
        headerName: 'Var LC (120 days)',
        field: 'var_lc_120_days',
      },
    ],
  },
];
export const deepDiveSkuSummary = [
  {
    field: "sku_id",
    headerName:"SKU ID"
  },
  {
    field: "order_qty",
    headerName:"Order Qty"
  },
  {
    field: "sku_description",
    headerName:"SKU Description"
  },
  {
    field: "class",
    headerName:"Class"
  },
  {
    field: "subclass",
    headerName:"Subclass"
  },
  {
    field: "product_grade",
    headerName:"Product Grade"
  },
  {
    field: "asset_vs_memo",
    headerName:"Asset vs Memo"
  },
  {
    field: "primary_source",
    headerName:"Primary Source"
  },
  {
    field: "source_id",
    headerName:"Source Id"
  },
  {
    field: "source_name",
    headerName:"Source Name"
  },
  {
    field: "destination_inventory",
    headerName:"Destination Inventory"
  },
  {
    field: "source_inventory",
    headerName:"Source Inventory"
  },
  {
    field: "total_inventory",
    headerName:"Total Inventory"
  },
  {
    field: "mrpc",
    headerName:"MRPC"
  },
  {
    field: "open_receipts",
    headerName:"Open Receipts"
  },
  {
    field: "inventory_hold",
    headerName:"Inventory Hold"
  },
  {
    field: "source_pack_size",
    headerName:"Source Pack Size"
  },
  {
    field: "min_order_qty",
    headerName:"Min. Order Qty"
  },
  {
    field: "max_order_qty",
    headerName:"Max Order Qty"
  },
  {
    field: "ia_suggested_order_qty",
    headerName: "IA Suggested Order Qty.",
    minWidth:200
  },
  {
    field: "mfp_constrained_order_qty",
    headerName: "MFP Constrained Order Qty.",
    minWidth:200
    
  },
  {
    field: "order_cost",
    headerName:"Order Cost ($)"
  },
  {
    field: "order_placement_recco_date",
    headerName: "Order Placement Recco. Date",
    minWidth:200
    
  },
  {
    field: "dc_not_before_date",
    headerName: "DC Not Before Date",
    minWidth:200
    
  },
  {
    field: "safety_stock",
    headerName:"Safety Stock"
  },
  {
    field: "lead_time",
    headerName:"Lead Time"
  }
  
];
export const deepDiveVs = [
  {
    field: "month",
    headerName:"Month"
  },
  {
    field: "week_start_date",
    headerName:"Store Forecast"
  },
  {
    field: "store_forecast",
    headerName:"Store Forecast"
  },
  {
    field: "dc_forecast",
    headerName:"DC Forecast"
  },
  {
    field: "proj_dc_inventory",
    headerName:"Proj. DC Inventory"
  },
  {
    field: "order_qty",
    headerName:"Order Qty."
  },
  {
    field: "order_cost",
    headerName:"Order Cost ($)"
  },
  {
    field: "on_order",
    headerName:"On Order"
  },
  {
    field: "receipt_inventory",
    headerName:"Receipt Inventory"
  },
  {
    field: "safety_stock_unit",
    headerName:"Safety Stock Unit"
  }
  
];
