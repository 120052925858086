import { combineReducers } from 'redux';
// import { connectRouter } from "connected-react-router";
import {
  decisionReducer,
  executiveSummaryReducer,
  commonReducer,
  orderManagement,
} from '../../modules/InventorySmart/reducers';
export const rootReducer = (history) =>
  combineReducers({
    // router: connectRouter(history),
    router: history,
    execSummary: executiveSummaryReducer,
    decision: decisionReducer,
    common: commonReducer,
    orderManagement: orderManagement,
  });
