import Button from '@mui/material/Button';

const tableData = {
  title: '',
  key: 'tb_supplier_to_warehouse_pending',
  column: [
    {
      headerName: 'Pinned',
      children: [
        {
          field: 'order_id',
          headerName: 'Order ID',
          headerCheckboxSelection: true,
          checkboxSelection: true,
          pinned: 'left',
          width: 145,
        },
        {
          field: 'vendor_name',
          headerName: 'Vendor Name',
          pinned: 'left',
          width: 140,
        },
        {
          field: 'location',
          headerName: 'Location',
          width: 120,
          pinned: 'left',
        },
        {
          field: 'parent_sku',
          headerName: 'Parent SKU',
          width: 140,
          pinned: 'left',
        },
        {
          field: 'sku_id',
          headerName: 'SKU ID',
          pinned: 'left',
          width: 130,
          columnGroupShow: 'closed',
          filter: 'agNumberColumnFilter',
        },
        {
          field: 'sku_id',
          headerName: 'SKU ID',
          pinned: 'left',
          width: 130,
          columnGroupShow: 'open',
        },
        {
          field: 'description',
          headerName: 'Description',
          width: 200,
          columnGroupShow: 'open',
          pinned: 'left',
        },
        {
          field: 'department',
          headerName: 'Department',
          columnGroupShow: 'open',
          pinned: 'left',
          width: 130,
        },
        {
          field: 'category',
          headerName: 'Category',
          columnGroupShow: 'open',
          pinned: 'left',
          width: 120,
        },
        {
          field: 'class',
          headerName: 'Class',
          columnGroupShow: 'open',
          pinned: 'left',
          width: 120,
        },
        {
          field: 'item_grading',
          headerName: 'Item gradding',
          width: 150,
          columnGroupShow: 'open',
          pinned: 'left',
        },
      ],
    },
    { field: 'store_inv', headerName: 'Store Inv.', width: 170 },
    { field: 'dc_inv', headerName: 'DC Inv.', width: 170 },
    { field: 'system_inv', headerName: 'System Inv.', width: 170 },
    { field: 'order_qty', headerName: 'Order Qty', width: 170 },
    { field: 'order_cost', headerName: 'Order Cost', width: 170 },
    {
      field: 'order_placement_date',
      headerName: 'Order Placement Date',
      width: 220,
    },
    {
      field: 'order_generation_type',
      headerName: 'Order Generation Type',
      width: 220,
    },
    {
      field: 'last_date_to_approve',
      headerName: 'Last Date to Approve',
      width: 250,
    },
    { field: 'lead_time', headerName: 'Lead Time (days)', width: 190 },
  ],
  data: [],
};

export const tabListData = {
  supplier_to_warehouse: {
    label: 'Supplier to Warehouse',
    value: 'supplier_to_warehouse',
    apiKey: [
      'tb_supplier_to_warehouse_pending',
      'tb_supplier_to_warehouse_approved',
      'tb_supplier_to_warehouse_card',
    ],
    filters: true,
    card: {
      title: '',
      key: 'tb_supplier_to_warehouse_card',
      data: [
        {
          label: 'Order status',
          list: [
            { name: 'Recomm. #Orders', count: 20 },
            { name: 'Approval pending orders', count: 20 },
            { name: 'Approved orders', count: 0 },
          ],
        },
        {
          label: 'Order quality',
          list: [
            { name: 'Recomm. #Orders', count: 20 },
            { name: 'Approval pending orders', count: 20 },
            { name: 'Approved orders', count: 0 },
          ],
        },
        {
          label: 'Order cost',
          list: [
            { name: 'Recomm. #Orders', count: 20 },
            { name: 'Approval pending orders', count: 20 },
            { name: 'Approved orders', count: 0 },
          ],
        },
      ],
    },
    table: [
      {
        ...tableData,
        title: 'Pending Orders',
        enableCheckbox: true,
        actionButton: [
          {
            name: 'Optimize',
            varient: 'contained',
            size: 'small',
            navigation: 'optimize',
            disableFeat: true,
          },
          {
            name: 'Approve',
            varient: 'contained',
            size: 'small',
            navigation: 'approve',
          },
          {
            name: 'Deep Dive',
            varient: 'contained',
            size: 'small',
            navigation: 'deep_dive',
          },
          {
            name: 'What If',
            varient: 'contained',
            size: 'small',
            navigation: 'what_if',
          },
          {
            name: 'Cancel',
            varient: 'outlined',
            size: 'small',
            navigation: 'cancel',
          },
        ],
      },
      {
        ...tableData,
        title: 'Approved Orders',
        key: 'tb_supplier_to_warehouse_approved',
        column: [
          {
            headerName: 'Pinned',
            children: [
              {
                field: 'order_id',
                headerName: 'Order ID',
                headerCheckboxSelection: true,
                checkboxSelection: true,
                pinned: 'left',
                width: 145,
              },
              {
                field: 'vendor_name',
                headerName: 'Vendor Name',
                pinned: 'left',
                width: 140,
              },
              {
                field: 'location',
                headerName: 'Location',
                width: 120,
                pinned: 'left',
              },
              {
                field: 'parent_sku',
                headerName: 'Parent SKU',
                width: 140,
                pinned: 'left',
              },
              {
                field: 'sku_id',
                headerName: 'SKU ID',
                pinned: 'left',
                width: 130,
                columnGroupShow: 'closed',
                filter: 'agNumberColumnFilter',
              },
              {
                field: 'sku_id',
                headerName: 'SKU ID',
                pinned: 'left',
                width: 130,
                columnGroupShow: 'open',
              },
              {
                field: 'description',
                headerName: 'Description',
                width: 200,
                columnGroupShow: 'open',
                pinned: 'left',
              },
              {
                field: 'department',
                headerName: 'Department',
                columnGroupShow: 'open',
                pinned: 'left',
                width: 130,
              },
              {
                field: 'category',
                headerName: 'Category',
                columnGroupShow: 'open',
                pinned: 'left',
                width: 120,
              },
              {
                field: 'class',
                headerName: 'Class',
                columnGroupShow: 'open',
                pinned: 'left',
                width: 120,
              },
              {
                field: 'item_grading',
                headerName: 'Item gradding',
                width: 150,
                columnGroupShow: 'open',
                pinned: 'left',
              },
            ],
          },
          { field: 'expedite_order', headerName: 'Expedite Order', width: 170 },
          { field: 'store_inv', headerName: 'Store Inv.', width: 170 },
          { field: 'dc_inv', headerName: 'DC Inv.', width: 170 },
          { field: 'system_inv', headerName: 'System Inv.', width: 170 },
          { field: 'order_qty', headerName: 'Order Qty', width: 170 },
          { field: 'order_cost', headerName: 'Order Cost', width: 170 },
          {
            field: 'order_placement_date',
            headerName: 'Order Placement Date',
            width: 220,
          },
          {
            field: 'order_generation_type',
            headerName: 'Order Generation Type',
            width: 220,
          },
          {
            field: 'last_date_to_modify',
            headerName: 'Last Date to Modify',
            width: 250,
          },
          { field: 'lead_time', headerName: 'Lead Time (days)', width: 190 },
        ],
        bottomActionButton: [
          {
            name: 'Create PO',
            varient: 'contained',
            size: 'medium',
            navigation: 'create',
          },
        ],
      },
    ],
  },
  warehouse_to_store: {
    label: 'Warehouse to Store',
    value: 'warehouse_to_store',
    apiKey: ['tb_warehouse_to_store'],
    filters: true,
    table: [
      {
        title: 'Allocation & Replenishment recom. plans',
        key: 'tb_warehouse_to_store',
        height : '70vh',
        column: [
          { field: 'plan_id', headerName: 'Plan ID', headerCheckboxSelection: true,
          checkboxSelection: true, },
          { field: 'plan_name', headerName: 'Plan Name' },
          { field: 'plan_type', headerName: 'Plan Type' },
          { field: 'created_by', headerName: 'Created by' },
          { field: 'created_on', headerName: 'Created on' },
          { field: 'styles', headerName: '# Styles' },
          { field: 'stores', headerName: '# Stores' },
          { field: 'release_status', headerName: 'Release Status' },
          { field: 'breach_cases', headerName: 'Breach Cases(True/False)', width: 300 },
          {
            field: 'action',
            headerName: 'Action',
            cellRendererFramework: (cell) => (
              <Button
                style={{ textTransform: 'none' }}
                autoFocus
                variant='text'
              >
                Detailed View
              </Button>
            ),
          },
        ],
        bottomActionButton: [
          {
            name: 'Approve',
            varient: 'contained',
            size: 'medium',
            navigation: 'daily_allocation_summary',
          },
        ],
        data: [],
      },
    ],
  },
};
