import callApi from '../../../../utils/callApi';

export const fetchSheetData = (filterProperty, reqObj) => {
  let url = encodeURIComponent(filterProperty);
  return callApi
    .post(`get_data_model?model_name=${url}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
