import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './Table.scss';
import CustomImage from './CustomImage';
import ProductAction from './ProductAction';
import Status from './Status';
import Exceptions from './Exceptions';
import BlockText from './BlockText';
import { theme } from '../../theme';
import PercentBlock from './PercentBlock';
import { EditWeekData } from '../../modules/InventorySmart/pages/ADAVisuals/editWeekData';
import { getColumnDefs } from './utils';

const Table = (props) => {
  const gridRef = useRef();
  const [modal, setModal] = useState(false);
  const [columnDefs, setColumnDefs] = useState([props.columnDefs]);
  const [defaultColDef, setDefaultColDef] = useState({
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: 'agTextColumnFilter',
    editable: false,
    resizable: true,
    minWidth: 120,
    initialWidth: 120,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  });
  const [context, setContext] = useState({
    editedColumns: [],
  });
  const [gridColumnApi, setGridColumnApi] = useState({});
  const [rowData, setRowData] = useState(props.rowData);
  const [frameworkComponents, setFrameworkComponents] = useState({
    childMessageRenderer: CustomImage,
    actionCellRenderer: ProductAction,
    statusCellRenderer: Status,
    exceptionsCellRenderer: Exceptions,
    blockCellRenderer: BlockText,
    percentCellRenderer: PercentBlock,
    editweekData: EditWeekData,
  });
  const [gridApi, setGridApi] = useState({});
  useEffect(() => {
    setRowData(props.rowData);
  }, [props.rowData]);

  useEffect(() => {
    if (props.columnDefs.length > 0) {
      setColumnDefs(getColumnDefs(props.columnDefs));
    }
    setColumnDefs(props.columnDefs);
  }, [props.columnDefs]);

  const onFirstDataRendered = (params) => {
    setGridApi(params.api);

    if (
      props.tableCssStyle === 'default' ||
      props.tableCssStyle === undefined
    ) {
      var allColumnIds = [];
      gridColumnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      gridColumnApi.autoSizeColumns(allColumnIds, 'skipHeader');
    }
    if (props.tableCssStyle === 'sizeColumnsToFit') {
      params.api.sizeColumnsToFit();
    }
    if (props.tableCssStyle === 'autoSizeAllColumns') {
      params.api.autoSizeAllColumns();
    }

    var padding = 10;
    var height = headerHeightGetter() + padding;
    var groupHeaderHeight = groupHeaderHeightGetter() - 10;

    params.api.setHeaderHeight(height);
    params.api.setGroupHeaderHeight(groupHeaderHeight);
    params.api.resetRowHeights();
  };

  // general search
  const onQuickFilterChanged = () => {
    gridApi.setQuickFilter(document.getElementById('quickFilter').value);
  };

  const headerHeightGetter = () => {
    var columnHeaderTexts = [
      ...document.querySelectorAll('.ag-header-cell-text'),
    ];
    var clientHeights = columnHeaderTexts.map(
      (headerText) => headerText.clientHeight
    );
    var tallestHeaderTextHeight = Math.max(...clientHeights);

    return tallestHeaderTextHeight;
  };

  const groupHeaderHeightGetter = () => {
    //ag-header-group-cell-label
    var columnHeaderTexts = [
      ...document.querySelectorAll('.ag-header-group-cell-label'),
    ];

    var clientHeights = columnHeaderTexts.map(
      (headerText) => headerText.clientHeight
    );
    var tallestHeaderTextHeight = Math.max(...clientHeights);

    return tallestHeaderTextHeight;
  };

  // clear filters
  const clearFilters = () => {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged();
  };

  const isImagePresent = () => {
    const imageColumn = props.columnDefs.find((column) => {
      return column?.cellRenderer === 'childMessageRenderer';
    });

    if (imageColumn) {
      return true;
    }

    return false;
  };

  const getRowStyle = (params) => {
    if (params.data && params.data.is_finalized) {
      return { background: theme.palette.bgTableFinalized };
    } else if (params.data && params.data.is_product_performance) {
      return {
        background:
          params.data.status === 'track'
            ? theme.palette.bgSuccessTransparent
            : params.data.status === 'over'
            ? theme.palette.bgWarningTransparent
            : theme.palette.bgDangerTransparent,
      };
    }

    return { background: theme.palette.textWhite };
  };
  const onGridReady = async (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const cellValueChanged = async (params) => {
    console.log("On Cell Value Changed")
    if (params.newValue !== params.oldValue) {
      const updatedData = [];
      updatedData.push({
        rowIndex: params.rowIndex,
        colId: params.column.colId,
      });
      setContext({
        // ...context,
        editedColumns: [...context.editedColumns].concat(updatedData),
      });
      if(props.onCellValueChanged){
        props.onCellValueChanged(params)
      }
      // gridApi.redrawRows();
      // gridRef.current.api.redrawRows();
    }
  };

  const onSelectionChanged = (event) => {
    const selectedOptions = gridApi.getSelectedRows();
    if (props.onRowselectionChanged) {
      props.onRowselectionChanged(selectedOptions, gridApi);
      console.log(selectedOptions);
    }
  };

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      // defaultToolPanel: 'filters',
    };
  }, []);

  return (
    <div
      id='myGrid'
      style={{
        height: props.height || '900px',
        width: '100%',
        // marginTop: '25px',
      }}
      className={
        isImagePresent()
          ? 'ag-theme-custom-react-image'
          : 'ag-theme-custom-react'
      }
    >
      <AgGridReact
        // onGridReady={(params) => (this.gridApi = params.api)}
        // ref={props.ref}
        ref={gridRef}
        onGridReady={onGridReady}
        // rowSelection={props.columnDefs[0].rowSelection || 'multiple'}
        rowSelection={props.rowSelection}
        suppressRowClickSelection={true}
        columnDefs={columnDefs}
        defaultColDef={{ ...defaultColDef, editable: true }}
        // defaultColDef={{ ...state.defaultColDef}}
        sideBar={sideBar}
        groupSelectsChildren={true}
        pagination={props.pagination}
        // paginationPageSize={pageSize}
        paginateChildRows={true}
        autoGroupColumnDef={props.autoGroupColumnDef}
        rowData={rowData}
        masterDetail={true}
        onFirstDataRendered={onFirstDataRendered}
        // detailCellRendererParams={state.detailCellRendererParams}
        floatingFilter={props.floatingFilter}
        cacheQuickFilter={true}
        // isExternalFilterPresent={isExternalFilterPresent}
        // doesExternalFilterPass={doesExternalFilterPass}
        suppressMenuHide={true}
        getRowStyle={getRowStyle}
        frameworkComponents={props.frameworkComponents}
        onCellValueChanged={cellValueChanged}
        context={context}
        onSelectionChanged={onSelectionChanged}
        suppressAggFuncInHeader={true}
        {...props}
      />
    </div>
  );
};

export default Table;
