import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function AllocationAlertColumns() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const changeFunction = (cell) => {
    // switch (cell.data.action_item) {
    //   case 'Inventory Transfer':
    //     navigate('/inventory-smart/review-recommendation');
    //     break;
    //   case 'New Product Introduction':
    //     navigate('/inventory-smart/new-product-allocation');
    //     break;
    //   default:
    //     dispatch({
    //       type: 'SHOW_REVIEW_TABLE',
    //       payload: true,
    //     });
    //     break;
    // }

    if (cell.data.action_item.includes('Inventory Transfer')) {
      navigate('/inventory-smart/review-recommendation');
    } else if (cell.data.action_item.includes('wholesale')) {
      navigate('/inventory-smart/wholesale-new-product-allocation');
    } else if (cell.data.action_item.includes('Product Introduction')) {
      navigate('/inventory-smart/new-product-allocation?alert-flow=pre-allocation');
    } else if (cell.data.action_item.includes('DC Inventory  Transfer')) {
      navigate('/inventory-smart/allocation-alert-review-recommendation');
    } else if (cell.data.action_item.includes('Preallocation from open PO')) {
      dispatch({
        type: 'SHOW_PREALLOCATION_TABLE',
        payload: true,
      });
    }
    else {
      dispatch({
        type: 'SHOW_REVIEW_TABLE',
        payload: true,
      });
    }
  };
  return [
    { field: 'action_item', headerName: 'Action Item', minWidth: 320 },
    { field: 'description', headerName: 'Description', minWidth: 320 },
    { field: 'style_color', headerName: '# Style Color' },
    { field: 'bulk_remaining', headerName: 'Bulk Remaining' },
    {
      field: 'action',
      headerName: 'Actions',
      cellRendererFramework: (cell) => {
        return (
          <u
            style={{ color: '#541a8b', cursor: 'pointer' }}
            onClick={() => changeFunction(cell)}
            className='table_url'
          >
            Review Recommendation
          </u>
        );
      },
    },
  ];
}
