export const order_attributes = [
  {
    label: 'Demand',
    value: '0',
  },
  {
    label: 'Service Level',
    value: '1',
  },
  {
    label: 'Order Quantity',
    value: '2',
  },
  {
    label: 'Allocation Quantity',
    value: '3',
  },
];

export const forecasting_attributes = [
  {
    label: 'Demand',
    value: '0',
  },
  {
    label: 'Seasonality',
    value: '1',
  },
  {
    label: 'Event',
    value: '2',
  },
  {
    label: 'Discounts/Promotions',
    value: '3',
  },
];

export const allocation_attributes = [
  {
    label: 'Allocated Quantity',
    value: '0',
  },
];

export const modules = [
  {
    label: 'Allocation',
    value: '0',
  },
  {
    label: 'Forecasting',
    value: '1',
  },
  {
    label: 'Order Management',
    value: '2',
  },
];

export const step_size = [
  {
    label: '5%',
    value: '0',
  },
  {
    label: '10%',
    value: '1',
  },
];

export const order_columns = [
  {
    field: 'scenarios',
    headerName: 'Scenarios',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    pinned: 'left',
    width: 160,
  },
  { field: 'order_id', headerName: 'Order ID', pinned: 'left', width: 120 },
  { field: 'sku_id', headerName: 'SKU ID', pinned: 'left', width: 120 },
  { field: 'order_status', headerName: 'Order Status', width: 150 },
  { field: 'department', headerName: 'Department', width: 140 },
  { field: 'class', headerName: 'Class', width: 120 },
  { field: 'sub_class', headerName: 'Sub Class', width: 120 },
  { field: 'dc_location', headerName: 'DC Location', width: 140 },
  { field: 'item_grading', headerName: 'Item Grading', width: 140 },
  { field: 'store_oh', headerName: 'Store OH', width: 120 },
  { field: 'dc_oh', headerName: 'DC OH', width: 120 },
  { field: 'dc_roq', headerName: 'DC ROQ', width: 120 },
  { field: 'order_value_usd', headerName: 'Order Value (USD)', width: 180 },
  { field: 'service_level', headerName: 'Service Level', width: 150 },
  {
    field: 'order_placement_date',
    headerName: 'Order Placement Date',
    width: 200,
  },
  { field: 'vendor_name', headerName: 'Vendor Name', width: 150 },
  { field: 'lead_time_effective_LT', headerName: 'Lead Time Effective LT', width: 200 },
];

export const allocation_columns = [
  {
    field: 'scenarios',
    headerName: 'Scenarios',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    pinned: 'left',
    width: 160,
  },
  {
    field: 'allocation_quantity_%_change',
    headerName: 'Allocation Quantity % Change',
    width: 200,
  },
  { field: '#_style_color', headerName: '# Style Color', width: 150 },
  { field: '#_stores', headerName: '# Stores', width: 150 },
  { field: 'style_color_dept', headerName: 'Style Color Dept.', width: 170 },
  {
    headerName: 'Allocation Qty.',
    children: [
      { field: 'allocation_qty_dc_1', headerName: 'DC1', width: 100 },
      { field: 'allocation_qty_dc_2', headerName: 'DC2', width: 100 },
      { field: 'allocation_qty_dc_3', headerName: 'DC3', width: 100 },
    ],
  },
  {
    headerName: 'Net DC Available',
    children: [
      { field: 'net_dc_available_dc_1', headerName: 'DC1', width: 100 },
      { field: 'net_dc_available_dc_2', headerName: 'DC2', width: 100 },
      { field: 'net_dc_available_dc_3', headerName: 'DC3', width: 100 },
    ],
  },
  {
    headerName: 'Store Grade Allocation',
    children: [
      { field: 'store_grade_allocation_AAA', headerName: 'AAA', width: 120 },
      { field: 'store_grade_allocation_A', headerName: 'A', width: 80 },
      { field: 'store_grade_allocation_B', headerName: 'B', width: 80 },
      { field: 'store_grade_allocation_C', headerName: 'C', width: 80 },
      { field: 'store_grade_allocation_D', headerName: 'D', width: 80 },
    ],
  },
  {
    headerName: 'Store grade - Allocation Index',
    children: [
      { field: 'store_grade-allocation_index_AAA', headerName: 'AAA', width: 120 },
      { field: 'store_grade-allocation_index_A', headerName: 'A', width: 80 },
      { field: 'store_grade-allocation_index_B', headerName: 'B', width: 80 },
      { field: 'store_grade-allocation_index_C', headerName: 'C', width: 80 },
      { field: 'store_grade-allocation_index_D', headerName: 'D', width: 80 },
    ],
  },
];

export const forecasting_columns = [
  {
    field: 'scenarios',
    headerName: 'Scenarios',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    pinned: 'left',
    width: 180,
  },
  { field: 'attribute_factor', headerName: 'Attribute Factor', width: 180 },
  { field: 'all_weeks', headerName: 'All Weeks', width: 150 },
  { field: 'week_01', headerName: 'Week 01', width: 130 },
  { field: 'week_02', headerName: 'Week 02', width: 130 },
  { field: 'week_03', headerName: 'Week 03', width: 130 },
  { field: 'week_04', headerName: 'Week 04', width: 130 },
  { field: 'week_05', headerName: 'Week 05', width: 130 },
  { field: 'week_06', headerName: 'Week 06', width: 130 },
  { field: 'week_07', headerName: 'Week 07', width: 130 },
  { field: 'week_08', headerName: 'Week 08', width: 130 },
];

export const filterData = {
  channel: { label: 'Department', section: ['Womens Shoes'] },
  date_range: { label: 'Class', section: ['Casual'] },
  store: { label: 'Sub Class', section: ['All'] },
  merchanise_category: { label: 'Merchandise Category', section: ['All'] },
  asset_vs_memo: { label: 'Asset vs Memo', section: ['All'] },
  product_grade: { label: 'Product Grade', section: ['All'] },
  region: { label: 'Region', section: ['All'] },
};
