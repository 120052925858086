import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SelectRenderer from '../../../../components/Select/SelectRenderer';
import TableVariation from '../ReviewAllocation/TableVariation';
import ReplenishmentStepper from '../../../../components/ReplenishmentStepper/ReplenishmentStepper';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import FiltersList from '../../../../molecules/FiltersList';
import CustomCard from '../../../../components/CustomCard';
import Snackbar from '../../../../components/SnackBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextFieldWithLabel from '../../../../components/TextField/TextFieldWithLabel';
import {
  wholesale_create_new_allocation_columns,
  wholesaleTabListData,
  style_colors,
  optimization,
  create_pre_allocation_columns,
  updatedTabListData
} from './data';
import Table from '../../../../components/Table/Table';
import CustomButton from '../../../../components/CustomButton';
import { fetchHierarchyData } from './action';

const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  headerCard: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
  },
});

const useStyles = makeStyles(styles);

const NewProductAllocation = () => {
  const actionBtn = useRef(null);
  const classes = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = useState('account_details');
  const [isLoading, setIsLoading] = React.useState(true);
  const [displayMessage, setDisplayMessage] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [updateData, setupdateData] = React.useState(false);
  const [createNewAllocationData, setCreateNewAllocationData] = React.useState(
    []
  );
  const [createNewAllocationColumns, setCreateNewAllocationColumns] = React.useState(wholesale_create_new_allocation_columns);
  const [deepDivetableData, setDeepDiveTableData] = React.useState([]);
  const [showStoreSource, setShowStoreSource] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [createNewAllocationDataCopy, setCreateNewAllocationDataCopy] =
    React.useState([]);
  const editableHeaders = [
    {
      name: 'Store Eligibility',
      type: 'text',
    },
    {
      name: 'Inventory Source',
      type: 'text',
    },
    {
      name: 'Demand Type',
      type: 'text',
    },
    {
      name: 'Inventory Source',
      type: 'text',
    },
    {
      name: 'WOS',
      type: 'number',
    },
    {
      name: 'Min',
      type: 'number',
    },
    {
      name: 'Max',
      type: 'number',
    },
  ];

  const getData = async (label, content) => {
    setIsLoading(true);
    const data = await fetchHierarchyData(label);
    if (data.length !== 0) {
      setIsLoading(false);
      if (content === 'create_new_allocation') {
        setCreateNewAllocationData(data);
        setCreateNewAllocationDataCopy(data);
      } else setDeepDiveTableData(data);
    }
  };

  useEffect(() => {
    getData('tb_wholesale_create_new_allocation', 'create_new_allocation');
    getData('tb_rep_alert_deep_dive', 'deep_dive');
    setCreateNewAllocationColumns(wholesale_create_new_allocation_columns);
  }, []);

  const clickHandler = () => {
    setShowStoreSource(true);
    setTimeout(() => {
      actionBtn.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  };

  const handleChange = (event, newValue) => {
    // setCurentObject(tabListData[newValue]);
    setValue(newValue);
    setIsLoading(false);
  };

  const selectedColor = () => {
    return null;
  };

  const handleClose = () => {
    setDisplayMessage(false);
  };

  const reloadFilters = async () => {};

  const openLoader = () => {
    console.log('Open Loader');
    setLoader(true);
  };
  const closeLoader = () => {
    setLoader(false);
  };
  const updateState = (params) => {
    console.log('Updating state', params);
    setSelectedFilters(params);
  };
  const appliedFiltersAction = async () => {
    console.log('Seldcted filters', selectedFilters);
    if (
      selectedFilters['Department'] &&
      selectedFilters['Department'].length > 0
    ) {
      const departmentValues = selectedFilters['Department'].map(
        (depObj) => depObj.value
      );
      const newAllocationData = createNewAllocationDataCopy.filter(
        (obj) => departmentValues.indexOf(obj['department']) !== -1
      );
      setCreateNewAllocationData([...newAllocationData]);
    } else {
      setCreateNewAllocationData([...createNewAllocationDataCopy]);
    }
  };
  const onReset = () => {
    reloadFilters();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSave = () => {
    setupdateData(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleApply = () => {
    setOpen(false);
    setMessage('Changes Applied');
    setDisplayMessage(true);
  };

  const onSelectionChanged = (rows, gridApiObj) => {
    console.log('selctedrows', gridApiObj.getSelectedRows());
  };

  return (
    <>
      <LoadingOverlay loader={loader}>
        {isLoading && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress className={classes.loader} />
          </div>
        )}

        {!isLoading && (
          <>
            {displayMessage && (
              <Snackbar
                message={message}
                variant='success'
                handleClose={handleClose}
              />
            )}

            <Grid>
              <Grid>
                <FiltersList
                  openLoader={openLoader}
                  closeLoader={closeLoader}
                  updateParentState={updateState}
                  appliedFilters={appliedFiltersAction}
                  onReset={onReset}
                  screenName={'Filter Products'}
                />
              </Grid>
            </Grid>
            <div className='impact_grid_sub_container'>
              <div class='create_allocation_contianer'>
                <p style={{ fontWeight: 'bold', fontSize: 'large' }}>
                  Create New Allocations
                </p>

                <ReplenishmentStepper activeStep={0} />
                <div className='spacer'></div>
                <div className='outer-container'>
                  <TextFieldWithLabel
                    type='text'
                    label={'Allocation Plan Name'}
                    width='15rem'
                  />
                </div>
                <div className='spacer'></div>
                <div style={{ textAlign: 'right' }}>
                  <CustomButton
                    isPrimary={true}
                    variant='contained'
                    height={36}
                    width={100}
                    label='Set All'
                    padding='10'
                    margin={13}
                    onButtonClick={() => handleClickOpen()}
                  />
                </div>
                <CustomCard cardStyles={classes.headerCard}>
                  <Table
                    rowData={createNewAllocationData}
                    columnDefs={createNewAllocationColumns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    floatingFilter={false}
                    height={'450px'}
                    rowSelection={'multiple'}
                    onRowselectionChanged={onSelectionChanged}
                  />
                </CustomCard>

                <div
                  className={classes.saveButton}
                  style={{ display: 'flex', justifyContent: 'right' }}
                >
                  <CustomButton
                    isPrimary={true}
                    variant='contained'
                    height={36}
                    width={150}
                    label='Review Forecast'
                    margin={10}
                    onButtonClick={() =>
                      navigate('/inventory-smart/ada_visuals')
                    }
                  />
                  <CustomButton
                    isPrimary={true}
                    variant='contained'
                    height={36}
                    width={250}
                    label='Review Accounts and Inv. Source'
                    margin={10}
                    onButtonClick={clickHandler}
                  />
                </div>
              </div>
            </div>
            {showStoreSource && (
              <>
                <Grid className='impact_grid_sub_container' item xs={12}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label='wrapped label tabs example'
                  >
                    {Object.values(wholesaleTabListData).map((item) => (
                      <Tab
                        value={item.value}
                        label={item.label}
                        style={{
                          textTransform: 'none',
                        }}
                      />
                    ))}
                  </Tabs>
                  <div
                    style={{
                      padding: '1rem',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Grid item md={3}>
                      <SelectRenderer
                        options={style_colors}
                        isMulti={false}
                        width='15rem'
                        filterLabel={'Style Color'}
                        updateSelected={selectedColor}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <SelectRenderer
                        options={optimization}
                        isMulti={false}
                        width='15rem'
                        filterLabel={'Optimization Method'}
                        updateSelected={selectedColor}
                      />
                    </Grid>
                    <div
                    className={classes.saveButton}
                    style={{ display: 'flex', justifyContent: 'right' }}
                  >
                    <div>
                      <CustomButton
                        isPrimary={true}
                        variant='contained'
                        height={36}
                        width={100}
                        label='Save'
                        padding='10'
                      />
                    </div>
                    <div>
                      <CustomButton
                        isPrimary={true}
                        variant='contained'
                        height={36}
                        width={100}
                        label='Set All'
                        margin={5}
                        padding='10'
                        onButtonClick={() => handleClickOpen()}
                      />
                    </div>
                  </div>
                  </div>
                  {isLoading && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress className={classes.loader} />
                    </div>
                  )}
                  {!isLoading && !updateData &&
                    wholesaleTabListData[value].tables?.map((item) => (
                      <TableVariation data={item} />
                    ))}
                  {!isLoading && updateData &&
                    updatedTabListData[value].tables?.map((item) => (
                      <TableVariation data={item} />
                    ))}
                  <div
                    className={classes.saveButton}
                    style={{ display: 'flex', justifyContent: 'right' }}
                  >
                    <CustomButton
                      isPrimary={true}
                      variant='outlined'
                      height={36}
                      width={150}
                      label='Save as draft'
                      padding='100'
                      margin={10}
                      onButtonClick={() => {
                        setMessage('Saved as draft');
                        setDisplayMessage(true);
                      }}
                    />

                    <CustomButton
                      isPrimary={true}
                      variant='contained'
                      height={36}
                      width={150}
                      label='Recommendations'
                      padding='100'
                      margin={10}
                      onButtonClick={() =>
                        navigate(
                          '/inventory-smart/wholesale-recommendations'
                        )
                      }
                    />
                  </div>
                </Grid>
              </>
            )}
            <Dialog
              open={open}
              onClose={handleCancel}
              aria-labelledby='alert-dialog-title'
              maxWidth={'lg'}
              fullWidth
            >
              <DialogTitle id='alert-dialog-title'>{'Set All'}</DialogTitle>
              <DialogContent>
                <div style={{ display: 'flex' }}>
                  {editableHeaders.map((header) => (
                    <div style={{ marginTop: '2rem', marginLeft: '2rem' }}>
                      <TextFieldWithLabel
                        type={header.type}
                        label={header.name}
                        width='8rem'
                      />
                    </div>
                  ))}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  style={{ textTransform: 'none' }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  style={{ textTransform: 'none' }}
                  onClick={handleApply}
                  autoFocus
                >
                  Apply
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </LoadingOverlay>
    </>
  );
};

export default NewProductAllocation;
