import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Table } from '../../../../components/Table';
import CustomCard from '../../../../components/CustomCard';
import SelectRenderer from '../../../../components/Select/SelectRenderer';
import { tableObject, modalData } from './data';
import getTabListData from './TableData';
import { fetchHierarchyData } from './action';
import Snackbar from '../../../../components/SnackBar';
import LoadingOverlay from '../../../../components/LoadingOverlay';
const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  headerCard: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
});

const useStyles = makeStyles(styles);

const ReviewRecommendation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showStoreModal } = useSelector((state) => state.decision);
  const [isLoading, setIsLoading] = useState(false);
  const [curentObject, setCurentObject] = useState({});
  const [tableData, setTableData] = useState([]);
  const [modalTableData, setModalTableData] = useState([]);
  const [unit, setUnit] = useState(0);
  const [store, setStore] = useState();
  const [displayMessage, setDisplayMessage] = useState(false);
  
  const [message, setMessage] = useState("Scenario Approved");

  const columns = useMemo(() => getTabListData());
  const navigate = useNavigate();
  const getData = async () => {
    const data = await fetchHierarchyData('tb_review_recommendation');
    const popUpData = await fetchHierarchyData('tb_rr_store_transfer');
    if (data) setTableData(data);
    if (popUpData) setModalTableData(popUpData);
    setIsLoading(false);
  };

  useEffect(() => {
    setCurentObject(tableObject);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  const handleClose = () => {
    setMessage("Scenario Approved!")
    setDisplayMessage(true);
    dispatch({
      type: 'SHOW_STORE_TRANSFER_POPUP',
      payload: false,
    });
    setTimeout(() => {
      navigate('inventory-smart/decision-dashboard');
    },1500);
  };

  const navigateToDashboard = () => {
    setMessage("All selected stores has been finalized!")
    setDisplayMessage(true);
    setTimeout(() => {
      navigate('inventory-smart/decision-dashboard');
    },2000);
  };

  const handleMessageClose = () => {
    setDisplayMessage(false);
  };

  const addScenario = () => {
    setModalTableData([
      ...modalTableData,
      {
        scenario: 'Scenario 1',
        store: store,
        units: unit,
        sales: '$26000',
        sales_unit: 1100,
        gm: '-$4200',
      },
    ]);
  };

  const handleOnSelect = (value) => {
    setStore(value.selectedItems[0].value);
  };
  return (
    <LoadingOverlay loader={isLoading}>
      {displayMessage && (
        <Snackbar
          message={message}
          variant='success'
          handleClose={handleMessageClose}
        />
      )}

      <Grid container spacing={2}>
        {/* <Button
          style={{ textTransform: 'none' }}
          onClick={handleClose}
          autoFocus
          variant='contained'
        >
          Approve Scenario
        </Button> */}
        <Grid item xs={12}>
          <CustomCard cardStyles={classes.headerCard}>
            <div className='spacer'></div>
            {!isLoading && (
              <>
                <Typography variant='h6'>{curentObject?.title}</Typography>
                <Table
                  rowData={tableData}
                  columnDefs={columns}
                  floatingFilter={false}
                  height={'450px'}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  rowSelection={'multiple'}
                />
              </>
            )}
          </CustomCard>
        </Grid>
      </Grid>
      <Dialog
        open={showStoreModal}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        maxWidth={'lg'}
        fullWidth
      >
        <DialogTitle id='alert-dialog-title'>{modalData.title}</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', margin: '2rem' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='h6subtitle2' style={{ margin: 10 }}>
                {'Units'}
              </Typography>
              <TextField
                defaultValue={unit}
                id='outlined-basic'
                variant='outlined'
                type='number'
                onChange={(e) => setUnit(e.target.value)}
                inputProps={{
                  style: {
                    height: '20px',
                    width: 80,
                    padding: 5,
                  },
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 30,
              }}
            >
              <Typography variant='h6subtitle2' style={{ margin: 10 }}>
                {'Store for transfer'}
              </Typography>
              <SelectRenderer
                options={modalData.selectList}
                selectedItems={modalData.value}
                isMulti={false}
                width='6rem'
                value={store}
                updateSelected={handleOnSelect}
              />
              <Button
                variant='contained'
                style={{ textTransform: 'none', marginLeft: 40 }}
                onClick={addScenario}
                size='small'
              >
                +Scenario
              </Button>
            </div>
          </div>
          <Table
            rowData={modalTableData}
            columnDefs={modalData.column}
            floatingFilter={false}
            height={'180px'}
            rowSelection={'multiple'}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            style={{ textTransform: 'none' }}
            onClick={handleClose}
            autoFocus
            variant='contained'
          >
            Approve Scenario
          </Button>
        </DialogActions>
      </Dialog>
      <div className='spacer'></div>
      <div className={classes.saveButton} style={{ padding : 10 , display: 'flex', justifyContent: 'right' }}>
      <Button
            style={{ textTransform: 'none' }}
            onClick={navigateToDashboard}
            autoFocus
            variant='contained'
          >
            Finalize transfer
          </Button>
          </div>
       <div className='spacer'></div>
    </LoadingOverlay>
  );
};

export default ReviewRecommendation;
