import axios from 'axios';

const apiJson = {
  arhaus:
    'https://sandbox.products.impactsmartsuite.com/arhaus/inventory/api/v1/',
  rl: 'https://sandbox.inventorysmart.impactsmartsuite.com/api/v1/',
  generic:
    'https://sandbox.products.impactsmartsuite.com/generic/inventory/api/v1/',
  jcpenny:
    'https://sandbox.products.impactsmartsuite.com/jcpenny/inventory/api/v1/',
  'vitamin-shoppe':
    'https://sandbox.products.impactsmartsuite.com/vitamin-shoppe/inventory/api/v1/',
  dev: 'http://127.0.0.1:25250',
  spanx:
    'https://sandbox.products.impactsmartsuite.com/spanx/inventory/api/v1/',
  vs: 'https://sandbox.products.impactsmartsuite.com/vs/inventory/api/v1/',
  chicos:
    'https://sandbox.products.impactsmartsuite.com/chicos/old-inventory/api/v1/',
  tumi:
    'https://sandbox.products.impactsmartsuite.com/tumi/old-inventory/api/v1/',
};

const urlParams = new URLSearchParams(window?.location?.search);

console.log('urlParams', urlParams?.get('queryPath')?.split('/')[1]);
const callApi = axios.create({
  baseURL: apiJson[urlParams?.get('queryPath')?.split('/')[1]],
  withCredentials: true,
});

console.log(
  'inventory api -> ',
  apiJson[urlParams?.get('queryPath')?.split('/')[1]]
);
callApi.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    console.log('request fails with error', error.message);
    return Promise.reject(error);
  }
);

callApi.interceptors.response.use(
  function (res) {
    return res;
  },
  async function (error) {
    const {
      response: { status },
    } = error;
    console.log(error, status);
    console.log('response fails with error', error.message, error.config);
    // return error;
  }
);

export default callApi;
