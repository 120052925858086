import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Table } from '../../../../components/Table';
import CustomCard from '../../../../components/CustomCard';
import { tabListData, singleProducttabListData, singleProductJCPennytabListData, tabListJCPennyData, re_al_label_data } from './data';
import TableVariation from './TableVariation';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { bulk_edit_table } from './data';
import Snackbar from '../../../../components/SnackBar';

const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  headerCard: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
});

const useStyles = makeStyles(styles);

const ReviewAllocation = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = useState('product_view');
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [curentObject, setCurentObject] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [showBulkEdit, setShowBulkEdit] = useState(true);
  const { singleProductSelected, jcPennyData } = useSelector((state) => state.decision);
  const tablesData = singleProductSelected
    ? jcPennyData
      ? singleProductJCPennytabListData
      : singleProducttabListData
    : jcPennyData
    ? tabListJCPennyData
    : tabListData;

  const handleChange = (event, newValue) => {
    // setCurentObject(tabListData[newValue]);
    setValue(newValue);
    setIsLoading(false);
  };

  const handleClose = () => {
    setShowMessage(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleApply = () => {
    setOpen(false);
    setShowMessage(true);
  };

  const bulkEdit = (bool) => {
    setShowBulkEdit(bool);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div style={{ display: 'flex' }}>
          {re_al_label_data.map((item) => (
            <>
              <p
                style={{
                  marginLeft: '20.25rem',
                  marginTop: '2rem',
                  fontSize: 'medium',
                  color: 'grey',
                }}
              >
                {item.label}
              </p>
              <p
                style={{
                  marginLeft: '1.25rem',
                  marginTop: '2rem',
                  fontSize: 'medium',
                }}
              >
                {item.value}
              </p>
            </>
          ))}
        </div>
        {showMessage && (
          <Snackbar
            message='Changes Applied'
            variant='success'
            handleClose={handleClose}
          />
        )}
        {/* {showMessage && (<Snackbar message="Navigate to 'Warehouse to Store' Page" variant='info' handleClose={handleClose} /> )} */}
        <div className='impact_grid_sub_container'>
          <CustomCard cardStyles={classes.headerCard}>
            <div className='spacer'></div>
            <Grid item xs={12}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label='wrapped label tabs example'
              >
                {Object.values(tablesData).map((item) => (
                  <Tab
                    value={item.value}
                    label={item.label}
                    style={{
                      textTransform: 'none',
                    }}
                  />
                ))}
              </Tabs>
              {isLoading && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress className={classes.loader} />
                </div>
              )}

              {!isLoading &&
                tablesData[value].tables?.map((item, index) => (
                  <div style={{ marginTop: 30 }}>
                    <TableVariation
                      data={item}
                      index={index}
                      bulkEdit={bulkEdit}
                    />
                  </div>
                ))}
            </Grid>
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                marginTop: '5vh',
              }}
            >
              {showBulkEdit && (
                <Button
                  style={{
                    marginRight: 5,
                    bottom: 20,
                    textTransform: 'none',
                  }}
                  size='small'
                  variant='outlined'
                  onClick={() => handleClickOpen()}
                >
                  Bulk Edit Eaches
                </Button>
              )}
              <Button
                style={{ marginLeft: 5, bottom: 20, textTransform: 'none' }}
                variant='outlined'
                onClick={() => navigate('/inventory-smart/decision-dashboard')}
              >
                Cancel
              </Button>
              <Button
                style={{ marginLeft: 5, bottom: 20, textTransform: 'none' }}
                variant='contained'
                onClick={() => navigate('/inventory-smart/decision-dashboard')}
              >
                Finalize
              </Button>
            </div>
            <Dialog
              open={open}
              onClose={handleCancel}
              aria-labelledby='alert-dialog-title'
              maxWidth={'lg'}
              fullWidth
            >
              <DialogTitle id='alert-dialog-title'>
                {'Bulk Edit Eaches'}
              </DialogTitle>
              <DialogContent>
                <Table
                  rowData={bulk_edit_table.data}
                  columnDefs={bulk_edit_table.column}
                  floatingFilter={false}
                  height={'200px'}
                  rowSelection={'multiple'}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  style={{ textTransform: 'none' }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  style={{ textTransform: 'none' }}
                  onClick={handleApply}
                  autoFocus
                >
                  Apply
                </Button>
              </DialogActions>
            </Dialog>
          </CustomCard>
        </div>
      </Grid>
    </Grid>
  );
};

export default ReviewAllocation;
