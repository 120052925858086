import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { CardActions, CardHeader, CircularProgress } from '@mui/material';
import { fetchHierarchyData } from './action';
import {
  comparison_columns,
  comparison_chart_data,
  getComparisonChartData,
  ia_forecast,
} from './data';
import { Table } from '../../../../components/Table';
import Chart from '../../../../utils/charts/Chart';
import CustomButton from '../../../../components/CustomButton';
import { Card, CardContent,Grid } from '@mui/material';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import { getMultipleModelsData } from '../../services/decisionDashboardService';
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import _ from 'lodash';
import * as css_constants from '../../../../constants/cssConstants';
import SelectRenderer from '../../../../components/Select/SelectRenderer';
const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    height: '100%',
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
});

const useStyles = makeStyles(styles);

export const Comparison = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [chartData, setChartData] = React.useState([]);
  const [salesUnitsChart, setSalesUnitChart] = React.useState({});
  const [salesUnitsOptions, setSalesUnitOptions] = React.useState([{
    label:"Revenue",value:"Revenue",
  },{
    label:"Gross Margin",value:"Gross Margin"
  }]);
  const [selecteItems, setSelectedItems] = React.useState([{
    label:"Revenue",value:"Revenue",
  }]);
  const gridRef = useRef();
  const navigate = useNavigate();
  const getData = async (label, content) => {
    setIsLoading(true);
    const data = await fetchHierarchyData(label);
    if (data.length !== 0) {
      setIsLoading(false);
      if (content === 'table') setTableData(data);
      else setChartData(data);
    }
  };

  const getScenarioData = (previousData, comparisionData) => {
    let updatedScenarioData = [...previousData];
    const scenarioTabs = [...Array(props.scenarioTabs).keys()].map(
      (obj) => `Scenario ${obj + 1}`
    );

    previousData.filter((obj, index) => {
      scenarioTabs.forEach((scenarioObj) => {
        const tabData = comparisionData.filter(
          (nestedObj) => nestedObj.scenario === scenarioObj 
        );
        obj[`${scenarioObj}`] = tabData[0][`w${index + 1}`];
      });
    });
    return previousData;
    /*
    comparisionData.filter(obj=> {
        [...Array(props.scenarioTabs).keys()].forEach((nestedObj,index)=>{
            if(index%2 == 0){
              obj[`Scenario_${index+1}`] = parseInt(parseInt(obj.forecast)*0.35)
              obj.label = `Scenerio ${index+1}`
            }else{
              obj[`Scenario_${index+1}`] = parseInt( parseInt(obj.ly)*0.35 )
              obj.label = `Scenario ${index+1}`
            }
        })
      })
        return updatedScenarioData;
      */
  };
  const getComparisionData = (previousData, foreCastData) => {
    let updateComparisionData = [...previousData];
    const newData = [];
    let scenarioTabs = [...Array(props.scenarioTabs).keys()]
      .map((obj) => `Scenario ${obj + 1}`)
      .concat(['LY', 'Forecast',"Merch Plan","Forecast vs LY","Forecast vs Merch Plan"]);
      [...Array(props.scenarioTabs).keys()].filter(obj=>{
          scenarioTabs.push(`Scenario ${obj + 1} vs LY`);
          scenarioTabs.push(`Scenario ${obj + 1} vs Merch plan`)
      })
      
      

    updateComparisionData = previousData.filter((obj) => {
      return scenarioTabs.includes(obj.scenario);
    });
    /*
        [...Array(props.scenarioTabs).keys()].forEach((nestedObj,index)=>{
          previousData.filter(obj=> {
            let udpatedObj = {};
              let sum = 0;
                if(index%2 == 0){
                  foreCastData.map(chartObj=> chartObj.forecast).forEach((rowValue,index)=>{
                    let value = parseInt(parseInt(rowValue)*0.35)
                    udpatedObj[`w${index+1}`] = value
                    sum = sum + value;
                  })
                  udpatedObj[`scenario`] = `Scenario ${index+1}`;
                  udpatedObj[`total`] = sum
                  updateComparisionData.push(udpatedObj)
                }else{
                  foreCastData.map(chartObj=> chartObj.ly).forEach((rowValue,index)=>{
                    let value = parseInt(parseInt(rowValue)*0.35);
                    udpatedObj[`w${index+1}`] = value
                    sum = sum + value;
                  })
                  udpatedObj[`scenario`] = `Scenario ${index+1}`;
                  udpatedObj[`total`] = sum
                  updateComparisionData.push(udpatedObj)
                }
            })
      })
      */
    return updateComparisionData;
  };

  useEffect(async () => {
    // getData('tb_comparison', 'table');
    // getData('tb_ada_comparison_chart', 'chart');
    setIsLoading(true);
    const result = await getMultipleModelsData({
      model_names: [
        // 'tb_comparision_sales_chart',
        // 'tb_ia_forecast_chart',
        'tb_comparison',
        'tb_ada_comparison_chart',
      ],
    });
    const { tb_ia_forecast_chart, tb_comparison, tb_ada_comparison_chart } =
      result;
    // setIsLoading(true);
    // if(tb_ia_forecast_chart.length > 0){
    //       let categories = tb_ia_forecast_chart.map(obj=> obj.week)
    //       let data = [
    //         {
    //           name: 'LY',
    //           data: tb_ia_forecast_chart.map(obj=> parseInt(obj.ly)),
    //           type:'spline'
    //         },
    //         {
    //           name: 'Forecast',
    //           data: tb_ia_forecast_chart.map(obj=> parseInt(obj.forecast)),
    //           type:'spline'
    //         },
    //       ]
    //       const options = {
    //         categories,
    //         data: data,
    //     }
    //       // setSalesUnitChart(options)
    setIsLoading(false);

    //   }
    let chartTypeData = tb_ada_comparison_chart.filter(obj=> obj.type === selecteItems[0].value )
    setSalesUnitChart({
      tb_ada_comparison_chart,
      tb_comparison
    })
    const data = getScenarioData(
      [...chartTypeData],
      [...tb_comparison]
    );
    const compareData = getComparisionData(
      [...tb_comparison],
      [...chartTypeData]
    );

    setChartData([...data]);
    setTableData(compareData);
    // setTableData(tb_comparison)
    // setChartData(tb_ada_comparison_chart)
  }, []);

  useEffect(() => {
    if (props.scenarioTabs && chartData.length > 0) {
      const data = getScenarioData([...chartData], [...tableData]);
      const compareData = getComparisionData([...tableData], [...chartData]);
      setTableData(compareData);
      setChartData([...data]);
    }
  }, [props.scenarioTabs]);

  const saveAndFinaliseClickHandler = () => {
    setTimeout(() => {
      toast.success('Scenario finalised');
      gridRef.current.gridApi.deselectAll();
      // navigate('inventory-smart/decision-dashboard');
    }, 1000);
    navigate('inventory-smart/decision-dashboard');
  };

const handleDropdownchange=(params)=>{
  if(params.selectedItems && params.selectedItems.length > 0){
    setSelectedItems(params.selectedItems)

  let tb_ada_comparison = salesUnitsChart['tb_ada_comparison_chart'].filter(obj=> obj.type === params.selectedItems[0].value )
  let tb_comparison = salesUnitsChart['tb_comparison']
    const data = getScenarioData(
      [...tb_ada_comparison],
      [...tb_comparison]
    );
    const compareData = getComparisionData(
      [...tb_comparison],
      [...tb_ada_comparison]
    );

    setChartData([...data]);
    setTableData(compareData);
  }
  
}
  return (
    <>
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress className={classes.loader + css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_CIRCULARPROGRESS_CLASS}
          />
        </div>
      )}
      {!isLoading && (
        <>
          {/* <Card>
          <CardHeader title='Sales Unit Forecasts' />
            <CardContent>
              {Object.keys(salesUnitsChart).length > 0 && (<Chart
                chartType={ia_forecast.chart.type}
                title={ia_forecast.chart.title}
                yaxis={ia_forecast.chart.yaxis}
                xaxis={{categories: salesUnitsChart.categories}}
                showLegend={ia_forecast.chart.showLegend}
                data={salesUnitsChart.data}
                // legend={ia_forecast.chart.legend}
                pointStart={ia_forecast.chart.pointStart}
              />)}
            </CardContent>
          </Card>
          <div className='spacer'></div>
          <div className='spacer'></div> */}
          <Card
          className={css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_CARD_CSS_CLASS }
          >
            <CardHeader title='Sales Unit Forecasts'
            className={css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_CARDHEADER_CLASS }
            />
            <CardContent
            className={css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_CARDCONTENT_CLASS }
            >
              <Grid
                  container
                  columnSpacing={{ md:2,xl:2,lg:2 }}
                >
                  <Grid item md={10} lg={10} xl={10}></Grid>
                  <Grid item md={2} lg={2} xl={2}>
                    <SelectRenderer
                      options={salesUnitsOptions}
                      selectedItems={selecteItems}
                      updateSelected={handleDropdownchange}
                      placeholder={"Select"}
                      filterLabel={""}
                      
                    />
                  </Grid>
                  
          </Grid>
          <div className="spacer"></div>
              {chartData.length && (
                <Chart
                  className={css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_CHART_CLASS }
                  chartType={comparison_chart_data.type}
                  yaxis={comparison_chart_data.yaxis}
                  xaxis={comparison_chart_data.xaxis}
                  showLegend={comparison_chart_data.showLegend}
                  data={getComparisonChartData(chartData, props.scenarioTabs)}
                  // legend={comparison_chart_data.legend}
                />
              )}
            </CardContent>
          </Card>
          <div className='spacer'></div>
          <div className='spacer'></div>
          <Card
            className={css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_CARD_CSS_CLASS }
          >
            <CardHeader title='Scenario Comparison'
            className={css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_CARDHEADER_CLASS }
            />
            <CardContent
            className={css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_CARDCONTENT_CLASS }
            >
              <div style={{ textAlign: 'right' }}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {}}
                  style={{ textTransform: 'none' }}
                >
                  Download to Excel
                </Button>
              </div>
              <div className='spacer' />
              <Table
                ref={gridRef}
                rowData={tableData}
                columnDefs={comparison_columns}
                floatingFilter={false}
                height={'350px'}
                rowSelection={'multiple'}
                tableCssStyle='sizeColumnsToFit'
                className={css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_TABLE_CLASS }
              />
            </CardContent>
          </Card>
          <div
            className={classes.saveButton + css_constants.IMPACT_BUTTON_SUBMIT_CSS_CLASS}
            style={{ display: 'flex', justifyContent: 'right' }}
          >
            <CustomButton
              isPrimary={true}
              variant='contained'
              height={36}
              width={160}
              label='Save and Finalise'
              padding='100'
              margin={10}
              onButtonClick={saveAndFinaliseClickHandler}
              className={css_constants.IMPACT_CSS_CLASS + css_constants.IMPACT_BUTTON_CSS_CLASS }
            />
          </div>
        </>
      )}
    </>
  );
};
