
export const filterData = {
  channel: { label: 'Channel', section: ['Channel 1'] },
  date_range: { label: 'Date Range', section: ['22-05-2021 to 22-05-2022'] },
  store: { label: 'Store', section: ['S1', 'S2', 'S3'] },
};

export const initialData = {
  ia_forecast: {
    label: 'IA Forecast',
    value: 'ia_forecast',
  },
  scenario_1: {
    label: 'Scenario 1',
    value: 'scenario_1',
  },
  comparison: {
    label: 'Comparison',
    value: 'comparison',
  },
};

export const tabsList = [
  {
    label: 'IA Forecast',
    value: 'ia_forecast',
  },
  {
    label: 'Adjusted Forecast',
    value: 'adjusted_forecast',
  },
  {
    label: 'Comparison',
    value: 'Comparison',
  }
];

export const scenario_accordion_list = [
  {
    label: 'Drivers of Forecast',
    id: 'Driver Forecast',
  },
  {
    label: 'Drivers Contribution',
    id: 'Drivers Contribution',
  },
  {
    label: 'Channel Contribution',
    id: 'Channel Contribution',
  },
  {
    label: 'Forecast adjustment and Edit hierarchy forecasts',
    id: 'Forecast Adjustments',
  },
  // {
  //   label: 'Forecast Adjustments and Hierarchy Forecasts',
  //   id: 'Forecast Adjustments',
  // },
];

export const driver_forecast_columns = [
  {
    field: 'drivers',
    headerName: 'Drivers',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    tooltipField: 'message',
  },
  // { field: 'discount_value', headerName: 'Discount Value' },
  // { field: 'fw_202405', headerName: 'FW-202405' },
  // { field: 'fw_202406', headerName: 'FW-202406' },
  // { field: 'fw_202407', headerName: 'FW-202407' },
  // { field: 'fw_202408', headerName: 'FW-202408' },
  // { field: 'fw_202409', headerName: 'FW-202409' },
  // { field: 'fw_202410', headerName: 'FW-202410' },
  // { field: 'fw_202411', headerName: 'FW-202411' },
  // { field: 'fw_202412', headerName: 'FW-202412' }
  { field: 'all_weeks', headerName: 'All Weeks' },
  { field: 'week_1', headerName: 'Week 1' },
  { field: 'week_2', headerName: 'Week 2' },
  { field: 'week_3', headerName: 'Week 3' },
  { field: 'week_4', headerName: 'Week 4' },
  { field: 'week_5', headerName: 'Week 5' },
  { field: 'week_6', headerName: 'Week 6' },
  { field: 'week_7', headerName: 'Week 7' },
  { field: 'week_8', headerName: 'Week 8' },
];

export const driver_contribution_columns = [
  {
    field: 'drivers',
    headerName: 'Drivers',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    tooltipField: 'message',
  },
  { field: 'contribution', headerName: 'Contribution' },
  { field: 'fw_202410', headerName: 'FW-202410' },
  { field: 'fw_202411', headerName: 'FW-202411' },
  { field: 'fw_202412', headerName: 'FW-202412' },
  { field: 'fw_202413', headerName: 'FW-202413' },
  { field: 'fw_202414', headerName: 'FW-202414' },
  // { field: 'all_weeks', headerName: 'All Weeks' },
  // { field: 'week_1', headerName: 'Week 1' },
  // { field: 'week_2', headerName: 'Week 2' },
  // { field: 'week_3', headerName: 'Week 3' },
  // { field: 'week_4', headerName: 'Week 4' },
  // { field: 'week_5', headerName: 'Week 5' },
  // { field: 'week_6', headerName: 'Week 6' },
  // { field: 'week_7', headerName: 'Week 7' },
  // { field: 'week_8', headerName: 'Week 8' },
];


export const forecast_adjustments_tabs = [
  {
    label: 'Forecast Multiplier',
    value: 'Forecast_Multiplier',
  },
  {
    label: 'View/Edit Hierachy Forecast',
    value: 'view_hierarchy',
  },
];

export const Forecast_Multiplier_columns = [
  {
    field: 'drivers',
    headerName: '',
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  { field: 'all_weeks', headerName: 'All Weeks' },
  { field: 'week_1', headerName: 'Week 1' },
  { field: 'week_2', headerName: 'Week 2' },
  { field: 'week_3', headerName: 'Week 3' },
  { field: 'week_4', headerName: 'Week 4' },
  { field: 'week_5', headerName: 'Week 5' },
  { field: 'week_6', headerName: 'Week 6' },
  { field: 'week_7', headerName: 'Week 7' },
  { field: 'week_8', headerName: 'Week 8' },
];
export const view_hierarchy_columns = [
  {
    field: 'skus',
    headerName: '',
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  { field: 'all_weeks', headerName: 'All Weeks' },
  { field: 'week_1', headerName: 'Week 1' },
  { field: 'week_2', headerName: 'Week 2' },
  { field: 'week_3', headerName: 'Week 3' },
  { field: 'week_4', headerName: 'Week 4' },
  { field: 'week_5', headerName: 'Week 5' },
  { field: 'week_6', headerName: 'Week 6' },
  { field: 'week_7', headerName: 'Week 7' },
  { field: 'week_8', headerName: 'Week 8' },
];

export const cellConditionColor = (params)=>{
    if (params.value && params.value.includes('%')) {
      let value = parseInt(params.value.replaceAll('%',""))
      if( value > 30 || value < -30 ){
        return {color: 'red'};
      }
      return null ;
        
    }
    return null;
}
export const comparison_columns = [
  {
    field: 'scenario',
    headerName: 'Scenario',
    pinned: 'left',
    width: 200,
    checkboxSelection: true,
    rowSelection: 'single',
  },
  { field: 'fw_202405', headerName: 'FW-202405', cellStyle:cellConditionColor },
  { field: 'fw_202406', headerName: 'FW-202406' , cellStyle:cellConditionColor},
  { field: 'fw_202407', headerName: 'FW-202407' , cellStyle:cellConditionColor},
  { field: 'fw_202408', headerName: 'FW-202408' , cellStyle:cellConditionColor},
  { field: 'fw_202409', headerName: 'FW-202409' , cellStyle:cellConditionColor},
  { field: 'fw_202410', headerName: 'FW-202410' , cellStyle:cellConditionColor},
  { field: 'fw_202411', headerName: 'FW-202411' , cellStyle:cellConditionColor},
  { field: 'fw_202412', headerName: 'FW-202412' , cellStyle:cellConditionColor}
  // {
  //   field: 'w1',
  //   headerName: 'W1',
  //   width: 150,
  // },
  // {
  //   field: 'w2',
  //   headerName: 'W2',
  //   width: 150,
  // },
  // {
  //   field: 'w3',
  //   headerName: 'W3',
  //   width: 150,
  // },
  // {
  //   field: 'w4',
  //   headerName: 'W4',
  //   width: 150,
  // },
  // {
  //   field: 'w5',
  //   headerName: 'W5',
  //   width: 150,
  // },
  // {
  //   field: 'w6',
  //   headerName: 'W6',
  //   width: 150,
  // },
  // {
  //   field: 'w7',
  //   headerName: 'W7',
  //   width: 150,
  // },
  // {
  //   field: 'w8',
  //   headerName: 'W8',
  //   width: 150,
  // },
  // {
  //   field: 'w9',
  //   headerName: 'W9',
  //   width: 150,
  // },
  // {
  //   field: 'total',
  //   headerName: 'Total',
  //   width: 150,
  // },
];

export const comparison_chart_data = {
  type: 'linechart',
  legend: {
    layout: 'vertical',
    align: 'left',
    verticalAlign: 'middle',
  },
  yaxis: {
    title: 'Sales Units',
  },
  xaxis: {
    categories: ["fw_202405","fw_202406","fw_202407","fw_202408","fw_202409","fw_202410","fw_202411","fw_202412"],
  },
  showLegend: true,
  data: [
    {
      name: 'Actual',
      data: [150, 230, 340, 430, 420, 540, 440, 650],
    },
    {
      name: 'Forecast',
      data: [270, 310, 480, 610, 790, 810, 770, 900],
    },
    {
      name: 'Adj. Forecast',
      data: [120, 350, 460, 620, 780, 830, 950, 860],
    },
    {
      name: 'LY',
      data: [260, 470, 590, 650, 740, 860, 920, 690],
    },
    {
      name: 'unconstrained_forecast',
      data: [360, 480, 580, 620, 720, 820, 950, 670],
    },
    {
      name: 'Scenario 2',
      data: [10, 180, 250, 370, 460, 570, 660, 720],
    },
    {
      name: 'Scenario 3',
      data: [390, 420, 520, 660, 710, 820, 980, 630],
    },
  ],
};

export const getComparisonChartData = (chartObj, tabs,defaultKey) => {
  const data = [
    // {
    //   name: 'Actual',
    //   data: chartObj.map((obj) => parseInt(obj.actual)),
    // },
    
    {
      name: 'Forecast',
      data: chartObj.map((obj) => parseInt(obj.forecast)),
      type: 'spline',
    },
    // {
    //   name: 'Adj. Forecast',
    //   data: chartObj.map((obj) => parseInt(obj.adj_forecast)),
    //   type: 'spline',
    // },
    {
      name: 'LY',
      data: chartObj.map((obj) => parseInt(obj.ly.replace(/,/g, ''))),
      type: 'spline',
    },
    {
      name: 'Merch Plan',
      data: chartObj.map((obj) => parseInt(obj.merch_plan.replace(/,/g, ''))),
      type: 'spline',
    }
    // {
    //   name: 'Unconstrained forecast',
    //   data: chartObj.map((obj) => parseInt(obj.unconstrained_forecast)),
    //   type: 'spline',
    // },
    // {
    //   name: 'Scenario 2',
    //   data: chartObj.map((obj) => parseInt(obj.scenario_2)),
    //   type:'spline'
    // },
    // {
    //   name: 'Scenario 3',
    //   data: chartObj.map((obj) => parseInt(obj.scenario_3)),
    //   type:'spline'
    // },
  ];
  [...Array(tabs).keys()].forEach((obj) => {
    data.push({
      name: `Scenario ${obj + 1}`,
      data: chartObj.map((nested) =>{
        console.log("Nested",obj,nested)
        return nested[`Scenario ${obj + 1}`] ? parseInt(nested[`Scenario ${obj + 1}`].replace(/,/g, '')) : 0 
      }
      
      ),
      type: 'spline',
    });
  });
  console.log(data);
  return data;
};

export const ia_forecast = {
  accordion: {
    label: 'Forecast Adjustments and Edit Hierarchy Forecasts',
    id: 'Forecast Adjustments',
  },
  tabs: [
    {
      label: 'Forecast Multiplier',
      value: 'ia_forecast_multiplier',
      column: [
        { field: 'drivers', headerName: '' },
        { field: 'all_weeks', headerName: 'All Weeks' },
        { field: 'week_1', headerName: 'Week 1' },
        { field: 'week_2', headerName: 'Week 2' },
        { field: 'week_3', headerName: 'Week 3' },
        { field: 'week_4', headerName: 'Week 4' },
        { field: 'week_5', headerName: 'Week 5' },
        { field: 'week_6', headerName: 'Week 6' },
        { field: 'week_7', headerName: 'Week 7' },
        { field: 'week_8', headerName: 'Week 8' },
      ],
      data: [],
    },
    {
      label: 'View/Edit Hierachy Forecast',
      value: 'ia_forecast_view_hierarchy',
      column: [
        {
          field: 'store_sku',
          headerName: "Sores/SKU's",
          cellRendererFramework: (cell) => (
            <i style={{ textTransform: 'none' }} autoFocus variant='text'>
              {cell.data.action_item}
            </i>
          ),
        },
        { field: 'all_weeks', headerName: 'All Weeks' },
        { field: 'week_1', headerName: 'Week 1' },
        { field: 'week_2', headerName: 'Week 2' },
        { field: 'week_3', headerName: 'Week 3' },
        { field: 'week_4', headerName: 'Week 4' },
        { field: 'week_5', headerName: 'Week 5' },
        { field: 'week_6', headerName: 'Week 6' },
        { field: 'week_7', headerName: 'Week 7' },
        { field: 'week_8', headerName: 'Week 8' },
      ],
      data: [],
    },
  ],

  chart: {
    type: 'linechart',
    // title: 'Sales Unit Forecasts',
    legend: {
      layout: 'vertical',
      align: 'left',
      verticalAlign: 'middle',
    },
    yaxis: {
      title: 'Sales Units',
    },
    xaxis: {
      categories: ['W 01', 'W 02', 'W 03', 'W 04', 'W 05'],
    },
    showLegend: true,
    data: [
      {
        name: 'LY',
        data: [0, 30, 50, 25, 20],
      },
      {
        name: 'Forecasts',
        data: [10, 60, 75, 50, 65],
      },
      {
        name: 'unconstrained_forecast',
        data: [10, 60, 75, 50, 65],
      },
    ],
  },
};

const handleCellChange = (value) => {
  console.log('Hanlde Cell Change');
};
export const scenario_level_columns = [
  {
    field: 'drivers',
    headerName: 'Drivers',
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  {
    field: 'all_weeks',
    headerName: 'All Weeks',
    cellRenderer: 'editWeekData',
    width: 110,
    cellRendererParams: {
      handleCellChange,
    },
  },
  {
    field: 'week_1',
    headerName: 'Week 1',
    cellRenderer: 'editWeekData',
    width: 110,
    cellRendererParams: {
      handleCellChange,
      type: '%',
    },
  },
  {
    field: 'week_2',
    headerName: 'Week 2',
    cellRenderer: 'editWeekData',
    width: 110,
    cellRendererParams: {
      handleCellChange,
    },
  },
  {
    field: 'week_3',
    headerName: 'Week 3',
    cellRenderer: 'editWeekData',
    width: 110,
    cellRendererParams: {
      handleCellChange,
    },
  },
  {
    field: 'week_4',
    headerName: 'Week 4',
    cellRenderer: 'editWeekData',
    width: 110,
    cellRendererParams: {
      handleCellChange,
    },
  },
  {
    field: 'week_5',
    headerName: 'Week 5',
    cellRenderer: 'editWeekData',
    width: 110,
    cellRendererParams: {
      handleCellChange,
    },
  },
  {
    field: 'week_6',
    headerName: 'Week 6',
    cellRenderer: 'editWeekData',
    width: 110,
    cellRendererParams: {
      handleCellChange,
    },
  },
  {
    field: 'week_7',
    headerName: 'Week 7',
    cellRenderer: 'editWeekData',
    width: 110,
    cellRendererParams: {
      handleCellChange,
    },
  },
  {
    field: 'week_8',
    headerName: 'Week 8',
    cellRenderer: 'editWeekData',
    width: 110,
    cellRendererParams: {
      handleCellChange,
    },
  },
];

export const chartRadioData = [
  { value: 'units', label: 'Units' },
  { value: 'cost', label: 'Revenue' },
];

export const tableRadioData = [
  { value: 'units', label: 'Units' },
  { value: 'cost', label: 'Revenue' },
];

export const childTableColumn = {
  label: 'Child SKU and Stores',
  value: 'ia_forecast_view_hierarchy',
  column: [
    { field: 'child_sku', headerName: 'Store ID' },
    { field: 'all_weeks', headerName: 'All Weeks' },
    { field: 'week_1', headerName: 'Week 1' },
    { field: 'week_2', headerName: 'Week 2' },
    { field: 'week_3', headerName: 'Week 3' },
    { field: 'week_4', headerName: 'Week 4' },
    { field: 'week_5', headerName: 'Week 5' },
    { field: 'week_6', headerName: 'Week 6' },
    { field: 'week_7', headerName: 'Week 7' },
    { field: 'week_8', headerName: 'Week 8' },
  ],
  data: [],
};

export const childCostTableColumn = {
  label: 'Child SKU and Stores',
  value: 'ia_forecast_view_hierarchy',
  column: [
    { field: 'store_id', headerName: 'Store ID' },
    { field: 'all_weeks', headerName: 'All Weeks' },
    { field: 'week_1', headerName: 'Week 1' },
    { field: 'week_2', headerName: 'Week 2' },
    { field: 'week_3', headerName: 'Week 3' },
    { field: 'week_4', headerName: 'Week 4' },
    { field: 'week_5', headerName: 'Week 5' },
    { field: 'week_6', headerName: 'Week 6' },
    { field: 'week_7', headerName: 'Week 7' },
    { field: 'week_8', headerName: 'Week 8' },
  ],
  data: [],
};


export const  convertToDropdownValues =(arrayList)=> {
  return arrayList.map(value=>{
    return {
      value: value,
      label: value,
    }
  })
}