import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';

export default function TableData() {
  const dispatch = useDispatch();
  const changeFunction = () => {
    dispatch({
      type: 'SHOW_STORE_TRANSFER_POPUP',
      payload: true,
    });
  };

  return [
    {
      field: 'store_id',
      headerName: 'Store ID',
      pinned: 'left',
      width: 180, 
      headerCheckboxSelection: true,
      checkboxSelection: true
    },
    {
      field: 'store_name',
      headerName: 'Store Name',
      pinned: 'left',
      width: 150,
    },
    {
      field: 'style_color',
      headerName: 'Style Color',
      width: 130,
      pinned: 'left',
    },
    {
      field: 'style_description',
      headerName: 'Style Description',
      width: 300,
      pinned: 'left',
    },
    {
      field: 'inventory',
      headerName: 'Inventory',
      width: 120,
      pinned: 'left',
    },
    { field: 'wos', headerName: 'WOS', width: 90, pinned: 'left' },
    { field: 'color', headerName: 'Color', width: 120},
    { field: 'color_id', headerName: 'Color ID', width: 120},
    {
      headerName: 'Recommended Source',
      children: [
        { field: 'rec_store_id', headerName: 'Store ID', width: 120 },
        { field: 'rec_distance', headerName: 'Distance', width: 120 },
        { field: 'rec_inventory', headerName: 'Inventory', width: 120 },
        { field: 'rec_wos', headerName: 'WOS', width: 100 },
        {
          field: 'ia_rec_transfer',
          headerName: 'IA Rec. transfer',
          width: 160,
        },
        { field: 'edit', headerName: 'Edit', width: 120 },
        
      ],
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 170,
      pinned: 'right',
      cellRendererFramework: (cell) => (
        <Button
          variant='contained'
          style={{
            width: '85%',
            textTransform: 'none',
            height: 25,
            fontWeight: 300,
            fontSize: 13,
            backgroundColor: 'mediumseagreen',
            borderRadius: 10,
          }}
          onClick={() => changeFunction()}
        >
          Store Transfer
        </Button>
      ),
    },
  ];
}
