import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  tabsData,
  accordionData,
  FORECAST,
  ALLOCATION,
  REPLENISHMENT,
  ORDER,
  ALLOCATION_VISUALISATION_INVENTORY_OVERVIEW,
} from './data';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DecisionDashboardAlert } from './Alert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from './TabPanel';
import Details from './Details';
import { Visualisation } from './Visualisation';
import { KPI } from './Kpi';
import { getMultipleModelsData } from '../../services/decisionDashboardService';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import FiltersList from '../../../../molecules/FiltersList';
const styles = (theme) => ({
  headerCard: {
    ...theme.content.card,
    width: '100%',
    height: '100%',
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
});

const useStyles = makeStyles(styles);
const Decision = () => {
  const [loader, setLoader] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [forecastData, setForecastData] = useState({
    visualisation: [],
    kpi: [],
    alert1: [],
    alert2: [],
    details: [],
  });
  const [allocationData, setAllocationData] = useState({
    visualisation: [],
    visualisation1: [],
    kpi: [],
    alert1: [],
    details: [],
  });
  const [replenishmentData, setReplenishmentData] = useState({
    visualisation: [],
    visualisation1: [],
    kpi: [],
    alert1: [],
    details: [],
  });
  const [orderData, setOrderData] = useState({
    visualisation: [],
    visualisation1: [],
    kpi: [],
    alert1: [],
    details: [],
  });
  const [tabIndex, setTabIndex] = useState(0);
  let result = {};
  const getData = async (tabName) => {
    openLoader();
    switch (tabName) {
      case FORECAST:
        if (forecastData.visualisation.length === 0) {
          result = await getMultipleModelsData({
            model_names: [
              'tb_forecast_visulisation',
              'tb_forecast_kpis',
              'tb_forecast_alerts',
              'tb_forecast_alerts_2',
              'tb_forecast_details',
            ],
            selectedFilters,
          });
          const {
            tb_forecast_visulisation,
            tb_forecast_kpis,
            tb_forecast_alerts,
            tb_forecast_alerts_2,
            tb_forecast_details,
          } = result;

          setForecastData({
            visualisation: tb_forecast_visulisation,
            kpi: tb_forecast_kpis,
            alert1: tb_forecast_alerts,
            alert2: tb_forecast_alerts_2,
            details: tb_forecast_details,
          });
        }
        break;
      case ALLOCATION:
        if (allocationData.visualisation.length === 0) {
          result = await getMultipleModelsData({
            model_names: [
              'tb_allocation_visulisation',
              'tb_allocation_visulisation1',
              'tb_allocation_kpis',
              'tb_allocation_alerts',
              'tb_allocation_details',
            ],
            selectedFilters,
          });
          const {
            tb_allocation_visulisation,
            tb_allocation_visulisation1,
            tb_allocation_kpis,
            tb_allocation_alerts,
            tb_allocation_details,
          } = result;

          setAllocationData({
            visualisation: tb_allocation_visulisation,
            visualisation1: tb_allocation_visulisation1,
            kpi: tb_allocation_kpis,
            alert1: tb_allocation_alerts,
            details: tb_allocation_details,
          });
        }
        break;
      case REPLENISHMENT:
        if (replenishmentData.visualisation.length === 0) {
          result = await getMultipleModelsData({
            model_names: [
              'tb_replenishment_visulisation',
              'tb_replenishment_visulisation1',
              'tb_replenishment_kpis',
              'tb_replenishment_alerts',
              'tb_replenishment_alerts_review',
              'tb_replenishment_details',
              'tb_replenishment_alerts_popup'
            ],
            selectedFilters,
          });
          const {
            tb_replenishment_visulisation,
            tb_replenishment_visulisation1,
            tb_replenishment_kpis,
            tb_replenishment_alerts,
            tb_replenishment_alerts_review,
            tb_replenishment_details,
            tb_replenishment_alerts_popup,
          } = result;

          setReplenishmentData({
            visualisation: tb_replenishment_visulisation,
            visualisation1: tb_replenishment_visulisation1,
            kpi: tb_replenishment_kpis,
            alert1: tb_replenishment_alerts,
            alert2: tb_replenishment_alerts_review,
            alertModal:tb_replenishment_alerts_popup,
            details: tb_replenishment_details,
          });
        }
        break;
      case ORDER:
        if (orderData.visualisation.length === 0) {
          result = await getMultipleModelsData({
            model_names: [
              'tb_order_visulisation',
              'tb_order_visulisation1',
              'tb_order_kpis',
              'tb_order_alerts',
              'tb_order_details',
            ],
            selectedFilters,
          });
          const {
            tb_order_visulisation,
            tb_order_visulisation1,
            tb_order_kpis,
            tb_order_alerts,
            tb_order_details,
          } = result;

          setOrderData({
            visualisation: tb_order_visulisation,
            visualisation1: tb_order_visulisation1,
            kpi: tb_order_kpis,
            alert1: tb_order_alerts,
            details: tb_order_details,
          });
        }
        break;

      default:
        break;
    }

    closeLoader();
  };

  useEffect(async () => {
    getData(FORECAST);
  }, []);

  const reloadFilters = async () => {
    setForecastData({
      visualisation: [],
      kpi: [],
      alert1: [],
      alert2: [],
      details: [],
    });
  };

  const openLoader = () => {
    console.log('Open Loader');
    setLoader(true);
  };
  const closeLoader = () => {
    setLoader(false);
  };
  const updateState = (params) => {
    console.log('Updating state', params);
    setSelectedFilters(params);
  };
  const appliedFiltersAction = async () => {
    console.log('Seldcted filters', selectedFilters);
    await getData(FORECAST);
    await getData(ALLOCATION);
    await getData(REPLENISHMENT);
    await getData(ORDER);
  };
  const onReset = () => {
    reloadFilters();
  };
  const handleTabChange = (_, newValue) => {
    console.log(newValue);
    setTabIndex(newValue);
    getData(tabsData[newValue]['label']);
  };
  const [expanded, setExpanded] = useState('Visualisation');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : '');
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const renderAccodianChild = (accordObj) => {
    const { label, parentLabel } = accordObj;
    console.log(parentLabel);
    let data = {};
    let visualisation_inventory = null;
    switch (parentLabel) {
      case FORECAST:
        data = forecastData;
        break;
      case ALLOCATION:
        data = allocationData;
        visualisation_inventory = (
          <Visualisation
            {...accordObj}
            parentLabel={ALLOCATION_VISUALISATION_INVENTORY_OVERVIEW}
            data={data.visualisation1}
          />
        );
        break;
      case REPLENISHMENT:
        data = replenishmentData;
        visualisation_inventory = (
          <Visualisation
            {...accordObj}
            parentLabel={ALLOCATION_VISUALISATION_INVENTORY_OVERVIEW}
            data={data.visualisation1}
          />
        );
        break;
      case ORDER:
        data = orderData;
        visualisation_inventory = (
          <Visualisation
            {...accordObj}
            parentLabel={ALLOCATION_VISUALISATION_INVENTORY_OVERVIEW}
            data={data.visualisation1}
          />
        );
        break;

      default:
        break;
    }
    switch (label) {
      case 'Visualisation':
        return (
          <>
            {visualisation_inventory}
            <div className='spacer'></div>
            <div className='spacer'></div>
            <Visualisation {...accordObj} data={data.visualisation} />
          </>
        );
        break;
      case 'KPIs':
        return (
          <>
            <KPI {...accordObj} data={data.kpi} />
          </>
        );
        break;
      case 'Alerts':
        return (
          <DecisionDashboardAlert
            {...accordObj}
            data={{ alert1: data.alert1, alert2: data.alert2,alertModal:data.alertModal }}
            kpiData={data.kpi}
          />
        );
        break;
      case 'Details':
        return <Details {...accordObj} data={data.details} />;
        break;

      default:
        break;
    }
  };
  return (
    <div>
      <LoadingOverlay loader={loader}>
        <Grid container spacing={2} className='impact_decision_container'>
          <Grid item xs={12} className='impact_decision_grid_container'>
            <FiltersList
              openLoader={openLoader}
              closeLoader={closeLoader}
              updateParentState={updateState}
              appliedFilters={appliedFiltersAction}
              onReset={onReset}
              screenName={'Decision Dashboard'}
            />
            {/* <div className='spacer'></div> */}
            {/* <SelectedFilters /> */}
            {forecastData.visualisation.length > 0 ? (
              <>
                <Grid item xs={12} className='impact_grid_sub_container'>
                  <Box sx={{ width: '100%' }}>
                    <div class='tab_heading_main_container'>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                          value={tabIndex}
                          onChange={handleTabChange}
                          aria-label='basic tabs example'
                        >
                          {tabsData.map((obj, index) => (
                            <Tab
                              label={obj.label}
                              {...a11yProps(0)}
                              style={{
                                textTransform: 'none',
                              }}
                            />
                          ))}
                        </Tabs>
                      </Box>
                    </div>
                    <Grid item xs={12} className='impact_table_container'>
                      {tabsData.map((mainTab, index) => (
                        <>
                          <TabPanel value={tabIndex} index={index}>
                            {accordionData.map((obj) => (
                              <>
                                <div className='accordion_main_container'>
                                  <Accordion
                                    expanded={expanded === obj.label}
                                    onChange={handleChange(obj.label)}
                                    style={{
                                      borderLeft: '2px solid rgb(0, 85, 175)',
                                    }}
                                    className='in'
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls='panel1bh-content'
                                      id='panel1bh-header'
                                    >
                                      <Typography
                                        sx={{ width: '33%', flexShrink: 0 }}
                                      >
                                        {obj.label}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      {renderAccodianChild({
                                        label: obj.label,
                                        parentLabel: mainTab.label,
                                      })}
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                                <div className='spacer'></div>
                              </>
                            ))}
                          </TabPanel>
                        </>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </LoadingOverlay>
    </div>
  );
};

export default Decision;
