import React, { useEffect, useState } from 'react';
import { Table } from '../Table';
import { getMultipleModelsData } from '../../modules/InventorySmart/services/decisionDashboardService';
export default function GenericTable(props) {
  const { apiKey } = props;
  const [rowData, setRowData] = useState([]);
  async function getData(key) {
    const result = await getMultipleModelsData({
      model_names: [key],
    });
    return result[key];
  }
  useEffect(async () => {
    let data = [];
    if (apiKey) {
      data = await getData(apiKey);
    }
    setRowData(data);
  }, [apiKey]);

  return (
    <div style={{ padding: '0.75rem' ,...props?.style}}>
      {props?.title ? <h2 className='filter_heading'>{props.title}</h2> : null}
      <Table
        className='impact impact-forecast-alert-review-table'
        rowData={rowData}
        columnDefs={props.columnDefs}
        floatingFilter={false}
        height={props?.height||'300px'}
        rowSelection={'multiple'}
        tableCssStyle='sizeColumnsToFit'
      />
    </div>
  );
}
